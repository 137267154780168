import React, { useContext,useEffect} from 'react'
// import PostCards from '../Social Meadia/PostCards';
import '../../Style/SocialMedia.css'
import PostFilters from './PostFilters';
import PostCards from './PostCards';
import AuthContext from '../../Context/AuthProvider';
import { useNavigate } from 'react-router-dom';



function SocialPost() {
  const navigate = useNavigate()
  const {RedirectDinabitetool,DinabiteToken,dinabitePosts,fetchDinabitePosts} = useContext(AuthContext)
  useEffect(() => {
    if(DinabiteToken==="None"){
      navigate('/dashboard/client/socialmedia/home')
    }
    else{
      fetchDinabitePosts(DinabiteToken)
    }
  }, []);
  return (
    // <div className="p-main">
    <div className="p-inner" style={{ width: '100%', paddingBottom: '50px' }}>
      <div className="toppost">
        {/* <FaFacebook className='afaceicon' style={{ color: '#3b5998' }} /> */}
        <h3>Post</h3>
        <PostFilters />
      </div>
      <div className="post-crds">
        {dinabitePosts.map((post)=>{
            return <PostCards data={post} Btn='Boost' />
        })}
          
      </div>
    </div>

    // </div>
  )
}

export default SocialPost
