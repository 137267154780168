import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import BackBtn from "../../Components/BackBtn";
import CmsImgForm from "../../Components/CmsImgForm";
import CmsTable1 from "../../Components/CmsTable1";
// import comingsoon from '../../Images/comingsoon.png'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddBannerCMS from "../../Components/AddBannerCMS";
import CmsBodyimgCard from "../../Components/CmsBodyimgCard";

function CmsHome() {
  return (
    <>
      <ToastContainer />
      <div
        className="homHead w-100"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 0 20px 0",
        }}
      >
        <BackBtn />
        <h3 className="cmsHeading">Navbar</h3>
        <Form>
          <Form.Check type="switch" id="custom-switch" />
        </Form>
      </div>
      <div className="AboutDiv mb-4">
        <CmsTable1 />
      </div>
      <div className="BannerDiv mb-4">
        <div className="cmsDiv d-flex justify-content-between">
          <h3 className="cmsHeading text-start pt-3">Banner</h3>
        </div>
        <CmsImgForm />
      </div>

      <div className="BodyDiv mb-4">
        <div className="cmsDiv d-flex justify-content-between">
          <h3 className="cmsHeading text-start">Body Image</h3>
        </div>

        <CmsBodyimgCard />
      </div>
    </>
  );
}

export default CmsHome;
