import React from "react";
import Carousel from "react-material-ui-carousel";
import { useContext } from "react";
import AuthContext from "../Context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
const BookingEngineRoomCard = () => {
  const navigate = useNavigate();
  const { setRoomDetail, EngineNewUrl } = useContext(AuthContext);
  const { RoomsData, setRoomsData } = useContext(AuthContext);

  const EditRoomDetail = (data) => {
    setRoomDetail(data);
    navigate("/dashboard/client/bookingengine/editroom");
  };

  const deleteRoom = async (roomid) => {
    const token = localStorage.getItem("Token");
    const deleteroom = `${EngineNewUrl}/room/delete/${roomid}`;
    const postToken = {
      token: token,
      hId: localStorage.getItem("hotelLocationId"),
    };
    const headers = {
      Accept: "application/json, text/plain, /",
      "Content-Type": "application/json",
    };
    console.log("enter");
    try {
      const response = await fetch(deleteroom, {
        method: "POST",
        header: headers,
        body: JSON.stringify(postToken),
      });

      if (!response.ok) {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      } else {
        console.log(response.status);
        console.log("Before deletion:", RoomsData);
        const updatedRoomsData = [];

        for (let i = 0; i < RoomsData.length; i++) {
          if (RoomsData[i]["roomType"] !== roomid) {
            console.log("Enter");
            updatedRoomsData.push(RoomsData[i]);
          }
        }
        console.log("After deletion:", updatedRoomsData);
        setRoomsData(updatedRoomsData);
        console.log(RoomsData);
      }
    } catch (err) {
      console.log("Error deleting", err);
    }
  };

  return (
    <>
      {RoomsData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <div>
          <div
            className="roomcard"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "auto",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            {RoomsData.map((roomData, index) => (
              <div key={index}>
                <div
                  className="card mb-3"
                  style={{ width: "22rem", margin: "0 1rem" }}
                >
                  <div class="carousel-item active">
                    <Carousel>
                      {Array.isArray(roomData.roomImage) ? (
                        roomData.roomImage.map((image, imageIndex) => (
                          <div key={imageIndex} style={{ height: "12.5rem" }}>
                            <img
                              src={image}
                              class="d-block"
                              alt={`Room ${imageIndex}`}
                              style={{
                                width: "100%",
                                height: "12.5rem",
                                overflow: "hidden",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <div style={{ height: "12.5rem" }}>
                          <img
                            src={roomData.roomImage}
                            class="d-block"
                            alt={`Room  0`}
                            style={{
                              width: "100%",
                              height: "12.5rem",
                              overflow: "hidden",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      )}
                    </Carousel>
                  </div>

                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h5 className="card-title" style={{ cursor: "pointer" }}>
                        {roomData.roomName}
                      </h5>
                      <div>
                        <button
                          className="btn btn-warning"
                          style={{ fontSize: "20px" }}
                          onClick={() => EditRoomDetail(roomData)}
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => deleteRoom(roomData.roomType)}
                        >
                          <i class="fa fa-trash-o"></i>
                        </button>
                      </div>
                    </div>
                    <h5 className="">{roomData.roomSubheading}</h5>
                    <p className="card-text">{roomData.roomTypeName}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default BookingEngineRoomCard;
