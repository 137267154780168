import React, { useContext, useRef, useState } from "react";
import BackBtn from "../../Components/BackBtn";
// import CmsAboutjodit from '../../Components/CmsAboutjodit';
import JoditEditor from "jodit-react";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { FaPlus } from "react-icons/fa";
import { HashLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../../Context/AuthProvider.js";

function CmsAboutus() {
  const {
    EditPageTitle,
    setPagetitles,
    baseUrl,
    websiteAbout,
    setWebsiteAbout,
    joditConfig,
    EngineUrl,
    pagetitles,
    WebsiteData,
  } = useContext(AuthContext);
  const [heading, setHeading] = useState(websiteAbout.Heading);
  const [text, settext] = useState(websiteAbout.Text);
  const [Image, setImage] = useState(websiteAbout.url);
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    WebsiteData();
    setHeading(websiteAbout.heading);
    settext(websiteAbout.Text);
    setImage(websiteAbout.url);
  }, []);

  function uploadImage(e) {
    e.preventDefault();
    const imageInput = document.getElementById("imageFile");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3(base64String);
    };
    reader.readAsDataURL(file);
  }
  function handleChangeAboutUs(fieldName, value) {
    setWebsiteAbout({ ...websiteAbout, [fieldName]: value });
  }
  function UploadingImageS3(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        // setImage(data.Image);
        handleChangeAboutUs("url", data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  async function handleChangeimage(fieldName, value) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      About: { ...prevPagetitles.About, [fieldName]: value },
    }));
    console.log(value);
  }
  function uploadImagepagetitle(e) {
    e.preventDefault();
    const imageInput = document.getElementById("aboutfileimage");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3pagetitle(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3pagetitle(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        handleChangeimage("Image", data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function updateAboutUs() {
    toast.success("Updated Successfully");
    fetch(`${EngineUrl}/cms/edit/aboutus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        heading: websiteAbout.Heading,
        Text: websiteAbout.Text,
        url: websiteAbout.url,
        video_url: websiteAbout.video_url,
      }),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  async function handleChange(fieldName, e) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      About: { ...prevPagetitles.About, [fieldName]: e.target.value },
    }));
    console.log(e.target.value);
  }

  async function submitPagetitleSave() {
    setEditable(false);
    let obj = {
      key: "About",
      Title: pagetitles.About.Title,
      Description: pagetitles.About.Description,
      Image: pagetitles.About.Image,
      Video: pagetitles.About.Video,
    };
    // console.log(obj);
    const resp = await EditPageTitle(obj);
    if (resp === true) {
      toast.success("Edited Successfully");
    } else {
      toast.error("Updation Failed");
    }
    WebsiteData();
  }

  const editor = useRef(null);
  return (
    <>
      {websiteAbout === "None" || pagetitles === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          {" "}
          <ToastContainer />
          <div
            className="homHead w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 0 20px 0",
            }}
          >
            <BackBtn />
            <h3 className="cmsHeading">About Us</h3>
            <Form>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>
          <div style={{ position: "relative", width: "79vw", height: "50vh" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={pagetitles.About.Image}
              alt="Gallery Image"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              <>
                {editable === false ? (
                  <>
                    <div style={{ fontWeight: "bold", fontSize: "2.5em" }}>
                      {pagetitles.About.Title}
                    </div>
                    <div style={{ fontSize: "1.5em" }}>
                      {pagetitles.About.Description}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="boldInput">
                      <input
                        className="sassyInput boldInput"
                        value={pagetitles.About.Title}
                        onChange={(e) => handleChange("Title", e)}
                        placeholder="Enter Title"
                        style={{ width: "800px" }}
                      />
                    </div>
                    <div>
                      <input
                        className="sassyInput"
                        value={pagetitles.About.Description}
                        onChange={(e) => handleChange("Description", e)}
                        placeholder="Enter Description"
                        style={{ width: "800px" }}
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        id="aboutfileimage"
                        onChange={(e) => {
                          uploadImagepagetitle(e);
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
            <div
              style={{
                position: "absolute",
                top: "90%",
                left: "95%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              {editable === false ? (
                <div>
                  <button
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "4px",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditable(true)}
                  >
                    EDIT
                  </button>
                </div>
              ) : (
                <button
                  style={{
                    backgroundColor: "#28a745",
                    color: "#fff",
                    border: "1px solid #28a745",
                    borderRadius: "4px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => submitPagetitleSave()}
                >
                  SAVE
                </button>
              )}
            </div>
          </div>
          <div className="cmsAboutDiv my-4">
            <div className="cmsAboutForm">
              <div className="cmsAboutHeading">
                <label htmlFor="/">Heading:</label>
                <input
                  type="text"
                  className="cmsAboutHeadingInput"
                  value={websiteAbout.Heading}
                  onChange={(e) => {
                    handleChangeAboutUs("Heading", e.target.value);
                  }}
                  placeholder="Enter Heading Here"
                />
              </div>

              <div className="About_imgDiv">
                <div className="cmsImgInput">
                  <label htmlFor="/">Image:</label>
                  <button className="upload">
                    <span className="cmsupldspn">Upload Image</span>
                    <FaPlus className="cmsplusicon" />
                    <input
                      type="file"
                      id="imageFile"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={uploadImage}
                    />
                  </button>
                </div>
                <img src={websiteAbout.url} alt="" />
              </div>
            </div>

            {/* Rich Editore Start */}

            <div className="CmsAbout_joditEditoore">
              <label htmlFor="/">About Us:</label>
              <JoditEditor
                ref={editor}
                value={websiteAbout.Text}
                onChange={(content) => {
                  handleChangeAboutUs("Text", content);
                }}
                // config={joditConfig}
              />
              {/* <textarea value={text} onChange={(e)=>{settext(e.target.value)}}></textarea> */}
            </div>
            {/* <div className='w-100 d-flex justify-content-center my-3'>
          <button className='saveBtn btn' onClick={updateAboutUs}>Save</button>
        </div> */}

            {/* <div className="about_section my-4">
          <h4 className='text-center my-4'>House Rules</h4>
          <div className="abt_inputDiv">
            <label htmlFor="#">Check In Time:</label>
            <input type="text" placeholder='Enter Here' />
          </div>
          <div className="abt_inputDiv">
            <label htmlFor="#">Check Out Time:</label>
            <input type="text" placeholder='Enter Here' />
          </div>

          <div className="abt_inputDiv">
            <label htmlFor="#">Additional Information/Rules:</label>
            <input type="text" placeholder='Enter Here' />
          </div>

        </div> */}

            <div className="about_section my-4">
              <div className="w-100 d-flex justify-content-center my-3">
                <button className="saveBtn btn" onClick={updateAboutUs}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CmsAboutus;
