import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginSocialGoogle } from "reactjs-social-login";
import AuthContext from "../../Context/AuthProvider";
import "../../Style/UserManagement.css";

// import AuthContext from '../../Context/AuthProvider';

function UserManagement() {
  const {
    Spreadsheet,
    Sheets,
    Leads,
    AddSpreadSheetToDb,
    FetchSpreadSheetFromDb,
    sheetaccessToken,
    sheetid,
    EngineNewUrl,
    setsheetaccessToken,
    setsheetid,
    FetchSheetofSpreadSheet,
    updateAccessTokenDb,
    FetchSheetsDataofSpreadSheet,
    FetchAccessTokenFromDb,
    tokenExpire,
    settokenExpire,
    setLeads,
    sheetNamess,
    setsheetNamess,
  } = useContext(AuthContext);
  const [id, setid] = useState("");
  const [sheetName, setSheetName] = useState("");

  function getSheetId(input) {
    // Check if the input is a link
    const linkMatch = input.match(/\/spreadsheets\/d\/(.+?)\//);

    if (linkMatch && linkMatch[1]) {
      // If it's a link, extract sheet ID from the link
      // console.log(linkMatch[1])
      return linkMatch[1];
    } else {
      // If it's not a link, assume it's already a sheet ID
      // console.log(input)
      return input;
    }
  }
  const ChangeSpreadsheetFetchData = (spreadsheetid) => {
    setsheetid(spreadsheetid);
    FetchSheetofSpreadSheet(spreadsheetid, sheetaccessToken);
  };
  const ChangeSheetFetchData = (sheetname) => {
    const spreadid = document.getElementById("spreadsheet").value;
    setsheetNamess(sheetname);
    setsheetid(spreadid);
    FetchSheetsDataofSpreadSheet(spreadid, sheetname);
  };

  const AddSpreadSheet = () => {
    if (id == "" || sheetName == "") {
      toast.warning("Sheet Not Added");
    } else {
      const sheetuid = getSheetId(id);
      toast.success("Sheet Added");
      AddSpreadSheetToDb(sheetuid, sheetName);
      setSheetName("");
      setid("");
    }
  };

  const handleConnectGoogleTool = async (provider, data) => {
    try {
      // console.log(data)
      const tokenEndpoint = "https://oauth2.googleapis.com/token";
      const response = await fetch(tokenEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          code: data.code,
          client_id:
            "525278251391-u3n6cbllcscrb6havb5mudprm2ioqlnk.apps.googleusercontent.com",
          client_secret: "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",
          redirect_uri: window.location.origin,
          grant_type: "authorization_code",
        }),
      });

      const tokenData = await response.json();
      console.log("Token Data:", tokenData.access_token);

      setsheetaccessToken(tokenData.access_token);
      updateAccessTokenDb(tokenData.access_token);

      if (Spreadsheet.length != 0) {
        FetchSheetofSpreadSheet(Spreadsheet[0].id);
      }
    } catch (error) {
      console.error("Google login error:", error);
      updateAccessTokenDb("None");
    }
  };

  const reconnect = () => {
    setsheetaccessToken("None");
    updateAccessTokenDb("None");
    settokenExpire(true);
  };

  useEffect(() => {
    FetchAccessTokenFromDb();
    // FetchSpreadSheetFromDb()
  }, []);

  const [alphabet, setalphabet] = useState({
    0: "A",
    1: "B",
    2: "C",
    3: "D",
    4: "E",
    5: "F",
    6: "G",
    7: "H",
    8: "I",
    9: "J",
    10: "K",
    11: "L",
    12: "M",
    13: "N",
    14: "O",
    15: "P",
    16: "Q",
    17: "R",
    18: "S",
    19: "T",
    20: "U",
    21: "V",
    22: "W",
    23: "X",
    24: "Y",
    25: "Z",
  });

  const getColumn = (column, rowindex, columnindex, newContent) => {
    // alert(column)
    setLeads((prevData) => {
      const updatedState = [...prevData];
      updatedState[rowindex][columnindex] = newContent;
      return updatedState;
    });
    // alert(sheetid)
    // updateSheetData()
  };

  const updateSheetData = async () => {
    const sheet = sheetid;
    alert(sheet);
    try {
      const response = await fetch(
        `${EngineNewUrl}/leadmanagement/updateSheet/${localStorage.getItem(
          "Token"
        )}/${sheet}/${sheetNamess}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            rowstart: "1",
            rowend: String(Leads.length + 1),
            colstart: "A",
            colend: "Z",
            values: Leads,
          }),
        }
      );

      const json = await response.json();
      console.log(json);
      FetchSheetsDataofSpreadSheet(sheetid, sheetNamess);
    } catch {
      alert("Some Problem update token");
    }
  };

  return (
    <>
      <ToastContainer />

      <div className="usermanagement">
        <div className="d-flex justify-content-between align-item-center">
          {sheetaccessToken !== "None" ? (
            <div className="d-flex justify-content-start mb-4 ">
              <select
                className="btn"
                id="spreadsheet"
                onChange={(event) =>
                  ChangeSpreadsheetFetchData(event.target.value)
                }
              >
                {Spreadsheet.reverse().map((spread) => (
                  <option key={spread.id} value={spread.id}>
                    {spread.Name}
                  </option>
                ))}
              </select>

              <select
                className="btn"
                onChange={(event) => ChangeSheetFetchData(event.target.value)}
              >
                {Sheets.map((sheet) => (
                  <option
                    key={sheet.properties.title}
                    value={sheet.properties.title}
                  >
                    {sheet.properties.title}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            ""
          )}
          <div style={{ height: "auto" }}>
            {sheetaccessToken !== "None" ? (
              "Connected"
            ) : (
              <LoginSocialGoogle
                client_id="525278251391-u3n6cbllcscrb6havb5mudprm2ioqlnk.apps.googleusercontent.com"
                scope="https://www.googleapis.com/auth/spreadsheets"
                discoveryDocs="claims_supported"
                access_type="offline"
                onResolve={({ provider, data }) => {
                  handleConnectGoogleTool(provider, data);
                }}
                onReject={(err) => {
                  console.log("error");
                  console.log(err);
                }}
              >
                <button className="googleLogin">Connect google</button>
              </LoginSocialGoogle>
            )}

            {sheetaccessToken != "None" && tokenExpire ? (
              <button
                className=""
                onClick={() => {
                  reconnect();
                }}
              >
                Reconnect
              </button>
            ) : sheetaccessToken !== "None" && !tokenExpire ? (
              <button
                onClick={() => {
                  updateSheetData();
                }}
              >
                Update Sheet
              </button>
            ) : (
              ""
            )}
          </div>
          {sheetaccessToken !== "None" ? (
            <div className="d-flex justify-content-end mb-4 ">
              <input
                type="text"
                value={sheetName}
                onChange={(e) => {
                  setSheetName(e.target.value);
                }}
                placeholder="Sheet Name"
                className="mx-2"
                style={{ width: "200px" }}
              />
              <input
                type="text"
                value={id}
                onChange={(e) => {
                  setid(e.target.value);
                }}
                placeholder="Sheet Id"
                className="mx-2"
                style={{ width: "200px" }}
              />
              <button
                className="btn"
                onClick={() => {
                  AddSpreadSheet();
                }}
              >
                Add
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        {sheetaccessToken !== "None" ? (
          <div
            className=""
            style={{
              padding: "18px",
              overflowX: "auto",
              overflowY: "auto",
              whiteSpace: "nowrap",
            }}
          >
            <table className="usermanagementtable">
              {Leads.map((data, rowindex) => {
                if (rowindex === 0) {
                  return (
                    <thead>
                      <tr className="tablerow">
                        {data.map((headerLabel) => (
                          <th key={headerLabel}>{headerLabel}</th>
                        ))}
                      </tr>
                    </thead>
                  );
                } else {
                  return (
                    <tbody>
                      <tr>
                        {data.map((head, index) => (
                          <td>
                            <input
                              style={{ width: "400px", border: "none" }}
                              type="text"
                              value={head}
                              onChange={(e) => {
                                getColumn(
                                  `${
                                    alphabet[String(index)] + String(rowindex)
                                  }`,
                                  rowindex,
                                  index,
                                  e.target.value
                                );
                              }}
                            />
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  );
                }
              })}
            </table>
          </div>
        ) : (
          "Connect Google account for sheets access"
        )}
      </div>
    </>
  );
}

export default UserManagement;
