import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { FaPlus } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackBtn from "../../Components/BackBtn";
import AuthContext from "../../Context/AuthProvider";
import "../../Style/CmsGallery.css";
import { HashLoader } from "react-spinners";
function CmsGallery() {
  const {
    imgUrl,
    EditPageTitle,
    baseUrl,
    websiteGalleryData,
    WebsiteData,
    EngineUrl,
    pagetitles,
    setPagetitles,
  } = useContext(AuthContext);
  const [selectedCategory, setSelectedCategory] = useState("Events");
  const [Image, setImage] = useState("");
  function uploadImage(e) {
    e.preventDefault();
    const imageInput = document.getElementById("fileimg");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3(base64String);
    };
    reader.readAsDataURL(file);
  }
  async function handleChange(fieldName, e) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      Gallery: { ...prevPagetitles.Gallery, [fieldName]: e.target.value },
    }));
    console.log(e.target.value);
  }

  async function submitPagetitleSave() {
    setEditable(false);
    let obj = {
      key: "Gallery",
      Title: pagetitles.Gallery.Title,
      Description: pagetitles.Gallery.Description,
      Image: pagetitles.Gallery.Image,
      Video: pagetitles.Gallery.Video,
    };
    // console.log(obj);
    const resp = await EditPageTitle(obj);
    if (resp === true) {
      toast.success("Edited Successfully");
    } else {
      toast.error("Updation Failed");
    }
    WebsiteData();
  }

  function UploadingImageS3(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${baseUrl}/api/uploadImage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: window.localStorage.getItem("Token"),
        Image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setImage(data.Image);

        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const AddImage = () => {
    toast.success("Updated");
    fetch(`${EngineUrl}/cms/edit/Gallery/Images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        operation: "append",
        category: selectedCategory,
        imageurl: Image,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        WebsiteData();
        setImage("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const DeleteImage = (selectedCategory, Image) => {
    toast.success("Updated");
    fetch(`${EngineUrl}/cms/edit/Gallery/Images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        operation: "delete",
        category: selectedCategory,
        imageurl: Image,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        WebsiteData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  useEffect(() => {
    setSelectedCategory("Events");
  }, []);
  async function handleChangeimage(fieldName, value) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      Gallery: { ...prevPagetitles.Gallery, [fieldName]: value },
    }));
    console.log(value);
  }
  function uploadImagepagetitle(e) {
    e.preventDefault();
    const imageInput = document.getElementById("aboutfileimage");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3pagetitle(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3pagetitle(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${imgUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        handleChangeimage("Image", data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const [editable, setEditable] = useState(false);
  const getCategoryButtonClass = (category) => {
    return category === selectedCategory ? "active-category" : "";
  };

  return (
    <>
      {websiteGalleryData === "None" || pagetitles === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div
            className="homHead w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 0 20px 0",
            }}
          >
            <BackBtn />
            <ToastContainer />
            <h3 className="cmsHeading my-3">CMS Gallery</h3>

            <Form>
              <Form.Check type="switch" id="custom-switch" />
            </Form>
          </div>
          <div style={{ position: "relative", width: "79vw", height: "50vh" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={pagetitles.Gallery.Image}
              alt="Gallery Image"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              <>
                {editable === false ? (
                  <>
                    <div style={{ fontWeight: "bold", fontSize: "2.5em" }}>
                      {pagetitles.About.Title}
                    </div>
                    <div style={{ fontSize: "1.5em" }}>
                      {pagetitles.About.Description}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="boldInput">
                      <input
                        className="sassyInput boldInput"
                        value={pagetitles.Gallery.Title}
                        onChange={(e) => handleChange("Title", e)}
                        placeholder="Enter Title"
                      />
                    </div>
                    <div>
                      <input
                        className="sassyInput"
                        value={pagetitles.Gallery.Description}
                        onChange={(e) => handleChange("Description", e)}
                        placeholder="Enter Description"
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        id="aboutfileimage"
                        onChange={(e) => {
                          uploadImagepagetitle(e);
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
            <div
              style={{
                position: "absolute",
                top: "90%",
                left: "95%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              {editable === false ? (
                <div>
                  <button
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "4px",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditable(true)}
                  >
                    EDIT
                  </button>
                </div>
              ) : (
                <button
                  style={{
                    backgroundColor: "#28a745",
                    color: "#fff",
                    border: "1px solid #28a745",
                    borderRadius: "4px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => submitPagetitleSave()}
                >
                  SAVE
                </button>
              )}
            </div>
          </div>

          <div className="gallery">
            <ul className="navbar p-3">
              {websiteGalleryData.map((item, index) => (
                <li key={index}>
                  <button
                    onClick={() => handleCategoryClick(item.Category)}
                    className={getCategoryButtonClass(item.Category)} // Apply the class here
                  >
                    {item.Category}
                  </button>
                </li>
              ))}
            </ul>
            {/* <button onClick={AddImage}>Add</button>
      <div className='' style={{"width":"120px","height":"80px"}}>
          <button className="upload">
            <span className='cmsupldspn'>Upload Image <FaPlus className='cmsplusicon' /></span>
            <input type="file" id="file" onChange={uploadImage}  />
          </button>
          
      </div> */}
            <div className="cmForm">
              <div className="cmsForm_div">
                <div className="cmsForm_div cmsimgdiv">
                  <div className="CmsNearImglabel">
                    <label htmlFor="/">Image:</label>
                    <button className="upload">
                      <span className="cmsupldspn">Upload Image</span>
                      <FaPlus className="cmsplusicon" />
                      <input type="file" id="fileimg" onChange={uploadImage} />
                    </button>
                  </div>
                  <div className="upl_img">
                    {/* <input type="file" name="/" id="/" /> */}
                    <img src={Image} alt="" />
                    <button onClick={AddImage}>upload</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="image-container w-30">
              {selectedCategory && (
                <div className="image-gallery">
                  {/* <img src={Image} alt="to upload" /> */}

                  {websiteGalleryData
                    .find((item) => item.Category === selectedCategory)
                    .Images.map((image, index) => (
                      <div>
                        <img key={index} src={image} alt={selectedCategory} />
                        <button
                          key={index}
                          className=""
                          style={
                            {
                              // position: "relative",
                              // top: "50%",
                              // left: "50%",
                              // zIndex: 9999
                            }
                          }
                          onClick={() => {
                            DeleteImage(selectedCategory, image);
                          }}
                        >
                          delete
                        </button>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          {/* <div className='d-flex justify-content-end'>
      <button type='button' className='addbtn' >Add More</button>
    </div> */}
        </>
      )}
    </>
  );
}

export default CmsGallery;
