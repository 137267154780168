import React, { useContext, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import AuthContext from "../../../Context/AuthProvider";

const DateRange = () => {
  const { Sports, SetRangeValue } = useContext(AuthContext);

  const [selectedsport, setSelectedsport] = useState("None");
  const [startDate, setStartDate] = useState("None"); // State for start date
  const [endDate, setEndDate] = useState("None"); // State for end date
  const [selectedslot, setselecteslot] = useState({});
  
  const handleChange = async () => {
    // console.log(selectedsport);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(selectedslot);

    Object.keys(selectedslot).forEach((key) => {
      console.log(key);
    });
    let innerobj = {};
    const start = new Date(startDate);
    const end = new Date(endDate);

    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      innerobj[formattedDate] = selectedslot;
    }
    let outerobj = {};
    outerobj[selectedsport.sportId] = innerobj;
    // console.log(innerobj);
    // console.log(outerobj);
    SetRangeValue(outerobj);
    setSelectedsport("None")
    setStartDate("None")
    setEndDate("None")
    setselecteslot({})
    
  };
  const handleinputChange = (val, key, name) => {
    // console.log(val);
    // console.log(key);
    // console.log(name);
    setselecteslot((prev) => {
      const updatedSlot = { ...prev };

      updatedSlot[key] = {
        ...prev[key],
        [name]: val,
      };
      return updatedSlot;
    });
  };
  const handlesportChnage = (val) => {
    setSelectedsport(val);
    // console.log(selectedsport);
  };
  const handleStartDate = (val) => {
    setStartDate(val);
  };
  const handleEndDate = (val) => {
    setEndDate(val);
  };
  const handleCheck = (e, key, name) => {
    // console.log(key);
    // console.log(name);
    // console.log(e.target.checked);
    const isChecked = e.target.checked;

    setselecteslot((prev) => {
      const updatedSlot = { ...prev };

      if (isChecked) {
        updatedSlot[key] = {
          ...prev[key],
          [name]: "",
        };
      } else {
        delete updatedSlot[key][name];
      }

      return updatedSlot;
    });
  };

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    let formattedHours = parseInt(hours, 10);
    const ampm = formattedHours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    formattedHours = formattedHours % 12 || 12;

    return `${formattedHours}:${minutes} ${ampm}`;
}
  return (
    <div style={{ display: "block", width: "100%", padding: 30 }} >
      <h4>Date Range Update</h4>
      <div className="row" style={{width:"100%"}}>
            <div className="col-lg-3">
              <Dropdown>
              <Dropdown.Toggle variant="success">
                {selectedsport === "None" ? "Select sport" : selectedsport.sportName}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Sports.map((val, idx) => (
                  <Dropdown.Item key={idx} onClick={() => handlesportChnage(val)}>
                    {val.sportName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-lg-3">
              <div >
                <label style={{ marginRight: 10 }}>From: </label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => handleStartDate(e.target.value)}
                  style={{ marginBottom: 10 }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div >
            <label style={{ marginRight: 10 }}>To: </label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => handleEndDate(e.target.value)}
              
            />
              </div>
            </div>
            <div className="col-lg-3">
              <div>
                <button className="price_bulk_update_btn" onClick={() => handleChange()}>Submit</button>
              </div>
            </div>
            
      </div>
      {selectedsport !== "None" && selectedsport.slots && (
        <div>
          <>Slots</>
          <br />
          
          <div className="row" >
          {Object.entries(selectedsport.slots)
            .sort(([keyA, valueA], [keyB, valueB]) => new Date(valueA.start) - new Date(valueB.start))
            .map(([key, value], idx) => (
              <div className="col-sm-4" style={{ border: "1px solid grey", borderRadius: "5px", marginTop: "3px" }}>
                <label htmlFor={key}>{formatTime(selectedsport.slots[key].start)} - {formatTime(selectedsport.slots[key].end)}</label>
                {selectedsport !== "None" && selectedsport?.slots && (
                  <div>
                    {selectedsport?.turfs.map((val, idx) => (
                      <>
                        <input
                          type="checkbox"
                          onChange={(e) => handleCheck(e, key, val.name)}
                        />
                        <label htmlFor={val?.name}> {val?.name}</label>
                        {selectedslot[key] &&
                          selectedslot[key][val.name] !== undefined ? (
                            <input
                              height="10rem"
                              width="10rem"
                              value={selectedslot[key][val.name]}
                              onChange={(e) =>
                                handleinputChange(e.target.value, key, val.name)
                              }
                            />
                          ) : (
                            ""
                          )}

                        <br />
                      </>
                    ))}
                  </div>
                )}
              </div>
          ))}


          </div>
        </div>
      )}

      
    </div>
  );
};

export default DateRange;
