import React, { useContext } from "react";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import AuthContext from "../Context/AuthProvider";
import { HashLoader } from "react-spinners";

function CmsTable() {
  const { websiteNavbarData, setwebsiteNavbarData, EngineUrl } =
    useContext(AuthContext);

  const handleServiceToggle = (serviceName) => {
    toast.success("Navbar Updated Successfully");
    setwebsiteNavbarData({
      ...websiteNavbarData,
      [serviceName]: !websiteNavbarData[serviceName],
    });

    fetch(`${EngineUrl}/cms/edit/Navbar`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        key: serviceName,
        value: (!websiteNavbarData[serviceName]).toString(),
      }),
    })
      .then((response) => {
        response.json();
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      {websiteNavbarData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div className="cmsTable">
            <Table responsive="sm md lg xl">
              <thead>
                <tr>
                  <th>Services</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(websiteNavbarData).map(([service, status]) => (
                  <tr key={service}>
                    <td>{service}</td>
                    <td>
                      <label>
                        <input
                          type="checkbox"
                          checked={status}
                          onChange={() => handleServiceToggle(service)}
                        />
                        {status ? " Active" : " Inactive"}
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
}

export default CmsTable;
