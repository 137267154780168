import React, { useContext } from "react";
import AddUserPopUP from "../../../Components/AddUserSport";
import AuthContext from "../../../Context/AuthProvider";
import "../../../Style/UserManagement.css";

// import AuthContext from '../../Context/AuthProvider';

function UserManagement() {
  const {
    popup,
    setPopup,
    userData,
    setuserData,
    fetchUserManagementData,
    EngineNewUrl,
    isAdmin,
    EngineUrl,
    isEditpPop,
    setIsEditPop,
    setUserEditData,
    Users,
    setUsers,userAccess
  } = useContext(AuthContext);

  const GetAllUsers = async () => {
    try {
      const url = `${EngineUrl}/sports/${localStorage.getItem("Token")}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      console.log(json);
      if (json.Status === true) {
        setUsers(json.Details);
      }
    } catch (err) {
      console.error("Error fetching ", err);
    }
  };

  const deleteUserFromManagement = async (emailId) => {
    try {
      const response1 = await fetch(
        `${EngineNewUrl}/sports/deleteuser/${localStorage.getItem("Token")}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: emailId }), // Serialize the data as JSON
        }
      );
      const json1 = await response1.json();

      // alert(json1.Message)
      GetAllUsers();
    } catch (error) {
      alert("User Management API error");
    }
  };

  const handlePopUp = () => {
    setPopup(true);
  };

  const editUserData = (user) => {
    setIsEditPop(true);
    setUserEditData(user);
    // setIsEdit(true)
    // console.log(user)
  };
  return (
    <div className="usermanagement">
      <div className="d-flex justify-content-end mb-4 ">
        {userAccess.isAdmin?<button className="btn " onClick={handlePopUp}>
          + Add User
        </button>:""}
      </div>
      <table className="usermanagementtable">
        <thead>
          <tr className="tablerow">
            {["Name", "Email", "Admin", "Action"].map((headerLabel) => (
              <th key={headerLabel}>{headerLabel}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {Users.map((user) => (
            <tr>
              <td>{user.UserName}</td>
              <td>{user.Email}</td>
              {/* <td className='d-flex flex-column'>
                {Object.entries(user.accessScope).map(([key, value]) => (
                  value && <span className='badge bg-success m-1 w-auto' key={key}>{key}</span>
                ))}
              </td> */}
              <td>{user.isAdmin ? "Yes" : "No"}</td>
              <td>
                {/* {isAdmin ? <button className='btn me-2' onClick={() => { editUserData(user) }}>edit</button> : ""} */}
                {userAccess.isAdmin?user.isAdmin ? (
                  ""
                ) : (
                  <button
                    className="btn"
                    onClick={() => {
                      deleteUserFromManagement(user.Email);
                    }}
                  >
                    delete
                  </button>
                ):""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {popup ? (
        <AddUserPopUP setPopup={setPopup} GetAllUsers={GetAllUsers} />
      ) : (
        ""
      )}
      {/* {isEditpPop ? <EditUserMagPopup /> : ""} */}
    </div>
  );
}

export default UserManagement;
