import React, { useContext, useState } from 'react'
import "../../Style/UserManagement.css"
import AddUserPopUP from '../../Components/AddUserPopUP';
import AuthContext from '../../Context/AuthProvider';
import { useEffect } from 'react';
import EditUserMagPopup from '../../Components/EditUserMagPopup';

// import AuthContext from '../../Context/AuthProvider';


function UserManagement() {

  const { popup, setPopup, userData, setuserData, fetchUserManagementData,EngineNewUrl, isAdmin, EngineUrl, isEditpPop, setIsEditPop, setUserEditData } = useContext(AuthContext);


  useEffect(() => {

    fetchUserManagementData(); // Call the async function to initiate the request
  }, []);


  const deleteUserFromManagement = async (emailId) => {
    try {
      const response1 = await fetch(`${EngineNewUrl}/user/delete/${emailId}`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      });
      const json1 = await response1.json();


      // alert(json1.Message)
      fetchUserManagementData()

    }

    catch (error) {
      alert("User Management API error")
    }
  }



  const handlePopUp = () => {
    setPopup(true);
  }

  const editUserData = (user) => {
    setIsEditPop(true)
    setUserEditData(user)
    // setIsEdit(true)
    // console.log(user)
  }
  return (
    <div className='usermanagement'>
      <div className='d-flex justify-content-end mb-4 '>
        {isAdmin ? <button
          className='btn '
          onClick={handlePopUp}
        >
          + Add User
        </button> : ""}
      </div>
      <table className='usermanagementtable'>
        <thead>
          <tr className='tablerow'>
            {['Name', 'Email', 'Access Scope', 'Admin', 'Action'].map((headerLabel) => (
              <th key={headerLabel}>{headerLabel}</th>
            ))}

          </tr>
        </thead>

        <tbody>
          {userData.map((user) => (
            <tr>
              <td>{user.displayName}</td>
              <td>{user.emailId}</td>
              <td className='d-flex flex-column'>
                {Object.entries(user.accessScope).map(([key, value]) => (
                  value && <span className='badge bg-success m-1 w-auto' key={key}>{key}</span>
                ))}
              </td>
              <td>{user.isAdmin ? 'Yes' : 'No'}</td>
              <td>
                {isAdmin ? <button className='btn me-2' onClick={() => { editUserData(user) }}>edit</button> : ""}
                {isAdmin ? user.isAdmin ? "" : <button className="btn" onClick={() => { deleteUserFromManagement(user.emailId) }}>delete</button> : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {popup ? <AddUserPopUP setPopup={setPopup} /> : ""}
      {isEditpPop ? <EditUserMagPopup /> : ""}
    </div>
  )
}

export default UserManagement

