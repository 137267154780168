import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../Context/AuthProvider";
import "../../Style/BookingRoomPrice.css";
import Form from "react-bootstrap/Form";
import Priceupdatefilter from "../../Components/Priceupdatefilter";
import Inventoryupdatefilter from "../../Components/InventoryUpdate";
import { Table } from "react-bootstrap";

const ChannelManagerPage = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        Make My Trip
      </div>
      <div style={{
          display: "flex",
          justifyContent: "center",
          margin: "1rem",
        }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Channel Name</th>
              <th>Price On Extranet</th>
              <th>Final Price on axisrooms</th>
              <th>OTACommission</th>
              <th>More Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Make my trip</td>
              <td>Sell Rate</td>
              <td>Sell Rate </td>
              <td>OTA Commission</td>
              <td>Nope</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div style={{
          display: "flex",
          justifyContent: "center",
          margin: "1rem",
        }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>18 Jan 2024</th>
              <th>19 Jan 2024</th>
              <th>20 Jan 2024</th>

              <th>21 Jan 2024</th>
              <th>22 Jan 2024</th>
              <th>23 Jan 2024</th>

              <th>24 Jan 2024</th>
              <th>25 Jan 2024</th>
              <th>26 Jan 2024</th>
              <th>27 Jan 2024</th>
              <th>28 Jan 2024</th>
              <th>29 Jan 2024</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
            </tr>
            <tr>
              <td>Double</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
            </tr>
            <tr>
              <td>Triple</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
            </tr>
            <tr>
              <td>Quad</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>200</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ChannelManagerPage;
