import React, { useContext, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from '../../../Context/AuthProvider';
import "./style.css";
function Landing() {
    const {AllClients,EngineUrl} = useContext(AuthContext)
    const [Details,setDetails] = useState({"location":0,"users":0,"bookings":0,"blogs":0,"contactus":0,"templateid":"-","plan":"-","rooms":"-","payment":{"Type":"-"},"currency":"-","domain":"-","websitelink":"-","bookinglink":"-"})
    const ChangeAnalysis = async(domain) =>{
        try {
            const response = await fetch(`${EngineUrl}/admin/clients/getallanalysis/${localStorage.getItem("Token")}/${domain}`, {
              method: "GET",
              headers: {
                Accept: "application/json, text/plain, /",
                "Content-Type": "application/json",
              }
            });
      
            const json = await response.json();
            if(json.Status){
                toast.success(json.Message)
                setDetails(json.Details)
            }
            else{
                toast.warning(json.Message)
            }
          } catch {
            // alert("Some Problem update token");
          }
        };
   
    return (
    <div>
        <ToastContainer />
        <div >
            <div>
                <select name="cars" id="cars" onChange={(e)=>{ChangeAnalysis(e.target.value)}} className='selectfield'>
                    <option value="" disabled selected>---Select Domain---</option>
                    {AllClients.map((client)=>{
                        return <option value={client.domain} >{client.domain}</option>
                    })}
                </select>
            </div>
            <div className='row' style={{"justifyContent":"space-between"}} >
                <div className='col-lg-8 analysissmallbox'>
                    <div className='row'>
                        <div className='col-lg-5 analysiscountbox' >
                            Total Locations <span style={{float:"right"}}>{Details.location}</span>
                        </div>
                        <div className='col-lg-5 analysiscountbox' >Total users <span style={{float:"right"}}>{Details.users}</span></div>
                        <div className='col-lg-5 analysiscountbox' >Total bookings<span style={{float:"right"}}>{Details.bookings}</span></div>
                        <div className='col-lg-5 analysiscountbox' >Total Blogs<span style={{float:"right"}}>{Details.blogs}</span></div>
                        <div className='col-lg-5 analysiscountbox' >Total Contactus<span style={{float:"right"}}>{Details.contactus}</span></div>
                        <div className='col-lg-5 analysiscountbox' >Payment Mode<span style={{float:"right"}}>{Details.payment.Type}</span></div>
                        <div className='col-lg-5 analysiscountbox' >Template Id<span style={{float:"right"}}>{Details.templateid}</span></div>
                        <div className='col-lg-5 analysiscountbox' >Currency<span style={{float:"right"}}>{Details.currency}</span></div>
                        <div className='col-lg-5 analysiscountbox' >Plan Taken<span style={{float:"right"}}>{Details.plan}</span></div>
                        <div className='col-lg-5 analysiscountbox' >Rooms Category<span style={{float:"right"}}>{Details.rooms}</span></div>
                   
                    </div>
                </div>
                <div className='col-lg-4 analysissmallbox'>
                    <div className='row' style={{backgroundColor:"white",border:"1px solid black","textAlign":"center"}}>
                        <h5>INFORMATION</h5>
                    </div>
                    <div className='row'>
                            <p><strong>Domain</strong> <span style={{float:"right"}}>{Details.domain}</span></p>
                            <p><strong>Website</strong> <a href={Details.websitelink} target='_blank'><span style={{float:"right"}}>{Details.websitelink}</span></a></p>
                            <p><strong>Booking Engine</strong> <a href={Details.bookinglink} target='_blank'><span style={{float:"right"}}>{Details.bookinglink}</span></a></p>
                        </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Landing