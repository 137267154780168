import React, { useState } from "react";
import "../Style/SeoManagerCard.css";
import AddSlugPopup from "./AddSlugPopup";
import { useContext } from "react";
import AuthContext from "../Context/AuthProvider";

const SeoManagerCard = ({ title, description, keyword, button }) => {
  const { addSlugPopup, setAddSlugPopup } = useContext(AuthContext);

  const handleAddSlug = () => {
    setAddSlugPopup(!addSlugPopup);
  };
  return (
    <div>
      <div className="seomanagercard">
        {/* <h3 className='title'>{title}</h3> */}
        {/* <p className='description'>{description}</p> */}
        {/* <p className='keyword'>{keyword}</p> */}

        <button onClick={handleAddSlug}>{button}</button>
      </div>
    </div>
  );
};

export default SeoManagerCard;
