import React, { useContext, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import BookingEngineRoomCard from '../../Components/BookingEngineRoomCard';
import AuthContext from '../../Context/AuthProvider';

function BookingSetup() {

    const { baseUrl ,EngineNewUrl} = useContext(AuthContext)
    const [Image, setImage] = useState([]) //upload from S3 

    const { RoomsData, setRoomsData,fetchRoomsData} = useContext(AuthContext)
    const [roomImages, setRoomImages] = useState([]);  //upload from local
    const [roomType, setRoomType] = useState("1");
    const [roomName, setRoomName] = useState('');
    const [roomSubheading, setRoomSubheading] = useState('');
    const [roomDescription, setRoomDescription] = useState('');
    const { selectedFacilities, setSelectedFacilities } = useContext(AuthContext)
    const [child, setChild] = useState('');
    const [adult, setAdult] = useState('');
    const [noOfRooms, setNoOfRooms] = useState('');
    const [price, setPrice] = useState('');

    
    
    const get = `${EngineNewUrl}/room/${localStorage.getItem('Token')}/${localStorage.getItem('hotelLocationId')}`
    const post = `${EngineNewUrl}/room/create/${localStorage.getItem('Token')}`


    const facilitiesData = [
        "wifi",
        "television",
        "airConditonar",
        "hairdryers",
        "coffeeMakers",
        "directDial",
        "tableWithChair",
        "alarmclock",
        "electronicLocker",
        "fridge",
        "bathroomWithShower",
        "freeBreakfast",
        "kidEquipment",
        "Balcony",
        "Bath",
        "View",
        "FlatscreenTV",
        "Privatepool",
        "Electrickettle",
        "Spabath",
        "RoomAmenities",
        "Cots",
        "Clothesrack",
        "FoldupBed",
        "SofaBed",
        "Trashcans",
        "Heatedpool",
        "Infinitypool",
        "Plungepool",
        "Poolcover",
        "Pooltowels",
        "Rooftoppool",
        "Dressingroom",
        "Fan",
        "Fireplace",
        "Heating",
        "Iron",
        "Ironingfacilities",
        "Hottub",
        "Mosquitonet",
        "PrivateEntrance",
        "Sofa",
        "Soundproofing",
        "SeatingArea",
        "Pantspress",
        "Washingmachine",
        "Desk",
        "Hypoallergenic",
        "Cleaningproducts",
        "Pajamas",
        "Yukata",
        "Adapter",
        "Featherpillow",
        "Non_feather_pillow",
        "Bathroom",
        "Privatebathroom",
        "Shared_bathroom",
        "Toilet_paper",
        "Bidet",
        "Bath_shower",
        "Bathrobe",
        "Free_toiletries",
        "Additional_toilet",
        "Hairdryer",
        "Shared_toilet",
        "Sauna",
        "Shower",
        "Slippers",
        "Toilet",
        "Additional_bathroom",
        "Toothbrush",
        "Shampoo",
        "Conditioner",
        "Cd_player",
        "Dvd_player",
        "Fax",
        "Radio",
        "Satellitechannels",
        "Telephone",
        "Tv",
        "Smartphone",
        "Streamingservice_like_Netflix",
        "Dining_table",
        "Bottle_of_water",
        "Chocolate_or_cookies",
        "Fruits",
        "Barbecue",
        "Oven",
        "Stovetop",
        "Toaster",
        "Dishwasher",
        "Outdoor_furniture",
        "Minibar",
        "Kitchen",
        "Key_card_access",
        "Lockers",
        "Key_access",
        "Alarm_clock",
        "Wake_up_service",
        "Linen",
        "Blanket",
        "Extra_blankets",
        "Pillow",
        "Towels",
        "Patio",
        "City_view",
        "Garden_view",
        "Lake_view",
        "Landmark_view",
        "Mountain_view",
        "Pool_view",
        "River_view",
        "Sea_view",
        "Hearingaccessible",
        "Adaptedbath",
        "Raisedtoilet",
        "Loweredsink",
        "Showerchair",
        "Entertainment_family_services",
        "Baby_safety_gates",
        "Books",
        "DVDs",
        "Smokealarm",
        "Fire_extinguisher",
        "Safety_features",
        "Air_purifiers",
        "Physicaldistancing",
        "Handsanitiser"

    ];

    const headers = {
        Accept: "application/json, text/plain, /",
        'Content-Type': 'application/json'
    }

    

    // GET All room API
    useEffect(() => {
        
        fetchRoomsData()

    }, [])

    // Add Room API

    const handleSubmit = async (e) => {
        console.log(roomType)

        e.preventDefault();
        const newRoomData = {
            'roomType': roomType,
            "hId":localStorage.getItem('hotelLocationId'),
            'roomName': roomName,
            'roomSubheading': roomSubheading,
            'roomDescription': roomDescription,
            'facilities': selectedFacilities,
            'child': child,
            'adult': adult,
            'noOfRooms': noOfRooms,
            'price': price,
            'roomImage': Image
        };
        const responseRoomData = {
            "roomType": roomType,
            "hId":localStorage.getItem('hotelLocationId'),
            "roomName": roomName,
            "roomDescription": roomDescription,
            "child": child,
            "adult": adult,
            "noOfRooms": noOfRooms,
            "price": price,
            "roomImage": Image,
            "roomFacilities": selectedFacilities,
            "roomSubheading": roomSubheading,
            "isWeekendFormat":"false",
            "changedPrice":{
                "weekend":price,
                "weekday":price
            }
        }

        try {

            const response = await fetch(post, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(responseRoomData)
            })

            if (!response.ok) {
                console.error(`Error: ${response.status} - ${response.statusText}`);
            }
            else {
                const d = await response.json();
                console.log(d)
                if (response.status) {
                    fetchRoomsData()
                }
                else {
                    console.log("roomdata has successfully set")

                    setRoomsData([...RoomsData, newRoomData]);

                }
            }

        } catch (error) {
            console.log('Error', error);
        }
        setRoomType("")
        setRoomName("")
        setRoomSubheading("")
        setRoomDescription("")
        setSelectedFacilities([])
        setChild("")
        setAdult("")
        setImage([])
        setNoOfRooms("")
        setPrice("")

    };

    const handleImageChange = (e) => {
        const files = e.target.files;

        if (files.length > 0) {
            const newRoomImages = [];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const imageUrl = URL.createObjectURL(file);

                newRoomImages.push(imageUrl);
            }
            setRoomImages(newRoomImages);
        }
    };

    // const handleFacilitiesChange = (e) => {
    //     const inputValue = e.target.value;

    //     if (inputValue) {
    //         const facilitiesArray = inputValue.split(',').map((facility) => facility.trim());
    //         setFacilities(facilitiesArray);
    //     } else {
    //         setFacilities([]);
    //     }
    // };

    const handleCheckboxChange = (facilityId) => {
        // if selected then unselect
        if (selectedFacilities.includes(facilityId)) {
            setSelectedFacilities(selectedFacilities.filter((id) => id !== facilityId))
        }
        // if not selected then select it
        else {
            setSelectedFacilities([...selectedFacilities, facilityId])
        }
    }

    // console.log(selectedFacilities)

    function uploadImage(e) {
        e.preventDefault();
        const imageInput = document.getElementById('fileimg');
        const files = imageInput.files;

        if (files.length === 0) {
            alert('Please select at least one image file.');
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const reader = new FileReader();
            reader.onloadend = function () {
                const base64String = reader.result.split(',')[1];
                UploadingImageS3(base64String);
            };

            reader.readAsDataURL(file);
        }
    }

    function UploadingImageS3(base64String) {
        fetch(`${EngineNewUrl}/upload/file/image`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: window.localStorage.getItem('Token'), image: base64String }),
        })
            .then(response => response.json())
            .then(data => {
                setImage(prevImages => prevImages.concat(data.Image));

                document.getElementById('fileimg').value = null;
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }


    return (
        <div className='cms_booking_setup'>
            <div className='d-flex justify-content-between my-3'>
                <h5>Step 1</h5>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row setup_content">
                    <div className='first col-md-6'>
                        <div className='BookingSetup_div'>
                            <label htmlFor="/">Room Type:</label>
                            <select
                                id="roomType"
                                value={roomType}
                                onChange={(e) => { setRoomType(e.target.value) }}
                                style={{
                                    width: "60%",
                                    borderRadius: "5px",
                                    border: "1px solid #eceaea",
                                    padding: "10px 15px"
                                }}
                            >
                                <option value="1">Deluxe</option>
                                <option value="2">Super Deluxe</option>
                                <option value="3">Suite</option>
                                <option value="4">Premium</option>
                                <option value="5">Premiere Retreat</option>
                                <option value="6">Elite Suite</option>
                                <option value="7">Grand Deluxe</option>
                                <option value="8">Imperial Suite</option>
                                <option value="9">Supreme Retreat</option>
                                <option value="10">Royal Deluxe</option>
                                <option value="11">Prestige Suite</option>
                                <option value="12">Exclusive Retreat</option>
                            </select>
                        </div>
                        <div className='BookingSetup_div'>
                            <label htmlFor="/">Room Name:</label>
                            <input
                                type="text"
                                id="roomName"
                                placeholder="Room Name"
                                value={roomName}
                                onChange={(e) => setRoomName(e.target.value)}
                            />
                        </div>
                        <div className='BookingSetup_div'>
                            <label htmlFor="/">Room Subheading:</label>
                            <input
                                type="text"
                                id="roomSubheading"
                                placeholder="Room Subheading"
                                value={roomSubheading}
                                onChange={(e) => setRoomSubheading(e.target.value)}
                            />
                        </div>
                        <div className='BookingSetup_div'>
                            <label htmlFor="/">Room Description:</label>
                            <textarea textarea name="w3review" rows="1"
                                style={{
                                    width: "60%",
                                    borderRadius: "5px",
                                    border: "1px solid #eceaea",
                                    padding: "10px 15px"
                                }}
                                type="textarea"
                                id="roomDescription"
                                placeholder="Room Description"
                                value={roomDescription}
                                onChange={(e) => setRoomDescription(e.target.value)}
                            />
                        </div>
                        <div className='BookingSetup_div'>
                            <label htmlFor="/">Choose Facilities :</label>

                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Facilities
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a class="dropdown-item">

                                        <div class="scrollable-content">
                                            {facilitiesData.map((facility) => (
                                                <div className='form-check row w-100' >
                                                    <input
                                                        type="checkbox"
                                                        className='form-check-input col-md-1'
                                                        checked={selectedFacilities.includes(facility)}
                                                        onChange={() => handleCheckboxChange(facility)}
                                                    />
                                                    <label className='form-check-label col-md-10 '>
                                                        {facility}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='second col-md-6'>

                        <div className='BookingSetup_div'>
                            <label htmlFor="/">Child:</label>
                            <input
                                type="number"
                                id="child"
                                placeholder="Child"
                                value={child}
                                onChange={(e) => setChild(e.target.value)}
                            />
                        </div>
                        <div className='BookingSetup_div'>
                            <label htmlFor="/">Adult:</label>
                            <input
                                type="number"
                                id="adult"
                                placeholder="Adult"
                                value={adult}
                                onChange={(e) => setAdult(e.target.value)}
                            />
                        </div>
                        <div className='BookingSetup_div'>
                            <label htmlFor="/">No of Rooms:</label>
                            <input
                                type="number"
                                id="noOfRooms"
                                placeholder="No Of Rooms"
                                value={noOfRooms}
                                onChange={(e) => setNoOfRooms(e.target.value)}
                            />
                        </div>
                        <div className='BookingSetup_div'>
                            <label htmlFor="/">Price:</label>
                            <input
                                type="number"
                                id="price"
                                placeholder="Price"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>


                    </div>
                </div>

                <div className='cmsForm_div'>
                    <div className='cmsForm_div cmsimgdiv'>
                        <div className='CmsNearImglabel'>
                            <label htmlFor="/">Image:</label>
                            <button className="upload">
                                <span className='cmsupldspn'>Upload Image</span>
                                <FaPlus className='cmsplusicon' />
                                <input type="file" id="fileimg" onChange={uploadImage} multiple/>
                            </button>
                        </div>
                        <div className='upl_img'>
                            {Image.map((img)=>{
                                return <img src={img} alt="" />
                            })}
                        </div>

                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <button type="submit" className='addbtn mt-4'>Add Room</button>

                </div>
            </form>
            {/* <SaveBtn /> */}
            <BookingEngineRoomCard />
        </div >
    )
}

export default BookingSetup
