import React from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import comingsoon from '../Images/comingsoon.png'
import { useContext } from 'react';
import AuthContext from '../Context/AuthProvider';

function CmsRestaurantCard({ Image, Name, Price,Description }) {
  const { baseUrl, websiteRestaurantData, setwebsiteRestaurantData } = useContext(AuthContext)
  const DeleteDish = () => {
    for (let i = 0; i < websiteRestaurantData.length; i++) {
      if (Name === websiteRestaurantData[i].Name && Price === websiteRestaurantData[i].Price) {
        websiteRestaurantData.splice(i, 1);
        break;
      } else {
        continue;
      }
    };
    setwebsiteRestaurantData([...websiteRestaurantData]);
    fetch(`${baseUrl}/api/dashboard/editMenu`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
        operation: "delete",
        image: Image,
        name: Name,
        price: Price,
        Description:Description
      }),
    })
      .then(response => response.json())
      .then(data => {
        alert("Deleted")

      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
  return (
    <>
    
    <Card style={{ width: '16rem' }}>
      <Card.Img variant="top" src={Image} />
      <Card.Body>
        <Card.Title>{Name}</Card.Title>
        <Card.Text>{Price}
        </Card.Text>
        <Card.Text>{Description}</Card.Text>
        <Button className='btn-danger' variant="delete" onClick={DeleteDish}>Delete</Button>
      </Card.Body>
    </Card>
    </>
      
  )
}

export default CmsRestaurantCard
