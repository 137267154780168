import React from 'react'
import FrontDeskTable from '../../Components/FrontDeskTable'

function FrontDesk() {


  return (
    <FrontDeskTable />
  )
}

export default FrontDesk
