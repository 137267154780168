import React from "react";
import "../../Style/ChannelManager.css";
// import Card from '../components/CardUI';
import Untitled from "../../Images/ChennalManagerImg/makemytripT.png";
import Untitled1 from "../../Images/ChennalManagerImg/goibiboT.png";
import Untitled2 from "../../Images/ChennalManagerImg/AirbnbT.png";
import Untitled3 from "../../Images/ChennalManagerImg/thrillophiliaT.png";
import Untitled4 from "../../Images/ChennalManagerImg/Bookingcom.png";
import Untitled5 from "../../Images/ChennalManagerImg/trivagoT.65ec6594-97b9-4885-b551-4aabd2598aa7";
import Untitled6 from "../../Images/ChennalManagerImg/Expedia_LogoT.png";
import Untitled7 from "../../Images/ChennalManagerImg/agodaT.png";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";

import Form from "react-bootstrap/Form";

const ChannelManager = ({ isOpen }) => {
  return (
    <>
      <div className="channelmanager container">
        <div style={{ width: "100%" }}>
          <div className="main">
            <div
              className="m-inner col-md-3"
              style={{ border: "1px solid #ccc;" }}
            >
              <div>
                <span>hotel id-</span>
                <span className="h-id">15332</span>
              </div>
              <div className="ed-btn">
                <Link className="edit">Edit</Link>
                <Link className="discnt">Disconnect</Link>
              </div>
            </div>
            <div className="m-inner col-md-3">
              <span>room types</span>

              <div className="ed-btn">
                <Link className="edit">Edit</Link>
                <Link className="discnt">Disconnect</Link>
              </div>
            </div>
            <div className="m-inner col-md-3">
              <span>room rates</span>

              <div className="ed-btn">
                <Link className="edit">Edit</Link>
                <Link className="discnt">Disconnect</Link>
              </div>
            </div>
            <div className="m-inner col-md-3">
              <Link className="add-chnl">add new channel+</Link>
            </div>
          </div>

          <div className="c-cards">
            <Link to="/dashboard/client/channelmanager/makemytrip">
              <Card className="c-crd-inr">
                <Card.Img
                  className="c-img"
                  variant="top"
                  src={Untitled}
                  title="Make My Trip"
                />
                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </Form>
              </Card>
            </Link>
            <Link to="/dashboard/client/channelmanager/goibibo">
              <Card className="c-crd-inr">
                <Card.Img
                  className="c-img"
                  variant="top"
                  src={Untitled1}
                  title="Goibibo"
                />
                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </Form>
              </Card>
            </Link>
            <Link to="/dashboard/client/channelmanager/airbnb">
              <Card className="c-crd-inr">
                <Card.Img
                  className="c-img"
                  variant="top"
                  src={Untitled2}
                  title="Airbnb"
                />
                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </Form>
              </Card>
            </Link>
            <Link to="/dashboard/client/channelmanager/thrillophilia">
              <Card className="c-crd-inr">
                <Card.Img
                  className="c-img"
                  variant="top"
                  src={Untitled3}
                  title="Thrillophilia.com "
                />
                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </Form>
              </Card>
            </Link>
            <Link to="/dashboard/client/channelmanager/booking">
              <Card className="c-crd-inr">
                <Card.Img
                  className="c-img"
                  variant="top"
                  src={Untitled4}
                  title="Booking.com "
                />
                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </Form>
              </Card>
            </Link>
            <Link to="/dashboard/client/channelmanager/trivago"> 
              <Card className="c-crd-inr">
                <Card.Img
                  className="c-img"
                  variant="top"
                  src={Untitled5}
                  title="Trivago.com "
                />
                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </Form>
              </Card>
            </Link>
            <Link to="/dashboard/client/channelmanager/expedia">
              <Card className="c-crd-inr">
                <Card.Img
                  className="c-img"
                  variant="top"
                  src={Untitled6}
                  title="Expedia.com "
                />
                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </Form>
              </Card>
            </Link>
            <Link to="/dashboard/client/channelmanager/agoda">
              <Card className="c-crd-inr">
                <Card.Img
                  className="c-img"
                  variant="top"
                  src={Untitled7}
                  title="Agoda.com "
                />
                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </Form>
              </Card>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChannelManager;
