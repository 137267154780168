import JoditEditor from "jodit-react";
import React, { useContext, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import AuthContext from "../Context/AuthProvider";
import { HashLoader } from "react-spinners";
function CmsImgForm() {
  const editor = useRef(null);
  const {
    baseUrl,
    WebsiteData,
    EngineUrl,
    websiteBannerData,
    setwebsiteBannerData,
    joditConfig,
  } = useContext(AuthContext);
  const [Image, setImage] = useState();
  const [subheading, setSubheading] = useState();
  const [heading, setheading] = useState();
  const [video, setvideo] = useState();

  function uploadImage(e) {
    e.preventDefault();
    const imageInput = document.getElementById("file");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setImage(data.Image);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const DeleteBanner = (heading, subheading, image, video, index) => {
    fetch(`${EngineUrl}/cms/operation/Banner`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        operation: "delete",
        Heading: heading,
        subheading: subheading,
        url: image,
        video: video,
        index: index,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Deleted");
        WebsiteData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  function uploadImagepage(e, index) {
    e.preventDefault();
    const imageInput = document.getElementById(`imageFile_${index}`); // Corrected ID
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3Banner(base64String, index);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3Banner(base64String, index) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setwebsiteBannerData((prevData) =>
          prevData.map((val, idx) =>
            idx === index ? { ...val, url: data.Image } : val
          )
        );
        console.log(websiteBannerData[index]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  function handleChange(index, e, fieldName) {
    setwebsiteBannerData((prevData) =>
      prevData.map((val, idx) =>
        idx === index ? { ...val, [fieldName]: e.target.value } : val
      )
    );
  }
  async function EditBanner(index) {
    try {
      let obj = {
        token: localStorage.getItem("Token"),
        removeindex: index,
        editImage: websiteBannerData[index].url,
        edittext: websiteBannerData[index].Heading,
        editsubhead: websiteBannerData[index].Subhead,
        editvideo: websiteBannerData[index].video,
      };
      const url = `${EngineUrl}/cms/edit/Banner`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const resp = await response.json();
      console.log(resp);
      if (resp.Status === true) {
        toast.success("Edited Successfully");
      } else {
        toast.error("Edited Some Error Ocurred");
      }
    } catch {
      toast.error("Edited Some Error Ocurred");
    }
  }
  const AddBanner = () => {
    setwebsiteBannerData(
      websiteBannerData.concat({
        Heading: heading,
        Subhead: subheading,
        url: Image,
        video: video,
      })
    );
    fetch(`${EngineUrl}/cms/operation/Banner`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        operation: "append",
        Heading: heading,
        subheading: subheading,
        url: Image,
        video: "video.mp4",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Added Banner");
        setImage("");
        setSubheading("");
        setheading("");
        setvideo("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      {websiteBannerData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          {" "}
          <div className="cmForm">
            <div className="cmsForm_div">
              <label htmlFor="/">Heading:</label>
              <JoditEditor
                ref={editor}
                value={heading}
                onChange={(content) => {
                  setheading(content);
                }}
                config={joditConfig}
              />
            </div>

            <div className="cmsForm_div">
              <label htmlFor="/">Subheading:</label>
              <input
                className="formInput"
                type="text"
                value={subheading}
                onChange={(e) => {
                  setSubheading(e.target.value);
                }}
                placeholder="Enter Heading Here"
              />
            </div>

            <div className="cmsForm_div cmsimgdiv">
              <div className="CmsNearImglabel">
                <label htmlFor="/">Image:</label>
                <button className="upload">
                  <span className="cmsupldspn">Upload Image</span>
                  <FaPlus className="cmsplusicon" />
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => uploadImage(e)}
                  />
                </button>
              </div>
              <div className="upl_img">
                {/* <input type="file" name="/" id="/" /> */}
                <img src={Image} alt="" />
              </div>
            </div>

            {/* <div className="preview_img_div">
                  <img src={comingsoon} alt="" />
              </div> */}
          </div>
          <div className="cmsForm_Btn">
            <Button className="saveBtn addBtn" onClick={AddBanner}>
              Add
            </Button>
          </div>
          <div className="imgDiv CmsBanner">
            {/* <img src={comingsoon} alt="" /> */}
            <div className="carousal_div">
              <Carousel>
                {websiteBannerData.map((banner, index) => {
                  return (
                    <Carousel.Item interval={1000}>
                      {/* <ExampleCarouselImage text="First slide" /> */}

                      <img src={banner.url} alt="" />
                      <Carousel.Caption>
                        <button
                          className="btn btn-warning"
                          onClick={() => {
                            EditBanner(index);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            DeleteBanner(
                              banner.Heading,
                              banner.Subhead,
                              banner.video,
                              banner.url,
                              index
                            );
                          }}
                        >
                          delete
                        </button>
                        <label
                          htmlFor={`imageFile_${index}`}
                          className="upload"
                          style={{ width: "5rem", marginLeft: "27rem" }}
                        >
                          {" "}
                          <span className="cmsupldspn">Upload Image</span>
                          <input
                            type="file"
                            id={`imageFile_${index}`}
                            accept="image/png, image/gif, image/jpeg"
                            style={{ display: "none" }}
                            onChange={(e) => uploadImagepage(e, index)}
                          />
                        </label>
                        <div>
                          <input
                            className="highlightInput"
                            value={banner.Heading}
                            onChange={(e) => handleChange(index, e, "Heading")}
                            placeholder="Enter Place"
                          />
                        </div>
                        <div>
                          <input
                            className="highlightInput"
                            value={banner.Subhead}
                            onChange={(e) => handleChange(index, e, "Subhead")}
                            placeholder="Enter Description"
                          />
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CmsImgForm;
