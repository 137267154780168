import React, { useContext } from 'react'
import "../Style/AddUserPopUp.css"
import { GrClose } from 'react-icons/gr'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa';


import AuthContext from "../Context/AuthProvider.js"

const AddUserPopUP = ({ seteditClick, bannerindex, banneredit }) => {
    const handleClickModel = () => {
        seteditClick(false)
    }
    const [Name, setName] = useState(banneredit.Name)
    const [Email, setEmail] = useState(banneredit.Email)
    const [Phone, setPhone] = useState(banneredit.Contact)
    const [Subject, setSubject] = useState(banneredit.Subject)
    const [Message, setMessage] = useState(banneredit.Message)
    const { EngineUrl, fetchUserManagementData, baseUrl, WebsiteData } = useContext(AuthContext)



    


    return (
        <div className='Addpopup'>


            <div className='userform shadow-lg p-3 mb-5 bg-white rounded'>
                <div className='heading'>
                    <h3></h3>
                    <GrClose size={25} style={{ color: '#0A3A75' }} onClick={handleClickModel} className='closebooking' />
                </div>
                <div className='row w-100' >
                    <div className='col-md-12'>
                        <label>Name</label>
                        <input type='phone' className='fromdata' value={Name}  placeholder='Name' required />

                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-12'>
                        <label>Email</label>
                        <input type='phone' className='fromdata' value={Email}  placeholder='Email' required />

                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-12'>
                        <label>Phone</label>
                        <input type='phone' className='fromdata' value={Phone} placeholder='Phone' required />

                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-12'>
                        <label>Subject</label>
                        <input type='phone' className='fromdata' value={Subject}  placeholder='Subject' required />

                    </div>
                </div>

                <div className='row w-100' >
                    <div className='col-md-12'>
                        <label>Message</label>
                        <input type='phone' className='fromdata' value={Message}  placeholder='Message' required />

                    </div>
                </div>

                <div className='row w-100' >
                    <div className='col-md-12'>
                        <label>Reply</label>
                        <input type='phone' className='fromdata'   placeholder='Reply' required />

                    </div>
                </div>

                <div className='row w-100'>
                    <div className='col-md-12'>
                        <button className='confirmUser'>Send Reply</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUserPopUP