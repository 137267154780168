import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import BackBtn from "../../Components/BackBtn";
import AuthContext from "../../Context/AuthProvider";
import { HashLoader } from "react-spinners";
function CmsContact() {
  const {
    Queries,
    setQueries,
    Contacts,
    setContacts,
    Newsletter,
    setNewsletter,
    DigitalCheckin,
    setDigitalCheckin,
    WebsiteData,
  } = useContext(AuthContext);
  return (
    <>
      {Newsletter === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div
            className="homHead w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 0 20px 0",
            }}
          >
            <BackBtn />
            <h3 className="text-center">Newsletter</h3>
            <Form>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                onChange={() => {
                  WebsiteData();
                }}
              />
            </Form>
          </div>

          <div className="Contact_table my-4">
            <div className="CmsCOntactTAble">
              <Table striped bordered hover variant="light">
                <thead>
                  <tr>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {Newsletter.reverse().map((contact) => {
                    return (
                      <tr>
                        <td>{contact.email}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CmsContact;
