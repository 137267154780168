import React from "react";
import Button from "react-bootstrap/Button";
import { ArrowLeft } from "react-bootstrap-icons";
import BackBtn from "../../Components/BackBtn";

function CmsMenu() {
  return (
    <>
      <BackBtn />
      <div>
        <ul className="nav nav-pills flex-column">hiii</ul>
      </div>
    </>
  );
}

export default CmsMenu;
