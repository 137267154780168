import React from 'react'

function SocialMedia() {
    return (
        <div>
    <h2 className='text-center'>Social Media</h2>
    </div >
    )
}

export default SocialMedia
