import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../Context/AuthProvider';
import AddadminPopup from "./AddadminPopup";

function Landingadmins() {
    const {EngineUrl,AllAdmins,setAllAdmins,
      AdminsCount,setAdminsCount,FetchAllAdmins} = useContext(AuthContext)

    const [addPopup,setaddPopup] = useState(false)

    

    const deleteAdmin = async (emailId)=>{
        try {
            const url = `${EngineUrl}/admin/delete/admin`;
            const response = await fetch(url, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(
                {
                    "token":localStorage.getItem("Token"),
                    "emailId":emailId
                }
            ),
            });
            const json = await response.json();
            if (json.Status === true) {
                FetchAllAdmins()
            }
          } catch (err) {
            console.error("Error fetching WATI credentials: ", err);
          }
    }

    useEffect(()=>{
        FetchAllAdmins()
    },[])
  return (
    <div className='usermanagement'>
      <div className='d-flex justify-content-end mb-4 '>
       <button
          className='btn '
          onClick={()=>{setaddPopup(true)}}
        >
          + Add User
        </button>
      </div>
      <table className='usermanagementtable'>
        <thead>
          <tr className='tablerow'>
            {['Name', 'Email', 'Access Scope',"Team",'Action'].map((headerLabel) => (
              <th key={headerLabel}>{headerLabel}</th>
            ))}

          </tr>
        </thead>

        <tbody>
          {AllAdmins.map((user) => (
            <tr>
              <td>{user.username}</td>
              <td>{user.emailId}</td>
              <td className='d-flex flex-column'>
                {Object.entries(user.accessScope).map(([key, value]) => (
                  value && <span className='badge bg-success m-1 w-auto' key={key}>{key}</span>
                ))}
              </td>
              <td>{user.team}</td>
              <td>
                {/* {isAdmin ? <button className='btn me-2' onClick={() => { editUserData(user) }}>edit</button> : ""} */}
                {user.owner ? "":<button className="btn" onClick={()=>{deleteAdmin(user.emailId)}} >delete</button> }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    {addPopup?<AddadminPopup setaddPopup={setaddPopup} FetchAllAdmins={FetchAllAdmins} />:""}
    </div>
  )
}

export default Landingadmins