import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { IoMdAdd } from "react-icons/io";
import { RiSubtractFill } from "react-icons/ri";
import useRazorpay from "react-razorpay";
import AuthContext from "../../../Context/AuthProvider";
import "../../../Style/sportDesk.css";

function Frontdesktble() {
    const {EngineUrl} = useContext(AuthContext)
    const [sportsData, setSportsData] = useState([])

    const [date, setDate] = useState(new Date())
    const [selectedSportName, setselectedSportName] = useState("")
    const [selectedSportId, setSelectedSportId] = useState(null);
    const [selectedSlotName, setselectedSlotName] = useState()
    const [selectedSlotStart, setselectedSlotStart] = useState()
    const [selectedSlotEnd, setselectedSlotEnd] = useState()
    const [selectedSlottId, setSelectedSlotId] = useState(null);
    const [price, setPrice] = useState(null);
    const [turfName, setTurfName] = useState(null);
    const [counter, setCounter] = useState(1);
    const [AuthFlowOtp, setAuthFlowOtp] = useState(false)
    const [Profile, setProfile] = useState({})
    const [SlotsCounterData, setSlotsCounterData] = useState({})
    const minDate = new Date();
    const Url = EngineUrl
    const [TotalPrice, setTotalPrice] = useState(0)
    const [OrderId, setOrderId] = useState("None")
    const [Razorpay, createOrder] = useRazorpay();
    const [bookingDone, setbookingDone] = useState(false)
    const [successBookingDetails, setsuccessBookingDetails] = useState("None");
    const [availableSlot, setAvailableSlot] = useState(null);
    const [maxValueCounter, setmaxValueCounter] = useState(1)
    const [openInitialPopup, setopenInitialPopup] = useState(false)
    
    const websiteData = async (curr) => {

        // console.log(date)
        const host = `${Url}/sports/getSlotsForDate/779f43ea-5ed0-43ee-86fb-c7d296a769cf/${curr}`

        try {
            const response = await axios.get(host);
            setSportsData(response.data.Data);
            setselectedSportName(response.data.Data[0].sportName)
            setSelectedSportId(response.data.Data[0].sportId)
            setselectedSlotName(response.data.Data[0].slotData[0].slotName)
            setselectedSlotStart(response.data.Data[0].slotData[0].slotStart)
            setselectedSlotEnd(response.data.Data[0].slotData[0].slotEnd)
            setSelectedSlotId(`${response.data.Data[0].slotData[0].slotStart}-${response.data.Data[0].slotData[0].slotEnd}`)
            getCounterMaxProbablity(response.data.Data[0].sportId, date)

        } catch (error) {
            console.log("Error:", error)
        }
    }
    function getMaxValue(obj) {
        const values = Object.values(obj);
        let maxv = Math.min(...values);
        if (maxv === 0) {
            return 1
        }
        return Math.min(...values);
    }

    const getCounterMaxProbablity = async (sportId, date) => {
        try {
            const response = await fetch(`${Url}/sports/countervalue`, {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, /",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "seid": "779f43ea-5ed0-43ee-86fb-c7d296a769cf",
                    "sportId": sportId,
                    "date": date
                }),
            });

            const json = await response.json();
            console.log(json)
            if (json.Status === true) {
                setSlotsCounterData(json.Data)

            } else {
                console.log("Problem")
            }
        }
        catch {
            console.log("Problem")
        }
    };

    const handleSportClick = (sportId, sportname) => {
        setSelectedSportId(sportId);
        setselectedSportName(sportname)
        setselectedSlotName(null)
        setselectedSlotStart()
        setselectedSlotEnd()
        setSelectedSlotId(null)
        getCounterMaxProbablity(sportId, date)
    };

    const handleSlotClick = (name, start, end) => {
        setselectedSlotName(name)
        setselectedSlotStart(start)
        setselectedSlotEnd(end)
        setSelectedSlotId(`${start}-${end}`);
        setmaxValueCounter(getMaxValue(SlotsCounterData[name]))
        setPrice(0)
        setTurfName(null)
        setPrice(0)
        if (counter > 1) {
            setCounter(getMaxValue(SlotsCounterData[name]))
        }

    }
    const handleTurfClick = (trufPrice, turfname) => {
        setPrice(trufPrice);
        setTurfName(turfname);
        setmaxValueCounter(SlotsCounterData[selectedSlotName][turfname])
    }

    const handleCalendarChange = (newValue) => {

        const currentDate = newValue.toLocaleDateString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\//g, '-');
        setDate(currentDate);
        getCounterMaxProbablity(selectedSportId, currentDate)
        
    }

    const handleChangeButton = () => {
        setAuthFlowOtp(true)
    }

    const handleopenInitialPopup = () => {
        setopenInitialPopup(true)
    }

    const increaseCounter = () => {
        if (counter >= maxValueCounter) {
            return;
        }
        setCounter(counter + 1)
    }
    const decreaseCounter = () => {
        if (counter === 1) {
            return;
        }
        setCounter(counter - 1)
    }

    function formatDate(date) {
        if (!(date instanceof Date) || isNaN(date)) {
            return "Invalid Date";
        }
    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const BlockSlotForDate = async () => {
        let data = {
            "date": date,
            "sportName": selectedSportName,
            "blockedSlot": [
                {
                    "slotName": selectedSlotName,
                    "slotstart": selectedSlotStart,
                    "slotEnd": selectedSlotEnd,
                    "turfName": [turfName],
                    "turfPrice": price
                }
            ],
            "duration": String(counter),
            "Token":localStorage.getItem("Token")
        }

        try {
            const resp = await fetch(
              `${Url}/sports/blockTurfs`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data), // Serialize the data as JSON
              }
            );
            const json = await resp.json();
            if (json.Status === true) {
                alert("Blocked")
                websiteData(date)
            }
            // Handle response as needed
          } catch (err) {
            console.error("Error adding WATI credentials:", err);
            // Handle error as needed
          }
    }

   

    const calculateAvailableSlot = (slot) => {
        console.log()
    }

    function formatTime(timeString) {
        const [hours, minutes] = timeString.split(':');
        let formattedHours = parseInt(hours, 10);
        const ampm = formattedHours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        formattedHours = formattedHours % 12 || 12;

        return `${formattedHours}:${minutes} ${ampm}`;
    }

    useEffect(() => {
        const currentDate = new Date().toLocaleDateString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\//g, '-');

        setDate(currentDate);
        setAvailableSlot(sportsData[0]?.slotData?.length);
       websiteData(currentDate)
    }, [])

    useEffect(()=>{
        websiteData(date)
    },[date])

    return (
        <div className='mt-5'>

            <div className="">


                <div className='row  gap-10'>

                    <div className='col-lg-6'>
                        <div className="heading mt-10 max-md:px-5 bringBgColor">
                            Select Sport
                        </div>
                        <div className="sportrow mt-5">

                            {sportsData.map((item, index) => (
                                <div
                                    key={index}
                                    className={`sportcol  ${selectedSportId === item.sportId ? 'sportborder' : 'sportborderBefore'}`}

                                    onClick={() => handleSportClick(item.sportId, item.sportName)}
                                >
                                    <img
                                        loading="lazy"
                                        src={item.images[0]}
                                        alt={item.sportName}
                                        className=""
                                    />
                                    <div className="position-absolute inset-0 bg-black opacity-50"></div>
                                    <p className='text !text-[12px] lg:!text-[18px] lg:!font-semibold !text-white absolute bottom-[15%] max-md:bottom-[10%] left-1/2 transform -translate-x-1/2 ' >
                                        {item.sportName}
                                    </p>


                                </div>
                            ))}


                        </div>


                    </div>

                    {/* Calendar */}

                    <div className='col-lg-6'>
                        <div className="heading bringBgColor">
                            Select Playing Date
                        </div>
                        <div className='calen mt-5'>
                            <Calendar
                                className="w-100 h-100 custom-calendar"
                                onChange={handleCalendarChange}
                                value={date}
                                width="100%"
                                minDate={minDate}
                            />

                        </div>
                    </div>
                </div>

                <div className='row mt-5'>
                    <div className='col-lg-6 flex flex-col '>
                        <div className="w-full  max-md:px-5 lg:mx-auto heading mt-10 max-md:mt-10 bringBgColor">
                            Select Slot
                        </div>

                        <div className='w-100 mt-5 max-md:px-5  lg:mx-auto'>
                            {selectedSportId === null ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <div className='text'>Please Select Any Sport</div>
                                </div>
                                :
                                <div className='slotgrid '>

                                    {sportsData.map((item, index) => (
                                        item?.sportId === selectedSportId && item.slotData ? (
                                            item.slotData
                                                // Sort slotData array based on slot start time
                                                .sort((a, b) => {
                                                    // Assuming slotStart is a string representing time in HH:MM format
                                                    const timeA = a.slotStart.split(':').map(Number);
                                                    const timeB = b.slotStart.split(':').map(Number);

                                                    if (timeA[0] !== timeB[0]) {
                                                        return timeA[0] - timeB[0]; // Sort by hour
                                                    } else {
                                                        return timeA[1] - timeB[1]; // Sort by minute if hours are equal
                                                    }
                                                })
                                                .map((slot, slotIndex) => (
                                                    slot.Turfs.length === 0 ? <div key={`${index}-${slotIndex}`} style={{ cursor: "not-allowed", opacity: "0.5", backgroundColor: "red" }} className={`d-flex justify-content-center background p-3`} >
                                                        <div className="d-flex justify-content-center  " >
                                                            {formatTime(slot.slotStart)} - {formatTime(slot.slotEnd)}
                                                        </div>
                                                    </div> :
                                                        <div key={`${index}-${slotIndex}`} style={{ cursor: "pointer", }} className={` ${selectedSlottId === `${slot.slotStart}-${slot.slotEnd}` ? 'border' : 'borderBefore'} d-flex justify-content-center background p-3 text-center w-100`} onClick={() => handleSlotClick(slot.slotName, slot.slotStart, slot.slotEnd)}>
                                                            <div className="d-flex justify-content-center">
                                                                {formatTime(slot.slotStart)} - {formatTime(slot.slotEnd)}
                                                            </div>
                                                        </div>
                                                ))
                                        ) : null
                                    ))}
                                </div>

                            }
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        {/* Counter */}

                        <div className='w-full gap-5' >


                            <div>
                                <div className="heading  max-md:px-5 bringBgColor">
                                    Select Duration
                                </div>
                                <div className="d-flex gap-5 justify-content-center m-auto mt-5 w-100" >
                                    <button onClick={decreaseCounter} className="increment d-flex justify-content-center align-items-center ">
                                        <RiSubtractFill size={25} className='icon' />
                                    </button>
                                    <div className="my-auto text-lg font-bold leading-5 text-black">
                                        {counter} Hour(s)
                                    </div>
                                    <button onClick={increaseCounter} className="increment  d-flex justify-content-center align-items-center ">
                                        <IoMdAdd size={25} className='icon' />
                                    </button>
                                </div>
                                {/* </div>

<div> */}
                                <div className=" mt-5 heading  max-md:px-5 bringBgColor">
                                    Select Turf
                                </div>
                                <div className="max-md:px-5 mt-5">
                                    {/* item?.sportId === selectedSportId && item.slotData ? ( */}

                                    {sportsData.map((item, index) => (
                                        item?.sportId === selectedSportId && item.slotData ? (
                                            item.slotData.map((slot, slotIndex) => {
                                                // Set a value here
                                                const availableSlot = calculateAvailableSlot(slot);

                                                
                                                if(selectedSportId==="Z9rTeMFLF7xLLXKBANqz88") {
                                                    
                                                    return <div key={`${index}-${slotIndex}`} className="slotgrid">
                                                                {/* {true && slot.Turfs.map((turf, turfIndex) => ( */}

                                                                {selectedSlottId === `${slot.slotStart}-${slot.slotEnd}` && slot.Turfs && slot.Turfs.map((turf, turfIndex) => (
                                                                    <div key={`${index}-${slotIndex}-${turfIndex}`} className={`d-flex justify-content-center background p-3 ${turfName === turf.name ? 'border' : 'borderBefore'}`} onClick={() => handleTurfClick(turf.price, turf.name)} style={{ cursor: "pointer" }}>
                                                                        <div className="flex justify-center max-md:text-[14px]  px-[20px] py-[16px] rounded">
                                                                            {turf.name}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                } else {
                                                    
                                                    return <div key={`${index}-${slotIndex}`} className="slotgrid">
                                                                {selectedSlottId === `${slot.slotStart}-${slot.slotEnd}` && slot.Turfs && slot.Turfs.map((turf, turfIndex) => (
                                                                    turf.name === "7 v 7 Turf C" ? (
                                                                        <div key={`${index}-${slotIndex}-${turfIndex}`} className={`d-flex justify-content-center background p-3 ${turfName === turf.name ? 'border' : 'borderBefore'}`} onClick={() => handleTurfClick(turf.price, turf.name)} style={{ cursor: "pointer" }}>
                                                                            <div className="flex justify-center max-md:text-[14px]  px-[20px] py-[16px] rounded">
                                                                                {turf.name}
                                                                            </div>
                                                                        </div>
                                                                    ) : null
                                                                ))}
                                                            </div>
                                                }
                                            })
                                        ) : null
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Turf */}
                        
                    </div>
                    {date==="" || selectedSportId===null || selectedSlotName===null ||turfName===null?"":<button className='btn btn-primary' onClick={()=>{BlockSlotForDate()}} style={{width:"400px","marginLeft":"35%","background":"#AF5800"}}>Block</button>}
                    
                </div>



            </div>
        </div>
    )
}

export default Frontdesktble