import JoditEditor from "jodit-react";
import React, { useEffect, useContext, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { FaPlus } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackBtn from "../../Components/BackBtn";
import AuthContext from "../../Context/AuthProvider";
import { HashLoader } from "react-spinners";
function CmsServices() {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const {
    EditWebsiteServicesData,
    EditPageTitle,
    setPagetitles,
    WebsiteData,
    baseUrl,
    websiteServicesData,
    setwebsiteServicesData,
    EngineUrl,
    pagetitles,
    OperationServices,
  } = useContext(AuthContext);
  const [Title, setTitle] = useState("");
  const [Text, setText] = useState("");
  const [Image, setImage] = useState("");

  const [editable, setEditable] = useState(false);
  function uploadImage(e) {
    e.preventDefault();
    const imageInput = document.getElementById("file");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3(base64String) {
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setImage(data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const AddServices = () => {
    toast.success("Added");
    setwebsiteServicesData(
      websiteServicesData.concat({ Title: Title, Text: Text, Image: Image })
    );
    fetch(`${EngineUrl}/cms/operation/Services`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        operation: "append",
        Title: Title,
        Text: Text,
        Image: Image,
        index: "0",
      }),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  async function handleChange(fieldName, e) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      Services: { ...prevPagetitles.Services, [fieldName]: e.target.value },
    }));
  }
  async function handleChangeimage(fieldName, value) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      Services: { ...prevPagetitles.Services, [fieldName]: value },
    }));
  }
  function uploadImagepagetitle(e) {
    e.preventDefault();
    const imageInput = document.getElementById("aboutfileimage");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3pagetitle(base64String);
    };
    reader.readAsDataURL(file);
  }
  async function uploadImageCard(e, index) {
    e.preventDefault();
    const imageInput = document.getElementById(`imageFileCard_${index}`);
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }
    console.log("ASD");

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      uploadImageS3Card(base64String, index);
    };
    reader.readAsDataURL(file);
  }
  async function EditServiceCardHandle(index) {
    let obj = {
      Title: websiteServicesData[index].Title,
      Text: websiteServicesData[index].Text,
      Image: websiteServicesData[index].Image,
      index: index,
    };
    const resp = await EditWebsiteServicesData(obj);
    if (resp) {
      toast.success("Edited Successfully");
    } else {
      toast.error("Updation Failed");
    }
  }
  async function uploadImageS3Card(base64String, index) {
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setwebsiteServicesData(
          websiteServicesData.map((event, i) =>
            index === i ? { ...event, Image: data.Image } : event
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function UploadingImageS3pagetitle(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        handleChangeimage("Image", data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  function changeCardTitleandDesc(e, index, fieldName) {
    console.log(index);
    setwebsiteServicesData(
      websiteServicesData.map((event, idx) =>
        idx === index ? { ...event, [fieldName]: e.target.value } : event
      )
    );
  }
  async function DeleteServices(index) {
    let obj = {
      operation: "remove",
      Title: websiteServicesData[index].Title,
      Text: websiteServicesData[index].Text,
      Image: websiteServicesData[index].Image,
      index: index,
    };
    const resp = await OperationServices(obj);
    if (resp) {
      toast.success("Deleted Successfully");
    } else {
      toast.error("Some error occured");
    }
    WebsiteData();
  }
  async function submitPagetitleSave() {
    setEditable(false);
    let obj = {
      key: "Services",
      Title: pagetitles.Services.Title,
      Description: pagetitles.Services.Description,
      Image: pagetitles.Services.Image,
      Video: pagetitles.Services.Video,
    };
    // console.log(obj);
    const resp = await EditPageTitle(obj);
    if (resp === true) {
      toast.success("Edited Successfully");
    } else {
      toast.error("Updation Failed");
    }
    WebsiteData();
  }
  useEffect(() => {
    WebsiteData();
  }, []);
  return (
    <>
      {websiteServicesData === "None" || pagetitles === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <ToastContainer />
          <div
            className="homHead w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 0 20px 0",
            }}
          >
            <BackBtn />
            <h3 className="text-center">Services</h3>
            <Form>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>
          <div style={{ position: "relative", width: "79vw", height: "50vh" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={pagetitles.Services.Image}
              alt="Gallery Image"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              <>
                {editable === false ? (
                  <>
                    <div style={{ fontWeight: "bold", fontSize: "2.5em" }}>
                      {pagetitles.Services.Title}
                    </div>
                    <div style={{ fontSize: "1.5em" }}>
                      {pagetitles.Services.Description}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="boldInput">
                      <input
                        className="sassyInput boldInput"
                        value={pagetitles.Services.Title}
                        onChange={(e) => handleChange("Title", e)}
                        placeholder="Enter Title"
                      />
                    </div>
                    <div>
                      <input
                        className="sassyInput"
                        value={pagetitles.Services.Description}
                        onChange={(e) => handleChange("Description", e)}
                        placeholder="Enter Description"
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        id="aboutfileimage"
                        onChange={(e) => {
                          uploadImagepagetitle(e);
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
            <div
              style={{
                position: "absolute",
                top: "90%",
                left: "95%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              {editable === false ? (
                <div>
                  <button
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "4px",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditable(true)}
                  >
                    EDIT
                  </button>
                </div>
              ) : (
                <button
                  style={{
                    backgroundColor: "#28a745",
                    color: "#fff",
                    border: "1px solid #28a745",
                    borderRadius: "4px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => submitPagetitleSave()}
                >
                  SAVE
                </button>
              )}
            </div>
          </div>
          {/* <Button>Add</Button> */}

          <div className="CmsServices">
            <div className="CmsServicesForm">
              <div className="CmsServicesHeading">
                <label htmlFor="/">Heading:</label>
                <input
                  type="text"
                  className="cmsAboutHeadingInput"
                  value={Title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  placeholder="Enter Heading Here"
                />
              </div>

              <div className="Service_imgDiv">
                <div className="CmsImgInput">
                  <label htmlFor="/">Image:</label>

                  <button className="upload">
                    <span className="cmsupldspn">Upload Image</span>
                    <FaPlus className="cmsplusicon" />
                    <input type="file" id="file" onChange={uploadImage} />
                  </button>
                </div>
                <div className="ImgDivs">
                  <img src={Image} alt="" />
                </div>
              </div>
            </div>
            <div className="CmsService_joditEditoore my-3">
              <label htmlFor="/">Text:</label>
              <JoditEditor
                ref={editor}
                value={Text}
                onChange={(newContent) => {
                  setText(newContent);
                }}
                className="my-3"
              />
              {/* <textarea rows="6" col="12" style={{width:"100%"}}  value={Text} onChange={(e)=>{setText(e.target.value)}} ></textarea> */}
            </div>
            <div className="d-flex justify-content-center w-100">
              <button className="saveBtn btn" onClick={AddServices}>
                Save
              </button>
            </div>

            <div className="CmsServices_cards my-3">
              {websiteServicesData.map((Service, index) => (
                <div className="eventdiv_cardDiv" style={{ gap: "3rem" }}>
                  <div class="card" style={{ width: "18rem" }}>
                    <img src={Service.Image} class="card-img-top" alt="..." />
                    <div className="Event_imgDiv" style={{ height: "4rem" }}>
                      <label
                        htmlFor={`imageFileCard_${index}`}
                        className="upload"
                      >
                        {" "}
                        {/* Added htmlFor attribute */}
                        <span>Upload Image</span>
                        <input
                          type="file"
                          id={`imageFileCard_${index}`}
                          accept="image/png, image/gif, image/jpeg"
                          style={{ display: "none" }}
                          onChange={(e) => uploadImageCard(e, index)}
                        />
                      </label>
                    </div>

                    <div style={{ width: "100%" }}>
                      <div style={{ width: "90%" }}>
                        <input
                          onChange={(e) =>
                            changeCardTitleandDesc(e, index, "Title")
                          }
                          value={Service.Title}
                          style={{
                            width: "100%",
                            marginLeft: "1rem",
                            marginBottom: "1rem",
                            height: "2rem",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            transition: "background-color 0.3s ease",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#f0f0f0")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "initial")
                          }
                        />
                      </div>
                      <div style={{ width: "90%" }}>
                        <input
                          onChange={(e) =>
                            changeCardTitleandDesc(e, index, "Text")
                          }
                          value={Service.Text}
                          style={{
                            width: "100%",
                            marginLeft: "1rem",
                            height: "2rem",
                            marginBottom: "1rem",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            transition: "background-color 0.3s ease",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#f0f0f0")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "initial")
                          }
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "1rem",
                          gap: "3rem",
                        }}
                      >
                        <a
                          href="#"
                          class="btn btn-primary"
                          onClick={() => EditServiceCardHandle(index)}
                        >
                          Edit
                        </a>
                        <a
                          href="#"
                          class="btn btn-danger"
                          onClick={() => DeleteServices(index)}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CmsServices;
