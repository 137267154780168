import { useContext, useEffect, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import "./App.css";
import Alert from "./Components/Alert";
import EditRoomPopup from "./Components/EditRoomPopup";
import LoginPage from "./Components/LoginPage";
import SideBarSports from "./Components/SideBarSports.jsx";
import Sidebar from "./Components/Sidebar";
import SidebarAdmin from "./Components/SidebarAdmin.jsx";
import Topbar from "./Components/Topbar";
import TopbarAdmin from "./Components/TopbarAdmin.jsx";
import TopbarSports from "./Components/TopbarSports.jsx";
import AuthContext from "./Context/AuthProvider.js";
import LandingAdminUser from "./Pages/Admin/Alladmins/Landingadmins.jsx";
import AnalysisPage from "./Pages/Admin/Analysis/Landing.jsx";
import LandingAdminClient from "./Pages/Admin/Clients/LandingClient.jsx";
import UsersAdmin from "./Pages/Admin/Clients/Users.jsx";
import LandingAdmin from "./Pages/Admin/LandingAdmin.jsx";
import QueriesLanding from "./Pages/Admin/Queries/Landing.jsx";
import BookingEngine from "./Pages/Booking Engine/BookingEngine";
import CmsDigital from "./Pages/CMS/CMSDigital";
import CmsNewsletter from "./Pages/CMS/CMSNewsletter";
import CmsQuery from "./Pages/CMS/CMSQuery";
import CMSTnc from "./Pages/CMS/CMSTnc.jsx";
import CmsAboutus from "./Pages/CMS/CmsAboutus";
import CmsAdvertisments from "./Pages/CMS/CmsAdvertisments";
import CmsBlogs from "./Pages/CMS/CmsBlogs";
import CmsContact from "./Pages/CMS/CmsContact";
import CmsEvents from "./Pages/CMS/CmsEvents";
import CmsFAQ from "./Pages/CMS/CmsFAQ.jsx";
import CmsFacilities from "./Pages/CMS/CmsFacilities";
import CmsGallery from "./Pages/CMS/CmsGallery";
import CmsGooglemap from "./Pages/CMS/CmsGooglemap";
import CmsHome from "./Pages/CMS/CmsHome";
import CmsLanding from "./Pages/CMS/CmsLanding";
import CmsMenu from "./Pages/CMS/CmsMenu";
import CmsNearby from "./Pages/CMS/CmsNearby";
import CmsProfile from "./Pages/CMS/CmsProfile";
import CmsRestaurants from "./Pages/CMS/CmsReataurants";
import CmsRooms from "./Pages/CMS/CmsRooms";
import CmsServices from "./Pages/CMS/CmsServices";
import CmsTeams from "./Pages/CMS/CmsTeams";
import CmsTestimonials from "./Pages/CMS/CmsTestimonials";
import ChannelManager from "./Pages/Channel Manager/ChannelManager";
import ChannelManagerPage from "./Pages/Channel Manager/ChannelManagerPage.jsx";
import FrontDesk from "./Pages/Front Desk/FrontDesk";
import Landing from "./Pages/Landing/Landing";
import LeadsMgmt from "./Pages/Leads Management/LeadsManagement.jsx";
import PCM from "./Pages/PCM/pcm.jsx";
import Payment from "./Pages/Payment Gateway/Payment";
import Reservationdesk from "./Pages/Reservation Desk/Reservationdesk";
import Seomanager from "./Pages/SEOManager/Seomanager";
import SocialActiveTools from "./Pages/Social Meadia/SocialActiveTools";
import SocialAnalytics from "./Pages/Social Meadia/SocialAnalytics";
import SocialHome from "./Pages/Social Meadia/SocialHome";
import SocialMedia from "./Pages/Social Meadia/SocialMedia";
import SocialNewPost from "./Pages/Social Meadia/SocialNewPost";
import SocialPost from "./Pages/Social Meadia/SocialPost";
import SocialReviews from "./Pages/Social Meadia/SocialReviews";
import SocialScheduled from "./Pages/Social Meadia/SocialScheduled";
import LandingSportsBookings from "./Pages/Sports/Bookings/Landing.jsx";
import LandingSportsPriceMgmt from "./Pages/Sports/DynamicPrice/Landinf.jsx";
import LandingSports from "./Pages/Sports/LandingSports.jsx";
import LandingSportsSlotsMgmt from "./Pages/Sports/SlotsMgmt/Landing.jsx";
import LandingSportsPage from "./Pages/Sports/SportsGame/Landing.jsx";
import LandingSportsUsersMgmt from "./Pages/Sports/UserMgmt/Landing.jsx";
import LandingSportsUsers from "./Pages/Sports/UsersLogs/Landing.jsx";
import Themes from "./Pages/Themes Manager/Themes";
import UserManagement from "./Pages/User management/UserManagement";
import Whatsapp from "./Pages/Whatsapp/Landing.jsx";


function App() {
  const [loader, setLoader] = useState(true);
  const {
    CheckDinabiteToken_inDb,
    baseUrl,
    Auth,
    setAuth,
    WebsiteData,
    isEazotelAdmin,
    setisEazotelAdmin,
  } = useContext(AuthContext);
  const {
    userProfile,
    setUserProfile,
    userLinks,
    setUserLinks,
    FetchSpreadSheetFromDb,
    userPlan,
    setUserPlan,
    userAccess,
    setUserAccess,
    isAdmin,
    setIsadmin,
    todaysAvaiblity,
    handleGetPrice,
    TemplateNumber,
    setTemplateNumber,
    GetAllLocations_hotel,
    FetchAccessTokenFromDb,
    EngineUrl,isEazotelSports, setisEazotelSports,GetAllUsers,
    GetAllUsersLogs,
    fetchLoneStarBookings,fetchAllSlotsPriceMgmt,fetchAllTodaysBookings,GetAllSports
  } = useContext(AuthContext);
  const [queryParameters] = useSearchParams();

  function getDateRange() {
    const currentDate = new Date();

    // Get today's date
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const today = `${year}-${month}-${day}`;

    // Get yesterday's date
    const yesterdayDate = new Date(currentDate);
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    const yesterdayYear = yesterdayDate.getFullYear();
    const yesterdayMonth = String(yesterdayDate.getMonth() + 1).padStart(2, '0');
    const yesterdayDay = String(yesterdayDate.getDate()).padStart(2, '0');
    const yesterday = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDay}`;

    // Get tomorrow's date
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const tomorrowYear = tomorrowDate.getFullYear();
    const tomorrowMonth = String(tomorrowDate.getMonth() + 1).padStart(2, '0');
    const tomorrowDay = String(tomorrowDate.getDate()).padStart(2, '0');
    const tomorrow = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay}`;

    return {
        today: today,
        yesterday: yesterday,
        tomorrow: tomorrow
    };
}

  const LonestarDashboard = async () => {
    try {
      const response = await fetch(
        `${EngineUrl}/sports/checkauthusertoken/${localStorage.getItem("Token")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();

      
      if (json.Status === true) {
        localStorage.setItem("Token", json.Token);
        sessionStorage.setItem("Token", json.Token);
        setisEazotelSports(true)
        setUserProfile(json.Information);
        setUserAccess(json.User)
        let currdate = getDateRange()
        GetAllSports()
        GetAllUsers()
        GetAllUsersLogs()
        fetchLoneStarBookings()
        fetchAllSlotsPriceMgmt(currdate.today,currdate.yesterday,currdate.tomorrow)
        fetchAllTodaysBookings()
        setAuth(true);
      }
       else {
        alert("Inavlid Credentials")
        localStorage.clear();
        setAuth(false);
      }
    } catch (error) {
      localStorage.clear();
    }
  }

  const CheckDashboard = async () => {
    try {
      if (queryParameters.get("id") !== null) {
        localStorage.setItem("Token", queryParameters.get("id"));
      }
      const response = await fetch(
        `${EngineUrl}/eazotel/getuser/${localStorage.getItem("Token")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();

      if (json.Status === true) {
        setUserProfile(json.Profile);
        setUserLinks(json.Data);
        setUserPlan(json.Plan);
        setUserAccess(json.Access);
        setIsadmin(json.Admin);

        GetAllLocations_hotel();
        CheckDinabiteToken_inDb();
        FetchAccessTokenFromDb();
        FetchSpreadSheetFromDb();
        setAuth(true);
      } else {
        LonestarDashboard()
        
      }
    } catch (error) {
      localStorage.clear();
    }
    setLoader(false);
  };

  useEffect(() => {
    if (!Auth) {
      let token = localStorage.getItem("Token");
      let token1 = queryParameters.get("id");
      if (token !== null || token1 !== null) {
        CheckDashboard();
      } else {
        setLoader(false);
      }
    }
  }, []);

  return (
    <>
      {Auth ? (
        <div>
          <div className="top_main">
            {!isEazotelAdmin && !isEazotelSports ? (
              <Topbar userProfile={userProfile} userLinks={userLinks} />
            ) :!isEazotelAdmin || isEazotelSports?(
              <TopbarSports userProfile={userProfile} />
            ):<TopbarAdmin />}
          </div>
          {/* main sidebar  */}
          <div className="main_sidebar">
          {!isEazotelAdmin && !isEazotelSports ? (
              <Sidebar
              setAuth={setAuth}
              userProfile={userProfile}
              userPlan={userPlan}
              userAccess={userAccess}
              isAdmin={isAdmin}
            />
            ) :!isEazotelAdmin || isEazotelSports?(
              <SideBarSports setAuth={setAuth} userProfile={userProfile} />
            ):<SidebarAdmin setAuth={setAuth} userProfile={userProfile} />}
            
          </div>

          <div className="main-content">
            <Alert />
            <Routes>
              {/* For Login  */}
              <Route path="/login" element={<LoginPage />} />

              {/* For main Landing Page  */}
              {!isEazotelAdmin && !isEazotelSports ? (
                <Route path="/" element={<Landing />} />
              ) :!isEazotelAdmin || isEazotelSports?(
                <Route path="/" element={<LandingSports />} />
              ):<Route path="/" element={<LandingAdmin />} />}

              {/* For CMS  */}
              <Route path="/dashboard/client/cms" element={<CmsLanding />} />
              <Route path="/dashboard/client/cms/home" element={<CmsHome />} />
              <Route
                path="/dashboard/client/cms/aboutus"
                element={<CmsAboutus />}
              />
              <Route
                path="/dashboard/client/cms/nearby"
                element={<CmsNearby />}
              />
              <Route
                path="/dashboard/client/cms/facilities"
                element={<CmsFacilities />}
              />
              <Route
                path="/dashboard/client/cms/gallery"
                element={<CmsGallery />}
              />
              <Route
                path="/dashboard/client/cms/rooms"
                element={<CmsRooms />}
              />
              <Route
                path="/dashboard/client/cms/googlemap"
                element={<CmsGooglemap />}
              />
              <Route
                path="/dashboard/client/cms/contact"
                element={<CmsContact />}
              />
              <Route
                path="/dashboard/client/cms/newsletter"
                element={<CmsNewsletter />}
              />
              <Route
                path="/dashboard/client/cms/queries"
                element={<CmsQuery />}
              />
              <Route
                path="/dashboard/client/cms/digitalcheckin"
                element={<CmsDigital />}
              />
              <Route
                path="/dashboard/client/cms/hoteladvertisment"
                element={<CmsAdvertisments />}
              />
              <Route
                path="/dashboard/client/cms/terms&conditions"
                element={<CMSTnc />}
              />
              <Route path="/dashboard/client/cms/faq" element={<CmsFAQ />} />
              <Route
                path="/dashboard/client/cms/services"
                element={<CmsServices />}
              />
              <Route
                path="/dashboard/client/cms/restaurants"
                element={<CmsRestaurants />}
              />
              <Route
                path="/dashboard/client/cms/testimonials"
                element={<CmsTestimonials />}
              />
              <Route
                path="/dashboard/client/cms/teams"
                element={<CmsTeams />}
              />
              <Route
                path="/dashboard/client/cms/blogs"
                element={<CmsBlogs />}
              />
              <Route path="/dashboard/client/cms/menu" element={<CmsMenu />} />
              <Route
                path="/dashboard/client/cms/profile"
                element={<CmsProfile />}
              />
              <Route
                path="/dashboard/client/cms/event"
                element={<CmsEvents />}
              />

              {/* For Booking Engine */}
              <Route
                path="/dashboard/client/bookingengine"
                element={<BookingEngine />}
              />
              <Route
                path="/dashboard/client/bookingengine/editroom"
                element={<EditRoomPopup />}
              />

              {/* For Social Media */}
              <Route
                path="/dashboard/client/socialmedia"
                element={<SocialMedia />}
              />
              <Route
                path="/dashboard/client/socialmedia/home"
                element={<SocialHome />}
              />
              <Route
                path="/dashboard/client/socialmedi/tools"
                element={<SocialActiveTools />}
              />
              <Route
                path="/dashboard/client/socialmedia/post"
                element={<SocialPost />}
              />
              <Route
                path="/dashboard/client/Socialmedia/post/scheduled"
                element={<SocialScheduled />}
              />
              <Route
                path="/dashboard/client/Socialmedia/post/newpost"
                element={<SocialNewPost />}
              />
              <Route
                path="/dashboard/client/Socialmedia/reviews"
                element={<SocialReviews />}
              />
              <Route
                path="/dashboard/client/socialmedia/analytics"
                element={<SocialAnalytics />}
              />

              {/* For Reservation Desk  */}
              <Route
                path="/dashboard/client/reservationdesk"
                element={<Reservationdesk />}
              />

              {/* For Front Desk  */}
              <Route
                path="/dashboard/client/frontdesk"
                element={<FrontDesk />}
              />

              {/* For Channel Manager  */}
              <Route
                path="/dashboard/client/channelmanager"
                element={<ChannelManager />}
              />

              {/* For Whatsapp  */}
              <Route path="/dashboard/client/whatsapp" element={<Whatsapp />} />

              {/* For SEO Manager  */}
              <Route
                path="/dashboard/client/seomanager"
                element={<Seomanager />}
              />

              {/* For Payment  */}
              <Route path="/dashboard/client/payment" element={<Payment />} />

              {/* For Themes  */}
              <Route path="/dashboard/client/themes" element={<Themes />} />

              {/* For Leads Management */}
              <Route
                path="/dashboard/client/usermanagement"
                element={<UserManagement />}
              />

              {/* For User Management */}
              <Route path="/dashboard/client/leads" element={<LeadsMgmt />} />
              <Route
                path="/dashboard/client/channelmanager/makemytrip"
                element={<ChannelManagerPage />}
              />
              <Route
                path="/dashboard/client/channelmanager/goibibo"
                element={<ChannelManagerPage />}
              />
              <Route
                path="/dashboard/client/channelmanager/thrillophilia"
                element={<ChannelManagerPage />}
              />
              <Route
                path="/dashboard/client/channelmanager/booking"
                element={<ChannelManagerPage />}
              />
              <Route
                path="/dashboard/client/channelmanager/trivago"
                element={<ChannelManagerPage />}
              />
              <Route
                path="/dashboard/client/channelmanager/expedia"
                element={<ChannelManagerPage />}
              />
              <Route
                path="/dashboard/client/channelmanager/airbnb"
                element={<ChannelManagerPage />}
              />
              <Route
                path="/dashboard/client/channelmanager/agoda"
                element={<ChannelManagerPage />}
              />

              <Route
                path="/dashboard/client/propertycontentmanagement"
                element={<PCM />}
              />

              {/* ADMIN */}
              {isEazotelAdmin ? (
                <Route path="/dashboard/admin" element={<LandingAdminUser />} />
              ) : (
                ""
              )}
              {isEazotelAdmin ? (
                <Route
                  path="/dashboard/admin/clients"
                  element={<LandingAdminClient />}
                />
              ) : (
                ""
              )}
              {isEazotelAdmin ? (
                <Route path="/dashboard/admin/users" element={<UsersAdmin />} />
              ) : (
                ""
              )}
              {isEazotelAdmin ? (
                <Route
                  path="/dashboard/admin/users/analysis"
                  element={<AnalysisPage />}
                />
              ) : (
                ""
              )}
              {isEazotelAdmin ? (
                <Route
                  path="/dashboard/admin/queries"
                  element={<QueriesLanding />}
                />
              ) : (
                ""
              )}


              {/* Lone Star Routes */}
              {isEazotelSports ? (
                <Route path="/dashboard/sports/clients/sports" element={<LandingSportsPage />} />) : ("")}
              {isEazotelSports ? (
                <Route path="/dashboard/sports/clients/bookings" element={<LandingSportsBookings />} />) : ("")}
              {isEazotelSports ? (
                <Route path="/dashboard/sports/clients/users" element={<LandingSportsUsers />} />) : ("")}

              {isEazotelSports ? (
                <Route path="/dashboard/sports/clients/usersMgmt" element={<LandingSportsUsersMgmt />} />) : ("")}

              {isEazotelSports ? (
                <Route path="/dashboard/sports/clients/sports/slots" element={<LandingSportsSlotsMgmt />} />) : ("")}

              {isEazotelSports ? (
                <Route path="/dashboard/sports/clients/sports/price" element={<LandingSportsPriceMgmt />} />) : ("")}   


             
            </Routes>
          </div>
        </div>
      ) : (
        <>
          {loader ? (
            <>
              <div
                style={{
                  position: "absolute",
                  top: "46%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1, // Ensure it's above the LoginPage
                }}
              >
                <HashLoader color="#E65502" />
              </div>
              <LoginPage
                loader={loader}
                Auth={Auth}
                setAuth={setAuth}
                setUserProfile={setUserProfile}
                setIsadmin={setIsadmin}
                setUserLinks={setUserLinks}
                setUserPlan={setUserPlan}
                userAccess={userAccess}
                setuserAccess={setUserAccess}
              />
            </>
          ) : (
            <>
              <LoginPage
                Auth={Auth}
                setAuth={setAuth}
                setUserProfile={setUserProfile}
                setIsadmin={setIsadmin}
                setUserLinks={setUserLinks}
                setUserPlan={setUserPlan}
                userAccess={userAccess}
                setuserAccess={setUserAccess}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default App;
