import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { IoMdSend } from "react-icons/io";
import "../Style/ReservDeskInfoPopup.css";
import "../Style/AddUserPopUp.css";

const DinabiteReviewPopup = ({ setmessagepopup, object }) => {
  const [inputValue, setInputValue] = useState(object.comment2);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="overlayStyle">
      <div className="w-100 h-auto">
        <div
          style={{
            position: "fixed",
            top: "10%",
            left: "25%",
            zIndex: 1,
            width: "950px",
          }}
        >
          <div className="h-100 shadow mb-1 bg-body rounded popUpBG">
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "end",
              }}
            >
              <GrClose
                className="closeIcon"
                onClick={() => {
                  setmessagepopup(false);
                }}
              />
            </div>

            <div
              className="row rounded text-white"
              style={{ marginTop: "-1.8rem", backgroundColor: "#0A3A75" }}
            >
              <div className="col py-2 text-center">NAME</div>
            </div>
            <div className="popupbg">
              <div
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  marginBottom: "10px", // Add margin bottom to create gap
                  marginTop: "10px", // Add margin top to create gap
                }}
              >
                {object.comment1}
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Reply the review"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={inputValue}
                onChange={handleInputChange}
              />

              <div
                class="input-group-append"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "1px",
                  marginLeft: "2px",
                }}
              >
                <IoMdSend size="2rem" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DinabiteReviewPopup;
