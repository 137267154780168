import React, { useContext, useEffect } from "react";
import LandingCard from "../../Components/LandingCard";
import AuthContext from "../../Context/AuthProvider";
import "../../Style/Landing.css";
// import LandingBookingTable from "../../Components/LandingBookingTable";
// import LandingActiviesTable from "../../Components/LandingActiviesTable";

function LandingAdmin() {
  const {EngineUrl,Sports,setSports,
    Users,setUsers,GetAllSports,
    GetAllUsers,GetAllUsersLogs,fetchLoneStarBookings,fetchAllSlotsPriceMgmt,fetchAllTodaysBookings,TodaysBookings,LoginUsers,LoneStarBookings} = useContext(AuthContext);
  
    function getDateRange() {
      const currentDate = new Date();
  
      // Get today's date
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const today = `${year}-${month}-${day}`;
  
      // Get yesterday's date
      const yesterdayDate = new Date(currentDate);
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      const yesterdayYear = yesterdayDate.getFullYear();
      const yesterdayMonth = String(yesterdayDate.getMonth() + 1).padStart(2, '0');
      const yesterdayDay = String(yesterdayDate.getDate()).padStart(2, '0');
      const yesterday = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDay}`;
  
      // Get tomorrow's date
      const tomorrowDate = new Date(currentDate);
      tomorrowDate.setDate(tomorrowDate.getDate() + 1);
      const tomorrowYear = tomorrowDate.getFullYear();
      const tomorrowMonth = String(tomorrowDate.getMonth() + 1).padStart(2, '0');
      const tomorrowDay = String(tomorrowDate.getDate()).padStart(2, '0');
      const tomorrow = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay}`;
  
      return {
          today: today,
          yesterday: yesterday,
          tomorrow: tomorrow
      };
  }


  useEffect(()=>{
    let currdate = getDateRange()
    GetAllSports()
    GetAllUsers()
    GetAllUsersLogs()
    fetchLoneStarBookings()
    fetchAllSlotsPriceMgmt(currdate.today,currdate.yesterday,currdate.tomorrow)
    fetchAllTodaysBookings()
    
  },[])
  return (
    <div className="landing">
      <div className="landingCardDiv">
        <LandingCard
          count={TodaysBookings}
          heading="Todays Booking"
          // para="Someone bookmarked your listing"
        />
        <LandingCard
          count={Sports.length}
          heading="Total Sports"
          // para="Someone bookmarked your listing"
        />
        <LandingCard
          count={LoginUsers.length}
          heading="User LoggedIn"
          // para="Someone bookmarked your listing"
        />
         <LandingCard
          count={LoneStarBookings.length}
          heading="Total Bookings"
          // para="Someone bookmarked your listing"
        />
      </div>
      <div className="landingCanvasDiv">{/* <LandingCanvas /> */}</div>
      {/* <div className="landingBookingDiv">
        <LandingBookingTable />
      </div>
      <div className="landingActivitiesDiv">
        <LandingActiviesTable />
      </div> */}
    </div>
  );
}

export default LandingAdmin;
