import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { HashLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import BackBtn from "../../Components/BackBtn";
import AuthContext from "../../Context/AuthProvider";
function CmsFAQ() {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { baseUrl, EngineUrl, websiteFAQs, setwebsiteFAQs, WebsiteData } =
    useContext(AuthContext);
  const [Question, setQuestion] = useState();
  const [Answer, setAnswer] = useState();
  

  const DeleteQuestionAnswer = (que, ans) => {
    for (let i = 0; i < websiteFAQs.length; i++) {
      if (que === websiteFAQs[i].Question && ans === websiteFAQs[i].Answer) {
        websiteFAQs.splice(i, 1);
        break;
      } else {
        continue;
      }
    }
    setwebsiteFAQs([...websiteFAQs]);
    fetch(`${baseUrl}/api/dashboard/editTerms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
        operation: "remove",
        question: que,
        answer: ans,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        alert("Deleted");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const AddQuestionAnswer = async () => {
    let obj = {
      Token: localStorage.getItem("Token"),
      operation: "append",
      question: Question,
      answer: Answer,
      index: 0,
    };
    try {
      obj["token"] = localStorage.getItem("Token");
      console.log(obj);
      const url = `${EngineUrl}/cms/operation/Faq`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const resp = await response.json();
      console.log(resp);
      if (resp.Status === true) {
        WebsiteData();
        toast.success("Added Successfully");
        setAnswer("");
        setQuestion("");

        return true;
      }

      toast.error("Some error occured");
      return false;
    } catch {
      toast.error("Some error occured");
      return false;
    }
  };
  useEffect(() => {
    WebsiteData();
  }, []);
  const { pagetitles, setPagetitles, EditPageTitle} = useContext(AuthContext);
  

  async function handleChange(fieldName, e) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      Faq: { ...prevPagetitles.Faq, [fieldName]: e.target.value },
    }));
    console.log(e.target.value);
  }

  async function submitPagetitleSave() {
    setEditable(false);
    let obj = {
      key: "Faq",
      Title: pagetitles.Faq.Title,
      Description: pagetitles.Faq.Description,
      Image: pagetitles.Faq.Image,
      Video: pagetitles.Faq.Video,
    };
    // console.log(obj);
    const resp = await EditPageTitle(obj);
    if (resp === true) {
      toast.success("Edited Successfully");
    } else {
      toast.error("Updation Failed");
    }
    WebsiteData();
  }
  async function handleChangeimage(fieldName, value) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      Faq: { ...prevPagetitles.Faq, [fieldName]: value },
    }));
    console.log(value);
  }
  function uploadImagepagetitle(e) {
    e.preventDefault();
    const imageInput = document.getElementById("Faqfileid");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3pagetitle(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3pagetitle(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        handleChangeimage("Image",data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }


  const [editable, setEditable] = useState(false);
  return (
    <>
      {websiteFAQs === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <ToastContainer />
          <div className="d-flex justify-content-between my-2">
            <BackBtn />
            <h3 className="text-center">FAQs</h3>
            <br />
            {/* <Button onClick={AddQuestionAnswer}>Add</Button> */}
          </div>
          <div style={{ position: "relative", width: "79vw", height: "50vh" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={pagetitles.Faq.Image}
              alt="Gallery Image"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              <>
                {editable === false ? (
                  <>
                    <div style={{ fontWeight: "bold", fontSize: "2.5em" }}>
                      {pagetitles.Faq.Title}
                    </div>
                    <div style={{ fontSize: "1.5em" }}>
                      {pagetitles.Faq.Description}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="boldInput">
                      <input
                        className="sassyInput boldInput"
                        value={pagetitles.Faq.Title}
                        onChange={(e) => handleChange("Title", e)}
                        placeholder="Enter Title"
                      />
                    </div>
                    <div>
                      <input
                        className="sassyInput"
                        value={pagetitles.Faq.Description}
                        onChange={(e) => handleChange("Description", e)}
                        placeholder="Enter Description"
                      />
                    </div>
                    <div>
                      <input type="file" id="Faqfileid" onChange={(e)=>{uploadImagepagetitle(e)}} />
                    </div>
                  </>
                )}
              </>
            </div>
            <div
              style={{
                position: "absolute",
                top: "90%",
                left: "95%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              {editable === false ? (
                <div>
                  <button
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "4px",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditable(true)}
                  >
                    EDIT
                  </button>
                </div>
              ) : (
                <button
                  style={{
                    backgroundColor: "#28a745",
                    color: "#fff",
                    border: "1px solid #28a745",
                    borderRadius: "4px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => submitPagetitleSave()}
                >
                  SAVE
                </button>
              )}
            </div>
          </div>
          <div className="mainAdvr">
            <div className="AdvrQuetionDiv">
              <div className="QuetionDiv">
                <label htmlFor="/">Quetion:</label>
                <input
                  className="AdvrInput"
                  type="text"
                  placeholder="Enter Question Here"
                  value={Question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                />
              </div>
              <div className="QuetionDiv my-3">
                <label htmlFor="/">Answer:</label>
                {/* <JoditEditor
            ref={editor}
            value={content}
            onChange={newContent => { }}
          /> */}
                <textarea
                  id="myTextarea"
                  className="p-2"
                  rows="4"
                  value={Answer}
                  placeholder="Type Here"
                  onChange={(e) => {
                    setAnswer(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className="my-4">
              <Accordion defaultActiveKey="0">
                {websiteFAQs.map((terms, key) => {
                  return (
                    <Accordion.Item eventKey={key + 1}>
                      <Accordion.Header>{terms.Question}</Accordion.Header>
                      <Accordion.Body>{terms.Answer}</Accordion.Body>
                      <button
                        className="btn btn-danger m-2"
                        onClick={() => {
                          DeleteQuestionAnswer(terms.Question, terms.Answer);
                        }}
                      >
                        Delete
                      </button>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
              <div className="tcSaveBTn my-4 text-center">
                <Button onClick={AddQuestionAnswer}>Add</Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CmsFAQ;
