import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { ArrowLeft } from "react-bootstrap-icons";
import BackBtn from "../../Components/BackBtn";
import AuthContext from "../../Context/AuthProvider";
import { HashLoader } from "react-spinners";
function CmsGooglemap() {
  const { baseUrl, websiteLocationData, setwebsiteLocationData } =
    useContext(AuthContext);

  const EditLocation = () => {
    fetch(`${baseUrl}/api/dashboard/editlocation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
        Location: websiteLocationData,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        alert("done");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      {websiteLocationData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div>
            <BackBtn />
            <div className="cmsAboutDiv my-4">
              <div className="cmsAboutForm">
                <div className="cmsAboutHeading">
                  <label htmlFor="/">Heading:</label>
                  <input
                    type="text"
                    className="cmsAboutHeadingInput"
                    value={websiteLocationData}
                    onChange={(e) => {
                      setwebsiteLocationData(e.target.value);
                    }}
                    placeholder="Enter Heading Here"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column" style={{ gap: "20px" }}>
              <iframe src={websiteLocationData}></iframe>
              <div className="d-flex justify-content-center">
                <button
                  className="locationsaveBtn"
                  style={{
                    padding: "8px 15px",
                    background: "#0A3A75",
                    color: "white",
                    borderRadius: "12px",
                    border: "0",
                  }}
                  onClick={EditLocation}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CmsGooglemap;
