import React from 'react'
import Card from 'react-bootstrap/Card';

function AnalyticsCard(props) {
    const { Title, Subtitle } = props;
    return (
        <Card className='anly-card' >
            <Card.Body>
                <div className='anlycrd' >
                    <Card.Title className='anly_titl'>{Title}</Card.Title>
                    <Card.Subtitle className="anly_subtitl" >{Subtitle}</Card.Subtitle>
                </div>
            </Card.Body>
        </Card>
    )
}

export default AnalyticsCard
