import React, { useContext, useEffect } from 'react';
import AuthContext from '../../../Context/AuthProvider';

function LandingClient() {
  const {AllClients,
    setAllClients,
    FetchAllEazotelClients} = useContext(AuthContext)

    useEffect(()=>{
      FetchAllEazotelClients()
    },[])
  return (
    <div
    className=""
    style={{
      padding: "18px",
      overflowX: "auto",
      overflowY: "auto",
      whiteSpace: "nowrap",
    }}
  >
    <table className='usermanagementtable'>
        <thead>
          <tr className='tablerow'>
            {['Hotel Name', 'Hotel Email', 'Hotel Phone','Website','Expiry','Verified'].map((headerLabel) => (
              <th key={headerLabel}>{headerLabel}</th>
            ))}

          </tr>
        </thead>

        <tbody>
          {AllClients.map((client) => (
            <tr>
              <td>{client.hotelName}</td>
              <td>{client.hotelEmail}</td>
              <td>{client.hotelPhone}</td>
              <td><a href={client.websiteLink.dashboardLink==="None"?client.websiteLink.websiteLink:client.websiteLink.dashboardLink} target='_blank'>{client.websiteLink.dashboardLink==="None"?client.websiteLink.websiteLink:client.websiteLink.dashboardLink}</a></td>
              <td>{client.plan.expiryDate}</td>
              <td>{client.isVerified?<span className='badge bg-success m-1 w-auto'>Yes</span>:<span className='badge bg-danger m-1 w-auto'>No</span>}</td>
            </tr>
          )).reverse()}
        </tbody>
      </table>
  </div>
  )
}

export default LandingClient