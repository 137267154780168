import React from 'react'
import "../Style/LandingActiviesTable.css"


const LandingActiviesTable = () => {
    return (
        <div className='landingactivitiestable'>
            <div class="row">
                <div class="col-lg-7 col-md-12 col-xs-12 traffic">
                    <div class="dashboard-list-box with-icons margin-top-20">
                        <h4 class="gray">Recent Activities</h4>
                        <ul>
                            <li>
                                {/* <i class="list-box-icon sl sl-icon-layers"> */}
                                Your listing <strong><a href="#">Hotel Govendor</a></strong> has been approved!
                                <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                            </li>

                            <li>
                                Kathy Brown left a review  on <strong><a href="#">Burger House</a></strong>
                                <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                            </li>

                            <li>
                                Someone bookmarked your <strong><a href="#">Burger House</a></strong> listing!
                                <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                            </li>

                            <li>
                                Kathy Brown left a review  on <strong><a href="#">Airport</a></strong>
                                <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                            </li>

                            <li>
                                Someone bookmarked your <strong><a href="#">Burger House</a></strong> listing!
                                <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                            </li>

                        </ul>
                    </div>
                </div>
                {/* <div class="col-lg-5 col-md-12 col-xs-12 traffic">
                        <div class="dashboard-list-box margin-top-20 user-list">
                            <h4 class="gray">User List</h4>
                            <ul>
                                <li>
                                    <div class="user-list-item">
                                        <div className='list d-flex justify-content-space-between w-100'>
                                            <div class="user-list-content">
                                                <h3>Loural Teak</h3>
                                            </div>
                                            <div class="user-btns">
                                                <a href="#" class="button">View</a>
                                                <a href="#" class="button">Edit</a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="user-list-item">

                                        <div className='list d-flex justify-content-space-between w-100'>
                                            <div class="user-list-content">
                                                <h3>Jim Gordon</h3>
                                            </div>
                                            <div class="user-btns">
                                                <a href="#" class="button">View</a>
                                                <a href="#" class="button">Edit</a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="user-list-item">

                                        <div className='list d-flex justify-content-space-between w-100'>
                                            <div class="user-list-content">
                                                <h3>Loural Teak</h3>
                                            </div>
                                            <div class="user-btns">
                                                <a href="#" class="button">View</a>
                                                <a href="#" class="button">Edit</a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="user-list-item">

                                        <div className=' list d-flex justify-content-space-between w-100'>
                                            <div class="user-list-content">
                                                <h3>Loural Teak</h3>
                                            </div>
                                            <div class="user-btns">
                                                <a href="#" class="button">View</a>
                                                <a href="#" class="button">Edit</a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="user-list-item">

                                        <div className=' list d-flex justify-content-space-between w-100'>
                                            <div class="user-list-content">
                                                <h3>Joseph Dean</h3>
                                            </div>
                                            <div class="user-btns">
                                                <a href="#" class="button">View</a>
                                                <a href="#" class="button">Edit</a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div> */}
            </div>
        </div>
    )
}

export default LandingActiviesTable