import React, { useContext, useEffect } from "react";
import AuthContext from "../../Context/AuthProvider";
import "../../Style/WhatsAppComingSoon.css";
import ContactList from "./ContacList";
import WatiMessagePage from "./WatiMessagepage";

let contact_list = [
  {
    id: "65d85d6db84e257601781fff",
    tenantId: "304958",
    wAid: "918799931000",
    firstName: "918799931000",
    fullName: "918799931000",
    phone: "918799931000",
    source: null,
    contactStatus: "VALID",
    photo: null,
    created: "Feb-23-2024",
    customParams: [
      {
        name: "name",
        value: "918799931000",
      },
      {
        name: "phone",
        value: "918799931000",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-23T08:55:08.839Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d5d338b73d61c11bb1c564",
    tenantId: "304958",
    wAid: "919140823654",
    firstName: "John",
    fullName: "John",
    phone: "919140823654",
    source: null,
    contactStatus: "VALID",
    photo: null,
    created: "Feb-21-2024",
    customParams: [
      {
        name: "name",
        value: "John",
      },
      {
        name: "phone",
        value: "919140823654",
      },
      {
        name: "ordernumber",
        value: "12345",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-21T10:41:43.705Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d4adae2a20ba306126bde4",
    tenantId: "304958",
    wAid: "919119059286",
    firstName: "919119059286",
    fullName: "919119059286",
    phone: "919119059286",
    source: null,
    contactStatus: "VALID",
    photo: null,
    created: "Feb-20-2024",
    customParams: [
      {
        name: "name",
        value: "919119059286",
      },
      {
        name: "phone",
        value: "919119059286",
      },
      {
        name: "member",
        value: "VIP",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-21T09:39:30.98Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d4edae5e1cc757dbfad1ac",
    tenantId: "304958",
    wAid: "919756143731",
    firstName: "ABC",
    fullName: "ABC",
    phone: "919756143731",
    source: null,
    contactStatus: "UNCONFIRMED",
    photo: null,
    created: "Feb-20-2024",
    customParams: [
      {
        name: "member",
        value: "VIP",
      },
      {
        name: "name",
        value: "ABC",
      },
      {
        name: "phone",
        value: "919756143731",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-20T18:21:34.789Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d4c6774e13f03ecdbda254",
    tenantId: "304958",
    wAid: "919501868775",
    firstName: "919501868775",
    fullName: "919501868775",
    phone: "919501868775",
    source: null,
    contactStatus: "INVALID",
    photo: null,
    created: "Feb-20-2024",
    customParams: [
      {
        name: "name",
        value: "919501868775",
      },
      {
        name: "phone",
        value: "919501868775",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-20T15:34:15.045Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d44a949034a574de168381",
    tenantId: "304958",
    wAid: "91917409697987",
    firstName: "divyanshu sharma",
    fullName: "divyanshu sharma",
    phone: "91917409697987",
    source: null,
    contactStatus: "INVALID",
    photo: null,
    created: "Feb-20-2024",
    customParams: [
      {
        name: "name",
        value: "divyanshu sharma",
      },
      {
        name: "phone",
        value: "91917409697987",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-20T06:45:40.079Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d44a949034aasfasf574de168381",
    tenantId: "304958",
    wAid: "91917409697987",
    firstName: "divyanshu sharma",
    fullName: "divyanshu sharma",
    phone: "91917409697987",
    source: null,
    contactStatus: "INVALID",
    photo: null,
    created: "Feb-20-2024",
    customParams: [
      {
        name: "name",
        value: "divyanshu sharma",
      },
      {
        name: "phone",
        value: "91917409697987",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-20T06:45:40.079Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d44a949034a574de1683gdsgsdf81",
    tenantId: "304958",
    wAid: "91917409697987",
    firstName: "divyanshu sharma",
    fullName: "divyanshu sharma",
    phone: "91917409697987",
    source: null,
    contactStatus: "INVALID",
    photo: null,
    created: "Feb-20-2024",
    customParams: [
      {
        name: "name",
        value: "divyanshu sharma",
      },
      {
        name: "phone",
        value: "91917409697987",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-20T06:45:40.079Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d44a949034a574de16asdsafsdgd8381",
    tenantId: "304958",
    wAid: "91917409697987",
    firstName: "divyanshu sharma",
    fullName: "divyanshu sharma",
    phone: "91917409697987",
    source: null,
    contactStatus: "INVALID",
    photo: null,
    created: "Feb-20-2024",
    customParams: [
      {
        name: "name",
        value: "divyanshu sharma",
      },
      {
        name: "phone",
        value: "91917409697987",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-20T06:45:40.079Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d44a949034a574de168asdasfdafds381",
    tenantId: "304958",
    wAid: "91917409697987",
    firstName: "divyanshu sharma",
    fullName: "divyanshu sharma",
    phone: "91917409697987",
    source: null,
    contactStatus: "INVALID",
    photo: null,
    created: "Feb-20-2024",
    customParams: [
      {
        name: "name",
        value: "divyanshu sharma",
      },
      {
        name: "phone",
        value: "91917409697987",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-20T06:45:40.079Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d44a94asdasfdsgfdhgd9034a574de168381",
    tenantId: "304958",
    wAid: "91917409697987",
    firstName: "divyanshu sharma",
    fullName: "divyanshu sharma",
    phone: "91917409697987",
    source: null,
    contactStatus: "INVALID",
    photo: null,
    created: "Feb-20-2024",
    customParams: [
      {
        name: "name",
        value: "divyanshu sharma",
      },
      {
        name: "phone",
        value: "91917409697987",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-20T06:45:40.079Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d44a949034a574sdgfdde168asfsd381",
    tenantId: "304958",
    wAid: "91917409697987",
    firstName: "divyanshu sharma",
    fullName: "divyanshu sharma",
    phone: "91917409697987",
    source: null,
    contactStatus: "INVALID",
    photo: null,
    created: "Feb-20-2024",
    customParams: [
      {
        name: "name",
        value: "divyanshu sharma",
      },
      {
        name: "phone",
        value: "91917409697987",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-20T06:45:40.079Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
  {
    id: "65d4asdasfdsg4a949034a574de168381",
    tenantId: "304958",
    wAid: "91917409697987",
    firstName: "divyanshu sharma",
    fullName: "divyanshu sharma",
    phone: "91917409697987",
    source: null,
    contactStatus: "INVALID",
    photo: null,
    created: "Feb-20-2024",
    customParams: [
      {
        name: "name",
        value: "divyanshu sharma",
      },
      {
        name: "phone",
        value: "91917409697987",
      },
    ],
    optedIn: false,
    isDeleted: false,
    lastUpdated: "2024-02-20T06:45:40.079Z",
    allowBroadcast: true,
    allowSMS: true,
    teamIds: ["65cc8805601e37c05d87a6c0"],
    isInFlow: false,
    lastFlowId: null,
    currentFlowNodeId: null,
    selectedHubspotId: null,
  },
];
function WatiChatMain() {
  const {
    watiContactList,
    FetchContactListWati,
    setWatiSelectedContact,
    watiSelectedContact,
  } = useContext(AuthContext);

  useEffect(() => {
    FetchContactListWati();
    setWatiSelectedContact(watiSelectedContact);
  }, [watiSelectedContact]);
  return (
    <div style={{ display: "flex", borderColor: "red" }}>
      <div
        style={{
          height: "100vh",
          overflow: "auto",
          // backgroundColor: "red",
          width: "30rem",
        }}
      >
        <div style={{ flex: "0 0 30%", height: "100%" }}>
          <ContactList data={watiContactList} />
        </div>
      </div>
      <WatiMessagePage />
    </div>
  );
}

export default WatiChatMain;
