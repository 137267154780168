import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import AuthContext from '../Context/AuthProvider';
import "../Style/ReservationDeskFilter.css";

const ReservationDeskFilter = (props) => {
    const { sendFilterBookingIdRequest,sendFilterBookingPaymentStatusRequest, sendFilterDatesRequest, EngineUrl, setBookingData,
        fetchLoneStarThisMonthBookings,fetchLoneStarBookings,fetchDateRangeBookings,fetchLoneStarBookingIdBookings,
        fetchLoneStarBookingPaymentstatusBookings } = useContext(AuthContext)


    const { mheading, setAllBookings } = props;

    const [selectedValue, setSelectedValue] = useState('div0');
    const [selectedNumber, setselectedNumber] = useState('1')
    const [bookingId, setBookingId] = useState('');
    const [checkinDate, setCheckinDate] = useState('')
    const [checkoutDate, setCheckoutDate] = useState('')

    


    




    const handleOptionChange = (event) => {
        setSelectedValue(event.target.value);

    };

    const handlePayStatusChange = (event) => {
        
        var type = event.target.value;
        setselectedNumber(event.target.value);
        fetchLoneStarBookingPaymentstatusBookings(type)

        
    };
    return (
        <div className='reservationdesktable'>
            <h3>{mheading}</h3>
            <div className="b-filters">
                {/* <h5>Filters</h5> */}
                {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                            inline
                            label="All"
                            name="group1"
                            type={type}
                            checked={selectedValue === 'div0'}
                            value="div0"
                            onClick={(e)=>{handleOptionChange(e);fetchLoneStarBookings()}}
                            id={`inline-${type}-2`}
                        />
                        <Form.Check
                            inline
                            label="This Month"
                            name="group1"
                            type={type}
                            checked={selectedValue === 'div01'}
                            value="div01"
                            onClick={(e)=>{handleOptionChange(e);fetchLoneStarThisMonthBookings()}}
                            id={`inline-${type}-2`}
                        />
                        <Form.Check
                            inline
                            label="Date Range Bookings"
                            name="group1"
                            type={type}
                            value="div1"
                            checked={selectedValue === 'div1'}
                            onChange={handleOptionChange}
                            id={`inline-${type}-2`}
                        />

                        <Form.Check
                            inline
                            label="Single Booking Search"
                            name="group1"
                            type={type}
                            value="div2"
                            checked={selectedValue === 'div2'}
                            onChange={handleOptionChange}
                            id={`inline-${type}-2`}
                        />
                        <Form.Check
                            inline
                            label="Filter Booking Status"
                            name="group1"
                            type={type}
                            value="div3"
                            checked={selectedValue === 'div3'}
                            onChange={handleOptionChange}
                            id={`inline-${type}-3`}
                        />
                        {/* <button className='btn btn-danger' onClick={() => { setSelectedValue("") }}>close</button> */}
                    </div>
                ))}
            </div>
            <div className="filter">
                <div id="div0" style={{ display: selectedValue === 'div0' ? 'block' : 'none' }}>
                </div>
                <div id="div01" style={{ display: selectedValue === 'div01' ? 'block' : 'none' }}>
                </div>

                <div id="div1" style={{ display: selectedValue === 'div1' ? 'block' : 'none' }}>

                    <div className="date-fltr">
                        <div className="dt-flt-inr">
                            <div className="book-chk">
                                <label htmlFor="/">Reservations From:</label>
                                <input className='dates' type="date" name="" id="checkin" value={checkinDate} onChange={(e) => { setCheckinDate(e.target.value) }} /></div>
                            <div className="book-chk">
                                <label htmlFor="/">Reservations To:</label>
                                <input className='dates' type="date" name="" id="checkout" value={checkoutDate} onChange={(e) => { setCheckoutDate(e.target.value) }} /></div>
                        </div>

                    </div>
                    <Button className="btn" onClick={() => { fetchDateRangeBookings(checkinDate, checkoutDate) }}>Show Bookings</Button>
                </div>


                <div className="sngle-bkng" id='div2' style={{ display: selectedValue === 'div2' ? 'block' : 'none' }}>
                    <span>booking id:</span>
                    <input className='text' type="text" id="BookingId" value={bookingId} onChange={(e) => { setBookingId(e.target.value) }} placeholder='Enter Booking Id' />
                    <div className="fltr-btn">
                        <Button className="btn" onClick={() => { fetchLoneStarBookingIdBookings(bookingId) }}>Show Bookings</Button>
                    </div>
                </div>

                <div className="sngle-bkng" id='div3' style={{ display: selectedValue === 'div3' ? 'block' : 'none' }}>
                    {['radio'].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                            
                            <Form.Check
                                inline
                                label="ADVANCED"
                                name="group1"
                                type={type}
                                value="1"
                                checked={selectedNumber === '1'}
                                onChange={handlePayStatusChange}
                                id={`inline-${type}-2`}
                            />
                            <Form.Check
                                inline
                                label="SUCCESS"
                                name="group1"
                                type={type}
                                value="3"
                                checked={selectedNumber === '2'}
                                onChange={handlePayStatusChange}
                                id={`inline-${type}-3`}
                            />
                        </div>
                    ))}
                </div>


            </div>
        </div>

    )
}

export default ReservationDeskFilter
