import React, { useContext, useEffect, useState } from "react";
import BackBtn from "../../Components/BackBtn";
import { FaPlus } from "react-icons/fa";
import AuthContext from "../../Context/AuthProvider";
import { HashLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function CmsEvents() {
  //     events
  // setEvents
  const [text, setText] = useState("");
  const [heading, setHeading] = useState("");
  const [image, setImage] = useState("None");

  const {
    EditEvent,
    EngineUrl,
    setEvents,
    events,
    WebsiteData,
    AddandDeleteEvent,
  } = useContext(AuthContext);
  useEffect(() => {
    WebsiteData();
  }, []);
  async function handleAdd() {
    if (heading === "" || text === "" || image === "None") {
      alert("Please enter required details and select some image");
      return;
    }
    let obj = {
      operation: "append",
      Image: image,
      Text: text,
      Heading: heading,
    };
    const resp = await AddandDeleteEvent(obj);
    if (resp) {
      toast.success("Deleted Successfully");
      WebsiteData();
    } else {
      toast.error("Deletion Failed");
    }
    setText("");
    setHeading("");
    setImage("None");
    WebsiteData();
  }
  function uploadImagepage(e) {
    e.preventDefault();
    const imageInput = document.getElementById("imageFile"); // Corrected ID
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setImage(data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function handleDelete(idx) {
    let obj = {
      operation: "pop",
      index: idx,
      Image: events[idx].Image,
      Text: events[idx].Text,
      Heading: events[idx].Heading,
    };
    const resp = await AddandDeleteEvent(obj);
    if (resp) {
      toast.success("Deleted Successfully");
      WebsiteData();
    } else {
      toast.error("Deletion Failed");
    }
  }
  function changeCardTitleandDesc(e, idx, fieldName) {
    console.log(fieldName);
    setEvents(
      events.map((event, index) =>
        index === idx ? { ...event, [fieldName]: e.target.value } : event
      )
    );
  }
  async function EditEventHandle(idx) {
    let obj = {
      Image: events[idx].Image,
      Text: events[idx].Text,
      Heading: events[idx].Heading,
      index: idx,
    };
    const resp = await EditEvent(obj);
    if (resp) {
      toast.success("Edited Successfully");
    } else {
      toast.error("Edition Failed");
    }
  }
  async function uploadImageCard(e, idx) {
    e.preventDefault();
    const imageInput = document.getElementById(`imageFileCard_${idx}`);
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }
    console.log("ASD");

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      uploadImageS3Card(base64String, idx);
    };
    reader.readAsDataURL(file);
  }
  function uploadImageS3Card(base64String, idx) {
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setEvents(
          events.map((event, index) =>
            index === idx ? { ...event, Image: data.Image } : event
          )
        );
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  return (
    <>
      {events === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <ToastContainer />
          <div
            className="homHead w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 0 20px 0",
            }}
          >
            <BackBtn />
            <h3 className="cmsHeading my-3">Events</h3>
            <br />
          </div>
          <div className="EventMain">
            <div className="eventmain_inputdiv">
              <label htmlFor="#">Heading:</label>
              <input
                type="text"
                placeholder="Enter Here"
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
              />
            </div>
            <div className="eventmain_inputdiv">
              <label htmlFor="#">Text:</label>
              <input
                type="text"
                placeholder="Enter Here"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
            <div className="eventPicDiv my-4">
              <div className="Event_imgDiv">
                <div className="EventcmsImgInput">
                  <label htmlFor="imageFile" className="upload">
                    {" "}
                    {/* Added htmlFor attribute */}
                    <span className="cmsupldspn">Upload Image</span>
                    <FaPlus className="cmsplusicon" />
                    <input
                      type="file"
                      id="imageFile"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ display: "none" }}
                      onChange={(e) => uploadImagepage(e)}
                    />
                  </label>
                </div>
                <div className="evntupl_img">
                  {image === "None" ? (
                    <FontAwesomeIcon
                      icon={faFileImage}
                      size="lg"
                      style={{ width: "10rem", height: "10rem" }}
                    />
                  ) : (
                    <img
                      className="evntImg"
                      src={image}
                      alt="IMG"
                      value={Image}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button className="btn btn-success" onClick={() => handleAdd()}>
                  Add
                </button>
              </div>
            </div>

            <div className="eventdiv_cardDiv" style={{ gap: "3rem" }}>
              {events.map((val, idx) => (
                <div class="card" style={{ width: "18rem" }}>
                  <img src={val.Image} class="card-img-top" alt="..." />
                  <div className="Event_imgDiv" style={{ height: "4rem" }}>
                    <label htmlFor={`imageFileCard_${idx}`} className="upload">
                      {" "}
                      {/* Added htmlFor attribute */}
                      <span>Upload Image</span>
                      <input
                        type="file"
                        id={`imageFileCard_${idx}`}
                        accept="image/png, image/gif, image/jpeg"
                        style={{ display: "none" }}
                        onChange={(e) => uploadImageCard(e, idx)}
                      />
                    </label>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div style={{ width: "90%" }}>
                      <input
                        onChange={(e) =>
                          changeCardTitleandDesc(e, idx, "Heading")
                        }
                        value={val.Heading}
                        style={{
                          width: "100%",
                          marginLeft: "1rem",
                          marginBottom: "1rem",
                          height: "2rem",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = "#f0f0f0")
                        } // Change background color on hover
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = "initial")
                        } // Reset background color on hover out
                      />
                    </div>
                    <div style={{ width: "90%" }}>
                      <input
                        onChange={(e) => changeCardTitleandDesc(e, idx, "Text")}
                        value={val.Text}
                        style={{
                          width: "100%",
                          marginLeft: "1rem",
                          height: "2rem",
                          marginBottom: "1rem",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = "#f0f0f0")
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = "initial")
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "1rem",
                        gap: "3rem",
                      }}
                    >
                      <a
                        href="#"
                        class="btn btn-primary"
                        onClick={() => EditEventHandle(idx)}
                      >
                        Edit
                      </a>
                      <a
                        href="#"
                        class="btn btn-danger"
                        onClick={() => handleDelete(idx)}
                      >
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CmsEvents;
