import React, { useContext, useEffect } from 'react'
import BookingTab from './BookingTab';
import '../../Style/BookingEngine.css'
import DashBackBtn from '../../Components/DashBackBtn';
import AuthContext from '../../Context/AuthProvider';
import BookingEngineRoomCard from '../../Components/BookingEngineRoomCard';

function BookingEngine() {
  const {fetchBookingDatatData} = useContext(AuthContext)
  useEffect(() => {
    fetchBookingDatatData()
  }, [])
  
  return (
    <>
      <div className='BookingHead'>
        <DashBackBtn />
        {/* <h2 className="text-center">Booking Engine</h2><br /> */}
      </div>
      <div className='py-4'>
        <BookingTab />
      </div>
    </>
  )
}

export default BookingEngine
