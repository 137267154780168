import React from "react";
import BackBtn from "../../Components/BackBtn";
import SaveBtn from "../../Components/SaveBtn";
import CmsRestaurantCard from "../../Components/CmsRestaurantCard";
import comingsoon from "../../Images/comingsoon.png";
import { useContext, useRef } from "react";
import JoditEditor from "jodit-react";
import AuthContext from "../../Context/AuthProvider";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import { FaPlus } from "react-icons/fa";
import { HashLoader } from "react-spinners";
function CmsReataurants() {
  const {
    baseUrl,
    websiteRestaurantData,
    setwebsiteRestaurantData,
    joditConfig,
  } = useContext(AuthContext);
  const editor = useRef(null);
  const [Name, setName] = useState("");
  const [Price, setPrice] = useState("");
  const [Image, setImage] = useState("");
  const [Description, setDescription] = useState("");

  function uploadImage(e) {
    e.preventDefault();
    const imageInput = document.getElementById("file");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${baseUrl}/api/uploadImage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: window.localStorage.getItem("Token"),
        Image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setImage(data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const AddDishes = () => {
    setwebsiteRestaurantData(
      websiteRestaurantData.concat({
        Name: Name,
        Price: Price,
        Image: Image,
        Description: Description,
      })
    );
    fetch(`${baseUrl}/api/dashboard/editMenu`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
        operation: "append",
        image: Image,
        name: Name,
        price: Price,
        Description: Description,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setName("");
        setPrice("");
        setImage("");
        alert("Added");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {websiteRestaurantData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div
            className="homHead w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 0 20px 0",
            }}
          >
            <BackBtn />
            <h3 className="text-center">Restaurants</h3>
            <Form>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>

          <div className="Cmsrestaurant">
            <div className="CmsrestaurantForm d-flex gap-20">
              <div className="CmsrestaurantDiv CmsrestaurantHeading">
                <label htmlFor="/">Dish Name:</label>
                {/* <JoditEditor
            ref={editor}
            value={Name}
            onChange={(content) => { setName(content) }}
            config={joditConfig}
          /> */}
                <input
                  type="text"
                  className="CmsServiceHeadingInput"
                  value={Name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Enter Heading Here"
                />
              </div>
              <div className="CmsrestaurantDiv CmsrestaurantHeading">
                <label htmlFor="/">Dish Price:</label>
                <input
                  type="text"
                  className="CmsServiceHeadingInput"
                  value={Price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                  placeholder="Enter Price Here"
                />
              </div>
              <div className="CmsrestaurantDiv CmsrestaurantHeading">
                <label htmlFor="/">Dish Description:</label>
                <input
                  type="text"
                  className="CmsServiceHeadingInput"
                  value={Description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  placeholder="Enter Price Here"
                />
              </div>

              <div className="Service_imgDiv">
                <div className="CmsRestaurantImgInput">
                  <label htmlFor="/">Image:</label>

                  <button className="upload">
                    <span className="cmsupldspn">Upload Image</span>
                    <FaPlus className="cmsplusicon" />
                    <input type="file" id="file" onChange={uploadImage} />
                  </button>
                </div>
                <div className="ImgDivs">
                  <img src={Image} alt="" />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center w-100 my-3">
              <button className="saveBtn btn" onClick={AddDishes}>
                Save
              </button>
            </div>

            <h3 style={{ fontWeight: "600" }}>Dish Preview</h3>
            <div className="Cmsrestaurant_cards my-3">
              {websiteRestaurantData.map((dish) => {
                return (
                  <CmsRestaurantCard
                    Image={dish.Image}
                    Name={dish.Name}
                    Price={dish.Price}
                    Description={dish.Description}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CmsReataurants;
