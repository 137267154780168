import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import AuthContext from "../Context/AuthProvider";
import "../Style/ReservationDeskBanner.css";

function CustomModal({ show, handleClose, title, tableData }) {
  const { EngineUrl, fetchBookingData, fetchCheckInData, fetchCheckOutData } =
    useContext(AuthContext);

  const BookingCehckinStatus = async (booking, checkin, checkout) => {
    const response = await fetch(`${EngineUrl}/booking/payment/checkinout`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        bookingid: booking,
        isCheckedin: checkin,
        isCheckout: checkout,
      }),
    });
    console.log(await response.json());
    fetchBookingData();
    fetchCheckInData();
    fetchCheckOutData();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-1">
        <Table
          striped
          bordered
          hover
          className="ReservationDeskTable  mt-0 mb-0"
        >
          <thead>
            <tr className="tablerow">
              <th>Booking Id</th>
              <th>Guest Name</th>
              <th>Adult / Child</th>
              <th>Check In</th>
              <th>Check Out</th>
              <th>Total Price</th>
              <th>Payment Status</th>
              <th>CheckIn</th>
              <th>CheckedOut</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{row.bookingId}</td>
                <td>{row.guestInfo.guestName}</td>
                <td>
                  {row.Adults} / {row.Kids}
                </td>
                <td>{row.checkIn}</td>
                <td>{row.checkOut}</td>
                <td>{row.price.Total} </td>
                <td>
                  {row.payment.Status === "PENDING" ? (
                    <span className="badge bg-danger">
                      {row.payment.Status}
                    </span>
                  ) : row.payment.Status === "ADVANCED" ? (
                    <span className="badge bg-warning">
                      {row.payment.Status}
                    </span>
                  ) : row.payment.Status === "CANCELLED" ? (
                    <span className="badge bg-secondary">
                      {row.payment.Status}
                    </span>
                  ) : (
                    <span className="badge bg-success">
                      {row.payment.Status}
                    </span>
                  )}
                </td>
                <td>
                  {row.payment.Status === "CANCELLED" ? (
                    "-"
                  ) : row.isCheckedIn === true ? (
                    <span className="badge bg-success">Checked In</span>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        BookingCehckinStatus(row.bookingId, "true", "false");
                      }}
                    >
                      Check In
                    </button>
                  )}
                </td>
                <td>
                  {row.payment.Status === "CANCELLED" ? (
                    "-"
                  ) : row.isCheckedIn === true && row.isCheckedOut === true ? (
                    <span className="badge bg-danger">Checked Out</span>
                  ) : row.isCheckedIn === false ? (
                    "-"
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        BookingCehckinStatus(row.bookingId, "true", "true");
                      }}
                    >
                      Check Out
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

const ReservationDeskBanner = () => {
  const { fetchCheckInData, tCheckIn, fetchCheckOutData, tCheckOut } =
    useContext(AuthContext);
  // CheckIn Modal
  const [showIn, setShowIn] = useState(false);
  const handleCloseIn = () => setShowIn(false);
  const handleShowIn = () => setShowIn(true);

  // CheckOut Modal
  const [showOut, setShowOut] = useState(false);
  const handleCloseOut = () => setShowOut(false);
  const handleShowOut = () => setShowOut(true);

  const checkInTableData = [
    {
      bookingId: "1231323",
      currency: "INR",
      guestName: "John Doe",
      guestInfo: {
        EmailId: "johndoe@example.com",
        Phone: "+1 123-456-7890",
        City: "New York",
        Country: "USA",
      },
      Adults: 2,
      Kids: 1,
      Bookings: [
        { RoomType: "1", Qty: 2 },
        { RoomType: "2", Qty: 1 },
        { RoomType: "3", Qty: 1 },
        { RoomType: "4", Qty: 0 },
      ],
      payment: {
        Status: "PENDING",
        RefNo: "",
        PaymentProvider: "RazorPay",
        Mode: "Online",
      },
      checkIn: "2023-10-10",
      checkOut: "2023-10-15",
      price: {
        Principal: 1500,
        Tax: 250,
        Total: 1750,
      },
      isCheckedIn: true,
      isCheckedOut: false,
    },
    {
      bookingId: "987654",
      currency: "USD",
      guestName: "Alice Smith",
      guestInfo: {
        EmailId: "alice.smith@example.com",
        Phone: "+1 555-123-4567",
        City: "Los Angeles",
        Country: "USA",
      },
      Adults: 3,
      Kids: 0,
      Bookings: [
        { RoomType: "1", Qty: 1 },
        { RoomType: "2", Qty: 2 },
        { RoomType: "3", Qty: 0 },
        { RoomType: "4", Qty: 1 },
      ],
      payment: {
        Status: "PAID",
        RefNo: "123456789",
        PaymentProvider: "PayPal",
        Mode: "Online",
      },
      checkIn: "2023-11-05",
      checkOut: "2023-11-10",
      price: {
        Principal: 2000,
        Tax: 300,
        Total: 2300,
      },
      isCheckedIn: true,
      isCheckedOut: false,
    },
  ];

  const checkOutTableData = [
    {
      bookingId: "1231323",
      currency: "INR",
      guestName: "John Doe",
      guestInfo: {
        EmailId: "johndoe@example.com",
        Phone: "+1 123-456-7890",
        City: "New York",
        Country: "USA",
      },
      Adults: 2,
      Kids: 1,
      Bookings: [
        { RoomType: "1", Qty: 2 },
        { RoomType: "2", Qty: 1 },
        { RoomType: "3", Qty: 1 },
        { RoomType: "4", Qty: 0 },
      ],
      payment: {
        Status: "PENDING",
        RefNo: "",
        PaymentProvider: "RazorPay",
        Mode: "Online",
      },
      checkIn: "2023-10-10",
      checkOut: "2023-10-15",
      price: {
        Principal: 1500,
        Tax: 250,
        Total: 1750,
      },
      isCheckedIn: false,
      isCheckedOut: true,
    },
    {
      bookingId: "987654",
      currency: "USD",
      guestName: "Alice Smith",
      guestInfo: {
        EmailId: "alice.smith@example.com",
        Phone: "+1 555-123-4567",
        City: "Los Angeles",
        Country: "USA",
      },
      Adults: 3,
      Kids: 0,
      Bookings: [
        { RoomType: "1", Qty: 1 },
        { RoomType: "2", Qty: 2 },
        { RoomType: "3", Qty: 0 },
        { RoomType: "4", Qty: 1 },
      ],
      payment: {
        Status: "PAID",
        RefNo: "123456789",
        PaymentProvider: "PayPal",
        Mode: "Online",
      },
      checkIn: "2023-11-05",
      checkOut: "2023-11-10",
      price: {
        Principal: 2000,
        Tax: 300,
        Total: 2300,
      },
      isCheckedIn: false,
      isCheckedOut: true,
    },
  ];

  useEffect(() => {
    fetchCheckInData();
    fetchCheckOutData();
  }, []);

  return (
    <>
      <div className="reservationdeskbanner">
        <div className="card-1">
          <h1>{tCheckIn ? tCheckIn.Checkin : 0}</h1>
          <div className="cardDiv-2">
            <h4>Today's CheckIn</h4>
            <button className="btn btn-primary" onClick={handleShowIn}>
              View
            </button>
          </div>
        </div>
        <div className="card-1">
          <h1>{tCheckOut ? tCheckOut.Checkout : 0}</h1>
          <div className="cardDiv-2">
            <h4>Today's CheckOut</h4>
            <button className="btn btn-primary" onClick={handleShowOut}>
              View
            </button>
          </div>
        </div>
      </div>
      <CustomModal
        show={showIn}
        handleClose={handleCloseIn}
        title="Today's CheckIn"
        tableData={tCheckIn != null ? tCheckIn.Details : checkInTableData}
      />
      <CustomModal
        show={showOut}
        handleClose={handleCloseOut}
        title="Today's CheckOut"
        tableData={tCheckOut != null ? tCheckOut.Details : checkOutTableData}
      />
    </>
  );
};

export default ReservationDeskBanner;
