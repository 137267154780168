import JoditEditor from "jodit-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { HashLoader } from "react-spinners";

import { ToastContainer, toast } from "react-toastify";
import BackBtn from "../../Components/BackBtn";
import AuthContext from "../../Context/AuthProvider";
function CMSTnc() {
  const editor = useRef(null);

  const [editable, setEditable] = useState("None");
  const [Text, setText] = useState("");
  const [content, setContent] = useState("");
  async function handleChangeimage(fieldName, value, key) {
    let name = "";
    if (key === "Privacy") {
      name = "Privacy";
    } else if (key === "Cancellation") {
      name = "Cancellation";
    } else if (key === "TermsServices") {
      name = "Terms";
    }
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      [name]: {
        ...prevPagetitles[name], // Access the nested object using square brackets
        [fieldName]: value,
      },
    }));
  }
  const {
    EditPageTitle,
    setPagetitles,
    pagetitles,
    termsAndCondition,
    setTermsAndCondition,
    WebsiteData,
    EngineUrl,
    EditTermAndCondition,
  } = useContext(AuthContext);
  async function handleChangeJodit(idx, name, value) {
    const updatedTerms = [...termsAndCondition]; // Create a copy of the array
    updatedTerms[idx][name] = value; // Update the value at the specified index
    setTermsAndCondition(updatedTerms); // Update the state with the modified array
  }
  async function handleChangeTitle(fieldname, e, key) {
    let name = "";
    if (key === "Privacy") {
      name = "Privacy";
    } else if (key === "Cancellation") {
      name = "Cancellation";
    } else if (key === "TermsServices") {
      name = "Terms";
    }
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      [name]: {
        ...prevPagetitles[name], // Access the nested object using square brackets
        [fieldname]: e.target.value,
      },
    }));
  }

  async function handlesubmit() {
    let obj = {}; // Create an empty object
    termsAndCondition.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        obj[key] = value; // Assign key-value pair to the obj
      });
    });

    const resp = await EditTermAndCondition(obj);
    if (resp) {
      toast.success("Updated successfully");
    } else {
      toast.error("Error Occured");
    }
  }
  async function submitPagetitleSave(key) {
    let name = "";
    if (key === "Privacy") {
      name = "Privacy";
    } else if (key === "Cancellation") {
      name = "Cancellation";
    } else if (key === "TermsServices") {
      name = "Terms";
    }
    setEditable("None");
    let obj = {
      key: name,
      Title: pagetitles[name].Title,
      Description: pagetitles[name].Description,
      Image: pagetitles[name].Image,
      Video: pagetitles[name].Video,
    };
    const resp = await EditPageTitle(obj);
    if (resp === true) {
      toast.success("Edited Successfully");
    } else {
      toast.error("Updation Failed");
    }
    WebsiteData();
  }
  function uploadImagepagetitle(e, key) {
    e.preventDefault();
    const imageInput = document.getElementById("aboutfileimage");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3pagetitle(base64String, key);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3pagetitle(base64String, key) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        handleChangeimage("Image", data.Image, key);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    WebsiteData();
  }, []);
  return (
    <>
      {termsAndCondition === "None" || pagetitles === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <ToastContainer />
          <div className="d-flex justify-content-between my-2">
            <BackBtn />
          </div>
          
          <br />
          <div className="mainAdvr">
            {/* <Button onClick={AddQuestionAnswer}>Add</Button> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                gap: "3rem",
              }}
            >
              {termsAndCondition.map((obj, idx) => (
                <div key={idx} style={{ width: "100%" }}>
                  {Object.entries(obj).map(([key, value]) => (
                    <>
                      <div
                        style={{
                          position: "relative",
                          width: "79vw",
                          height: "50vh",
                        }}
                      >
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={
                            pagetitles[
                              key === "Privacy"
                                ? "Privacy"
                                : key === "Cancellation"
                                ? "Cancellation"
                                : "Terms"
                            ].Image
                          }
                          alt="Gallery Image"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                            color: "white",
                          }}
                        >
                          <>
                            {editable === key ? (
                              <>
                                <div className="boldInput">
                                  <input
                                    className="sassyInput boldInput"
                                    value={
                                      pagetitles[
                                        key === "Privacy"
                                          ? "Privacy"
                                          : key === "Cancellation"
                                          ? "Cancellation"
                                          : "Terms"
                                      ].Title
                                    }
                                    onChange={(e) =>
                                      handleChangeTitle("Title", e, key)
                                    }
                                    placeholder="Enter Title"
                                  />
                                </div>
                                <div>
                                  <input
                                    className="sassyInput"
                                    value={
                                      pagetitles[
                                        key === "Privacy"
                                          ? "Privacy"
                                          : key === "Cancellation"
                                          ? "Cancellation"
                                          : "Terms"
                                      ].Description
                                    }
                                    onChange={(e) =>
                                      handleChangeTitle("Description", e, key)
                                    }
                                    placeholder="Enter Description"
                                  />
                                </div>
                                <div>
                                  <input
                                    type="file"
                                    id="aboutfileimage"
                                    onChange={(e) => {
                                      uploadImagepagetitle(e, key);
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "2.5em",
                                  }}
                                >
                                  {
                                    pagetitles[
                                      key === "Privacy"
                                        ? "Privacy"
                                        : key === "Cancellation"
                                        ? "Cancellation"
                                        : "Terms"
                                    ].Title
                                  }
                                </div>
                                <div style={{ fontSize: "1.5em" }}>
                                  {pagetitles.About.Description}
                                </div>
                              </>
                            )}
                          </>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: "90%",
                            left: "95%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                            color: "white",
                          }}
                        >
                          {editable === key ? (
                            <button
                              style={{
                                backgroundColor: "#28a745",
                                color: "#fff",
                                border: "1px solid #28a745",
                                borderRadius: "4px",
                                padding: "10px 20px",
                                cursor: "pointer",
                              }}
                              onClick={() => submitPagetitleSave(key)}
                            >
                              SAVE
                            </button>
                          ) : (
                            <div>
                              <button
                                style={{
                                  backgroundColor: "#007bff",
                                  color: "#fff",
                                  border: "1px solid #007bff",
                                  borderRadius: "4px",
                                  padding: "10px 20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => setEditable(key)}
                              >
                                EDIT
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="CmsService_joditEditoore my-3">
                        <label htmlFor="/">{key}</label>
                        <JoditEditor
                          ref={editor}
                          value={value}
                          onChange={(newContent) => {
                            setText(newContent);
                            handleChangeJodit(idx, key, newContent);
                          }}
                          className="my-3"
                        />
                        {/* <textarea rows="6" col="12" style={{width:"100%"}}  value={Text} onChange={(e)=>{setText(e.target.value)}} ></textarea> */}
                      </div>
                    </>
                  ))}
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "4rem",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{ marginRight: "4rem" }}
                  onClick={() => {
                    handlesubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CMSTnc;
