import React, { useContext } from 'react'
import "../Style/AddUserPopUp.css"
import { GrClose } from 'react-icons/gr'
import { useState } from 'react'
import AuthContext from "../Context/AuthProvider.js"

const EditUserMagPopup = () => {
    const { isEditpPop, setIsEditPop, userEditdata,EngineNewUrl,fetchUserManagementData, setUserEditData, setPopup } = useContext(AuthContext);
    console.log(userEditdata)
    const [Cms, setCms] = useState(userEditdata.accessScope.cms)
    const [bookingEngine, setbookingEngine] = useState(userEditdata.accessScope.bookingEngine)
    const [socialMedia, setsocialMedia] = useState(userEditdata.accessScope.socialMedia)
    const [reservationDesk, setreservationDesk] = useState(userEditdata.accessScope.reservationDesk)
    const [frontDesk, setfrontDesk] = useState(userEditdata.accessScope.frontDesk)
    const [channelManager, setchannelManager] = useState(userEditdata.accessScope.channelManager)
    const [seoManager, setseoManager] = useState(userEditdata.accessScope.seoManager)
    const [foodManager, setfoodManager] = useState(userEditdata.accessScope.foodManager)
    const [themes, setthemes] = useState(userEditdata.accessScope.themes)
    const [gatewayManager, setgatewayManager] = useState(userEditdata.accessScope.gatewayManager)


    const handlecmsChange = () => { setCms(!Cms) }
    const bookingEngineChange = () => { setbookingEngine(!bookingEngine) }
    const socialMediaChange = () => { setsocialMedia(!socialMedia) }
    const reservationDeskChange = () => { setreservationDesk(!reservationDesk) }
    const frontDeskChange = () => { setfrontDesk(!frontDesk) }
    const channelManagerChange = () => { setchannelManager(!channelManager) }
    const seoManagerChange = () => { setseoManager(!seoManager) }
    const foodManagerChange = () => { setfoodManager(!foodManager) }
    const themesChange = () => { setthemes(!themes) }
    const gatewayManagerChange = () => { setgatewayManager(!gatewayManager) }
    // const adminManager = () => {setisAdmin()}

    const [Name, setName] = useState(userEditdata.displayName)
    const [Email, setEmail] = useState(userEditdata.emailId)
    const [Password, setPassword] = useState('')
    const [isAdmin, setisAdmin] = useState('false')

    const updated = async () => {
        const response1 = await fetch(`${EngineNewUrl}/user/edit/${localStorage.getItem('Token')}`, {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(
                {
                    "emailId": Email,
                    "displayName": Name,
                    "userName": Name,
                    "access_id": Password,
                    "isAdmin":isAdmin,
                    "accessScope": {
                        "cms": Cms.toString(),
                        "bookingEngine": bookingEngine.toString(),
                        "socialMedia": socialMedia.toString(),
                        "reservationDesk": reservationDesk.toString(),
                        "frontDesk": frontDesk.toString(),
                        "channelManager": channelManager.toString(),
                        "seoManager": seoManager.toString(),
                        "foodManager": foodManager.toString(),
                        "themes": themes.toString(),
                        "gatewayManager": gatewayManager.toString()
                    }
                }
            ),
        });
        fetchUserManagementData()
        setIsEditPop(false)
    }


    const handleClickModel = () => {
        console.log('handleClickModel')
        setIsEditPop(false)
    }

    return (
        <div className='Addpopup'>


            <div className='userform shadow-lg p-3 mb-5 bg-white rounded'>
                <div className='heading'>
                    <h3>Edit User</h3>
                    <GrClose size={25} style={{ color: '#0A3A75' }} onClick={handleClickModel} className='closebooking' />


                </div>
                <div className='row w-100'>
                    <div className='col-md-6'>
                        <input type='text' className='fromdata' value={Name} placeholder='Name' onChange={(e) => { setName(e.target.value) }} required />

                    </div>
                    <div className='col-md-6'>
                        <input type='email' className='fromdata' value={Email} placeholder='Email' onChange={(e) => { setEmail(e.target.value) }} required />

                    </div>
                </div>
                
                <div className='row w-100' >
                    <div className='col-md-6' style={{ lineHeight: "5px" }}>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                checked={Cms}
                                onChange={() => handlecmsChange()}
                            />
                            <span style={{ width: "100%" }}>CMS</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                checked={bookingEngine}
                                onChange={() => bookingEngineChange()}
                            />
                            <span style={{ width: "100%" }}>Booking Engine</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                checked={socialMedia}
                                onChange={() => socialMediaChange()}
                            />
                            <span style={{ width: "100%" }}>Social Media</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                checked={reservationDesk}
                                onChange={() => reservationDeskChange()}
                            />
                            <span style={{ width: "100%" }}>Reservation Desk</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                checked={frontDesk}
                                onChange={() => frontDeskChange()}
                            />
                            <span style={{ width: "100%" }}>Frontdesk</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                checked={channelManager}
                                onChange={() => channelManagerChange()}
                            />
                            <span style={{ width: "100%" }}>Channel Manager</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                checked={seoManager}
                                onChange={() => seoManagerChange()}
                            />
                            <span style={{ width: "100%" }}>SEO Manager</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                checked={foodManager}
                                onChange={() => foodManagerChange()}
                            />
                            <span style={{ width: "100%" }}>Food Manager</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                checked={themes}
                                onChange={() => themesChange()}
                            />
                            <span style={{ width: "100%" }}>Themes Manager</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                checked={gatewayManager}
                                onChange={() => gatewayManagerChange()}
                            />
                            <span style={{ width: "100%" }}>Gateway Manager</span>
                        </label>
                    </div>

                </div>
                <div className='row w-100'>
                    <div className='col-md-12'>
                        <button className='confirmUser' onClick={updated}>Update</button>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default EditUserMagPopup