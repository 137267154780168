import React, { useContext, useState } from 'react'
import { GrClose } from 'react-icons/gr'
import "../../../Style/AddUserPopUp.css"


import AuthContext from "../../../Context/AuthProvider.js"

const AddUserPopUP = ({ setaddPopup,FetchAllAdmins }) => {
    const [accessScope, setaccessScope] = useState(false)
    const [bookings, setbookings] = useState(false)
    const [clients, setclients] = useState(false)
    const [data, setdata] = useState(false)
    const [domain, setdomain] = useState(false)
    const [gateway, setgateway] = useState(false)
    const [plans, setplans] = useState(false)
    const [profile, setprofile] = useState(false)
    const [seo, setseo] = useState(false)
    const [templates, settemplates] = useState(false)
    const [websites,setwebsites] = useState(false)
    const handleClickModel = () => {
        setaddPopup(false)
    }

    const handlesetaccessScope = () => { setaccessScope(!accessScope) }
    const handlesetbookings = () => { setbookings(!bookings) }
    const handlesetclients = () => { setclients(!clients) }
    const handlesetdata = () => { setdata(!data) }
    const handlesetdomain = () => { setdomain(!domain) }
    const handlesetgateway = () => { setgateway(!gateway) }
    const handlesetplans = () => { setplans(!plans) }
    const handlesetprofile = () => { setprofile(!profile) }
    const handlesetseo = () => { setseo(!seo) }
    const handlesettemplates = () => { settemplates(!templates) }
    const handlesetwebsites = () =>{setwebsites(!websites)}

    const [Name, setName] = useState(null)
    const [Email, setEmail] = useState(null)
    const [Password, setPassword] = useState(null)
    const [Phone,setPhone] = useState(null)
    const [Team,setTeam] = useState(null)
    const [isowner, setisowner] = useState("false")

    const { EngineUrl, fetchUserManagementData,EngineNewUrl } = useContext(AuthContext)

    const addUserToManagement = async () => {
        if (Name === null && Email === null && Password === null) {
            alert("Please fill all the fields")
        }
        else {
            try {
                const response1 = await fetch(`${EngineNewUrl}/admin/add/admin`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(
                        {
                            "token":localStorage.getItem('Token'),
                            "username":Name,
                            "password":Password,
                            "emailId":Email,
                            "phone":Phone,
                            "team":Team,
                            "owner":isowner,
                            "accessScope":{
                                "accessScope": String(accessScope),
                                "bookings": String(bookings),
                                "clients": String(clients),
                                "data": String(data),
                                "domain": String(domain),
                                "gateway": String(gateway),
                                "plans": String(plans),
                                "profile": String(profile),
                                "seo": String(seo),
                                "templates": String(templates),
                                "websites": String(websites)
                            }
                        }
                    ),
                });
                const json1 = await response1.json();
                setaccessScope(false)
                setbookings(false)
                setclients(false)
                setdata(false)
                setdomain(false)
                setgateway(false)
                setplans(false)
                setprofile(false)
                setseo(false)
                settemplates(false)
                setwebsites(false)
                setName(null)
                setEmail(null)
                setPassword(null)
                setPhone(null)
                setTeam(null)

                //   alert(json1.Message)
                FetchAllAdmins()

            }

            catch (error) {
                alert("User Management API error")
            }
        }
    }
    return (
        <div className='Addpopup'>


            <div className='userform shadow-lg p-3 mb-5 bg-white rounded'>
                <div className='heading'>
                    <h3>Add User</h3>
                    <GrClose size={25} style={{ color: '#0A3A75' }} onClick={handleClickModel} className='closebooking' />


                </div>
                <div className='row w-100'>
                    <div className='col-md-6'>
                        <input type='text' className='fromdata' value={Name} placeholder='Name' onChange={(e) => { setName(e.target.value) }} required />

                    </div>
                    <div className='col-md-6'>
                        <input type='email' className='fromdata' value={Email} placeholder='Email' onChange={(e) => { setEmail(e.target.value) }} required />

                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-12'>
                        <input type='phone' className='fromdata' value={Password} onChange={(e) => { setPassword(e.target.value) }} placeholder='Password' required />

                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-12'>
                        <input type='phone' className='fromdata' value={Phone} onChange={(e) => { setPhone(e.target.value) }} placeholder='Phone Number' required />

                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-12'>
                        <input type='phone' className='fromdata' value={Team} onChange={(e) => { setTeam(e.target.value) }} placeholder='Team' required />

                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-6' style={{ lineHeight: "5px" }}>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                
                                onChange={() => handlesetaccessScope()}
                            />
                            <span style={{ width: "100%" }}>Access Scope Access</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => handlesetbookings()}
                            />
                            <span style={{ width: "100%" }}>Bookings Access</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => handlesetclients()}
                            />
                            <span style={{ width: "100%" }}>Clients Access</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => handlesetdata()}
                            />
                            <span style={{ width: "100%" }}>Data Access</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => handlesetdomain()}
                            />
                            <span style={{ width: "100%" }}>Domain Access</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => handlesetgateway()}
                            />
                            <span style={{ width: "100%" }}>Gateway Access</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => handlesetplans()}
                            />
                            <span style={{ width: "100%" }}>Plans Access</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => handlesetprofile()}
                            />
                            <span style={{ width: "100%" }}>Profiles Access</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => handlesetseo()}
                            />
                            <span style={{ width: "100%" }}>SEO Access</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => handlesettemplates()}
                            />
                            <span style={{ width: "100%" }}>Templates Access</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => handlesetwebsites()}
                            />
                            <span style={{ width: "100%" }}>Websites Access</span>
                        </label>
                    </div>

                </div>
                <div className='row w-100'>
                    <div className='col-md-12'>
                        <button className='confirmUser' onClick={() => { addUserToManagement() }}>Add</button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUserPopUP