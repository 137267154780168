import React, { useState } from "react";
import { useContext } from "react";
import "../Style/AddSlugPopup.css";
import AuthContext from "../Context/AuthProvider";

const AddSlugPopup = ({ data, onEditClick }) => {
  const {
    slugEditingData,
    setSlugEditingData,
    websiteSlugsData,
    setwebsiteSlugsData,
  } = useContext(AuthContext);
  // console.log(websiteSlugsData)

  const [dataArray1, setdataArray1] = useState(
    Object.keys(websiteSlugsData).map((page) => ({
      Page: page,
      ...websiteSlugsData[page],
    }))
  );

  //   console.log(dataArray1)
  const [items, setItems] = useState(data);

  function handleDescriptionChange(pageName, newValue) {
    const pageIndex = dataArray1.findIndex((item) => item.Page === pageName);
    if (pageIndex !== -1) {
      // Clone the dataArray1 to make changes
      const updatedDataArray = [...dataArray1];

      // Update the "Slug" property for the specific page
      updatedDataArray[pageIndex] = {
        ...updatedDataArray[pageIndex],
        Slug: newValue,
      };

      setdataArray1(updatedDataArray);
    }
  }

  return (
    <div className="addSlugPop">
      <table className="addslug">
        <thead>
          <tr>
            <th>Page</th>
            <th>Slug</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {dataArray1.map((item, index) => (
            <tr key={index}>
              <td>{item.Page}</td>
              <td>
                <input
                  type="text"
                  name="action"
                  value={item.Slug}
                  onChange={(e) =>
                    handleDescriptionChange(item.Page, e.target.value)
                  }
                  style={{
                    border: "none",
                  }}
                />
              </td>
              <td>
                <button onClick={() => onEditClick(item.Page, item.Slug)}>
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddSlugPopup;
