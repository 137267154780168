import React, { useContext } from 'react'
import "../Style/AddUserPopUp.css"
import { GrClose } from 'react-icons/gr'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa';


import AuthContext from "../Context/AuthProvider.js"
import { Pass } from 'react-bootstrap-icons';

const AddUserPopUP = ({ seteditClick, bannerindex, banneredit }) => {
    const handleClickModel = () => {
        seteditClick(false)
    }
    const [BookingId,setBookingId] = useState(banneredit.BookingId)
    const [Name, setName] = useState(banneredit.Name)
    const [Email, setEmail] = useState(banneredit.Email)
    const [Phone, setPhone] = useState(banneredit.Phone)
    const [GenderSize, setGenderSize] = useState(banneredit.GroupSize)
    const [Gender, setGender] = useState(banneredit.Gender)
    const [Address, setAddress] = useState(banneredit.Address)
    const [State, setState] = useState(banneredit.State)
    const [City, setCity] = useState(banneredit.City)
    const [GovtId, setGovtId] = useState(banneredit.GovtId)
    const [Passport, setPassport] = useState(banneredit.Passport)
    const [Visa, setVisa] = useState(banneredit.Visa)
    const { EngineUrl, fetchUserManagementData, baseUrl, WebsiteData } = useContext(AuthContext)



    


    return (
        <div className='Addpopup'>


            <div className='userform shadow-lg p-3 mb-5 bg-white rounded'>
                <div className='heading'>
                    <h3></h3>
                    <GrClose size={25} style={{ color: '#0A3A75' }} onClick={handleClickModel} className='closebooking' />
                </div>
                <div className='row w-100' >
                <div className='col-md-6'>
                        <label>BookingId</label>
                        <input type='phone' className='fromdata' value={BookingId}  placeholder='Name' required />

                    </div>
                    <div className='col-md-6'>
                        <label>Name</label>
                        <input type='phone' className='fromdata' value={Name}  placeholder='Name' required />

                    </div>
                </div>
                
                <div className='row w-100' >
                <div className='col-md-6'>
                        <label>Email</label>
                        <input type='phone' className='fromdata' value={Email}  placeholder='Email' required />

                    </div>
                    <div className='col-md-6'>
                        <label>Phone</label>
                        <input type='phone' className='fromdata' value={Phone}  placeholder='Email' required />

                    </div>
                </div>
                
                <div className='row w-100' >
                    <div className='col-md-6'>
                        <label>Group Size</label>
                        <input type='phone' className='fromdata' value={GenderSize}  placeholder='Email' required />
                    </div>
                    <div className='col-md-6'>
                        <label>Gender</label>
                        <input type='phone' className='fromdata' value={Gender}  placeholder='Email' required />

                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-6'>
                        <label>Address</label>
                        <input type='phone' className='fromdata' value={Address}  placeholder='Email' required />

                    </div>
                    <div className='col-md-6'>
                        <label>State</label>
                        <input type='phone' className='fromdata' value={State}  placeholder='Email' required />

                    </div>
                </div>
               
                <div className='row w-100' >
                <div className='col-md-6'>
                        <label>City</label>
                        <input type='phone' className='fromdata' value={City} placeholder='Phone' required />

                    </div>
                    <div className='col-md-6'>
                        <label>Govt.Id</label>
                        <div className='upl_img'>
                                {/* <input type="file" name="/" id="/" /> */}
                                <img style={{width:"180px",height:"80px"}} src={GovtId} alt="" />
                        </div>

                    </div>
                </div>

                <div className='row w-100' >
                    <div className='col-md-6'>
                        <label>Passport</label>
                        <div className='upl_img'>
                                {/* <input type="file" name="/" id="/" /> */}
                                <img style={{width:"180px",height:"80px"}} src={Passport} alt="" />
                        </div>

                    </div>
                    <div className='col-md-6'>
                        <label>Visa</label>
                        <div className='upl_img'>
                                {/* <input type="file" name="/" id="/" /> */}
                                <img style={{width:"180px",height:"80px"}} src={Visa} alt="" />
                        </div>

                    </div>
                </div>

                

                
            </div>
        </div>
    )
}

export default AddUserPopUP