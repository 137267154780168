import React, { useContext, useEffect } from "react";
// import ComingSoon from '../../Components/ComingSoon'
import { Link, useLocation } from "react-router-dom";
import WebBookcard from "./WebBookcard.jsx";

import AuthContext from "../../Context/AuthProvider.js";
import "../../Style/CMS.css";

const CmsLanding = () => {
  const { Home, setHome, WebsiteData } = useContext(AuthContext);
  const { About, setAbout } = useContext(AuthContext);
  const { Contact, setContact } = useContext(AuthContext);
  const { HotelAds, setHotelAds } = useContext(AuthContext);
  const { Terms, setTerms } = useContext(AuthContext);
  const { Service, setService } = useContext(AuthContext);
  const { Restaurant, setRestaurant } = useContext(AuthContext);
  const { Gallery, setGallery } = useContext(AuthContext);
  const { Testimonial, setTestimonial } = useContext(AuthContext);
  const { Teams, setTeams } = useContext(AuthContext);
  const { Blogs, setBlogs } = useContext(AuthContext);
  const { Menu, setMenu } = useContext(AuthContext);
  const { Facility, setFacility } = useContext(AuthContext);
  const { baseUrl, Nearby, setNearby, EngineUrl } = useContext(AuthContext);

  //data storage

  const TemplateInfo = async () => {
    try {
      const response = await fetch(
        `${EngineUrl}/cms/get/UsertemplateDetail/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();

      if (json.Status === true) {
        setHome(json.Pages.home);
        setAbout(json.Pages.about);
        setContact(json.Pages.contact);
        setHotelAds(json.Pages.hotelAdvr);
        setTerms(json.Pages.termsCondition);
        setService(json.Pages.services);
        setRestaurant(json.Pages.restaurants);
        setGallery(json.Pages.gallery);
        setTestimonial(json.Pages.testimonials);
        setTeams(json.Pages.teams);
        setBlogs(json.Pages.blogs);
        setMenu(json.Pages.menu);
        setFacility(json.Pages.facilities);
        setNearby(json.Pages.nearbyPlaces);
      } else {
        alert("Facility");
      }
    } catch (error) {
      alert("Integration PROBLEM");
    }
  };

  // Use location for active CMS sidebar

  const location = useLocation();

  useEffect(() => {
    TemplateInfo();
    WebsiteData();
  }, []);
  // const a = useContext(MainContext)
  return (
    <div className="wb-crd">
      <Link to="/dashboard/client/cms/profile">
        <WebBookcard
          Title={"Profile"}
          icon="fa-solid fa-user"
          Btn
          color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
          bg_color="#0A3A75"
        />
      </Link>
      {Home ? (
        <Link to="/dashboard/client/cms/home">
          <WebBookcard
            Title={"Home"}
            icon="fa-solid fa-house"
            Btn
            color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
            bg_color="#0A3A75"
          />
        </Link>
      ) : (
        ""
      )}

      {About ? (
        <Link to="/dashboard/client/cms/aboutus">
          <WebBookcard
            Title={"About Us"}
            icon="fa-solid fa-address-card"
            Btn
            color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.1))"
            bg_color="#0A3A75"
          />
        </Link>
      ) : (
        ""
      )}

      {Nearby ? (
        <Link to="/dashboard/client/cms/nearby">
          <WebBookcard
            Title={"Nearby Attraction"}
            icon="fa-solid fa-location-dot"
            Btn
            color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.3))"
            bg_color="#0A3A75"
          />
        </Link>
      ) : (
        ""
      )}

      {Facility ? (
        <Link to="/dashboard/client/cms/facilities">
          <WebBookcard
            Title={"Facilities"}
            icon="fa-solid fa-screwdriver-wrench"
            Btn
            color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.1))"
            bg_color="#0A3A75"
          />
        </Link>
      ) : (
        ""
      )}

{Service ? (
        <Link to="/dashboard/client/cms/services">
          <WebBookcard
            Title={"Services"}
            icon="fa-solid fa-bell-concierge"
            Btn
            color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
            bg_color="#0A3A75"
          />
        </Link>
      ) : (
        ""
      )}
      {Gallery ? (
        <Link to="/dashboard/client/cms/gallery">
          <WebBookcard
            Title={"Gallery"}
            icon="fa-solid fa-image"
            Btn
            color=""
            bg_color="#0A3A75"
          />
        </Link>
      ) : (
        ""
      )}

      <Link to="/dashboard/client/cms/blogs">
        <WebBookcard
          Title={"Blogs"}
          icon="fa-solid fa-file"
          Btn
          color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
          bg_color="#0A3A75"
        />
      </Link>
      {/* <Link to="/dashboard/client/cms/rooms"><WebBookcard Title={'Rooms'} Img={roomsimg} Btn color="" bg_color="red" /></Link> */}
      
      <Link to="/dashboard/client/cms/terms&conditions">
        <WebBookcard
          Title={"Policies"}
          icon="fa-solid fa-house"
          Btn
          color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
          bg_color="#0A3A75"
        />
      </Link>

      {HotelAds ? (
        <Link to="/dashboard/client/cms/hoteladvertisment">
          <WebBookcard
            Title={"Hotel Advertisment "}
            icon="fa-solid fa-hotel"
            Btn
            color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
            bg_color="#0A3A75"
          />
        </Link>
      ) : (
        ""
      )}


      
      
      {Terms ? (
        <Link to="/dashboard/client/cms/faq">
          <WebBookcard
            Title={"FAQs "}
            icon="fa-solid fa-question"
            Btn
            color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
            bg_color="#0A3A75"
          />
        </Link>
      ) : (
        ""
      )}


      {Restaurant ? (
        <Link to="/dashboard/client/cms/restaurants">
          <WebBookcard
            Title={"Restaurants "}
            icon="fa-solid fa-utensils"
            Btn
            color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
            bg_color="#0A3A75"
          />
        </Link>
      ) : (
        ""
      )}

      {/* {Testimonial ? <Link to="/dashboard/client/cms/testimonials"><WebBookcard Title={'Testimonials'} icon="fa-brands fa-rocketchat" Btn color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))" bg_color="#0A3A75" /></Link> : ""} */}

      {/* {Teams ? (
        <Link to="/dashboard/client/cms/teams">
          <WebBookcard
            Title={"Teams"}
            icon="fa-solid fa-people-group"
            Btn
            color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
            bg_color="#0A3A75"
          />
        </Link>
      ) : (
        ""
      )} */}

      {/* {Menu?<Link to="/dashboard/client/cms/menu"><WebBookcard Title={'Menu'} Img={menu} color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))" bg_color="#0A3A75" /></Link>:""} */}

      <Link to="/dashboard/client/cms/googlemap">
        <WebBookcard
          Title={"Google Map"}
          icon="fa-solid fa-file"
          Btn
          color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
          bg_color="#0A3A75"
        />
      </Link>

      <Link to="/dashboard/client/cms/event">
        <WebBookcard
          Title={"Events"}
          icon="fa-solid fa-file"
          Btn
          color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
          bg_color="#0A3A75"
        />
      </Link>

      {Contact ? (
        <Link to="/dashboard/client/cms/contact">
          <WebBookcard
            Title={"Contact"}
            icon="fa-solid fa-address-book"
            Btn
            color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
            bg_color="#0A3A75"
          />
        </Link>
      ) : (
        ""
      )}
      <Link to="/dashboard/client/cms/newsletter">
        <WebBookcard
          Title={"Newsletter"}
          icon="fa-solid fa-file"
          Btn
          color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
          bg_color="#0A3A75"
        />
      </Link>
      <Link to="/dashboard/client/cms/queries">
        <WebBookcard
          Title={"Queries"}
          icon="fa-solid fa-file"
          Btn
          color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
          bg_color="#0A3A75"
        />
      </Link>
      <Link to="/dashboard/client/cms/digitalcheckin">
        <WebBookcard
          Title={"Digital Checkin"}
          icon="fa-solid fa-file"
          Btn
          color="linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2))"
          bg_color="#0A3A75"
        />
      </Link>
    </div>
  );
};

export default CmsLanding;
