import React, { useContext } from 'react'
import "../Style/AddUserPopUp.css"
import { GrClose } from 'react-icons/gr'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa';


import AuthContext from "../Context/AuthProvider.js"

const AddUserPopUP = ({ seteditClick, bannerindex, banneredit }) => {
    const handleClickModel = () => {
        seteditClick(false)
    }
    const [Name, setName] = useState(banneredit.Name)
    const [Email, setEmail] = useState(banneredit.EmailId)
    const [Phone, setPhone] = useState(banneredit.Number)
    const [Adults, setSubject] = useState(banneredit.Adults)
    const [Kid, setKid] = useState(banneredit.Kids)
    const [Rooms, setRooms] = useState(banneredit.Rooms)
    const [Checkin, setCheckin] = useState(banneredit.Checkin)
    const [Checkout, setCheckout] = useState(banneredit.Checkout)
    const [City, setCity] = useState(banneredit.City)
    const [Message, setMessage] = useState(banneredit.Message)

    const { EngineUrl, fetchUserManagementData, baseUrl, WebsiteData } = useContext(AuthContext)

    



    


    return (
        <div className='Addpopup'>


            <div className='userform shadow-lg p-3 mb-5 bg-white rounded'>
                <div className='heading'>
                    <h2></h2>
                    <GrClose size={25} style={{ color: '#0A3A75' }} onClick={handleClickModel} className='closebooking' />
                </div>
                <div className='row w-100' >
                    <div className='col-md-6'>
                        <label>Name</label>
                        <input type='phone' className='fromdata' value={Name}  placeholder='Name' required />

                    </div>
                    <div className='col-md-6'>
                        <label>Email</label>
                        <input type='phone' className='fromdata' value={Email}  placeholder='Email' required />

                    </div>
                </div>
                
                <div className='row w-100' >
                    <div className='col-md-6'>
                        <label>Phone</label>
                        <input type='phone' className='fromdata' value={Phone}  placeholder='Email' required />
                    </div>
                    <div className='col-md-6'>
                        <label>Adults</label>
                        <input type='phone' className='fromdata' value={Adults}  placeholder='Email' required />
                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-6'>
                        <label>Kids</label>
                        <input type='phone' className='fromdata' value={Kid}  placeholder='Email' required />
                    </div>
                    <div className='col-md-6'>
                        <label>Rooms</label>
                        <input type='phone' className='fromdata' value={Rooms} placeholder='Phone' required />
                    </div>
                </div>
                
                <div className='row w-100' >
                    <div className='col-md-6'>
                        <label>Check-In</label>
                        <input type='phone' className='fromdata' value={Checkin}  placeholder='Subject' required />
                    </div>
                    <div className='col-md-6'>
                        <label>Check-Out</label>
                        <input type='phone' className='fromdata' value={Checkout}  placeholder='Message' required />
                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-12'>
                        <label>Message</label>
                        <input type='phone' className='fromdata' value={Message}  placeholder='Message' required />

                    </div>
                </div>
               
                <div className='row w-100' >
                    <div className='col-md-12'>
                        <label>Reply</label>
                        <input type='phone' className='fromdata'   placeholder='Reply' required />

                    </div>
                </div>

                <div className='row w-100'>
                    <div className='col-md-12'>
                        <button className='confirmUser'>Send Reply</button>
                    </div>
                </div>
            </div>
            
        </div>
        
    )
}

export default AddUserPopUP