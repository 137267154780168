import React, { useState } from 'react'
import { useContext } from 'react'
import AuthContext from '../Context/AuthProvider'
import BookingBackbtn from './BookingBackbtn'
import "../Style/EditRoomPopup.css"
import Card from 'react-bootstrap/Card';
import { FaPlus } from 'react-icons/fa';


const EditRoomPopup = () => {
    const { baseUrl ,EngineNewUrl} = useContext(AuthContext)
    const { setAlertMessage, setIsAlertOpen } = useContext(AuthContext);
    const { roomDetail } = useContext(AuthContext)
    const [RoomType] = useState(roomDetail.roomType)
    const [RoomName, setRoomName] = useState(roomDetail.roomName)
    const [roomSubTitle, setRoomSubTitle] = useState(roomDetail.roomSubheading)
    const [adult, setAdult] = useState(roomDetail.adult)
    const [child, setChild] = useState(roomDetail.child)
    const [noRoom, setNoRoom] = useState(roomDetail.noOfRooms)
    const [price, setPrice] = useState(roomDetail.price)
    const [facilities, setFacilities] = useState(roomDetail.roomFacilities)
    const [roomDescription, setRoomDescription] = useState(roomDetail.roomDescription)
    const [image, setImage] = useState(roomDetail.roomImage)
    const { selectedFacilities, setSelectedFacilities } = useContext(AuthContext)


    console.log(selectedFacilities)
    console.log(facilities)
    const trueAmenities = [];

    const facilitiesData = [
        "wifi",
        "television",
        "airConditonar",
        "hairdryers",
        "coffeeMakers",
        "directDial",
        "tableWithChair",
        "alarmclock",
        "electronicLocker",
        "fridge",
        "bathroomWithShower",
        "freeBreakfast",
        "kidEquipment",
        "Balcony",
        "Bath",
        "View",
        "FlatscreenTV",
        "Privatepool",
        "Electrickettle",
        "Spabath",
        "RoomAmenities",
        "Cots",
        "Clothesrack",
        "FoldupBed",
        "SofaBed",
        "Trashcans",
        "Heatedpool",
        "Infinitypool",
        "Plungepool",
        "Poolcover",
        "Pooltowels",
        "Rooftoppool",
        "Dressingroom",
        "Fan",
        "Fireplace",
        "Heating",
        "Iron",
        "Ironingfacilities",
        "Hottub",
        "Mosquitonet",
        "PrivateEntrance",
        "Sofa",
        "Soundproofing",
        "SeatingArea",
        "Pantspress",
        "Washingmachine",
        "Desk",
        "Hypoallergenic",
        "Cleaningproducts",
        "Pajamas",
        "Yukata",
        "Adapter",
        "Featherpillow",
        "Non_feather_pillow",
        "Bathroom",
        "Privatebathroom",
        "Shared_bathroom",
        "Toilet_paper",
        "Bidet",
        "Bath_shower",
        "Bathrobe",
        "Free_toiletries",
        "Additional_toilet",
        "Hairdryer",
        "Shared_toilet",
        "Sauna",
        "Shower",
        "Slippers",
        "Toilet",
        "Additional_bathroom",
        "Toothbrush",
        "Shampoo",
        "Conditioner",
        "Cd_player",
        "Dvd_player",
        "Fax",
        "Radio",
        "Satellitechannels",
        "Telephone",
        "Tv",
        "Smartphone",
        "Streamingservice_like_Netflix",
        "Dining_table",
        "Bottle_of_water",
        "Chocolate_or_cookies",
        "Fruits",
        "Barbecue",
        "Oven",
        "Stovetop",
        "Toaster",
        "Dishwasher",
        "Outdoor_furniture",
        "Minibar",
        "Kitchen",
        "Key_card_access",
        "Lockers",
        "Key_access",
        "Alarm_clock",
        "Wake_up_service",
        "Linen",
        "Blanket",
        "Extra_blankets",
        "Pillow",
        "Towels",
        "Patio",
        "City_view",
        "Garden_view",
        "Lake_view",
        "Landmark_view",
        "Mountain_view",
        "Pool_view",
        "River_view",
        "Sea_view",
        "Hearingaccessible",
        "Adaptedbath",
        "Raisedtoilet",
        "Loweredsink",
        "Showerchair",
        "Entertainment_family_services",
        "Baby_safety_gates",
        "Books",
        "DVDs",
        "Smokealarm",
        "Fire_extinguisher",
        "Safety_features",
        "Air_purifiers",
        "Physicaldistancing",
        "Handsanitiser"

    ];

    const handleCheckboxChange = (facility) => {
        // Create a copy of the selectedFacilities object and toggle the value for the clicked facility
        setSelectedFacilities((prevFacilities) => ({
            ...prevFacilities,
            [facility]: !prevFacilities[facility],
        }));
        if (selectedFacilities.includes(facility)) {
            setSelectedFacilities(selectedFacilities.filter((id) => id !== facility))
        }
        // if not selected then select it
        else {
            setSelectedFacilities([...selectedFacilities, facility])
        }
    };


    const editRoomData = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('Token');
        const editRoom = `${EngineNewUrl}/room/edit/${token}`;

        const headers = {
            Accept: "application/json, text/plain, /",
            'Content-Type': 'application/json'
        }

        const editRoomUpdatedData = {
            "roomType": RoomType,
            "roomName": RoomName,
            "roomSubheading": roomSubTitle,
            "adult": adult,
            "child": child,
            "noOfRooms": noRoom,
            "price": price,
            "roomDescription": roomDescription,
            "roomImage": image,
            "facilities": selectedFacilities
        }
        const responseRoomData = {
            "roomType": RoomType,
            "hId":localStorage.getItem("hotelLocationId"),
            "roomName": RoomName,
            "roomDescription": roomDescription,
            "child": child,
            "adult": adult,
            "noOfRooms": noRoom,
            "price": price,
            "roomImage": image, // Assuming these are the image URLs
            "roomFacilities": selectedFacilities, // Example facilities
            "roomSubheading": roomSubTitle,
            
        }

        try {

            console.log("enter")
            const response = await fetch(editRoom, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(responseRoomData)
            });

            if (!response.ok) {
                console.log("Not OK");
            }
            else {
                console.log("enter")

                const updatedRoomDetail = await response.json();
                console.log(updatedRoomDetail);
                const message = "Rooms updated successfully"
                setAlertMessage(message)
                setIsAlertOpen(true);

                setTimeout(() => {
                    setIsAlertOpen(false);
                }, 1000)
            }
        }
        catch (err) {
            console.log('Error', err);
        }
    }



    const DeleteBanner = (img) => {
        for (let i = 0; i < image.length; i++) {
            if (img === image[i]) {
                image.splice(i, 1);
                break;
            } else {
                continue;
            }
        };
        setImage([...image]);
        
    }

    function uploadImage(e) {
        e.preventDefault();
        const imageInput = document.getElementById('fileimg');
        const files = imageInput.files;

        if (files.length === 0) {
            alert('Please select at least one image file.');
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const reader = new FileReader();
            reader.onloadend = function () {
                const base64String = reader.result.split(',')[1];
                UploadingImageS3(base64String);
            };

            reader.readAsDataURL(file);
        }
    }

    function UploadingImageS3(base64String) {
        fetch(`${EngineNewUrl}/upload/file/image`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: window.localStorage.getItem('Token'), image: base64String }),
        })
            .then(response => response.json())
            .then(data => {
                setImage(prevImages => prevImages.concat(data.Image));

                document.getElementById('fileimg').value = null;
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    return (
        <div>
            <BookingBackbtn />
            <div className='w-100 h-100 mt-3'>


                <form onSubmit={editRoomData}>

                    <div className="row" >
                        <div className='col-md-6 Address'>
                            <div className='innerAdd p-3 pt-0' style={{ border: "1px solid #ccc", borderRadius: "5px", }}>
                                <div className="CmsFooterDiv gap-0  w-100" style={{ padding: "5px 0" }}>
                                    <label htmlFor="/">Room Type</label>
                                    <input className="inputdes" id='roomtype' type="text" value={RoomType} placeholder='Room Name' />
                                </div>
                                <div className="CmsFooterDiv gap-0  w-100" style={{ padding: "5px 0" }}>
                                    <label htmlFor="/">Room Name:</label>
                                    <input className="inputdes" type="text" id="roomName" value={RoomName} onChange={(e) => { setRoomName(e.target.value) }} placeholder='Room Name' />
                                </div>
                                <div className="CmsFooterDiv gap-0  w-100" style={{ padding: "5px 0" }}>
                                    <label htmlFor="/">Room SubTitle:</label>
                                    <input className="inputdes" type="text" id='roomSubtitle' value={roomSubTitle} onChange={(e) => { setRoomSubTitle(e.target.value) }} placeholder='Room SubTitle' />
                                </div>
                                <div className="CmsFooterDiv gap-0  w-100" style={{ padding: "5px 0" }}>
                                    <label htmlFor="/">Description:</label>
                                    <input className="inputdes" type="text" id="roomDescription" value={roomDescription} onChange={(e) => { setRoomDescription(e.target.value) }} placeholder='Enter Description here' />
                                </div>
                                <div className="CmsFooterDiv gap-0  w-100" style={{ padding: "5px 0" }}>
                                    <div className='BookingSetup_div'>
                                        <label htmlFor="/">Choose Facilities :</label>

                                        <div class="dropdown">
                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                Facilities
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a class="dropdown-item" href="#">

                                                    <div class="scrollable-content">
                                                        {facilitiesData.map((facility) => (
                                                            <div className='form-check row w-100' >
                                                                <input
                                                                    type="checkbox"
                                                                    className='form-check-input col-md-1'
                                                                    checked={selectedFacilities.includes(facility)}
                                                                    onChange={() => { handleCheckboxChange(facility) }}
                                                                />
                                                                <label className='form-check-label col-md-10 '>
                                                                    {facility}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 Address " >
                            <div className='innerAdd p-3 pt-0' style={{ border: "1px solid #ccc", borderRadius: "5px" }}>
                                <div className="CmsFooterDiv gap-0  w-100" style={{ padding: "5px 0" }}>
                                    <label htmlFor="/">Adult:</label>
                                    <input className="inputdes" type="text" id='adult' value={adult} onChange={(e) => { setAdult(e.target.value) }} placeholder='Adults' />
                                </div>
                                <div className="CmsFooterDiv gap-0 w-100" style={{ padding: "5px 0" }}>
                                    <label htmlFor="/">Child:</label>
                                    <input className="inputdes" type="text" id='child' value={child} onChange={(e) => { setChild(e.target.value) }} placeholder='Childs' />
                                </div>
                                <div className="CmsFooterDiv gap-0 w-100" style={{ padding: "5px 0" }}>
                                    <label htmlFor="/">No of Room:</label>
                                    <input className="inputdes" type="text" id='noofRoom' value={noRoom} onChange={(e) => { setNoRoom(e.target.value) }} placeholder='No of Room' />
                                </div>
                                <div className="CmsFooterDiv gap-0 w-100" style={{ padding: "5px 0" }}>
                                    <label htmlFor="/">Price:</label>
                                    <input className="inputdes" type="text" id='price' value={price} onChange={(e) => { setPrice(e.target.value) }} placeholder='Price of Room' />
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className='cmsForm_div'>
                        <div className='cmsForm_div cmsimgdiv'>
                            <div className='CmsNearImglabel'>
                                <label htmlFor="/">Image:</label>
                                <button className="upload">
                                    <span className='cmsupldspn'>Upload Image</span>
                                    <FaPlus className='cmsplusicon' />
                                    <input type="file" id="fileimg" onChange={uploadImage} multiple/>
                                </button>
                            </div>
                            <div className='upl_img'>
                                <img src={Image} alt="" />
                            </div>

                        </div>
                    </div>


                    <div className='col-md-12 mt-5 '>
                        <div className='p-3 pt-0' style={{ border: "1px solid #ccc", borderRadius: "5px" }}>
                            <div className="CmsFooterDiv gap-0 w-100" style={{ padding: "5px 0" }}>
                                <label htmlFor="/">Image 1:</label>
                                <div >
                                    <div className="bodyCardDiv" style={{
                                        display: "grid",
                                        gridTemplateColumns: "Repeat(4, 1fr)",
                                        gap: 15
                                    }}>
                                        {image.map((img) => (
                                            <Card style={{ width: '17rem' }}>
                                                <Card.Body>
                                                    <img style={{ "width": "100%", height: "80%" }} src={img} alt="body" />
                                                    <div className='mt-5'>
                                                        <button onClick={() => { DeleteBanner(img) }}
                                                            style={{
                                                                border: 0,
                                                                position: "absolute",
                                                                bottom: 10,
                                                                left: 14,
                                                                padding: ".3rem .5rem",
                                                                borderRadius: "5px",
                                                                background: "orangered",
                                                                color: "white"

                                                            }}
                                                        >Delete</button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className='addbtn mt-4'>Update Room</button>
                </form>
            </div>
        </div>
    )
}

export default EditRoomPopup