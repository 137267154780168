import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';


function BookingBackbtn() {
    return (

        // <div className='w-100' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin:'0 0 20px 0' }}>
        <div style={{ padding: '8px 0', height: 'auto' }}>
            <Link style={{ padding: '12px 15px', background: "#0A3A75", borderRadius: '8px', color: '#fff' }} to='/dashboard/client/bookingengine'><ArrowLeft /></Link>
        </div>

        // <Form >
        //   <Form.Check // prettier-ignore
        //     type="switch"
        //     id="custom-switch"
        //   />
        // </Form>
        // {/* </ div> */}

    )
}

export default BookingBackbtn
