import React from 'react'
import '../../Style/SocialMedia.css'
import PostFilters from './PostFilters';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FaInstagram, FaFacebook, FaYoutube, FaGoogle, FaTwitter, FaLinkedin, FaPlus } from 'react-icons/fa';



function SocialNewPost() {
  return (
    <div style={{ width: '100%', paddingBottom:'50px' }}>
      {/* <Topnav /> */}
      <div>
        <div className="toppost">
          <h3>New Post</h3>
          <PostFilters />
        </div>
        <div className="newpost py-4">
          <Card style={{ width: '25rem', height: '11rem', borderRadius: '20px', border: '1px solid #9FADC4' }}>
            <h3 className='post-ttle'>Social network</h3>
            <Card.Body style={{ display: 'flex', justifyContent: 'space-around' }}>
              <FaFacebook style={{ fontSize: '3rem', color: '#3b5998' }} />
              <FaInstagram className='instacode' style={{ fontSize: '3rem' }} />
              <FaTwitter style={{ fontSize: '3rem', color: '#00acee' }} />
            </Card.Body>
          </Card>

          <Card style={{ width: '45rem', height: '11rem', borderRadius: '20px', border: '1px solid #9FADC4' }}>
            <h3 className='post-ttle'>Social network</h3>
            <p className='post-para'>-  28 June 2023 +</p>
          </Card>
        </div>

        {/* 2nd card start  */}


        <div className="newpost my-4">
          <Card style={{ width: '25rem', height: '14rem', borderRadius: '20px', border: '1px solid #9FADC4' }}>
            <h3 className='post-ttle'>Image</h3>
            <Card.Body style={{ display: 'flex', justifyContent: 'space-around', padding: '0rem 5rem 1rem 5rem' }}>
              <button className="upload">
                <span className='pst-upld'>Upload Image</span>
                <FaPlus className='pst-pls' />
                <input type="file" name="file" id="uploadFile" />
              </button>
            </Card.Body>
          </Card>

          <Card style={{ width: '45rem', height: '14rem', borderRadius: '20px', border: '1px solid #9FADC4' }}>
            <h3 className='post-ttle'>Text</h3>
            <div style={{ padding: '0 4rem' }}>
              <p className='post-para pt'>-  Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae officiis adipisci blanditiis tenetur, veniam laborum eum dignissimos repellendus corrupti dolores veritatis saepe dolore consequatur. Debitis dolor eaque magnam iusto inventore?</p>
            </div>
          </Card>
        </div>
        <div className="postbtn d-flex" style={{ justifyContent: 'space-between' }}>
          <Button className='boost-btn' style={{ color: '#039E00' }}>Preview</Button>
          <Button className='boost-btn' style={{ color: 'red' }}>Cancel</Button>
        </div>
      </div>
    </div>
  )
}

export default SocialNewPost
