import React, { useContext, useEffect, useState } from 'react';
import { FaFacebook, FaGoogle, FaInstagram, FaTripadvisor, FaTwitter } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { LoginSocialGoogle } from 'reactjs-social-login';
import AuthContext from '../../Context/AuthProvider';
import '../../Style/SocialMedia.css';

import DinabiteFbPagepopup from '../../Components/DinabiteFbPagepopup';
import '../../Style/LoginPage.css';

function SocialHome() {
  const { DinabiteToken,DinabiteUser,LogoutDinabiteUser, 
    setDinabiteToken,isAdmin, CheckDinabiteToken_inDb,DinabiteRegisterAPI,
    DinabiteUserInformation,isFacebookConnected,FacebookConnect,
    FacebookUserId,dinabiteFacebookPages,CompanyId,FacebookConnectedPage,isGoogleConnected,GoogleConnect} = useContext(AuthContext);

  const [FacebookPagepopup,setFacebookPagepopup] = useState(false)

  const fbInitParams = {
    appId: "1082235725666050",
    cookie: true,
    xfbml: true,
    version: 'v12.0' // Set version to 12.0
  };

  function initFacebookSDK() {
    // Initialize the Facebook SDK with the updated App ID
    // console.log(appid);
  
    if (window.FB) {
      const latestVersion = 'v12.0'; // Use the latest version available
      window.FB.init({
        appId:"1082235725666050",
        cookie: true,
        xfbml: true,
        version: latestVersion
      });
  
      // Check the login status
      window.FB.getLoginStatus(function (response) {
        statusChangeCallback(response);
      });
    } else {
      console.error('Facebook SDK not loaded');
      // initFacebookSDK()
    }
  }
  const [isfacebookConnected,setisfacebookConnected] = useState(true)
  useEffect(() => {
    CheckDinabiteToken_inDb();
    initFacebookSDK();
  }, []);



  const handleGoogleLogin = async (provider, data) => {
    try {
      console.log(window.location.origin)
      const tokenEndpoint = 'https://oauth2.googleapis.com/token';
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          code: data.code,
          client_id: '760661012175-t15e7ct1uhnhhi6bisal5ecobv8tj4ud.apps.googleusercontent.com',
          client_secret: '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',
          redirect_uri: window.location.origin,
          grant_type: 'authorization_code',
        }),
      });

      const tokenData = await response.json();
      console.log('Access Token Data:');
      // Use the access token to make additional requests (if needed)
      // Example: Request user information
      const userInfoEndpoint = 'https://www.googleapis.com/oauth2/v2/userinfo';
      const userInfoResponse = await fetch(userInfoEndpoint, {
        headers: {
          Authorization: `Bearer ${tokenData.access_token}`,
        },
      });

      const userInfo = await userInfoResponse.json();
      // // console.log('User Information:', userInfo);
      console.log(tokenData.access_token)
      // console.log(userInfo.email)
      DinabiteRegisterAPI(userInfo.email,tokenData.access_token)

      // Rest of your code...
    } catch (error) {
      console.error('Google login error:', error);
    }
  };

  const handleConnectGoogleTool = async (provider, data) => {
    try {
      // console.log(data)
      const tokenEndpoint = 'https://oauth2.googleapis.com/token';
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          code: data.code,
          client_id: '760661012175-t15e7ct1uhnhhi6bisal5ecobv8tj4ud.apps.googleusercontent.com',
          client_secret: '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',
          redirect_uri: window.location.origin,
          grant_type: 'authorization_code',
        }),
      });

      const tokenData = await response.json();
      // console.log(tokenData);

      const userInfoEndpoint = 'https://www.googleapis.com/oauth2/v2/userinfo';
      const userInfoResponse = await fetch(userInfoEndpoint, {
        headers: {
          Authorization: `Bearer ${tokenData.access_token}`,
        },
      });

      const userInfo = await userInfoResponse.json();
      // console.log('User Information:', userInfo);
      // console.log(tokenData.access_token)

      GoogleConnect(data.code,userInfo.id)

    } catch (error) {
      console.error('Google login error:', error);
    }
  };

  

  (function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));




  

  function statusChangeCallback(response) {
    if (response.status === 'connected') {
      let facebooktoken = response.authResponse.accessToken
      FacebookConnect(facebooktoken,DinabiteToken)
      console.log('Logged in and authenticated');
    } else if (response.status === 'not_authorized') {
      // User is logged into Facebook but not your app
      console.log('Not authorized');
    } else {
      // User is not logged into your app or Facebook
      console.log('Not logged in or not authenticated');
      console.log(response.authResponse); // Log any errors
    }
  }

  function facebookLogin() {
    window.FB.login(function(response) {
      // Handle the response after the login button is clicked
      statusChangeCallback(response);
    }, {scope: 'public_profile,pages_manage_posts,pages_read_engagement,email,pages_read_user_content,ads_management,ads_read,read_insights,pages_read_engagement,pages_messaging'}); // Add any additional permissions your app needs
  }

  return (
    <div style={{ width: '100%' }}>
      <div className="toppost">
        <h3>Tools <span style={{fontSize:"15px"}}>{DinabiteUser?DinabiteUser.email:"-"}({CompanyId===0?"":CompanyId})</span></h3>
        {DinabiteToken!=="None"?<button onClick={()=>{LogoutDinabiteUser()}}>Logout</button>:""}
      </div>
      {DinabiteToken === 'None' ? (
        <div className="googleauth w-100" style={{ marginTop: '10%' }}>
          <p style={{ marginLeft: '20%' }}>
            You are not authenticated for using Social Media Manager, Please signup or login using options below
          </p>
          {isAdmin?
          <LoginSocialGoogle
            client_id="760661012175-t15e7ct1uhnhhi6bisal5ecobv8tj4ud.apps.googleusercontent.com"
            scope="openid profile email"
            discoveryDocs="claims_supported"
            access_type="offline"
            onResolve={({ provider, data }) => {
              handleGoogleLogin(provider, data);
            }}
            onReject={(err) => {
              console.log('error');
              console.log(err);
            }}
          >
            <div className="googleLogin my-4 w-50" style={{ marginLeft: '25%', backgroundColor: 'white' }}>
              <FcGoogle size={30} style={{ marginRight: '1rem' }} />
              <p className="glb m-0">Continue with Google</p>
            </div>
          </LoginSocialGoogle>:<p style={{ marginLeft: '40%',color:"red",fontWeight:"bold"}}>Ask Admin to login again</p>}

        </div>
      ) 
            :
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', paddingBottom: "40px", flexWrap: "wrap", gap: "40px" }}>

                <div className="toolsdiv" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '30rem', height: '12rem', borderRadius: '14px', border: '1px solid #C3CBDA' }}>
                  <FaFacebook style={{ fontSize: '4rem', color: '#3b5998' }} />
                  <div class="form-check form-switch">

                  {(isFacebookConnected===false)?<button className='tool-connect-button' onClick={()=>{facebookLogin()}} >Connect</button>:
                    <span className='badge bg-success' >Connected</span>}
                  
                  </div>
                  <div className='row'>
                    {dinabiteFacebookPages.length>0?<span className='badge bg-success' onClick={()=>{setFacebookPagepopup(true)}}>Connect Pages</span>:<span className='badge bg-success'  onClick={facebookLogin} >See Pages</span>}
                  </div>
                  
                </div>


                <div className="toolsdiv" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '30rem', height: '12rem', borderRadius: '14px', border: '1px solid #C3CBDA' }}>
                  <FaGoogle className='googlecode' style={{ fontSize: '4rem' }} />
                  <div class="form-check form-switch">
                    {isGoogleConnected?<span className='badge bg-danger'  >Coming Soon</span>
                    :<LoginSocialGoogle
                    client_id="760661012175-t15e7ct1uhnhhi6bisal5ecobv8tj4ud.apps.googleusercontent.com"
                    scope="openid profile email"
                    discoveryDocs="claims_supported"
                    access_type="offline"
                    onResolve={({ provider, data }) => {
                      handleConnectGoogleTool(provider, data);
                    }}
                    onReject={(err) => {
                      console.log('error');
                      console.log(err);
                    }}
                  >
                    <div className="googleLogin my-4 w-100" style={{ marginLeft: '25%', backgroundColor: 'white' }}>
                      <FcGoogle size={90} style={{ marginRight: '1rem' }} />
                        <p className="glb m-0">Connect</p>
                    </div>
                  </LoginSocialGoogle>
                    }
                    {/* <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" /> */}
                  </div>
                </div>


                <div className="toolsdiv" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '30rem', height: '12rem', borderRadius: '14px', border: '1px solid #C3CBDA' }}>
                  <FaInstagram className='instacode' style={{ fontSize: '4rem' }} />
                  <div class="form-check form-switch">
                    {false?<button className='tool-connect-button' >Connect</button>:
                    <span className='badge bg-danger' >Coming soon</span>}
                    {/* <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" /> */}
                  </div>
                </div>


                <div className="toolsdiv" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '30rem', height: '12rem', borderRadius: '14px', border: '1px solid #C3CBDA' }}>
                  <FaTwitter style={{ fontSize: '4rem', color: '#0072b1 ' }} />
                  <div class="form-check form-switch">
                    {false?<button className='tool-connect-button' >Connect</button>:
                    <span className='badge bg-danger' >Coming soon</span>}
                    {/* <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" /> */}
                  </div>
                </div>

                <div className="toolsdiv" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '30rem', height: '12rem', borderRadius: '14px', border: '1px solid #C3CBDA' }}>
                  <FaTripadvisor style={{ fontSize: '4rem', color: '#c4302b' }} />
                  <div class="form-check form-switch">
                    {false?<button className='tool-connect-button' >Connect</button>:
                    <span className='badge bg-danger' >Coming soon</span>}
                    {/* <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" /> */}
                  </div>
                </div>

            </div>
            }

            {FacebookPagepopup?<DinabiteFbPagepopup setFacebookPagepopup={setFacebookPagepopup} />:""}
    </div>
  )
}

export default SocialHome
