import React from "react";
import SeoManagerData from "../../Components/SeoManagerData";
import SeoManagerCard from "../../Components/SeoManagerCard";
import { useEffect } from "react";
import { useContext } from "react";
import AuthContext from "../../Context/AuthProvider";
import AddSlugManagerData from "../../Components/AddSlugManagerData";

function Seomanager() {
  const { WebsiteData } = useContext(AuthContext);

  const { addSlugPopup } = useContext(AuthContext);

  const SeoData = [
    {
      title: "Page Title 1",
      description: "A sample description for SEO purposes.",
      // keyword: 'Keyword 1, Keyword 2, Keyword 3',
      button: "Website title/Description",
    },
    {
      title: "Page Title 1",
      description: "A sample description for SEO purposes.",
      // keyword: 'Keyword 1, Keyword 2, Keyword 3',
      button: "Add Slugs",
    },
  ];
  useEffect(() => {
    WebsiteData();
  }, []);

  return (
    <>
      <div className="seo-card-container d-flex" style={{ gap: "20px" }}>
        {SeoData.map((data, index) => (
          <SeoManagerCard
            key={index}
            title={data.title}
            description={data.description}
            keyword={data.keyword}
            button={data.button}
          />
        ))}
      </div>

      {addSlugPopup ? <AddSlugManagerData /> : <SeoManagerData />}
    </>
  );
}

export default Seomanager;
