import React, { useContext, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { FaFacebook, FaGoogle, FaInstagram, FaTwitter } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import DinabitePopup from '../../Components/DinabiteMessagepop';
import AuthContext from '../../Context/AuthProvider';
import '../../Style/SocialMedia.css';
import Filters from '../Social Meadia/Filters';


function SocialActiveTools() {
  const navigate = useNavigate()
  const {RedirectDinabitetool,DinabiteToken,dinabiteMessages,fetchDinabiteMessages,isFacebookConnected,isGoogleConnected} = useContext(AuthContext)
  useEffect(() => {
    if(DinabiteToken==="None"){
      navigate('/dashboard/client/socialmedia/home')
    }
    else{
      fetchDinabiteMessages(DinabiteToken)
    }
  
  }, []);

  const [messagepopup,setmessagepopup] = useState(false)
  const [objectpopup,setobjectpopup] = useState({})
  
  const lastMessage = (message,name)=>{
    let data = message.data
    const sender = data.filter(message => (
      message.from.name === name
    ));
    
    // Sort messages by created_time in descending order
    sender.sort((a, b) => new Date(b.created_time) - new Date(a.created_time));
    
    // Get the latest message
    const latestMessage = sender[0].message;
    return latestMessage
  }

  const TogglePopup = (object)=>{
    setobjectpopup(object)
    setmessagepopup(true)
  }
  return (
    <div style={{ width: '100%' }}>
      <Filters />
      <div className="c-main">
        <section>
          <div className="icon-main">
            {isFacebookConnected?<FaFacebook className="sicon" style={{ color: '#3b5998' }} />:""}
            {false?<FaInstagram className="sicon instacode" />:""}
            {false?<FaTwitter className="sicon" style={{ color: '#00acee' }} />:""}
            {isGoogleConnected?<FaGoogle className="sicon" style={{ color: '#00acee' }} />:""}
          </div>
          <div className="activity-card py-4">
            <h3 className='head'>ACTIVITY</h3>
            <div className="i-card d-flex py-3" style={{ gap: '2rem', flexWrap:'wrap' }}>
              <Card style={{ width: '15rem', height: '12rem', borderRadius: '14px', border: '1px solid rgb(159, 173, 196)' }}>
                <Card.Body style={{ width: '100%', height: '100%' }}>
                  <div className='c-child'>
                    <Card.Title className='c-ttle' >New Posts</Card.Title>
                    <Card.Subtitle className="mb-2 c-sub">0</Card.Subtitle>
                  </div>
                </Card.Body>
              </Card>
              <Card style={{ width: '15rem', height: '12rem', borderRadius: '14px', border: '1px solid rgb(159, 173, 196)' }}>
                <Card.Body>
                  <div className='c-child'>
                    <Card.Title className='c-ttle'>Ad Spend</Card.Title>
                    <Card.Subtitle className="mb-2 c-sub" >$0</Card.Subtitle>
                    {/* <Card.Text>
                                    </Card.Text> */}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>

          {/* activity end  */}

          {/* reach cards staart  */}

          <div className="reach-card">
            <h3 className='head'>Reach</h3>
            <div className="r-card d-flex py-3" style={{ gap: '2rem' }}>
              <Card style={{ width: '15rem', height: '12rem', borderRadius: '14px', border: '1px solid rgb(159, 173, 196)' }}>
                <Card.Body>
                  <div className='c-child' style={{ gap: '0' }}>
                    <Card.Title className='c-ttle'>View</Card.Title>
                    <Card.Subtitle className="mb-2 c-sub" style={{ fontSize: '4rem', color: '#193971', fontFamily: 'Antonio' }}>0</Card.Subtitle>
                    <Card.Text className='f-text'>-40%
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
              <Card style={{ width: '15rem', height: '12rem', borderRadius: '14px', border: '1px solid rgb(159, 173, 196)' }}>
                <Card.Body>
                  <div className='c-child' style={{ gap: '0' }}>
                    <Card.Title className='c-ttle'>Engagement</Card.Title>
                    <Card.Subtitle className="mb-2 c-sub" style={{ fontSize: '4rem', color: '#193971', fontFamily: 'Antonio' }}>0</Card.Subtitle>
                    <Card.Text>60%
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
              <Card style={{ width: '15rem', height: '12rem', borderRadius: '14px', border: '1px solid rgb(159, 173, 196)' }}>
                <Card.Body>
                  <div className='c-child'>
                    <Card.Title className='c-ttle'>Total Reviews</Card.Title>
                    <Card.Subtitle className="mb-2 c-sub" style={{ fontSize: '4rem', color: '#193971', fontFamily: 'Antonio' }}>0</Card.Subtitle>
                    {/* <Card.Text>60%
                                    </Card.Text> */}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </section>
        <aside>


          <div className="letst-msg">
            <h3 className='my-4 lmsg'>LATEST MESSAGES</h3>
            <div className="sidemsg d-flex" style={{ width: 'auto', height: 'auto', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid #9FADC4', borderRadius: '5px', paddingBottom: '1rem' }}>
              {dinabiteMessages.map((message)=>{
                return <Card className='msg-card' onClick={()=>{TogglePopup(message)}}>
                          <Card.Body>
                            <div style={{ width: 'auto', height: 'auto' }}>
                              <Card.Title className='lsocial'>{message.type}</Card.Title>
                            </div>
                            <div style={{ width: 'auto', height: 'auto' }}>
                              <Card.Subtitle className="mb-2 lsub">{message.senderName}</Card.Subtitle>
                            </div>
                            <div style={{ width: 'auto', height: 'auto', padding: '0 5px' }}>
                              <Card.Text>{lastMessage(message.messages,message.senderName)}</Card.Text>
                            </div>
                          </Card.Body>
                        </Card>
              })}
                
            </div>
          </div>
          
        </aside>

      </div>
      {messagepopup?<DinabitePopup setmessagepopup={setmessagepopup} object={objectpopup} />:""}
      {/* </div> */}
    </div>
  )
}

export default SocialActiveTools
