import React, { useContext } from "react";
import Table from "react-bootstrap/Table";
import AuthContext from "../Context/AuthProvider";
import { HashLoader } from "react-spinners";

function CmsTable() {
  const {
    baseUrl,
    websiteFacilitiesData,
    setwebsiteFacilitiesData,
    EngineUrl,
  } = useContext(AuthContext);
  const handleServiceToggle = (serviceName) => {
    setwebsiteFacilitiesData({
      ...websiteFacilitiesData,
      [serviceName]: !websiteFacilitiesData[serviceName],
    });
    fetch(`${EngineUrl}/cms/edit/Facilities`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        key: serviceName,
        value: (!websiteFacilitiesData[serviceName]).toString(),
      }),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      {websiteFacilitiesData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div className="cmsTable">
            <Table responsive="sm md lg xl">
              <thead>
                <tr>
                  <th>Services</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(websiteFacilitiesData).map(
                  ([service, status]) => (
                    <tr key={service}>
                      <td>{service}</td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={status}
                            onChange={() => handleServiceToggle(service)}
                          />
                          {status ? " Active" : " Inactive"}
                        </label>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
}

export default CmsTable;
