import React, { useContext } from 'react'
import { GrClose } from "react-icons/gr"
import AuthContext from '../Context/AuthProvider'
import '../Style/ReservDeskInfoPopup.css'

const InfoPopup = () => {

    const { infoData, setInfoData, fetchBookingData, EngineUrl, todaysAvaiblity,EngineNewUrl,RoomsData } = useContext(AuthContext)
    const { openInfoPopUp, setOpenInfoPopUp } = useContext(AuthContext)

    const handleClosePopup = () => {
        setOpenInfoPopUp(!openInfoPopUp)
    }
    const CancelBookingFun = async () => {
        const response = await fetch(`${EngineNewUrl}/booking/cancel/${localStorage.getItem("Token")}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                bookingId: infoData.bookingId,
                hId:localStorage.getItem('hotelLocationId')
            }),
        });
        await response.json()
        setOpenInfoPopUp(!openInfoPopUp)
        todaysAvaiblity()
        fetchBookingData()
    }

    const PaidBookingFun = async (status) => {
        const response = await fetch(`${EngineNewUrl}/booking/payment/status/${status}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token:localStorage.getItem('Token'),
                bookingId: infoData.bookingId,
                hId:localStorage.getItem('hotelLocationId')
            }),
        });
        await response.json()
        setOpenInfoPopUp(!openInfoPopUp)
        todaysAvaiblity()
        fetchBookingData()
    }
    function convertDateFormat(inputDate) {
        const parts = inputDate.split('-');
        if (parts.length === 3) {
          const [year, month, day] = parts;
          // Create a new Date object with the given parts
          const dateObject = new Date(`${year}-${month}-${day}`);
          // Format the date as 'dd-mm-yyyy'
          const formattedDate = `${String(dateObject.getDate()).padStart(2, '0')}-${String(dateObject.getMonth() + 1).padStart(2, '0')}-${dateObject.getFullYear()}`;
          return formattedDate;
        } else {
          // Handle invalid input
          console.error('Invalid date format');
          return inputDate;
        }
      }
    return (
        <div className='overlayStyle'>
            <div className='w-100 h-100' >
                <div style={{ position: "fixed", top: "20%", left: "35%", zIndex: 1, width: "850px", }}>
                    <div className='h-100 shadow mb-1 bg-body rounded popUpBG'>
                        <div style={{ display: "flex", position: 'relative', justifyContent: "end", }}>
                            <GrClose className='closeIcon' onClick={handleClosePopup} />
                        </div>

                        <div className="row rounded text-white" style={{ marginTop: '-1.8rem', backgroundColor: '#0A3A75' }} >
                            <div className="col py-2 text-center">
                                Booking ID :{infoData.bookingId}
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col p-1">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>Payment Id:</th>
                                            <td>{infoData.payment.RefNo}</td>
                                        </tr>
                                        <tr>
                                            <th>Guest Name:</th>
                                            <td>{infoData.guestInfo.guestName}</td>
                                        </tr>
                                        <tr>
                                            <th>Email:</th>
                                            <td>{infoData.guestInfo.EmailId}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone:</th>
                                            <td>{infoData.guestInfo.Phone}</td>
                                        </tr>
                                        <tr>
                                            <th>Addr:</th>
                                            <td>{infoData.guestInfo.City.label ? infoData.guestInfo.City.label : infoData.guestInfo.City}, {infoData.guestInfo.Country.label ? infoData.guestInfo.Country.label : infoData.guestInfo.Country}</td>
                                        </tr>
                                        <tr>
                                            <th>Adults:</th>
                                            <td>{infoData.Adults ? infoData.Adults.toString() : 0}</td>
                                        </tr>
                                        <tr>
                                            <th>Kids:</th>
                                            <td>{infoData.Kids ? infoData.Kids.toString() : 0}</td>
                                        </tr>

                                        <tr>
                                            <th>Bookings:</th>
                                            <td>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        {/* {infoData.Bookings[0].Qty > 0 ? <tr>
                                                            <td>Delux</td>
                                                            <td>{infoData.Bookings[0].Qty}</td>
                                                        </tr> : ""}
                                                        {infoData.Bookings[1].Qty > 0 ? <tr>
                                                            <td>Super Delux</td>
                                                            <td>{infoData.Bookings[1].Qty}</td>
                                                        </tr> : ""}
                                                        {infoData.Bookings[2].Qty > 0 ? <tr>
                                                            <td>Suit</td>
                                                            <td>{infoData.Bookings[2].Qty}</td>
                                                        </tr> : ""}
                                                        {infoData.Bookings[3].Qty > 0 ? <tr>
                                                            <td>Premium</td>
                                                            <td>{infoData.Bookings[3].Qty}</td>
                                                        </tr> : ""} */}
                                                        {RoomsData.map((room)=>{
                                                            return infoData.Bookings.map((ctgry)=>{
                                                                if(ctgry.RoomType===room.roomType && ctgry.Qty){
                                                                    return <tr>
                                                                                <td>{room.roomName}</td>
                                                                                <td>{ctgry.Qty}</td>
                                                                            </tr>
                                                                }
                                                                
                                                            })
                                                        })}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Payment Status:</th>
                                            <td>
                                                {infoData.payment.Status === "PENDING" ? (
                                                    <span className='badge bg-danger'>{infoData.payment.Status}</span>
                                                ) : infoData.payment.Status === "ADVANCED" ? <span className='badge bg-warning'>{infoData.payment.Status}</span> :
                                                    infoData.payment.Status === "CANCELLED" ? <span className='badge bg-secondary'>{infoData.payment.Status}</span> : (
                                                        <span>
                                                            <span className='badge bg-success'>{infoData.payment.Status}</span>

                                                        </span>
                                                    )}
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col p-1">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>Check In:</th>
                                            <td>{convertDateFormat(infoData.checkIn)}</td>
                                        </tr>
                                        <tr>
                                            <th>Check Out:</th>
                                            <td>{convertDateFormat(infoData.checkOut)}</td>
                                        </tr>
                                        <tr>
                                            <th>Checked In:</th>
                                            <td>{infoData.isCheckedIn === true ? <span className='badge bg-success'>Checked In</span> : <span className='badge bg-danger'>Pending</span>}</td>
                                        </tr>


                                        <tr>
                                            <th>Checked Out:</th>
                                            <td>{infoData.isCheckedOut === true ? <span className='badge bg-success'>Checked Out</span> : <span className='badge bg-danger'>Pending</span>}</td>
                                        </tr>
                                        <tr>
                                            <th>Price:</th>
                                            <td>
                                                <table className="table table-bordered" style={{left:"0"}}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Amount:</td>
                                                            <td>{infoData.price.Principal.toString()}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tax:</td>
                                                            <td>{infoData.price.Tax.toString()}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total:</td>
                                                            <td>{infoData.price.Total.toString()}</td>
                                                        </tr>
                                                        {infoData.payment.Status!== "PENDING"?<tr>
                                                            <td>Paid:</td>
                                                            <td>{infoData.price.amountPay.toString()}</td>
                                                        </tr>:""}
                                                    </tbody>
                                                </table>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <button className='btn btn-warning text-light' onClick={() => { CancelBookingFun() }}>Cancel Booking</button>
                            {infoData.payment.Status === "PENDING" || infoData.payment.Status === "ADVANCED" ? <button style={{float:"right"}} className='btn btn-success text-light' onClick={() => { PaidBookingFun("SUCCESS") }}>Amount Paid</button>:""}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default InfoPopup