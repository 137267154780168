import React from 'react'
import Button from 'react-bootstrap/Button';
import { ArrowLeft } from 'react-bootstrap-icons';
import BackBtn from '../../Components/BackBtn';
import Form from 'react-bootstrap/Form';

function CmsTestimonials() {
  return (
    <>
    <div className="homHead w-100" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: '0 0 20px 0' }}>
        <BackBtn />
        <h3 className="cmsHeading my-3">Testimonials</h3>
        <Form >
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
          />
        </Form>
      </div>
      <section className="testimonial-area ptb_100">hooooooo</section>
    
    </>
    
  )
}

export default CmsTestimonials
