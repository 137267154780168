import React, { useContext, useEffect } from "react";
import LandingCard from "../../Components/LandingCard";
import AuthContext from "../../Context/AuthProvider";
import "../../Style/Landing.css";
// import LandingBookingTable from "../../Components/LandingBookingTable";
// import LandingActiviesTable from "../../Components/LandingActiviesTable";

function LandingAdmin() {
  const {ClientsCount,setClientsCount,FetchAllEazotelClients,AdminsCount,setAdminsCount,AllUsers,setAllUsers,
    UsersCount,setUsersCount,
    FetchAllUsers,FetchAllAdmins,FetchAllQueries} = useContext(AuthContext)
  useEffect(()=>{
    FetchAllEazotelClients()
    FetchAllUsers()
    FetchAllAdmins()
    FetchAllQueries()
    
  },[])
  return (
    <div className="landing">
      <div className="landingCardDiv">
        <LandingCard
          count={ClientsCount}
          heading="Total Clients"
          // para="Someone bookmarked your listing"
        />
        <LandingCard
          count={AdminsCount}
          heading="Total Admins"
          // para="Someone bookmarked your listing"
        />
        <LandingCard
          count={UsersCount}
          heading="Active Users"
          // para="Someone bookmarked your listing"
        />
        <LandingCard
          count="0"
          heading="-"
          // para="Someone bookmarked your listing"
        />
      </div>
      <div className="landingCanvasDiv">{/* <LandingCanvas /> */}</div>
      {/* <div className="landingBookingDiv">
        <LandingBookingTable />
      </div>
      <div className="landingActivitiesDiv">
        <LandingActiviesTable />
      </div> */}
    </div>
  );
}

export default LandingAdmin;
