import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { LoginSocialGoogle } from "reactjs-social-login";
import AuthContext from "../Context/AuthProvider";
import logo from "../Images/companylogo.png";
import "../Style/LoginPage.css";

function LoginPage({
  Auth,
  setAuth,
  setUserProfile,
  setUserLinks,
  setUserPlan,
  userAccess,
  setuserAccess,
  setIsadmin,
  loader,
}) {
  const {
    baseUrl,EngineUrl,
    handleGetPrice,
    GetAllLocations_hotel,
    FetchAccessTokenFromDb,isEazotelAdmin,setisEazotelAdmin,isEazotelSports, setisEazotelSports,GetAllSports,
    GetAllUsers,
    GetAllUsersLogs,
    fetchLoneStarBookings,fetchAllSlotsPriceMgmt,fetchAllTodaysBookings
  } = useContext(AuthContext);
  const [Name, setName] = useState("");
  const [Password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  function getDateRange() {
    const currentDate = new Date();

    // Get today's date
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const today = `${year}-${month}-${day}`;

    // Get yesterday's date
    const yesterdayDate = new Date(currentDate);
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    const yesterdayYear = yesterdayDate.getFullYear();
    const yesterdayMonth = String(yesterdayDate.getMonth() + 1).padStart(2, '0');
    const yesterdayDay = String(yesterdayDate.getDate()).padStart(2, '0');
    const yesterday = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDay}`;

    // Get tomorrow's date
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const tomorrowYear = tomorrowDate.getFullYear();
    const tomorrowMonth = String(tomorrowDate.getMonth() + 1).padStart(2, '0');
    const tomorrowDay = String(tomorrowDate.getDate()).padStart(2, '0');
    const tomorrow = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay}`;

    return {
        today: today,
        yesterday: yesterday,
        tomorrow: tomorrow
    };
}
  const [manualLoginLoader, setManualLoginLoader] = useState(false);
  const handleGoogleLogin = async (provider, data) => {
    const email = data.email;
    const password = data.email;
    console.log(data);
    try {
      const response = await fetch(`${baseUrl}/api/login`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailId: email,
          accesskey: password,
        }),
      });
      const json = await response.json();
      console.log(json);
      if (json.Status === true) {
        localStorage.setItem("Token", json.Token);
        sessionStorage.setItem("Token", json.Token);
        try {
          const response1 = await fetch(
            `${baseUrl}/api/getDashboardStatus?id=${json.Token}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
              },
            }
          );
          const json1 = await response1.json();

          if (json1.Status === true) {
            setUserProfile(json1.Profile);
            setUserLinks(json1.Data);
            setUserPlan(json1.Plan);
            setAuth(true);
            GetAllLocations_hotel();
          } else {
            setAuth(false);
          }
        } catch (error) {
          alert("Dashboard Status API Error");
        }
      } else {
        alert("Invalid Creds");
      }
    } catch (error) {
      alert("Login Page API Error");
    }
  };

  const ManualLogin = async () => {
    try {
      setManualLoginLoader(true);
      if(!isEazotelAdmin){
        if(isEazotelSports){
          const response = await fetch(`${EngineUrl}/sports/logindashboard`, {
            method: "POST",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "passKey":Password,
              "email":Name,
            }),
          });
          const json = await response.json();
    
          if (json.Information !== "None") {
            localStorage.setItem("Token", json.Token);
            sessionStorage.setItem("Token", json.Token);
            setUserProfile(json.Information);
            setuserAccess(json.User)
            let currdate = getDateRange()
            GetAllSports()
            GetAllUsers()
            GetAllUsersLogs()
            fetchLoneStarBookings()
            fetchAllSlotsPriceMgmt(currdate.today,currdate.yesterday,currdate.tomorrow)
            fetchAllTodaysBookings()
            setAuth(true);
            
          } else {
            alert("Invalid Creds");
          }
    
          setManualLoginLoader(false);
        }
        else{
          const response = await fetch(`${EngineUrl}/eazotel/ceateuser`, {
            method: "POST",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "register":"false",
                emailId: Name,
                userName:"",
                accesskey: Password,
            }),
          });
          const json = await response.json();
    
          if (json.Status === true) {
            localStorage.setItem("Token", json.Token);
            sessionStorage.setItem("Token", json.Token);
            try {
              const response1 = await fetch(
                `${EngineUrl}/eazotel/getuser/${json.Token}`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                  },
                }
              );
              const json1 = await response1.json();
    
              if (json1.Status === true) {
                setUserProfile(json1.Profile);
                setUserLinks(json1.Data);
                setUserPlan(json1.Plan);
                setuserAccess(json1.Access);
                setIsadmin(json1.Admin);
                setAuth(true);
                GetAllLocations_hotel();
                FetchAccessTokenFromDb();
              } else {
                setAuth(false);
              }
            } catch (error) {
              alert("Dashboard Status API Error");
            }
          } else {
            alert("Invalid Creds");
          }
    
          setManualLoginLoader(false);
        }
      }
      else{
        const response = await fetch(`${EngineUrl}/admin/login`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "password":Password,
            "emailId":Name,
          }),
        });
        const json = await response.json();
  
        if (json.Information !== null) {
          localStorage.setItem("Token", json.Token);
          sessionStorage.setItem("Token", json.Token);
          setUserProfile(json.Information);
          setAuth(true);
          
        } else {
          alert("Invalid Creds");
        }
  
        setManualLoginLoader(false);
      }
    } catch (error) {
      setManualLoginLoader(false);
      alert("Login Page API Error");
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const changeSupplierType = (data)=>{
    if(data==="admin"){
      setisEazotelAdmin(true)
      setisEazotelSports(false)
    }
    else if(data==="sports"){
      setisEazotelAdmin(false)
      setisEazotelSports(true)
    }
    else{
      setisEazotelAdmin(false)
      setisEazotelSports(false)
    }
  }
  return (
    <>
      {manualLoginLoader ? (
        <div
          style={{
            position: "absolute",
            top: "46%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000, // Ensure it's above the LoginPage
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        ""
      )}
      <div
        className="loginMain"
        style={
          loader || manualLoginLoader
            ? { filter: "blur(5px) grayscale(80%)" }
            : {}
        }
      >
        <div className="eazotel-logo">
          <img className="img_logo" src={logo} alt="eazotel" />
        </div>
        <div className="loginForm">
          <h4 className="my-4">
            <strong>Login to Your Dashboard</strong>
          </h4>
          <Form>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '15px' }}>
            <div>
              <input
                type="radio"
                onClick={() => {
                  changeSupplierType('admin');
                }}
                checked={isEazotelAdmin}
              />
              Admin
            </div>
            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <input
                type="radio"
                onClick={() => {
                  changeSupplierType('client');
                }}
                checked={!isEazotelAdmin || isEazotelSports}
              />
              Client
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <input
                type="radio"
                onClick={() => {
                  changeSupplierType('sports');
                }}
                checked={isEazotelSports}
              />
              Lone Star
            </div>
          </div>

            <Form.Group className="mb-3 Form" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={Name}
                disabled={manualLoginLoader}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Enter email"
              />
              {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text> */}
            </Form.Group>
            
            <Form.Group
              className="mb-3 passfield"
              controlId="formBasicPassword"
            >
              <Form.Label>Password</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={Password}
                disabled={manualLoginLoader}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Password"
              />
              {showPassword ? (
                <BsEyeSlash className="eye-icon" onClick={toggleShowPassword} />
              ) : (
                <BsEye className="eye-icon" onClick={toggleShowPassword} />
              )}
            </Form.Group>
            <div className="forget-pass">
              <Form.Group className="mb-3 " controlId="formBasicCheckbox">
                {/* <Form.Check type="checkbox" label="Check me out" /> */}
              </Form.Group>
              <Link to="" className="forget mb-3">
                Forget password
              </Link>
            </div>
          </Form>
          <Button
            className="w-100"
            variant="primary"
            onClick={() => {
              ManualLogin();
            }}
          >
            Submit
          </Button>
          <div className="googleauth mt-1 w-100">
            <LoginSocialGoogle
              client_id="525278251391-g3jigd28se6a4fse2ld8pcp2spvv2jnp.apps.googleusercontent.com"
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={({ provider, data }) => {
                console.log(data);
                handleGoogleLogin(provider, data);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              
              <div className="googleLogin my-4 w-100">
                <FcGoogle size={30} style={{ marginRight: "1rem" }} />
                <p className="glb m-0">Continue with Google</p>
              </div>
            </LoginSocialGoogle>
          </div>
        </div>
        <p className="mt-3">
          Follow us on
          <Link
            className="social-link"
            to="https://www.instagram.com/eazotel/ "
            target="_blank"
          >
            {" "}
            <i
              class="fa fa-instagram mx-2 me-2"
              style={{ fontSize: "24px" }}
            ></i>
          </Link>
          {/* <Link className="social-link" to="https://www.instagram.com/eazotel/ " target='_blank'><i class="fa fa-facebook-square mx-2 me-2" style={{ fontSize: "24px" }}></i></Link> */}
          <Link
            className="social-link"
            to="https://www.linkedin.com/company/eazotel/mycompany/"
            target="_blank"
          >
            <i
              class="fa fa-linkedin-square me-2 mx-2"
              style={{ fontSize: "24px" }}
            ></i>
          </Link>
        </p>
      </div>
    </>
  );
}

export default LoginPage;
