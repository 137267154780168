import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from '../Context/AuthProvider';

function CmsBlogCrad({Image,Heading,likes,share,comment,blogid,getAllBlogs}) {

    const {baseUrl,websiteBlogsData,setwebsiteBlogsData,EngineUrl}=useContext(AuthContext)

    const DeleteBlog=()=>{
      fetch(`${EngineUrl}/blogs/deletedblog`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "token":localStorage.getItem("Token"),
            "blogid":blogid
        }),
      })
        .then(response => response.json())
        .then(data => {
          toast.success("Deleted")
          getAllBlogs()

          
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }
    return (
        <div>
            <Card style={{width:'16rem'}}>
                <Card.Img variant="top" src={Image} />
                <Card.Body>
                <Card.Text>
                    </Card.Text>
                    <Card.Title>{Heading}</Card.Title>
                    <Card.Text>
                      <i class="fa fa-heart" style={{color:"red"}}></i>{likes}
                      <br />
                      <i class="far fa-comment" ></i>{comment}
                      <br />
                      <i class="fa fa-share-alt" style={{}}></i>{share}
                    </Card.Text>
                    <Button variant="danger" onClick={DeleteBlog}>Delete</Button>
                </Card.Body>
            </Card>
        </div>
    )
}

export default CmsBlogCrad
