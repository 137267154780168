import React, { useContext, useEffect } from 'react'
import Filters from '../Social Meadia/Filters';
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import '../../Style/SocialMedia.css'
import AnalyticsCard from './AnalyticsCard';
import AuthContext from '../../Context/AuthProvider';
import { useNavigate } from 'react-router-dom';


function SocialAnalytics() {
  const navigate = useNavigate()
  const {RedirectDinabitetool,DinabiteToken} = useContext(AuthContext)
  useEffect(() => {
    if(DinabiteToken==="None"){
      navigate('/dashboard/client/socialmedia/home')
    }
  }, []);
  return (
    <div style={{ width: '100%', paddingBottom: "50px" }}>
      <Filters />

      <div className="a-main">
        <div className="a-inner">
          <div className="topAnlt">
            <FaFacebook className='afaceicon' style={{ color: '#3b5998' }} />
            <h3>Facebook</h3>
          </div>
          <div className="acrds">
            <AnalyticsCard Title='Impressions' Subtitle='10' />
            <AnalyticsCard Title='Views' Subtitle='0' />
            <AnalyticsCard Title='Profile visits' Subtitle='0' />
            <AnalyticsCard Title=' Total likes' Subtitle='0' />
          </div>
        </div>
        <div className="a-inner">
          <div className="topAnlt">
            <FaInstagram className='afaceicon instacode' />
            <h3>Instagram</h3>
          </div>
          <div className="acrds">
            <AnalyticsCard Title='Impressions' Subtitle='10' />
            <AnalyticsCard Title='Views' Subtitle='0' />
            <AnalyticsCard Title='Profile visits' Subtitle='0' />
            <AnalyticsCard Title=' Total likes' Subtitle='0' />
          </div>
        </div>
        <div className="a-inner">
          <div className="topAnlt">
            <FaFacebook className='afaceicon' style={{ color: '#3b5998' }} />
            <h3>Facebook</h3>
          </div>
          <div className="acrds">
            <AnalyticsCard Title='Impressions' Subtitle='10' />
            <AnalyticsCard Title='Views' Subtitle='0' />
            <AnalyticsCard Title='Profile visits' Subtitle='0' />
            <AnalyticsCard Title=' Total likes' Subtitle='0' />
          </div>
        </div>
        <div className="a-inner">
          <div className="topAnlt">
            <FaInstagram className='afaceicon instacode' />
            <h3>Youtube</h3>
          </div>
          <div className="acrds">
            <AnalyticsCard Title='Impressions' Subtitle='10' />
            <AnalyticsCard Title='Views' Subtitle='0' />
            <AnalyticsCard Title='Profile visits' Subtitle='0' />
            <AnalyticsCard Title=' Total likes' Subtitle='0' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SocialAnalytics
