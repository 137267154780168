import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import "../Style/ReservationDeskFilter.css"
import AuthContext from '../Context/AuthProvider'

const Priceupdatefilter = (props) => {
    const { EngineUrl,EngineNewUrl, handleGetPrice } = useContext(AuthContext)


    const { mheading, setAllBookings } = props;

    const [selectedValue, setSelectedValue] = useState('div1');
    const [checkinDate, setCheckinDate] = useState('')
    const [checkoutDate, setCheckoutDate] = useState('')
    const [Type, setType] = useState('1')
    const [Price, setPrice] = useState(0)

    const [weekday, setweekday] = useState('')
    const [weekend, setWeekend] = useState('')

    const [percentage,setpercentage] = useState(0)

    const [Mon,setMon] = useState("None")
    const [Tue,setTue] = useState("None")
    const [Wed,setWed] = useState("None")
    const [Thu,setThu] = useState("None")
    const [Fri,setFri] = useState("None")
    const [Sat,setSat] = useState("None")
    const [Sun,setSun] = useState("None")

    const { priceData } = useContext(AuthContext); // Use destructuring to get priceData directly
    // console.log(priceData)

    const roomTypes = Object.keys(priceData);


    const UpdateDateRangePrice = async () => {

        try {
            const response = await fetch(`${EngineNewUrl}/price/update/price`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: localStorage.getItem('Token'),
                    "roomType": Type,
                    "date1": checkinDate,
                    "date2": checkoutDate,
                    "price": Price,
                    "hId":localStorage.getItem("hotelLocationId")
                }),
            });

            const json = await response.json();

            if (json.Status === true) {
                //   alert("updated")
                handleGetPrice()
                Referesh()

            } else {
                console.error("Error filtering dates:", json.Error);
            }
        } catch (error) {
            console.error("Error sending POST request:", error);
        }
    }

    const UpdateDateRangePrice_weakend_day = async () => {
        try {
            const response = await fetch(`${EngineNewUrl}/price/update/weakendprice/range`,{
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: localStorage.getItem('Token'),
                    "roomType": Type,
                    "date1": checkinDate,
                    "date2": checkoutDate,
                    "priceweakday": weekday,
                    "priceweakend":weekend,
                    "hId":localStorage.getItem("hotelLocationId")
                }),
            });

            const json = await response.json();

            if (json.Status === true) {
                //   alert("updated")
                handleGetPrice()
                Referesh()

            } else {
                console.error("Error filtering dates:", json.Error);
            }
        } catch (error) {
            console.error("Error sending POST request:", error);
        }
    }

    const UpdateWeekendWeekdayPrice = async () => {
        try {
            const response = await fetch(`${EngineUrl}/room/update/weakendprice`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: localStorage.getItem('Token'),
                    "roomType": Type,
                    "weekend": weekend,
                    "weekday": weekday
                }),
            });

            const json = await response.json();

            if (json.Status === true) {
                //   alert("updated")
                handleGetPrice()
                Referesh()

            } else {
                console.error("Error filtering dates:", json.Error);
            }
        } catch (error) {
            console.error("Error sending POST request:", error);
        }
    }

    const UpdateDateRangePrice_byPercentage = async()=>{
        
        let percent = String(parseFloat(percentage))
        try {
            const response = await fetch(`${EngineNewUrl}/price/update/bulkprice/percentage`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: localStorage.getItem('Token'),
                    "roomtype":Type,
                    "percent":percent,
                    "start_date":checkinDate,
                    "end_date":checkoutDate,
                    "hId":localStorage.getItem("hotelLocationId")
                }),
            });

            const json = await response.json();

            if (json.Status === true) {
                //   alert("updated")
                handleGetPrice()
                Referesh()

            } else {
                console.error("Error filtering dates:", json.Error);
            }
        } catch (error) {
            console.error("Error sending POST request:", error);
        }
    }

    const UpdateDateRangePrice_byDays = async()=>{
        
        let percent = String(parseFloat(percentage))
        try {
            const response = await fetch(`${EngineNewUrl}/price/update/bulkprice/dayswise`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: localStorage.getItem('Token'),
                    "roomtype":Type,
                    "Mon":Mon,
                    "Tue":Tue,
                    "Wed":Wed,
                    "Thu":Thu,
                    "Fri":Fri,
                    "Sat":Sat,
                    "Sun":Sun,
                    "start_date":checkinDate,
                    "end_date":checkoutDate,
                    "hId":localStorage.getItem("hotelLocationId")
                }),
            });

            const json = await response.json();

            if (json.Status === true) {
                //   alert("updated")
                handleGetPrice()
                Referesh()

            } else {
                console.error("Error filtering dates:", json.Error);
            }
        } catch (error) {
            console.error("Error sending POST request:", error);
        }
    }

    const Referesh = () => {
        // setSelectedValue()
        setCheckinDate('')
        setCheckoutDate('')
        setType('1')
        setPrice('')
        setweekday('')
        setWeekend('')
        setpercentage(0)
        setMon("None")
        setTue("None")
        setWed("None")
        setThu("None")
        setFri("None")
        setSat("None")
        setSun("None")
    }

    const handleOptionChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <div className='reservationdesktable'>
            <h3>Update Price</h3>
            <div className="b-filters">
                {/* <h5>Filters</h5> */}
                {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                            inline
                            label="Date Range"
                            name="group1"
                            type={type}
                            value="div1"
                            checked={selectedValue === 'div1'}
                            onChange={handleOptionChange}
                            id={`inline-${type}-2`}
                        />
                        <Form.Check
                            inline
                            label="Date Range Weakday/Weakday Price"
                            name="group1"
                            type={type}
                            value="div2"
                            checked={selectedValue === 'div2'}
                            onChange={handleOptionChange}
                            id={`inline-${type}-2`}
                        />
                        <Form.Check
                            inline
                            label="Percentage Price Update"
                            name="group1"
                            type={type}
                            value="div3"
                            checked={selectedValue === 'div3'}
                            onChange={handleOptionChange}
                            id={`inline-${type}-2`}
                        />
                        <Form.Check
                            inline
                            label="Days Wise Price Update"
                            name="group1"
                            type={type}
                            value="div4"
                            checked={selectedValue === 'div4'}
                            onChange={handleOptionChange}
                            id={`inline-${type}-2`}
                        />
                        {/* <button className='btn btn-danger' onClick={() => { Referesh() }}>close</button> */}
                    </div>
                ))}
            </div>
            <div className="filter">
                <div id="div1" style={{ display: selectedValue === 'div1' ? 'block' : 'none' }}>

                    <div className="date-fltr">
                        <div className="dt-flt-inr">
                            <div className='book-chk'>
                                <label>Room Type</label>
                                <Form.Select className='form1 dates' value={Type} onChange={(e) => { setType(e.target.value) }} id="type" aria-label="Default select example">
                                    {roomTypes.map((roomType, index) => (
                                        <option value={roomType}>{roomType === "1" ? "Deluxe" : roomType === "2" ? "Super Delux" : roomType === "3" ? "Suite" : roomType == "4" ? "Premium" : ""}</option>
                                    ))}
                                </Form.Select>
                            </div>
                            <div className="book-chk">
                                <label htmlFor="/">Booking Date From:</label>
                                <input className='dates' type="date" name="" id="checkin" value={checkinDate} onChange={(e) => { setCheckinDate(e.target.value) }} /></div>
                            <div className="book-chk">
                                <label htmlFor="/">Booking Date To:</label>
                                <input className='dates' type="date" name="" id="checkout" value={checkoutDate} onChange={(e) => { setCheckoutDate(e.target.value) }} /></div>
                            <div className='book-chk'>
                                <label>Price</label>
                                <input className='dates' type="tel" placeholder='Price' value={Price} onChange={(e) => { setPrice(e.target.value) }} />
                            </div>
                        </div>

                    </div>
                    <Button className="btn" onClick={() => { UpdateDateRangePrice() }}>Update Price</Button>
                </div>

                <div id="div2" style={{ display: selectedValue === 'div2' ? 'block' : 'none' }}>

                    <div className="date-fltr">
                        <div className="dt-flt-inr">
                        <div className="book-chk">
                                <label htmlFor="/">Start Date:</label>
                                <input className='dates' type="date" name="" id="checkin" value={checkinDate} onChange={(e) => { setCheckinDate(e.target.value) }} /></div>
                            <div className="book-chk">
                                <label htmlFor="/">End Date:</label>
                                <input className='dates' type="date" name="" id="checkout" value={checkoutDate} onChange={(e) => { setCheckoutDate(e.target.value) }} /></div>
                            <div className="book-chk">
                                <label htmlFor="/">Weekday Price:</label>
                                <input className='dates' type="text" placeholder="Weekday Price" name="" id="checkout" value={weekday} onChange={(e) => { setweekday(e.target.value) }} /></div>
                                <div className="book-chk">
                                <label htmlFor="/">Weekend Price:</label>
                                <input className='dates' type="text" placeholder='Weekend Price' name="" id="checkin" value={weekend} onChange={(e) => { setWeekend(e.target.value) }} /></div>
                            <div className='book-chk'>
                                <label>Room Type</label>
                                <Form.Select className='form1 dates' value={Type} onChange={(e) => { setType(e.target.value) }} id="type" aria-label="Default select example">
                                    {roomTypes.map((roomType, index) => (
                                        <option value={roomType}>{roomType === "1" ? "Deluxe" : roomType === "2" ? "Super Delux" : roomType === "3" ? "Suite" : roomType == "4" ? "Premium" : ""}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>

                    </div>
                    <Button className="btn" onClick={() => { UpdateDateRangePrice_weakend_day() }}>Update Price</Button>
                </div>

                <div id="div3" style={{ display: selectedValue === 'div3' ? 'block' : 'none' }}>

                    <div className="date-fltr">
                        <div className="dt-flt-inr">
                        <div className="book-chk">
                                <label htmlFor="/">Start Date:</label>
                                <input className='dates' type="date" name="" id="checkin" value={checkinDate} onChange={(e) => { setCheckinDate(e.target.value) }} /></div>
                            <div className="book-chk">
                                <label htmlFor="/">End Date:</label>
                                <input className='dates' type="date" name="" id="checkout" value={checkoutDate} onChange={(e) => { setCheckoutDate(e.target.value) }} /></div>
                            <div className="book-chk">
                                <label htmlFor="/">Percentage:</label>
                                <input className='dates' type="text" placeholder="Weekday Price" name="" id="checkout" value={percentage} onChange={(e) => { setpercentage(e.target.value) }} /></div>
                            <div className='book-chk'>
                                <label>Room Type</label>
                                <Form.Select className='form1 dates' value={Type} onChange={(e) => { setType(e.target.value) }} id="type" aria-label="Default select example">
                                    {roomTypes.map((roomType, index) => (
                                        <option value={roomType}>{roomType === "1" ? "Deluxe" : roomType === "2" ? "Super Delux" : roomType === "3" ? "Suite" : roomType == "4" ? "Premium" : ""}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>

                    </div>
                    <Button className="btn" onClick={() => { UpdateDateRangePrice_byPercentage() }}>Update Price</Button>
                </div>
                <div id="div4" style={{ display: selectedValue === 'div4' ? 'block' : 'none' }}>

                    <div className="date-fltr">
                        <div className="dt-flt-inr">
                        <div className="book-chk">
                                <label htmlFor="/">Start Date:</label>
                                <input className='dates' type="date" name="" id="checkin" value={checkinDate} onChange={(e) => { setCheckinDate(e.target.value) }} /></div>
                            <div className="book-chk">
                                <label htmlFor="/">End Date:</label>
                                <input className='dates' type="date" name="" id="checkout" value={checkoutDate} onChange={(e) => { setCheckoutDate(e.target.value) }} /></div>
                            <div className='book-chk'>
                                <label>Room Type</label>
                                <Form.Select className='form1 dates' value={Type} onChange={(e) => { setType(e.target.value) }} id="type" aria-label="Default select example">
                                    {roomTypes.map((roomType, index) => (
                                        <option value={roomType}>{roomType === "1" ? "Deluxe" : roomType === "2" ? "Super Delux" : roomType === "3" ? "Suite" : roomType == "4" ? "Premium" : ""}</option>
                                    ))}
                                </Form.Select>
                            </div>
                            <br />
                           
                        </div>
                        <div style={{display:"flex"}}>
                                <div className="book-chk">
                                    <label htmlFor="/">Monday:</label>
                                    <input className='dates' type="text" placeholder="Weekday Price" name="" id="checkout" value={Mon} onChange={(e) => { setMon(e.target.value) }} /></div>
                                <div className="book-chk">
                                    <label htmlFor="/">Tuesday:</label>
                                    <input className='dates' type="text" placeholder="Weekday Price" name="" id="checkout" value={Tue} onChange={(e) => { setTue(e.target.value) }} /></div>
                                <div className="book-chk">
                                    <label htmlFor="/">Wednessday:</label>
                                    <input className='dates' type="text" placeholder="Weekday Price" name="" id="checkout" value={Wed} onChange={(e) => { setWed(e.target.value) }} /></div>
                                <div className="book-chk">
                                    <label htmlFor="/">Thursday:</label>
                                    <input className='dates' type="text" placeholder="Weekday Price" name="" id="checkout" value={Thu} onChange={(e) => { setThu(e.target.value) }} /></div>
                                <div className="book-chk">
                                    <label htmlFor="/">Friday:</label>
                                    <input className='dates' type="text" placeholder="Weekday Price" name="" id="checkout" value={Fri} onChange={(e) => { setFri(e.target.value) }} /></div>
                                <div className="book-chk">
                                    <label htmlFor="/">Saturday:</label>
                                    <input className='dates' type="text" placeholder="Weekday Price" name="" id="checkout" value={Sat} onChange={(e) => { setSat(e.target.value) }} /></div>
                                <div className="book-chk">
                                    <label htmlFor="/">Sunday:</label>
                                    <input className='dates' type="text" placeholder="Weekday Price" name="" id="checkout" value={Sun} onChange={(e) => { setSun(e.target.value) }} /></div>

                            </div>
                    </div>
                    <Button className="btn" onClick={() => { UpdateDateRangePrice_byDays() }}>Update Price</Button>
                </div>
            </div>
        </div>

    )
}

export default Priceupdatefilter
