import React from 'react'
import "../../Style/Theme.css"
import ThemeCard from '../../Components/ThemeCard'
import Hostily from "../../Images/Hostily.png"
import Colina from "../../Images/Colina.png"
import Balena from "../../Images/Balina.png"
import Atoli from "../../Images/temp3.png"
import Atoli2 from "../../Images/temp4.png"
import { useContext } from 'react'
import AuthContext from '../../Context/AuthProvider'
import Alert from '../../Components/Alert'

function Themes() {

  const { TemplateNumber } = useContext(AuthContext)
  // alert(TemplateNumber)

  return (
    <>
      <div className='theme'>


        <div className="row g-4 portfolio-container">
          <ThemeCard templatename="Template 1" img={Colina} templatenumber="1" link="https://temps.eazotel.com/colina%20template/colina%20template/index.html"/>
          <ThemeCard templatename="Template 2" img={Balena} templatenumber="2" link="https://temps.eazotel.com/Balena/balena-hotel-html-site-template-2022-10-29-08-46-53-utc/HTML/index.html"/>
          <ThemeCard templatename="Template 3" img={Hostily} templatenumber="3" link="https://temps.eazotel.com/main-file/main-file/html/hotel/index.html" />
          

        </div>

      </div>
    </>
  )
}

export default Themes
