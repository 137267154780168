import React, { useState, useContext } from "react";
import SEOTable from "./SeoManagerTable";
import EditPopup from "./SeoManagerPopup";
import AuthContext from "../Context/AuthProvider.js";

const SeoManagerData = () => {
  const {
    baseUrl,
    EngineNewUrl,
    websiteSeoData,
    setwebsiteSeoData,
    setAlertMessage,
    setIsAlertOpen,
  } = useContext(AuthContext);
  const initialData = websiteSeoData;

  const [seoData, setSeoData] = useState(initialData);
  // const [editingData, setEditingData] = useState(null);
  const { editingData, setEditingData } = useContext(AuthContext);

  const handleEditClick = (PageName) => {
    const dataToEdit = seoData.find((item) => item.PageName === PageName);
    console.log(dataToEdit);
    setEditingData(dataToEdit);
  };

  const handleSaveEdit = (editedData) => {
    console.log(editedData);
    const updatedData = seoData.map((item) =>
      item.PageName === editedData.PageName ? editedData : item
    );
    setSeoData(updatedData);
    setwebsiteSeoData(updatedData);
    fetch(`${EngineNewUrl}/cms/edit/seo/seodata`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        operation: "delete",
        Page: editedData.PageName,
        Title: editedData.Data.Title,
        description: editedData.Data.Description,
        keyword: editedData.Data.keyword,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const message = "Seo Updated";
        setAlertMessage(message);
        setIsAlertOpen(true);
        setTimeout(() => {
          setIsAlertOpen(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setEditingData(null);
  };

  return (
    <div>
      <SEOTable data={seoData} onEditClick={handleEditClick} />

      {/* {editingData && <EditPopup data={editingData} onSave={handleSaveEdit} />} */}
      {editingData && <EditPopup onSave={handleSaveEdit} />}
    </div>
  );
};

export default SeoManagerData;
