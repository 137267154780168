import React, { useContext, useState } from "react";
import { GrClose } from "react-icons/gr";
import { IoMdSend } from "react-icons/io";
import AuthContext from "../Context/AuthProvider";
import "../Style/AddUserPopUp.css";
import "../Style/ReservDeskInfoPopup.css";

const InfoPopUpII = ({ setmessagepopup, object }) => {
  const {DinabiteMessagesReply} = useContext(AuthContext)
  const [Message,setMessage] = useState("")
  const sender = object.senderName;
  const messagesdata = object.messages.data;
  // console.log(messagesdata)
  const SendMessage=(senderid,message,type)=>{
    if(message===""){

    }
    else{
      DinabiteMessagesReply(type,senderid,message)
    }
    
  }

  return (
    <div className="overlayStyle">
      <div className="w-100 h-auto">
        <div
          style={{
            position: "fixed",
            top: "10%",
            left: "25%",
            zIndex: 1,
            width: "950px",
          }}
        >
          <div className="h-100 shadow mb-1 bg-body rounded popUpBG">
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "end",
              }}
            >
              <GrClose
                className="closeIcon"
                onClick={() => {
                  setmessagepopup(false);
                }}
              />
            </div>

            <div
              className="row rounded text-white"
              style={{ marginTop: "-1.8rem", backgroundColor: "#0A3A75" }}
            >
              <div className="col py-2 text-center">{object.senderName}</div>
            </div>
            <div className="popupbg">
              {messagesdata.map((message) => (
                <div key={message.id}>
                  {message.from.name === sender && (
                    <div className="row mt-1">
                      <div className="col">
                        <div
                          style={{
                            float: "right",
                            width: "100%",
                            textAlign: "left",
                            fontSize: "14px",
                            color: "red",
                          }}
                        >
                          <strong>{message.from.name}</strong>
                        </div>
                        <div
                          style={{
                            width: "auto",
                            maxWidth: "60%",
                            float: "left",
                            textAlign: "left",
                            fontSize: "18px",
                          }}
                        >
                          <strong>{message.message}</strong>
                        </div>
                      </div>
                    </div>
                  )}
                  {message.from.name !== sender && (
                    <div className="row mt-1">
                      <div className="col">
                        <div
                          style={{
                            float: "right",
                            width: "100%",
                            textAlign: "right",
                            fontSize: "14px",
                            color: "green",
                          }}
                        >
                          <b>{message.from.name}</b>
                        </div>
                        <div
                          style={{
                            width: "auto",
                            maxWidth: "60%",
                            float: "right",
                            textAlign: "left",
                            fontSize: "18px",
                          }}
                        >
                          <strong>{message.message}</strong>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Send Message"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={Message}
                onChange={(e)=>{setMessage(e.target.value)}}
              />
              <div
                class="input-group-append"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "1px",
                  marginLeft: "2px",
                }}
              >
                <IoMdSend onClick={()=>{SendMessage(object.senderId,Message,object.type)}} size="2rem" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPopUpII;
