import React, { useContext } from 'react';
import { CgProfile } from 'react-icons/cg';
import '../Style/LandingCard.css';
import { redirect,Link } from 'react-router-dom';
import AuthContext from '../Context/AuthProvider';

const LandingCard = (props) => {
    const { count, heading, para } = props;

    return (
            <div className='landingCard'>
                <div className='dashboard-stat color-2 '>
                    <div className='dashboard-stat-content'>
                        <h4>{count}</h4> <span></span>
                    </div>
                    <div className='dashboard-stat-icon'>

                        {/* <i class=" im bi bi-person-circle"></i> */}
                        <CgProfile className='im im-icon-Map2' />
                    </div>
                    <div className='dashboard-stat-item'>
                        <p>{heading}</p>
                    </div>
                </div>
            </div>
    );
};

export default LandingCard;
