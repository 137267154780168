import React, { useContext, useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import AuthContext from "../Context/AuthProvider";
import "../Style/ReservationDeskTable.css";
import InfoPopup from "./LonebookingInfopopup";
const ReservationDeskTable = () => {
  const {fetchLoneStarBookings,LoneStarBookings,setLoneStarBookings,EngineUrl} = useContext(AuthContext)

  useEffect(()=>{
    fetchLoneStarBookings()
  },[])
  
  const [Infodata,setInfodata] = useState()
  const [openInfoPopUp, setopenInfoPopUp] = useState(false);

  const ChangeCheckinOutStatus =async(bookingid,checkin,checkout)=>{
    try {
      const response = await fetch(
        `${EngineUrl}/sports/changeStatusCheckInCheckOut/${localStorage.getItem("Token")}/${bookingid}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "isCheckedIn":checkin,
            "isCheckedOut":checkout
            
          }),
        }
      );

      const json = await response.json();
      fetchLoneStarBookings()
      
    } catch {
      // alert("Some Problem update token");
    }
  }
  

  const handleInfoPopup = (data) => {
    setInfodata(data);
    setopenInfoPopUp(!openInfoPopUp);
  };
//   const handleInfoPopupII = (data) => {
//     setInfoData(data);
//     setOpenInfoPopUpII(!openInfoPopUpII);
//   };
//   const handleInfoPopupIII = (data) => {
//     setchechinData(data);
//     setopenInfoPopUpIII(!openInfoPopUpIII);
//   };

    const bookingData = []

  function convertDateFormat(inputDate) {
    const parts = inputDate.split('-');
    if (parts.length === 3) {
      const [year, month, day] = parts;
      // Create a new Date object with the given parts
      const dateObject = new Date(`${year}-${month}-${day}`);
      // Format the date as 'dd-mm-yyyy'
      const formattedDate = `${String(dateObject.getDate()).padStart(2, '0')}-${String(dateObject.getMonth() + 1).padStart(2, '0')}-${dateObject.getFullYear()}`;
      return formattedDate;
    } else {
      // Handle invalid input
      console.error('Invalid date format');
      return inputDate;
    }
  }

  
  return (
    <>
      {bookingData === null ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div>
            <div
              className="tble-head d-flex"
              style={{ border: "1px solid gray", borderRadius: "8px" }}
            >
              <div
                className="tbl-inr d-flex"
                style={{
                  justifyContent: "space-around",
                  height: "100%",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                <span>Total Bookings</span>
                <span>
                  {bookingData != null ? LoneStarBookings.length : 0}
                </span>
              </div>
            </div>
            <table className="ReservationDeskTable">
              <thead>
                <tr className="tablerow">
                  <th>Booking Id</th>
                  <th>Guest Name</th>
                  <th>Reservation Date</th>
                  <th>Email-Id</th>
                  <th>Phone</th>
                  <th>Total Price</th>
                  <th>Payment Status</th>
                  <th>Checked In</th>
                  <th>CheckedOut</th>
                </tr>
              </thead>
              <tbody>
                {bookingData != null
                  ? LoneStarBookings.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td >
                          {" "}
                          <a style={{color:"darkblue"}} onClick={()=>{handleInfoPopup(row)}}>{row.sportBookingId}</a>{" "}
                        </td>
                        <td >
                          {row.guestInfo.Name}
                        </td>
                        
                        <td>{convertDateFormat(row.bookingDate)}<br></br>
                            </td>
                        <td>
                          {row.guestInfo.Email}<br></br>
                          
                        </td>
                        <td>{row.guestInfo.Phone}</td>
                      
                        <td>{row.price.totalAmount} </td>
                        <td>
                          {row.payment.Status === "PENDING" ? (
                            <span className="badge bg-danger">
                              {row.payment.Status}
                            </span>
                          ) : row.payment.Status === "ADVANCED" ? (
                            <span className="badge bg-warning">
                              {row.payment.Status}
                            </span>
                          ) : row.payment.Status === "CANCELLED" ? (
                            <span className="badge bg-secondary">
                              {row.payment.Status}
                            </span>
                          ) : row.payment.Status === "REFUND" ? (
                            <span className="badge text-bg-info">
                              {row.payment.Status}
                            </span>
                          ) : (
                            <span className="badge bg-success">
                              {row.payment.Status}
                            </span>
                          )}
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          {row.payment.Status === "CANCELLED" ? (
                            "-"
                          ) : row.isCheckedIn === true ? (
                            <span
                              
                              className="badge bg-success"
                            >
                              Checked In
                            </span>
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={()=>{ChangeCheckinOutStatus(row.sportBookingId,"true","false")}}
                            >
                              Check In
                            </button>
                          )}
                        </td>
                        <td>
                          {row.payment.Status === "CANCELLED" ? (
                            "-"
                          ) : row.isCheckedIn === true &&
                            row.isCheckedOut === true ? (
                            <span className="badge bg-danger">Checked Out</span>
                          ) : row.isCheckedIn === false ? (
                            "-"
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={()=>{ChangeCheckinOutStatus(row.sportBookingId,"true","true")}}
                            >
                              Check Out
                            </button>
                          )}
                        </td>
                      </tr>
                    )).reverse()
                  : ""}
              </tbody>
            </table>

            {openInfoPopUp ? <InfoPopup Infodata={Infodata} setopenInfoPopUp={setopenInfoPopUp} /> : ""}

            {/* {openInfoPopUpII ? <InfoPopUpII /> : ""} */}

            {/* {openInfoPopUpIII ? (
              <InfoPopUpIII
                infoData={chechinData}
                setopenInfoPopUpIII={setopenInfoPopUpIII}
                openInfoPopUpIII={openInfoPopUpIII}
              />
            ) : (
              ""
            )} */}
          </div>
        </>
      )}
    </>
  );
};

export default ReservationDeskTable;
