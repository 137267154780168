import React, { useContext, useState } from 'react'
import EditSports from '../../../Components/EditSports'
import AuthContext from '../../../Context/AuthProvider'

function Landing() {
  const {EngineUrl,Sports} = useContext(AuthContext)
  const [isEdit,setisEdit] = useState(false)
  const [editObject,seteditObject] = useState("None")
  const [editsportid,seteditsportid] = useState("None")
  const TogglePopup = (data)=>{
    seteditObject(data)
    seteditsportid(data.sportId)
    setisEdit(true)
  }
  return (
    <>
      
      {isEdit && editObject!=="None"?<EditSports setPopup={setisEdit} data={editObject} setdata={seteditObject} editsportid={editsportid} />:""}
      <div style={{display:"grid",gridTemplateColumns:"repeat(4,1fr)"}}>
        {Sports.map((sport)=>{
            return <div class="card" style={{width:"18rem"}}>
                      <img src={sport["images"][0]} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">{sport.sportName}</h5>
                        {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        <a  class="btn btn-primary" onClick={()=>{TogglePopup(sport)}}>Edit</a>
                      </div>
                    </div>
                      
                    

        })}
      </div>
    </>
  )
}

export default Landing