import React, { useContext, useEffect } from 'react';
import AuthContext from '../../../Context/AuthProvider';

function LandingClient() {
  const {AllUsers,
    setAllUsers,
    FetchAllUsers} = useContext(AuthContext)

    useEffect(()=>{
        FetchAllUsers()
    },[])
  return (
    <div
    className=""
    style={{
      padding: "18px",
      overflowX: "auto",
      overflowY: "auto",
      whiteSpace: "nowrap",
    }}
  >
    <table className='usermanagementtable'>
        <thead>
          <tr className='tablerow'>
            {['Email Id', 'Display Name', 'Admin','created'].map((headerLabel) => (
              <th key={headerLabel}>{headerLabel}</th>
            ))}

          </tr>
        </thead>

        <tbody>
          {AllUsers.map((client) => (
            <tr>
              <td>{client.emailId}</td>
              <td>{client.displayName}</td>
              <td>{client.isAdmin?"Yes":"No"}</td>
              <td>{client.createdAt}</td>
            </tr>
          )).reverse()}
        </tbody>
      </table>
  </div>
  )
}

export default LandingClient