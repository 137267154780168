import React from 'react'
import { useContext,useState } from 'react'
import AuthContext from '../Context/AuthProvider'
import { GrClose } from "react-icons/gr"
import '../Style/ReservDeskInfoPopup.css'
import "../Style/AddUserPopUp.css"

const DinabiteFbPagepopup = ({setFacebookPagepopup}) => {
    const {dinabiteFacebookPages,FacebookSpecificPageAPI,FacebookConnectedPage} = useContext(AuthContext)

    return (
        <div className='overlayStyle'>
            <div className='w-100 h-100' >
                <div style={{ position: "fixed", top: "10%", left: "25%", zIndex: 1, width: "950px", }}>
                    <div className='h-100 shadow mb-1 bg-body rounded popUpBG'>
                        <div style={{ display: "flex", position: 'relative', justifyContent: "end", }}>
                            <GrClose className='closeIcon' onClick={()=>{setFacebookPagepopup(false)}} />
                        </div>

                        <div className="row rounded text-white" style={{ marginTop: '-1.8rem', backgroundColor: '#0A3A75' }} >
                            <div className="col py-2 text-center">
                                Facebook Pages
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col p-1">
                                <table className="table">
                                    <tbody>
                                        {dinabiteFacebookPages.map((pages)=>{
                                            return <tr>
                                                        <th>{pages.name}</th>
                                                        <td>{pages.Category} {FacebookConnectedPage!==pages.id?<span className='badge bg-primary' style={{"float":"right","cursor":"pointer","fontSize":"20px"}}  onClick={()=>{FacebookSpecificPageAPI(pages)}}>Connect</span>:<span className='badge bg-success' style={{"float":"right","cursor":"pointer","fontSize":"20px"}}>Connected</span>}</td>
                                                    </tr>
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DinabiteFbPagepopup