import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import AddBannerCMS from "../../Components/AddDigitalcheckin";
import BackBtn from "../../Components/BackBtn";
import AuthContext from "../../Context/AuthProvider";
import { HashLoader } from "react-spinners";

function CmsContact() {
  const { DigitalCheckin, WebsiteData } = useContext(AuthContext);

  const [editContact, seteditContact] = useState(false);
  const [banneredit, setbanneredit] = useState();

  return (
    <>
      {DigitalCheckin === "None" || WebsiteData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div
            className="homHead w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 0 20px 0",
            }}
          >
            <BackBtn />
            <h3 className="text-center">Digital Checkin</h3>
            <Form>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                onChange={() => {
                  WebsiteData();
                }}
              />
            </Form>
          </div>

          <div className="Contact_table my-4">
            <div className="CmsCOntactTAble">
              <Table striped bordered hover variant="light">
                <thead>
                  <tr>
                    <th>Booking Id</th>
                    <th>Name</th>
                    <th>Group Size</th>
                  </tr>
                </thead>
                <tbody>
                  {DigitalCheckin.reverse().map((contact) => {
                    return (
                      <tr>
                        <td
                          onClick={() => {
                            setbanneredit(contact);
                            seteditContact(true);
                          }}
                        >
                          {contact.BookingId}
                        </td>
                        <td>{contact.Name}</td>
                        <td>{contact.GroupSize}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
          {editContact ? (
            <AddBannerCMS
              seteditClick={seteditContact}
              bannerindex={1}
              banneredit={banneredit}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}

export default CmsContact;
