import React, { useContext, useState } from "react";
import "../Style/SeoManagerPopup.css";
import { GrClose } from "react-icons/gr";
import AuthContext from "../Context/AuthProvider";

const SeoManagerPopup = ({ onSave }) => {
  const { editingData, setEditingData } = useContext(AuthContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingData((prevData) => ({
      ...prevData,
      Data: {
        ...prevData.Data, // Copy existing Data object
        [name]: value,
      },
    }));
  };

  const handleSave = () => {
    onSave(editingData);
  };

  const handleClosePopup = () => {
    setEditingData(null);
  };

  return (
    <div className="edit-popup-overlay">
      <div className="edit-popup">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <GrClose onClick={handleClosePopup} />
        </div>
        <h3>Update SEO Data</h3>
        <div>
          <label>Title</label>
          <input type="text" name="PageName" value={editingData.PageName} />
        </div>
        <div>
          <label>Title</label>
          <textarea
            name="Title"
            value={editingData.Data.Title}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Description</label>
          <textarea
            name="Description"
            value={editingData.Data.Description}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Keywords</label>
          <textarea
            name="keyword"
            value={editingData.Data.keyword}
            onChange={handleInputChange}
          />
        </div>
        {/* <div>
                    <label>Keywords</label>
                    <input
                        type="text"
                        name="keywords"
                        value={editedData.keywords}
                        onChange={handleInputChange}
                    />
                </div> */}
        <div>
          <button
            onClick={() => {
              setEditingData(null);
            }}
            style={{ float: "left" }}
          >
            Close
          </button>
          <button onClick={handleSave} style={{ float: "right" }}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SeoManagerPopup;
