import React from "react";
import "../Style/SeoManagerTable.css";

const SeoManagerTable = ({ data, onEditClick }) => {
  return (
    <table className="seomanagertable">
      <thead>
        <tr>
          <th>Page</th>
          <th>Title</th>
          <th>Description</th>
          <th>Kezywords</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr>
            <td>{item.PageName}</td>
            <td>{item.Data.Title}</td>
            <td>{item.Data.Description}</td>
            <td>{item.Data.keyword}</td>
            <td>
              <button onClick={() => onEditClick(item.PageName)}>Edit</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SeoManagerTable;
