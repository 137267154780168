import React from "react";
// import ComingSoon from '../../Components/ComingSoon'
import ReservationDeskFilter from "../../../Components/ReservationDeskSportfilter";
// import ReservationDeskBanner from "../../../Components/ReservationDesksports";
import ReservationDeskTable from "../../../Components/ReservationDeskSportTable";

function Reservationdesk() {
  
  return (
    <>
      {/* <ReservationDeskBanner /> */}
      <ReservationDeskFilter />
      <ReservationDeskTable />
    </>
  );
}

export default Reservationdesk;
