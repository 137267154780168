import React from 'react'
import Card from 'react-bootstrap/Card';

import { FaHome } from 'react-icons/fa';
function WebBookcard(props) {
    const { Title, Img, Btn,icon } = props;
    return (
        <Card className='book_card' style={{ width: '12rem', height: '11rem', background: props.color, backgroundColor: props.bg_color }}>
            <div className="wb_card">
                {/* <Card.Img variant="top" style={{ color: "white" }} src={Img} /> */}
                <i className={props.icon}></i>
                {/* <i class="fa-solid fa-house"></i> */}

            </div>
            <Card.Body className="wb_body">
                <Card.Title style={{ textAlign: 'center', color: "white", fontWeight: '400', fontSize:'18px' }}>{Title}</Card.Title>
            </Card.Body>
        </Card>
    )
}

export default WebBookcard
