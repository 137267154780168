import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FaFacebook,FaInstagram,FaGoogle } from 'react-icons/fa';
import xyz from '../../Images/SocialMediaImgs/agoda.png'


function PostCards(props) {
    const { Title, data, Btn } = props;
    const type=data.network
    const image=data.media.image
    const text = data.text
    const boostable=data.isBoostable
    return (
        <div className='postCrdm'>
            <Card className='post-cmi'>
                <div className="post-icon postDiv">
                    {type==="facebook"?
                    <FaFacebook className="sicon" style={{ color: '#3b5998' }} />:type==="instagram"?
                    <FaInstagram className="sicon" style={{ color: '#3b5998' }} />:type==="google"?
                    <FaGoogle className="sicon" style={{ color: '#3b5998' }} />:""}
                </div>
                <div className="postimg postDiv">
                    <Card.Img variant="top" src={image} />
                </div>
                <div className="postText postDiv">
                    <Card.Text>
                        {text}
                    </Card.Text>
                </div>

                <Card.Body className="postDiv pc-body">
                    <Card.Title>{Title}</Card.Title>

                    {boostable?<Button className='boost-btn'>{Btn}</Button>:""}
                </Card.Body>
            </Card>
        </div>
    )
}

export default PostCards
