import React from 'react'
import "../Style/LandingBookingTable.css"
import { useContext } from 'react';
import AuthContext from './../Context/AuthProvider'

const LandingBookingTable = () => {
    const {todayroom , settodayroom} = useContext(AuthContext)
    const tableData = [
        { Date: '2023-08-23', Delux: 10, Super: 20, Suite: 15, Premium: 5 }
        // Add more data rows as needed
    ];

    return (
        <>  
            <div>
            <h5>Todays Room Avaiblity</h5>
            </div>
            <table className='Landingbookingtable'>
                
                <thead>
                    <tr className='tablerow'>
                        <th>Delux</th>
                        <th>Super</th>
                        <th>Suite</th>
                        <th>Premium</th>
                    </tr>
                </thead>

                <tbody>
                    {todayroom.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{row.DELUX}</td>
                            <td>{row.SUPERDELUX}</td>
                            <td>{row.SUITE}</td>
                            <td>{row.PREMIUM}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default LandingBookingTable