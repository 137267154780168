import React, { useContext, useState, useRef } from "react";
// import Button from 'react-bootstrap/Button';
// import { ArrowLeft } from 'react-bootstrap-icons';
import BackBtn from "../../Components/BackBtn";
import JoditEditor from "jodit-react";
import AuthContext from "../../Context/AuthProvider";
import Form from "react-bootstrap/Form";
import { FaPlus } from "react-icons/fa";
import { HashLoader } from "react-spinners";
function CmsAdvertisments() {
  const { baseUrl, websiteHotelAdData, setwebsiteHotelAdData, joditConfig } =
    useContext(AuthContext);
  const [heading, setHeading] = useState(websiteHotelAdData.heading);
  const [Image, setImage] = useState(websiteHotelAdData.Image);

  function uploadImage(e) {
    e.preventDefault();
    const imageInput = document.getElementById("file");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${baseUrl}/api/uploadImage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: window.localStorage.getItem("Token"),
        Image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setImage(data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function updateAd() {
    fetch(`${baseUrl}/api/dashboard/editHotelAd`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
        heading: heading,
        image: Image,
        video: "test",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        alert("Updated");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const editor = useRef(null);
  return (
    <>
      {websiteHotelAdData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div
            className="homHead w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 0 20px 0",
            }}
          >
            <BackBtn />
            <h3 className="text-center">Advertisement</h3>

            <Form>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>
          <div className="AdvrDiv">
            <div className="Advrinput">
              <label htmlFor="/">Text:</label>
              {/* <input type="text" placeholder='Enter Here' value={heading} onChange={(e)=>{setHeading(e.target.value)}} /> */}
              <JoditEditor
                ref={editor}
                value={heading}
                onChange={(content) => {
                  setHeading(content);
                }}
                config={joditConfig}
              />
            </div>
            <div className="AdvrImginput">
              <div className="adrlabeldiv">
                <label htmlFor="/">Image:</label>

                <button className="upload">
                  <span className="cmsupldspn">Upload Image</span>
                  <FaPlus className="cmsplusicon" />
                  <input type="file" id="file" onChange={uploadImage} />
                </button>
              </div>
              <div className="adrimgdiv">
                <img src={Image} alt="alt" />
              </div>
            </div>
            {/* <div className="Advrinput AdvrVideoinput">
        <label htmlFor="/">Video:</label>
        <input type="file" />
      </div> */}
            <div className="w-100 d-flex justify-content-center">
              <button className="saveBtn btn" onClick={updateAd}>
                Save
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CmsAdvertisments;
