import React, { useEffect, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import BackBtn from "../../Components/BackBtn";
import CmsTable from "../../Components/CmsTable";
import { HashLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../Context/AuthProvider";
function CmsFacilities() {
  const { EngineUrl, pagetitles, EditPageTitle, setPagetitles, WebsiteData } =
    useContext(AuthContext);
  useEffect(() => {
    WebsiteData();
  }, []);
  async function handleChange(fieldName, e) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      Facilities: { ...prevPagetitles.Facilities, [fieldName]: e.target.value },
    }));
    console.log(e.target.value);
  }
  async function handleChangeimage(fieldName, value) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      Facilities: { ...prevPagetitles.Facilities, [fieldName]: value },
    }));
    console.log(value);
  }
  function uploadImagepagetitle(e) {
    e.preventDefault();
    const imageInput = document.getElementById("aboutfileimage");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3pagetitle(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3pagetitle(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        handleChangeimage("Image", data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function submitPagetitleSave() {
    setEditable(false);
    let obj = {
      key: "Facilities",
      Title: pagetitles.Facilities.Title,
      Description: pagetitles.Facilities.Description,
      Image: pagetitles.Facilities.Image,
      Video: pagetitles.Facilities.Video,
    };
    // console.log(obj);
    const resp = await EditPageTitle(obj);
    if (resp === true) {
      toast.success("Edited Successfully");
    } else {
      toast.error("Updation Failed");
    }
    WebsiteData();
  }
  const [editable, setEditable] = useState(false);
  return (
    <>
      {pagetitles === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <ToastContainer />
          <div
            className="homHead w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 0 20px 0",
            }}
          >
            <BackBtn />

            <h3 className="cmsHeading my-3">Our Facilities</h3>
            <Form>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>
          <div style={{ position: "relative", width: "79vw", height: "50vh" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={pagetitles.Facilities.Image}
              alt="Gallery Image"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              <>
                {editable === false ? (
                  <>
                    <div style={{ fontWeight: "bold", fontSize: "2.5em" }}>
                      {pagetitles.Facilities.Title}
                    </div>
                    <div style={{ fontSize: "1.5em" }}>
                      {pagetitles.Facilities.Description}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="boldInput">
                      <input
                        className="sassyInput boldInput"
                        value={pagetitles.Facilities.Title}
                        onChange={(e) => handleChange("Title", e)}
                        placeholder="Enter Title"
                      />
                    </div>
                    <div>
                      <input
                        className="sassyInput"
                        value={pagetitles.Facilities.Description}
                        onChange={(e) => handleChange("Description", e)}
                        placeholder="Enter Description"
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        id="aboutfileimage"
                        onChange={(e) => {
                          uploadImagepagetitle(e);
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
            <div
              style={{
                position: "absolute",
                top: "90%",
                left: "95%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              {editable === false ? (
                <div>
                  <button
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "4px",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditable(true)}
                  >
                    EDIT
                  </button>
                </div>
              ) : (
                <button
                  style={{
                    backgroundColor: "#28a745",
                    color: "#fff",
                    border: "1px solid #28a745",
                    borderRadius: "4px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => submitPagetitleSave()}
                >
                  SAVE
                </button>
              )}
            </div>
          </div>

          <div>
            <CmsTable />
          </div>
        </>
      )}
    </>
  );
}

export default CmsFacilities;
