import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'react-bootstrap-icons';

function DashBackBtn() {
    return (
        <div style={{ padding: '20px 0', height: 'auto' }}>
            <Link style={{ padding: '10px 15px', background: "#0A3A75", borderRadius: '8px', color: '#fff' }} to='/'><ArrowLeft /></Link>
        </div>
    )
}

export default DashBackBtn
