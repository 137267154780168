import React, { useContext, useEffect } from "react";
import AuthContext from "../../../Context/AuthProvider";
import "../../../Style/UserManagement.css";

// import AuthContext from '../../Context/AuthProvider';

function UserManagement() {
  const { LoginUsers, GetAllUsersLogs } = useContext(AuthContext);

  useEffect(() => {
    GetAllUsersLogs();
  }, []);

  return (
    <div className="usermanagement">
      <div className="d-flex justify-content-end mb-4 "></div>
      <table className="usermanagementtable">
        <thead>
          <tr className="tablerow">
            {["Name", "Phone Number", "Email", "LoggedIn"].map(
              (headerLabel) => (
                <th key={headerLabel}>{headerLabel}</th>
              )
            )}
          </tr>
        </thead>

        <tbody>
          {LoginUsers.map((user) => (
            <tr>
              <td>{user.Name}</td>
              <td>{user.phoneNumber}</td>

              <td>{user.Email}</td>
              <td>
                {user.isAuth === true ? (
                  <span class="badge text-bg-primary">Active</span>
                ) : (
                  <span class="badge text-bg-danger">Not Active</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* {isEditpPop ? <EditUserMagPopup /> : ""} */}
    </div>
  );
}

export default UserManagement;
