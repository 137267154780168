import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import { FaPlus } from "react-icons/fa";
import { HashLoader } from "react-spinners";
import { toast } from "react-toastify";
import AuthContext from "../Context/AuthProvider";
function CmsBodyimgCard() {
  const {
    baseUrl,
    websiteBodyImageData,
    setwebsiteBodyImageData,
    EngineUrl,
    WebsiteData,
  } = useContext(AuthContext);
  const [Image, setImage] = useState();
  const [editImages, seteditImages] = useState(false);
  const [imageIndex, setimageIndex] = useState(0);

  function uploadImage(e) {
    e.preventDefault();
    const imageInput = document.getElementById("fileimgTag");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        // setwebsiteImage(data.Image);
        // handleChnageDataAccounts(data.Image, "Logo");
        setImage(data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function uploadImageCard(e, index) {
    e.preventDefault();
    const imageInput = document.getElementById(`imageFileCard_${index}`);
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }
    console.log("ASD");

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      uploadImageS3Card(base64String, index);
    };
    reader.readAsDataURL(file);
  }
  async function uploadImageS3Card(base64String, index) {
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setwebsiteBodyImageData(
          websiteBodyImageData.map((event, i) =>
            index === i ? { ...event, Image: data.Image } : event
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  async function EditCardHandle(index) {
    fetch(`${EngineUrl}/cms/edit/Images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        index: index,
        image: websiteBodyImageData[index].Image,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status) {
          console.log(data);
          WebsiteData();
          toast.success("Edited Successfully");
        } else {
          toast.error("Some error occured");
        }
      })
      .catch((error) => {
        toast.error("Some error occured");
      });
  }

  async function DeleteServices(index) {
    let obj = {
      token: localStorage.getItem("Token"),
      operation: "delete",
      imageurl: websiteBodyImageData[index].Image,
      index: index,
    };
    fetch(`${EngineUrl}/cms/operation/Images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        WebsiteData();
        toast.success("Deleted Successfully");
      })
      .catch((error) => {
        toast.error("Deleted Successfully");
      });
  }

  const AddBanner = () => {
    toast.success("Image added to website");
    setwebsiteBodyImageData(websiteBodyImageData.concat({ Image: Image }));
    fetch(`${EngineUrl}/cms/operation/Images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        operation: "append",
        imageurl: Image,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // alert("Added")
        setImage("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      {websiteBodyImageData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div className="cmForm">
            <div className="cmsForm_div">
              <div className="cmsForm_div cmsimgdiv">
                <div className="CmsNearImglabel">
                  <label htmlFor="/">Image:</label>
                  <button className="upload">
                    <span className="cmsupldspn">Upload Image</span>
                    <FaPlus className="cmsplusicon" />
                    <input type="file" id="fileimgTag" onChange={uploadImage} />
                  </button>
                </div>
                <div className="upl_img">
                  {/* <input type="file" name="/" id="/" /> */}
                  <img src={Image} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="cmsForm_Btn">
            <Button className="saveBtn addBtn" onClick={AddBanner}>
              Add
            </Button>
          </div>

          <div className="CmsServices_cards my-3">
            {websiteBodyImageData.map((Service, index) => (
              <div className="eventdiv_cardDiv" style={{ gap: "3rem" }}>
                <div class="card" style={{ width: "18rem" }}>
                  <img src={Service.Image} class="card-img-top" alt="..." />
                  <div className="Event_imgDiv" style={{ height: "4rem" }}>
                    <label
                      htmlFor={`imageFileCard_${index}`}
                      className="upload"
                    >
                      {" "}
                      {/* Added htmlFor attribute */}
                      <span>Change Image</span>
                      <input
                        type="file"
                        id={`imageFileCard_${index}`}
                        accept="image/png, image/gif, image/jpeg"
                        style={{ display: "none" }}
                        onChange={(e) => uploadImageCard(e, index)}
                      />
                    </label>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "1rem",
                        gap: "3rem",
                      }}
                    >
                      <a
                        
                        class="btn btn-primary"
                        onClick={() => EditCardHandle(index)}
                      >
                        Save Changes
                      </a>
                      <a
                      
                        class="btn btn-danger"
                        onClick={() => DeleteServices(index)}
                      >
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default CmsBodyimgCard;
