import React, { useContext, useState } from 'react'
import { GrClose } from 'react-icons/gr'
import "../Style/AddUserPopUp.css"


import AuthContext from "../Context/AuthProvider.js"

const AddUserPopUP = ({ setPopup,GetAllUsers }) => {
    

    const { EngineUrl, fetchUserManagementData,EngineNewUrl } = useContext(AuthContext)
    const [Name,setName] = useState("")
    const [Email,setEmail] = useState("")
    const [Password,setPassword] = useState("")
    const [Phone,setPhone] = useState("")

    const AddSportsDashboardUser = async () => {
        if(Name==="" || Email==="" || Password==="" || Phone===""){
            alert("Please fill feilds")
        }
        else{
            try {
                const response1 = await fetch(`${EngineNewUrl}/sports/addnewuser/${localStorage.getItem("Token")}`, {
                  method: "POST",
                  headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                      "username":Name,
                      "email":Email,
                      "password":Password,
                      "phone":Phone
                  }), // Serialize the data as JSON
                });
                const json1 = await response1.json();
          
          
                alert(json1.Message)
                GetAllUsers()
                setPopup(false)
          
              }
          
              catch (error) {
                alert("User Management API error")
              }
        }
      }
   
    return (
        <div className='Addpopup'>


            <div className='userform shadow-lg p-3 mb-5 bg-white rounded'>
                <div className='heading'>
                    <h3>Add User</h3>
                    <GrClose size={25} style={{ color: '#0A3A75' }} onClick={()=>{setPopup(false)}} className='closebooking' />


                </div>
                <div className='row w-100'>
                    <div className='col-md-4'>
                        <input type='text' className='fromdata' value={Name} onChange={(e)=>{setName(e.target.value)}} placeholder='Name' required />

                    </div>
                    <div className='col-md-4'>
                        <input type='email' className='fromdata' value={Email} onChange={(e)=>{setEmail(e.target.value)}} placeholder='Email'  required />

                    </div>
                    <div className='col-md-4'>
                        <input type='email' className='fromdata' value={Password} onChange={(e)=>{setPassword(e.target.value)}} placeholder='Password'  required />

                    </div>
                    <div className='col-md-4'>
                        <input type='phone' className='fromdata' value={Phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder='Phone'  required />

                    </div>
                </div>
                
                <div className='row w-100'>
                    <div className='col-md-12'>
                        <button className='confirmUser' onClick={()=>{AddSportsDashboardUser()}} >Add</button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUserPopUP