import React, { useContext } from 'react'
import "../Style/AddUserPopUp.css"
import { GrClose } from 'react-icons/gr'
import { useState } from 'react'


import AuthContext from "../Context/AuthProvider.js"

const AddUserPopUP = ({ setPopup }) => {
    const [Cms, setCms] = useState(false)
    const [bookingEngine, setbookingEngine] = useState(false)
    const [socialMedia, setsocialMedia] = useState(false)
    const [reservationDesk, setreservationDesk] = useState(false)
    const [frontDesk, setfrontDesk] = useState(false)
    const [channelManager, setchannelManager] = useState(false)
    const [seoManager, setseoManager] = useState(false)
    const [foodManager, setfoodManager] = useState(false)
    const [themes, setthemes] = useState(false)
    const [gatewayManager, setgatewayManager] = useState(false)
    const handleClickModel = () => {
        setPopup(false)
    }

    const handlecmsChange = () => { setCms(!Cms) }
    const bookingEngineChange = () => { setbookingEngine(!bookingEngine) }
    const socialMediaChange = () => { setsocialMedia(!socialMedia) }
    const reservationDeskChange = () => { setreservationDesk(!reservationDesk) }
    const frontDeskChange = () => { setfrontDesk(!frontDesk) }
    const channelManagerChange = () => { setchannelManager(!channelManager) }
    const seoManagerChange = () => { setseoManager(!seoManager) }
    const foodManagerChange = () => { setfoodManager(!foodManager) }
    const themesChange = () => { setthemes(!themes) }
    const gatewayManagerChange = () => { setgatewayManager(!gatewayManager) }

    const [Name, setName] = useState(null)
    const [Email, setEmail] = useState(null)
    const [Password, setPassword] = useState(null)
    const [isAdmin, setisAdmin] = useState("false")

    const { EngineUrl, fetchUserManagementData,EngineNewUrl } = useContext(AuthContext)

    const addUserToManagement = async () => {
        if (Name === null && Email === null && Password === null) {
            alert("Please fill all the fields")
        }
        else {
            try {
                const response1 = await fetch(`${EngineNewUrl}/user/create/${localStorage.getItem('Token')}`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(
                        {
                            "emailId": Email,
                            "displayName": Name,
                            "userName": Name,
                            "access_id": Password,
                            "isAdmin":isAdmin,
                            "accessScope": {
                                "cms": Cms.toString(),
                                "bookingEngine": bookingEngine.toString(),
                                "socialMedia": socialMedia.toString(),
                                "reservationDesk": reservationDesk.toString(),
                                "frontDesk": frontDesk.toString(),
                                "channelManager": channelManager.toString(),
                                "seoManager": seoManager.toString(),
                                "foodManager": foodManager.toString(),
                                "themes": themes.toString(),
                                "gatewayManager": gatewayManager.toString()
                            }
                        }
                    ),
                });
                const json1 = await response1.json();
                setCms(false)
                setPopup(false)
                setbookingEngine(false)
                setsocialMedia(false)
                setreservationDesk(false)
                setfrontDesk(false)
                setchannelManager(false)
                setseoManager(false)
                setfoodManager(false)
                setthemes(false)
                setgatewayManager(false)
                setName(null)
                setEmail(null)
                setPassword(null)

                //   alert(json1.Message)
                fetchUserManagementData()

            }

            catch (error) {
                alert("User Management API error")
            }
        }
    }
    return (
        <div className='Addpopup'>


            <div className='userform shadow-lg p-3 mb-5 bg-white rounded'>
                <div className='heading'>
                    <h3>Add User</h3>
                    <GrClose size={25} style={{ color: '#0A3A75' }} onClick={handleClickModel} className='closebooking' />


                </div>
                <div className='row w-100'>
                    <div className='col-md-6'>
                        <input type='text' className='fromdata' value={Name} placeholder='Name' onChange={(e) => { setName(e.target.value) }} required />

                    </div>
                    <div className='col-md-6'>
                        <input type='email' className='fromdata' value={Email} placeholder='Email' onChange={(e) => { setEmail(e.target.value) }} required />

                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-12'>
                        <input type='phone' className='fromdata' value={Password} onChange={(e) => { setPassword(e.target.value) }} placeholder='Password' required />

                    </div>
                </div>
                <div className='row w-100' >
                    <div className='col-md-6' style={{ lineHeight: "5px" }}>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                value={Cms}
                                onChange={() => handlecmsChange()}
                            />
                            <span style={{ width: "100%" }}>CMS</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => bookingEngineChange()}
                            />
                            <span style={{ width: "100%" }}>Booking Engine</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => socialMediaChange()}
                            />
                            <span style={{ width: "100%" }}>Social Media</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => reservationDeskChange()}
                            />
                            <span style={{ width: "100%" }}>Reservation Desk</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => frontDeskChange()}
                            />
                            <span style={{ width: "100%" }}>Frontdesk</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => channelManagerChange()}
                            />
                            <span style={{ width: "100%" }}>Channel Manager</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => seoManagerChange()}
                            />
                            <span style={{ width: "100%" }}>SEO Manager</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => foodManagerChange()}
                            />
                            <span style={{ width: "100%" }}>Food Manager</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => themesChange()}
                            />
                            <span style={{ width: "100%" }}>Themes Manager</span>
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label className='d-flex align-item-center'>
                            <input
                                type="checkbox"
                                // checked={selectedFacilities.includes(facility)}
                                onChange={() => gatewayManagerChange()}
                            />
                            <span style={{ width: "100%" }}>Gateway Manager</span>
                        </label>
                    </div>

                </div>
                <div className='row w-100'>
                    <div className='col-md-12'>
                        <button className='confirmUser' onClick={() => { addUserToManagement() }}>Add</button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUserPopUP