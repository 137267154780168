import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../Context/AuthProvider'
import { useContext } from 'react'

const ThemeCard = (props) => {
  const { setAlertMessage, setIsAlertOpen } = useContext(AuthContext);
  const { baseUrl, TemplateNumber, setTemplateNumber } = useContext(AuthContext)
  const { templatename, img, templatenumber, link } = props
  const [load, setLoad] = useState(false)

  const BuyTemplateButton = async () => {
    try {
      setLoad(true)
      const response1 = await fetch(`${baseUrl}/api/transferTemplate`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Token: localStorage.getItem('Token'),
          template: templatenumber
        }),
      });
      const json1 = await response1.json();

      if (json1.Status === true) {
        const message = "Template Changed successfully"
        setLoad(false)
        setTemplateNumber(templatenumber)
        setAlertMessage(message)
        setIsAlertOpen(true);

        setTimeout(() => {
          setIsAlertOpen(false);
        }, 1000);

      } else {
        alert('False')
      }
    }

    catch (error) {
      const errormsg = "Network Problem please try again"
      setAlertMessage(errormsg)
    }
  }

  return (

    <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">

      <div className="rounded overflow-hidden">
        <div className="position-relative overflow-hidden">
          <img
            className="img-fluid w-100"
            src={img}
            alt="template"
          />
          <div className="portfolio-overlay">
            <Link
              className="btn btn-square btn-outline-light mx-1"
              to={link} target='_blank'
              data-lightbox="portfolio"
            >
              <i className="fa fa-eye" />
            </Link>
            <a className="btn btn-square btn-outline-light mx-1" onClick={BuyTemplateButton}>
              <i className="fa fa-link" />
            </a>
          </div>
        </div>
      </div>

    </div>

  )
}

export default ThemeCard