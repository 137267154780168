import React, { useState, useContext } from "react";
import AuthContext from "../Context/AuthProvider.js";
import AddSlugPopup from "./AddSlugPopup";

const AddSlugManagerData = () => {
  const {
    baseUrl,
    EngineNewUrl,
    websiteSeoData,
    setwebsiteSeoData,
    setAlertMessage,
    setIsAlertOpen,
    slugEditingData,
    setSlugEditingData,
  } = useContext(AuthContext);
  const initialData = websiteSeoData;
  // const initialData = websiteSlugData;

  const [slugData, setSlugData] = useState(initialData);

  const handleEditClick = async (PageName, slug) => {
    try {
      const response = await fetch(`${EngineNewUrl}/cms/edit/seo/slugs`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: localStorage.getItem("Token"),
          Page: PageName,
          slug: slug,
        }),
      });

      const json = await response.json();

      alert("Updated");
    } catch (error) {
      console.error("Error sending POST request:", error);
    }
  };

  return (
    <div>
      <AddSlugPopup data={slugData} onEditClick={handleEditClick} />

      {/* {editingData && <EditPopup data={editingData} onSave={handleSaveEdit} />} */}
      {/* {slugEditingData && <EditPopup onSave={handleSaveEdit} />} */}
    </div>
  );
};

export default AddSlugManagerData;
