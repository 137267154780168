import React, { useContext, useEffect, useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTripadvisor,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { HashLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackBtn from "../../Components/BackBtn";
import AuthContext from "../../Context/AuthProvider";
import "../../Style/CmsProfile.css";
const CmsProfile = () => {
  const {
    websiteFooterData,
    setwebsiteFooterData,
    WebsiteData,
    baseUrl,
    EngineUrl,
    websiteLinksData,
    setwebsiteLinksData,
    websiteReviewsData,
    setwebsiteReviewsData,
  } = useContext(AuthContext);
  function handleChnageSocialAccount(value, fieldName) {
    setwebsiteLinksData({
      ...websiteLinksData,
      [fieldName]: value,
    });
  }
  function handleChnageDataAccounts(value, fieldName) {
    setwebsiteFooterData({
      ...websiteFooterData,
      [fieldName]: value,
    });
  }
  function handleChnageInbuiltCodes(value, fieldName) {
    setwebsiteReviewsData({
      ...websiteReviewsData,
      [fieldName]: value,
    });
  }

  const UpdateSocialAccounts = () => {
    fetch(`${EngineUrl}/cms/edit/sociallinks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        Facebook: websiteLinksData.Facebook,
        Instagram: websiteLinksData.Instagram,
        Twitter: websiteLinksData.Twitter,
        Youtube: websiteLinksData.Youtube,
        Linkedin: websiteLinksData.Linkedin,
        Tripadvisors: websiteLinksData.Tripadvisors,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Updated");
        UpdateDataAccounts();
        UpdateInbuiltCodes();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const UpdateDataAccounts = () => {
    fetch(`${EngineUrl}/cms/edit/footer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        Address: websiteFooterData.Address,
        Phone: websiteFooterData.Phone,
        WhatsApp: websiteFooterData.WhatsApp,
        NewsLetterText: websiteFooterData.NewsLetterText,
        city: websiteFooterData.City,
        email: websiteFooterData.Email,
        Abouttext: websiteFooterData.AboutText,
        logo: websiteFooterData.Logo,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const message = "Profile has been updated";
        toast.success("Updated");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const UpdateInbuiltCodes = () => {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/cms/post/reviewsection`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: window.localStorage.getItem("Token"),
        Clarity: websiteReviewsData.Clarity,
        TagManager: websiteReviewsData.TagManager,
        Console: websiteReviewsData.Console,
        Pixel: websiteReviewsData.Pixel,
        Analytics: websiteReviewsData.Analytics,
        Pagespeed: websiteReviewsData.Pagespeed,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  function uploadImage(e) {
    e.preventDefault();
    const imageInput = document.getElementById("file");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        // setwebsiteImage(data.Image);
        handleChnageDataAccounts(data.Image, "Logo");
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  useEffect(() => {
    WebsiteData();
  }, []);

  return (
    <>
      {websiteLinksData === "None" ||
      websiteReviewsData === "None" ||
      websiteFooterData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div className="mb-3">
            <BackBtn />
          </div>

          <div className="row CmsProfile w-100">
            <ToastContainer />

            <div className="col-md-5 SocialLink ">
              <div
                className="row p-3 pb-0 px-0"
                style={{ border: "1px solid #ccc", borderRadius: "5px" }}
              >
                <div className="profileimage px-2 pb-2">
                  <div className="image">
                    <img
                      src={websiteFooterData.Logo}
                      alt=""
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                  <div className="adminDiv">
                    <span className="adminpannel">Logo</span>
                    <input type="file" id="file" onChange={uploadImage} />
                    <button
                      className="btn mt-2"
                      style={{
                        backgroundColor: " #0A3A75",
                        color: "#fff",
                        width: "100px",
                      }}
                      onClick={UpdateSocialAccounts}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="row Social_link mt-3"
                style={{ border: "1px solid #ccc", borderRadius: "5px" }}
              >
                <div className="links_div ">
                  <label htmlFor="/">
                    <FaFacebook /> Facebook
                  </label>
                  <input
                    type="text"
                    value={websiteLinksData.Facebook}
                    onChange={(e) => {
                      handleChnageSocialAccount(e.target.value, "Facebook");
                    }}
                    placeholder="https://www.facebook.com/"
                  />
                </div>
                <div className="links_div">
                  <label htmlFor="/">
                    <FaInstagram /> Instagram
                  </label>
                  <input
                    type="text"
                    value={websiteLinksData.Instagram}
                    onChange={(e) => {
                      handleChnageSocialAccount(e.target.value, "Instagram");
                    }}
                    placeholder="https://www.Instagram.com/"
                  />
                </div>
                <div className="links_div">
                  <label htmlFor="/">
                    <FaLinkedin /> Linkedin
                  </label>
                  <input
                    type="text"
                    value={websiteLinksData.Linkedin}
                    onChange={(e) => {
                      handleChnageSocialAccount(e.target.value, "Linkedin");
                    }}
                    placeholder="https://www.Linkedin.com/"
                  />
                </div>
                <div className="links_div">
                  <label htmlFor="/">
                    <FaYoutube /> Youtube
                  </label>
                  <input
                    type="text"
                    value={websiteLinksData.Youtube}
                    onChange={(e) => {
                      handleChnageSocialAccount(e.target.value, "Youtube");
                    }}
                    placeholder="https://www.Youtube.com/"
                  />
                </div>
                <div className="links_div pb-3">
                  <label htmlFor="/">
                    <FaTwitter /> Twitter
                  </label>
                  <input
                    type="text"
                    value={websiteLinksData.Twitter}
                    onChange={(e) => {
                      handleChnageSocialAccount(e.target.value, "Twitter");
                    }}
                    placeholder="https://www.Twitter.com/"
                  />
                </div>
                <div className="links_div pb-3">
                  <label htmlFor="/">
                    <FaTripadvisor />
                    Tripadvisors
                  </label>
                  <input
                    type="text"
                    value={websiteLinksData.Tripadvisors}
                    onChange={(e) => {
                      handleChnageSocialAccount(e.target.value, "Tripadvisors");
                    }}
                    placeholder="https://www.Twitter.com/"
                  />
                </div>
              </div>

              <div
                className="row Social_link mt-3"
                style={{ border: "1px solid #ccc", borderRadius: "5px" }}
              >
                <div className="links_div ">
                  <label htmlFor="/">
                    <FaFacebook />
                    Google Tag Manager
                  </label>
                  <input
                    type="text"
                    value={websiteReviewsData.TagManager}
                    onChange={(e) => {
                      handleChnageInbuiltCodes(e.target.value, "TagManager");
                    }}
                    placeholder="https://www.facebook.com/"
                  />
                </div>
                <div className="links_div">
                  <label htmlFor="/">
                    <FaInstagram />
                    Facebook Pixel
                  </label>
                  <input
                    type="text"
                    value={websiteReviewsData.Pixel}
                    onChange={(e) => {
                      handleChnageInbuiltCodes(e.target.value, "Pixel");
                    }}
                    placeholder="https://www.Instagram.com/"
                  />
                </div>
                <div className="links_div">
                  <label htmlFor="/">
                    <FaLinkedin />
                    Google Console
                  </label>
                  <input
                    type="text"
                    value={websiteReviewsData.Console}
                    onChange={(e) => {
                      handleChnageInbuiltCodes(e.target.value, "Console");
                    }}
                    placeholder="https://www.Linkedin.com/"
                  />
                </div>
                <div className="links_div">
                  <label htmlFor="/">
                    <FaYoutube /> Google Analytics
                  </label>
                  <input
                    type="text"
                    value={websiteReviewsData.Analytics}
                    onChange={(e) => {
                      handleChnageInbuiltCodes(e.target.value, "Analytics");
                    }}
                    placeholder="https://www.Youtube.com/"
                  />
                </div>
                <div className="links_div pb-3">
                  <label htmlFor="/">
                    <FaTwitter /> Page-Insight
                  </label>
                  <input
                    type="text"
                    value={websiteReviewsData.Pagespeed}
                    onChange={(e) => {
                      handleChnageInbuiltCodes(e.target.value, "Pagespeed");
                    }}
                    placeholder="https://www.Twitter.com/"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-7 Address">
              <div
                className="innerAdd p-3"
                style={{ border: "1px solid #ccc", borderRadius: "5px" }}
              >
                <div className="CmsFooterDiv">
                  <label htmlFor="/">Address:</label>
                  <input
                    type="text"
                    value={websiteFooterData.Address}
                    onChange={(e) => {
                      handleChnageDataAccounts(e.target.value, "Address");
                    }}
                    placeholder="Enter Heading Here"
                  />
                </div>
                <div className="CmsFooterDiv">
                  <label htmlFor="/">Phone:</label>
                  <input
                    type="text"
                    value={websiteFooterData.Phone}
                    onChange={(e) => {
                      handleChnageDataAccounts(e.target.value, "Phone");
                    }}
                    placeholder="Enter Phone Number Here"
                  />
                </div>
                <div className="CmsFooterDiv">
                  <label htmlFor="/">Whatsapp-Phone:</label>
                  <input
                    type="text"
                    value={websiteFooterData.WhatsApp}
                    onChange={(e) => {
                      handleChnageDataAccounts(e.target.value, "WhatsApp");
                    }}
                    placeholder="Enter Phone Number Here"
                  />
                </div>
                <div className="CmsFooterDiv">
                  <label htmlFor="/">Email:</label>
                  <input
                    type="text"
                    value={websiteFooterData.Email}
                    onChange={(e) => {
                      handleChnageDataAccounts(e.target.value, "Email");
                    }}
                    placeholder="Enter Email Here"
                  />
                </div>
                <div className="CmsFooterDiv">
                  <label htmlFor="/">City:</label>
                  <input
                    type="text"
                    value={websiteFooterData.City}
                    onChange={(e) => {
                      handleChnageDataAccounts(e.target.value, "City");
                    }}
                    placeholder="Enter City Here"
                  />
                </div>
                <div className="CmsFooterDiv">
                  <label htmlFor="/">About Text:</label>
                  <input
                    type="text"
                    value={websiteFooterData.AboutText}
                    onChange={(e) => {
                      handleChnageDataAccounts(e.target.value, "AboutText");
                    }}
                    placeholder="Enter About Text Here"
                  />
                </div>
                <div className="CmsFooterDiv">
                  <label htmlFor="/">NewsLetter Text:</label>
                  <input
                    type="text"
                    value={websiteFooterData.NewsLetterText}
                    onChange={(e) => {
                      handleChnageDataAccounts(
                        e.target.value,
                        "NewsLetterText"
                      );
                    }}
                    placeholder="Enter About Text Here"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CmsProfile;
