import JoditEditor from "jodit-react";
import React, { useContext, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import { FaPlus } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../Context/AuthProvider";
import { HashLoader } from "react-spinners";
function CmsImgForm() {
  const editor = useRef(null);
  const {
    baseUrl,
    EditWebsiteNearByData,
    imgUrl,
    websiteNearbyData,
    setwebsiteNearbyData,
    joditConfig,
    EngineUrl,
  } = useContext(AuthContext);
  const [Image, setImage] = useState();
  const [Place, setPlace] = useState();
  const [Description, setDescription] = useState();
  const [openpopup, setopenpopup] = useState(false);

  function uploadImage(e) {
    e.preventDefault();
    const imageInput = document.getElementById("file");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3Img(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3Img(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setImage(data.Image);
        console.log(Image);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const DeleteBanner = (image, place, Description, index) => {
    toast.success("Deleted");
    for (let i = 0; i < websiteNearbyData.length; i++) {
      if (
        place === websiteNearbyData[i].Place &&
        image === websiteNearbyData[i].Image
      ) {
        websiteNearbyData.splice(i, 1);
        break;
      } else {
        continue;
      }
    }
    setwebsiteNearbyData([...websiteNearbyData]);
    fetch(`${EngineUrl}/cms/operation/Nearby`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        operation: "delete",
        Image: image,
        Place: place,
        Description: Description,
        index: index,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // alert("Deleted")
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const AddBanner = () => {
    toast.success("Updated Successfully");
    setwebsiteNearbyData(
      websiteNearbyData.concat({ Image: Image, Place: Place })
    );
    fetch(`${EngineUrl}/cms/operation/Nearby`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("Token"),
        operation: "append",
        Image: Image,
        Place: Place,
        Description: Description,
        index: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // alert("Added")
        setImage("");
        setPlace("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function handleChange(index, e, fieldName) {
    setwebsiteNearbyData((prevData) =>
      prevData.map((val, idx) =>
        idx === index ? { ...val, [fieldName]: e.target.value } : val
      )
    );
  }
  function uploadImagepage(e, index) {
    e.preventDefault();
    const imageInput = document.getElementById(`imageFile_${index}`); // Corrected ID
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3(base64String, index);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3(base64String, index) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setwebsiteNearbyData((prevData) =>
          prevData.map((val, idx) =>
            idx === index ? { ...val, Image: data.Image } : val
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  async function EditNearby(index) {
    let obj = {
      Image: websiteNearbyData[index].Image,
      Place: websiteNearbyData[index].Place,
      Description: websiteNearbyData[index].Description,
      index: index,
    };
    const resp = await EditWebsiteNearByData(obj);
    if (resp) {
      toast.success("Edited Successfully");
    } else {
      toast.error("Updation Failed");
    }
  }
  return (
    <>
      {websiteNearbyData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <ToastContainer />
          <div className="cmForm">
            <div className="cmsForm_div">
              <label htmlFor="/">Place:</label>
              <JoditEditor
                ref={editor}
                value={Place}
                onChange={(content) => {
                  setPlace(content);
                }}
                config={joditConfig}
              />
              <label htmlFor="/">Description:</label>
              <JoditEditor
                ref={editor}
                value={Description}
                onChange={(content) => {
                  setDescription(content);
                }}
                config={joditConfig}
              />
            </div>

            <div className="cmsForm_div">
              <label htmlFor="/">Image:</label>
              <div className="d-flex justify-content-between w-100">
                <div className="upldDiv">
                  <button className="upload">
                    <span className="cmsupldspn">Upload Image</span>
                    <FaPlus className="cmsplusicon" />
                    <input
                      className="fileImg"
                      type="file"
                      id="file"
                      onChange={uploadImage}
                    />
                  </button>
                </div>
                <div className="upl_img">
                  {/* <input type="file" name="/" id="/" /> */}
                  <img src={Image} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center w-100 my-3">
            <Button className="saveBtn addBtn" onClick={AddBanner}>
              Add
            </Button>
          </div>

          <div className="imgDiv CmsBanner">
            {/* <img src={comingsoon} alt="" /> */}
            <div className="carousal_div">
              <Carousel>
                {websiteNearbyData.map((banner, index) => {
                  return (
                    <Carousel.Item interval={1000}>
                      {/* <ExampleCarouselImage text="First slide" /> */}

                      <img src={banner.Image} alt="" />
                      <Carousel.Caption>
                        <button
                          className="btn btn-warning"
                          onClick={() => {
                            EditNearby(index);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            DeleteBanner(
                              banner.Image,
                              banner.Place,
                              banner.Description,
                              index
                            );
                          }}
                        >
                          delete
                        </button>
                        <label
                          htmlFor={`imageFile_${index}`}
                          className="upload"
                          style={{ width: "5rem", marginLeft: "27rem" }}
                        >
                          {" "}
                          <span className="cmsupldspn">Upload Image</span>
                          <input
                            type="file"
                            id={`imageFile_${index}`}
                            accept="image/png, image/gif, image/jpeg"
                            style={{ display: "none" }}
                            onChange={(e) => uploadImagepage(e, index)}
                          />
                        </label>
                        <div>
                          <input
                            className="highlightInput"
                            value={banner.Place}
                            onChange={(e) => handleChange(index, e, "Place")}
                            placeholder="Enter Place"
                          />
                        </div>
                        <div>
                          <input
                            className="highlightInput"
                            value={banner.Description}
                            onChange={(e) =>
                              handleChange(index, e, "Description")
                            }
                            placeholder="Enter Description"
                          />
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </div>
          {openpopup ? <div>Test</div> : ""}
        </>
      )}
    </>
  );
}

export default CmsImgForm;
