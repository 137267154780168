import React from 'react'
import { useContext } from 'react'
import AuthContext from '../Context/AuthProvider'
import { GrClose } from "react-icons/gr"
import '../Style/ReservDeskInfoPopup.css'
const InfoPopUpII = () => {

    const { infoData, openInfoPopUpII, setOpenInfoPopUpII } = useContext(AuthContext)
    const handleClosePopup = () => {
        setOpenInfoPopUpII(!openInfoPopUpII)
    }
    return (
        <div className='overlayStyle'>
            <div className='w-100 h-100' >
                <div style={{ position: "fixed", top: "20%", left: "35%", zIndex: 1, width: "850px", }}>
                    <div className='h-100 shadow mb-1 bg-body rounded popUpBG'>
                        <div style={{ display: "flex", position: 'relative', justifyContent: "end", }}>
                            <GrClose className='closeIcon' onClick={handleClosePopup} />
                        </div>

                        <div className="row rounded text-white" style={{ marginTop: '-1.8rem', backgroundColor: '#0A3A75' }} >
                            <div className="col py-2 text-center">
                                Booking ID :{infoData.bookingId}
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col p-1">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>Order ID:</th>
                                            <td>{infoData.payment.RefNo}</td>
                                        </tr>
                                        <tr>
                                            <th>Guest Name:</th>
                                            <td>{infoData.guestInfo.guestName}</td>
                                        </tr>
                                        <tr>
                                            <th>Email:</th>
                                            <td>{infoData.guestInfo.EmailId}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone:</th>
                                            <td>{infoData.guestInfo.Phone}</td>
                                        </tr>
                                        <tr>
                                            <th>Addr:</th>
                                            <td>{infoData.guestInfo.City.label ? infoData.guestInfo.City.label : infoData.guestInfo.City}, {infoData.guestInfo.Country.label ? infoData.guestInfo.Country.label : infoData.guestInfo.Country}</td>
                                        </tr>
                                        <tr>
                                            <th>Adults:</th>
                                            <td>{infoData.Adults ? infoData.Adults.toString() : 0}</td>
                                        </tr>
                                        <tr>
                                            <th>Kids:</th>
                                            <td>{infoData.Kids ? infoData.Kids.toString() : 0}</td>
                                        </tr>


                                        <tr>
                                            <th>Payment Status:</th>
                                            <td>
                                                {infoData.payment.Status === "PENDING" ? (
                                                    <span className='badge bg-danger'>{infoData.payment.Status}</span>
                                                ) : infoData.payment.Status === "ADVANCED" ? <span className='badge bg-warning'>{infoData.payment.Status}</span> :
                                                    infoData.payment.Status === "CANCELLED" ? <span className='badge bg-secondary'>{infoData.payment.Status}</span> : (
                                                        <span>
                                                            <span className='badge bg-success'>{infoData.payment.Status}</span>

                                                        </span>
                                                    )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoPopUpII