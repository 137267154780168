import JoditEditor from "jodit-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackBtn from "../../Components/BackBtn";
import CmsBlogCrad from "../../Components/CmsBlogCrad";
import AuthContext from "../../Context/AuthProvider";
import { HashLoader } from "react-spinners";

function CmsBlogs() {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const {
    EngineUrl,
    WebsiteData,
    EditPageTitle,
    setPagetitles,
    pagetitles,
    baseUrl,
    websiteBlogsData,
    setwebsiteBlogsData,
    joditConfig,
    EngineNewUrl,
  } = useContext(AuthContext);
  const [Image, setImage] = useState();
  const [Text, setText] = useState();
  const [Heading, setHeading] = useState();
  const [Date, setDate] = useState();

  const [blogHeadImage, setblogHeadImage] = useState("");
  const [blogTitle, setblogTitle] = useState("");
  const [blogsubtitle, setblogsubtitle] = useState("");
  const [blogDescription, setblogDescription] = useState("");
  const [blogImages, setblogImages] = useState([]);
  const [blogLikes, setblogLikes] = useState("0");
  const [blogViews, setblogViews] = useState("0");
  const [blogShares, setblogShares] = useState("0");
  const [blogsComments, setblogsComments] = useState([]);
  const [ishide, setishide] = useState("false");
  const [author, setauthor] = useState("");
  const [Label, setLabel] = useState("");
  const [blogLink, setblogLink] = useState("");
  const [blogLinks, setblogLinks] = useState({});
  const [blogIssueDate, setblogIssueDate] = useState("");
  const [isPin, setisPin] = useState("false");

  const [Blogs, setBlogs] = useState([]);

  const [editable, setEditable] = useState(false);
  function uploadImageHandle(e) {
    e.preventDefault();
    const imageInput = document.getElementById("file");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3Handle(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3Handle(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        // setwebsiteImage(data.Image);
        setImage(data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  async function handleChange(fieldName, e) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      Blogs: { ...prevPagetitles.Blogs, [fieldName]: e.target.value },
    }));
    console.log(e.target.value);
  }

  async function submitPagetitleSave() {
    setEditable(false);
    let obj = {
      key: "Blogs",
      Title: pagetitles.Blogs.Title,
      Description: pagetitles.Blogs.Description,
      Image: pagetitles.Blogs.Image,
      Video: pagetitles.Blogs.Video,
    };
    // console.log(obj);
    const resp = await EditPageTitle(obj);
    if (resp === true) {
      toast.success("Edited Successfully");
    } else {
      toast.error("Updation Failed");
    }
    WebsiteData();
  }

  const getAllBlogs = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/blogs/getblog/blogs/dashboard/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      console.log(json);
      if (json.Status) {
        setBlogs(json.Blogs);
      }
    } catch {
      alert("Some Problem update token");
    }
  };

  useEffect(() => {
    WebsiteData();
    getAllBlogs();
  }, []);

  const AddDishes = async () => {
    try {
      const response = await fetch(`${EngineNewUrl}/blogs/createblog`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: localStorage.getItem("Token"),
          blogHeadImage: Image,
          blogTitle: blogTitle,
          blogsubtitle: blogsubtitle,
          blogDescription: blogDescription,
          blogImages: blogImages,
          blogLikes: blogLikes,
          blogViews: blogViews,
          blogShares: blogShares,
          blogsComments: blogsComments,
          ishide: ishide,
          author: author,
          Label: Label,
          blogLink: blogLink,
          blogLinks: blogLinks,
          blogIssueDate: blogIssueDate,
          isPin: isPin,
        }),
      });

      const json = await response.json();
      getAllBlogs();
      setblogHeadImage("");
      setblogTitle("");
      setblogsubtitle("");
      setblogDescription("");
      setblogImages([]);
      setblogLikes("0");
      setblogViews("0");
      setblogShares("0");
      setblogsComments([]);
      setishide("false");
      setauthor("");
      setLabel("");
      setblogLink("");
      setblogLinks({});
      setblogIssueDate("");
      setisPin("false");
    } catch {
      alert("Some Problem update token");
    }
  };

  async function handleChangeimage(fieldName, value) {
    setPagetitles((prevPagetitles) => ({
      ...prevPagetitles,
      Blogs: { ...prevPagetitles.Blogs, [fieldName]: value },
    }));
    console.log(value);
  }
  function uploadImagepagetitle(e) {
    e.preventDefault();
    const imageInput = document.getElementById("aboutfileimage");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3pagetitle(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3pagetitle(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${EngineUrl}/upload/file/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("Token"),
        image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        handleChangeimage("Image", data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  return (
    <>
      {pagetitles === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <BackBtn />
          <ToastContainer />
          <h3 className="text-center">Blogs</h3>
          <br />
          <div style={{ position: "relative", width: "79vw", height: "50vh" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={pagetitles.Blogs.Image}
              alt="Gallery Image"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              <>
                {editable === false ? (
                  <>
                    <div style={{ fontWeight: "bold", fontSize: "2.5em" }}>
                      {pagetitles.Blogs.Title}
                    </div>
                    <div style={{ fontSize: "1.5em" }}>
                      {pagetitles.Blogs.Description}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="boldInput">
                      <input
                        className="sassyInput boldInput"
                        value={pagetitles.Blogs.Title}
                        onChange={(e) => handleChange("Title", e)}
                        placeholder="Enter Title"
                      />
                    </div>
                    <div>
                      <input
                        className="sassyInput"
                        value={pagetitles.Blogs.Description}
                        onChange={(e) => handleChange("Description", e)}
                        placeholder="Enter Description"
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        id="aboutfileimage"
                        onChange={(e) => {
                          uploadImagepagetitle(e);
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
            <div
              style={{
                position: "absolute",
                top: "90%",
                left: "95%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
              }}
            >
              {editable === false ? (
                <div>
                  <button
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "4px",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditable(true)}
                  >
                    EDIT
                  </button>
                </div>
              ) : (
                <button
                  style={{
                    backgroundColor: "#28a745",
                    color: "#fff",
                    border: "1px solid #28a745",
                    borderRadius: "4px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={() => submitPagetitleSave()}
                >
                  SAVE
                </button>
              )}
            </div>
          </div>
          <div className="CmsBlogs">
            <div className="CmsBlogsForm">
              <div className="BlogImgDiv">
                <div className="CmsblogImgInput">
                  <label htmlFor="/">Image:</label>
                  <button className="upload">
                    <span className="cmsupldspn">Upload Image</span>
                    <FaPlus className="cmsplusicon" />
                    <input type="file" id="file" onChange={uploadImageHandle} />
                  </button>
                </div>
                <div className="ImgDivs">
                  <img
                    src={Image}
                    alt=""
                    style={{ height: "200px", width: "800px" }}
                  />
                </div>
              </div>
            </div>
            <div className="Cmsblog_joditEditoore my-3">
              <label htmlFor="/">Title:</label>
              <input
                value={blogTitle}
                onChange={(e) => {
                  setblogTitle(e.target.value);
                }}
              />

              <label htmlFor="/">Sub-Title:</label>
              <input
                value={blogsubtitle}
                onChange={(e) => {
                  setblogsubtitle(e.target.value);
                }}
              />
            </div>

            <div className="Cmsblog_joditEditoore my-3">
              <label htmlFor="/">Blog Description:</label>
              <JoditEditor
                ref={editor}
                value={blogDescription}
                onChange={(newContent) => {
                  setblogDescription(newContent);
                }}
                config={joditConfig}
              />
            </div>

            <div className="Cmsblog_joditEditoore my-3">
              <label htmlFor="/">Author:</label>
              <input
                value={author}
                onChange={(e) => {
                  setauthor(e.target.value);
                }}
              />
            </div>

            <div className="d-flex justify-content-center w-100">
              <button className="saveBtn btn" onClick={AddDishes}>
                Save
              </button>
            </div>
            <h3 className="my-3" style={{ fontWeight: "500" }}>
              Blogs Preview
            </h3>
            <div className="CmsBlogs_cards my-3">
              {Blogs.map((blog) => {
                return (
                  <CmsBlogCrad
                    Image={blog.blogHeadImage}
                    Text={blog.Text}
                    Heading={blog.blogTitle}
                    Date={blog.blogIssueDate}
                    likes={blog.blogLikes}
                    comment={blog.blogViews}
                    share={blog.blogShares}
                    blogid={blog.blogId}
                    getAllBlogs={getAllBlogs}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CmsBlogs;
