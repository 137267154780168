import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../Context/AuthProvider';

function Landingadmins() {
    const {EngineUrl,AllQueries, setAllQueries,
      AdminsCount,setAdminsCount,FetchAllQueries} = useContext(AuthContext)

    const [addPopup,setaddPopup] = useState(false)

    useEffect(()=>{
        FetchAllQueries()
    },[])

    const UpdateQuery = async(id)=>{
        alert(id)
    }
  return (
    <div className='usermanagement'>
      
      <table className='usermanagementtable'>
        <thead>
          <tr className='tablerow'>
            {['Hotel Name','Owner','Email', 'Number',"Property/Location","Contacted","Follow-up","Next-Follow-up","Remark","Action"].map((headerLabel) => (
              <th style={{whiteSpace:"nowrap",width:"auto"}} key={headerLabel}>{headerLabel}</th>
            ))}

          </tr>
        </thead>

        <tbody>
          {AllQueries.map((query) => (
            <tr>
              <td>{query.Business_Name}</td>
              <td>{query.fullName}</td>
              <td>{query.Email}</td>
              <td>{query.countryCode}-{query.Number}</td>
              <td>{query.numberOfProperties}/{query.location}</td>
              <td>{query.flag?"Yes":"No"}</td>
              <td><input type='date' /></td>
              <td><input type='date' /></td>
              <td><input type='text' style={{width:"300px"}} /></td>
              <td><button onClick={()=>{UpdateQuery(query.clientQueryId)}}>Update</button></td>
              {/* <td></td> */}
              
            </tr>
          )).reverse()}
        </tbody>
      </table>
    {/* {addPopup?<AddadminPopup setaddPopup={setaddPopup} FetchAllAdmins={FetchAllAdmins} />:""} */}
    </div>
  )
}

export default Landingadmins