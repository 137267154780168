import React, { useContext } from 'react'
import AuthContext from '../../Context/AuthProvider'

function Filters() {
    const {fetchDinabiteMessages} = useContext(AuthContext)
    return (
        <>
            <div className="f-main d-flex" style={{ justifyContent: 'space-between', padding: '15px 0px' }}>
                <div className="s-act">
                    <span className='head'>Active Tools</span>&nbsp;
                    <span className='head at'>(click to modify)</span>
                </div>

                <div className="ryt-fltr">
                    <a className='ancr' onClick={()=>{fetchDinabiteMessages()}}>Refresh </a>
                    <a href="#" className='ancr'>Yesterday </a>
                    <a href="#" className='ancr'>Last week</a>
                    <a href="#" className='ancr'>Last Month</a>
                </div>
            </div>
        </>
    )
}

export default Filters
