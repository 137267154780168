import React, { useContext, useEffect, useState } from "react";
// import Button from 'react-bootstrap/Button';
import Card from "react-bootstrap/Card";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DinabiteReviewPopup from "../../Components/DinabiteReviewPopup";
import AuthContext from "../../Context/AuthProvider";
import "../../Style/SocialMedia.css";
// import xyz from '../../Images/SocialMediaImgs/agoda.png'

function SocialReviews() {
  const navigate = useNavigate();

  const [messagepopup, setmessagepopup] = useState(false);
  const [objectpopup, setobjectpopup] = useState({});
  const {
    RedirectDinabitetool,
    DinabiteToken,
    DinabiteReviews,
    fetchDinabiteReviews,CompanyId,FacebookConnectedPage
  } = useContext(AuthContext);
  useEffect(() => {
    if (DinabiteToken === "None") {
      navigate("/dashboard/client/socialmedia/home");
    } else {
      fetchDinabiteReviews(DinabiteToken,CompanyId);
    }
  }, []);
  const TogglePopup = (obj) => {
    setobjectpopup(obj);
    setmessagepopup(true);
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  return (
    <div style={{ width: "100%" }}>
      {/* <Topnav /> */}
      <div>
        {DinabiteReviews.map((reviews) => {
          return (
            <Card className="post-rev my-3">
              <div className="rev-icon rev-div" style={{ width: "20%" }}>
                {reviews.type === "facebook" ? (
                  <FaFacebook style={{ color: "#3b5998", fontSize: "4rem" }} />
                ) : (
                  <FaGoogle style={{ color: "#3b5998", fontSize: "4rem" }} />
                )}
              </div>
              <div className="rev-div social_profile" style={{ width: "20%" }}>
                <div className="pr-inner"></div>
                <span>Name</span>
              </div>
              <div className=" rev-div rating" style={{ width: "20%" }}>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="31"
                    viewBox="0 0 32 31"
                    fill="none"
                  >
                    <path
                      d="M17.1901 1.43266L21.0515 9.22838C21.1394 9.42624 21.2775 9.59763 21.4522 9.72547C21.6269 9.8533 21.8321 9.93311 22.0472 9.95695L30.5715 11.2198C30.8183 11.2515 31.051 11.3528 31.2424 11.5119C31.4338 11.6709 31.576 11.8811 31.6524 12.1179C31.7288 12.3548 31.7363 12.6084 31.6739 12.8493C31.6115 13.0902 31.4819 13.3084 31.3001 13.4784L25.1558 19.5741C24.999 19.7206 24.8813 19.904 24.8134 20.1076C24.7456 20.3112 24.7297 20.5285 24.7672 20.7398L26.2487 29.3127C26.2916 29.559 26.2645 29.8124 26.1704 30.0441C26.0764 30.2758 25.9192 30.4764 25.7168 30.6232C25.5143 30.77 25.2748 30.857 25.0253 30.8743C24.7759 30.8916 24.5266 30.8386 24.3058 30.7212L16.6315 26.6655C16.435 26.569 16.219 26.5189 16.0001 26.5189C15.7812 26.5189 15.5652 26.569 15.3687 26.6655L7.69438 30.7212C7.4736 30.8386 7.2243 30.8916 6.97486 30.8743C6.72542 30.857 6.48586 30.77 6.28342 30.6232C6.08098 30.4764 5.92381 30.2758 5.82978 30.0441C5.73575 29.8124 5.70863 29.559 5.75153 29.3127L7.23296 20.6427C7.2705 20.4314 7.25462 20.214 7.18676 20.0104C7.11889 19.8068 7.0012 19.6235 6.84438 19.4769L0.627241 13.4784C0.443307 13.3038 0.313955 13.0795 0.254853 12.8329C0.195752 12.5863 0.209438 12.3278 0.294252 12.0887C0.379067 11.8497 0.531371 11.6404 0.732715 11.4862C0.93406 11.332 1.17581 11.2394 1.42867 11.2198L9.95296 9.95695C10.1681 9.93311 10.3733 9.8533 10.548 9.72547C10.7227 9.59763 10.8608 9.42624 10.9487 9.22838L14.8101 1.43266C14.9153 1.20561 15.0832 1.01338 15.294 0.878664C15.5049 0.743947 15.7499 0.672363 16.0001 0.672363C16.2503 0.672363 16.4953 0.743947 16.7062 0.878664C16.917 1.01338 17.0849 1.20561 17.1901 1.43266Z"
                      fill="url(#paint0_linear_1169_1109)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1169_1109"
                        x1="15.9673"
                        y1="0.672363"
                        x2="15.9673"
                        y2="30.8775"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#F2761C" />
                        <stop offset="1" stop-color="#FFC700" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="31"
                    viewBox="0 0 32 31"
                    fill="none"
                  >
                    <path
                      d="M17.1901 1.43266L21.0515 9.22838C21.1394 9.42624 21.2775 9.59763 21.4522 9.72547C21.6269 9.8533 21.8321 9.93311 22.0472 9.95695L30.5715 11.2198C30.8183 11.2515 31.051 11.3528 31.2424 11.5119C31.4338 11.6709 31.576 11.8811 31.6524 12.1179C31.7288 12.3548 31.7363 12.6084 31.6739 12.8493C31.6115 13.0902 31.4819 13.3084 31.3001 13.4784L25.1558 19.5741C24.999 19.7206 24.8813 19.904 24.8134 20.1076C24.7456 20.3112 24.7297 20.5285 24.7672 20.7398L26.2487 29.3127C26.2916 29.559 26.2645 29.8124 26.1704 30.0441C26.0764 30.2758 25.9192 30.4764 25.7168 30.6232C25.5143 30.77 25.2748 30.857 25.0253 30.8743C24.7759 30.8916 24.5266 30.8386 24.3058 30.7212L16.6315 26.6655C16.435 26.569 16.219 26.5189 16.0001 26.5189C15.7812 26.5189 15.5652 26.569 15.3687 26.6655L7.69438 30.7212C7.4736 30.8386 7.2243 30.8916 6.97486 30.8743C6.72542 30.857 6.48586 30.77 6.28342 30.6232C6.08098 30.4764 5.92381 30.2758 5.82978 30.0441C5.73575 29.8124 5.70863 29.559 5.75153 29.3127L7.23296 20.6427C7.2705 20.4314 7.25462 20.214 7.18676 20.0104C7.11889 19.8068 7.0012 19.6235 6.84438 19.4769L0.627241 13.4784C0.443307 13.3038 0.313955 13.0795 0.254853 12.8329C0.195752 12.5863 0.209438 12.3278 0.294252 12.0887C0.379067 11.8497 0.531371 11.6404 0.732715 11.4862C0.93406 11.332 1.17581 11.2394 1.42867 11.2198L9.95296 9.95695C10.1681 9.93311 10.3733 9.8533 10.548 9.72547C10.7227 9.59763 10.8608 9.42624 10.9487 9.22838L14.8101 1.43266C14.9153 1.20561 15.0832 1.01338 15.294 0.878664C15.5049 0.743947 15.7499 0.672363 16.0001 0.672363C16.2503 0.672363 16.4953 0.743947 16.7062 0.878664C16.917 1.01338 17.0849 1.20561 17.1901 1.43266Z"
                      fill="url(#paint0_linear_1169_1109)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1169_1109"
                        x1="15.9673"
                        y1="0.672363"
                        x2="15.9673"
                        y2="30.8775"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#F2761C" />
                        <stop offset="1" stop-color="#FFC700" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="31"
                    viewBox="0 0 32 31"
                    fill="none"
                  >
                    <path
                      d="M17.1901 1.43266L21.0515 9.22838C21.1394 9.42624 21.2775 9.59763 21.4522 9.72547C21.6269 9.8533 21.8321 9.93311 22.0472 9.95695L30.5715 11.2198C30.8183 11.2515 31.051 11.3528 31.2424 11.5119C31.4338 11.6709 31.576 11.8811 31.6524 12.1179C31.7288 12.3548 31.7363 12.6084 31.6739 12.8493C31.6115 13.0902 31.4819 13.3084 31.3001 13.4784L25.1558 19.5741C24.999 19.7206 24.8813 19.904 24.8134 20.1076C24.7456 20.3112 24.7297 20.5285 24.7672 20.7398L26.2487 29.3127C26.2916 29.559 26.2645 29.8124 26.1704 30.0441C26.0764 30.2758 25.9192 30.4764 25.7168 30.6232C25.5143 30.77 25.2748 30.857 25.0253 30.8743C24.7759 30.8916 24.5266 30.8386 24.3058 30.7212L16.6315 26.6655C16.435 26.569 16.219 26.5189 16.0001 26.5189C15.7812 26.5189 15.5652 26.569 15.3687 26.6655L7.69438 30.7212C7.4736 30.8386 7.2243 30.8916 6.97486 30.8743C6.72542 30.857 6.48586 30.77 6.28342 30.6232C6.08098 30.4764 5.92381 30.2758 5.82978 30.0441C5.73575 29.8124 5.70863 29.559 5.75153 29.3127L7.23296 20.6427C7.2705 20.4314 7.25462 20.214 7.18676 20.0104C7.11889 19.8068 7.0012 19.6235 6.84438 19.4769L0.627241 13.4784C0.443307 13.3038 0.313955 13.0795 0.254853 12.8329C0.195752 12.5863 0.209438 12.3278 0.294252 12.0887C0.379067 11.8497 0.531371 11.6404 0.732715 11.4862C0.93406 11.332 1.17581 11.2394 1.42867 11.2198L9.95296 9.95695C10.1681 9.93311 10.3733 9.8533 10.548 9.72547C10.7227 9.59763 10.8608 9.42624 10.9487 9.22838L14.8101 1.43266C14.9153 1.20561 15.0832 1.01338 15.294 0.878664C15.5049 0.743947 15.7499 0.672363 16.0001 0.672363C16.2503 0.672363 16.4953 0.743947 16.7062 0.878664C16.917 1.01338 17.0849 1.20561 17.1901 1.43266Z"
                      fill="url(#paint0_linear_1169_1109)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1169_1109"
                        x1="15.9673"
                        y1="0.672363"
                        x2="15.9673"
                        y2="30.8775"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#F2761C" />
                        <stop offset="1" stop-color="#FFC700" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="32"
                    viewBox="0 0 16 32"
                    fill="none"
                  >
                    <path
                      d="M15.8926 0C15.6405 0.00147377 15.3942 0.0755586 15.1832 0.213386C14.9722 0.351213 14.8053 0.546942 14.7026 0.777143L10.8412 8.93714C10.7547 9.13815 10.6174 9.31322 10.4428 9.4452C10.2683 9.57718 10.0624 9.66154 9.84546 9.69L1.24832 10.9286C0.987054 10.9706 0.742914 11.0854 0.543867 11.2598C0.344821 11.4341 0.19892 11.661 0.122881 11.9145C0.0468421 12.168 0.04374 12.4377 0.11393 12.6929C0.184121 12.948 0.324764 13.1782 0.519748 13.3571L6.73689 19.7443C6.89258 19.9019 7.00891 20.094 7.07642 20.305C7.14394 20.5159 7.16074 20.7399 7.12546 20.9586L5.57118 29.9443C5.52701 30.1921 5.5525 30.4474 5.64483 30.6816C5.73715 30.9158 5.89267 31.1198 6.09408 31.2709C6.29549 31.4219 6.53488 31.5141 6.78558 31.5372C7.03628 31.5602 7.28846 31.5132 7.51403 31.4014L15.2855 27.1757C15.48 27.0711 15.6961 27.013 15.9169 27.0057L15.8926 0Z"
                      fill="url(#paint0_linear_1169_1107)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1169_1107"
                        x1="7.99018"
                        y1="0"
                        x2="7.99018"
                        y2="31.5429"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#F2761C" />
                        <stop offset="1" stop-color="#FFC700" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="rt-span">
                  <span>{formatDate(reviews.createTime)}</span>
                </div>
              </div>

              <Card.Body className="rev-div sr-body" style={{ width: "39%" }}>
                <Card.Title>Review</Card.Title>
                <Card.Text>{reviews.comment}</Card.Text>
                {reviews.reviewReply ? (
                  <>
                    <Card.Title>Reply</Card.Title>
                    <div
                      style={{
                        display: "flex",
                        gap: "2rem",
                        alignItems: "center",
                      }}
                    >
                      <Card.Text>{reviews.reviewReply.comment}</Card.Text>
                      <button
                        type="button"
                        class="btn btn-success"
                        onClick={() => {
                          TogglePopup({
                            comment1: reviews.comment,
                            comment2: reviews.reviewReply.comment,
                          });
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </>
                ) : reviews.type === "facebook"?
                <button
                    type="button"
                    class="btn btn-success"
                    onClick={()=>{window.open(`https://www.facebook.com/${FacebookConnectedPage}/reviews`,'_blank')}}
                  >
                    Reply
                </button>
                
                :(
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={() => {
                      TogglePopup({
                        comment1: reviews.comment,
                        comment2: "",
                      });
                    }}
                  >
                    Reply
                  </button>
                )}
              </Card.Body>
            </Card>
          );
        })}
      </div>
      {messagepopup ? (
        <DinabiteReviewPopup
          setmessagepopup={setmessagepopup}
          object={objectpopup}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default SocialReviews;
