import React, { useContext,useEffect} from 'react'
import '../../Style/SocialMedia.css'
import PostFilters from './PostFilters';
import PostCards from './PostCards';
import AuthContext from '../../Context/AuthProvider';

function SocialScheduled() {
  const {RedirectDinabitetool,DinabiteToken,dinabiteScheduled} = useContext(AuthContext)

  return (
    <div style={{ width: '100%', paddingBottom:'50px' }}>
      {/* <Topnav /> */}
      <div className="p-main">
        <div className="p-inner">
          <div className="toppost">
            <h3>Schedule Post</h3>
            <PostFilters />
          </div>
          <div className="post-crds">
              {dinabiteScheduled.map((post)=>{
                return <PostCards data={post} Btn='Boost' />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SocialScheduled
