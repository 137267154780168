import React from 'react'
import BackBtn from '../../Components/BackBtn';


function CmsRooms() {
  return (
    <>
        <BackBtn/>
      <h2 className="text-center">Booking Engine</h2><br />

      

    </>
  )
}

export default CmsRooms
