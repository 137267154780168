import React, { useContext } from 'react'
import { GrClose } from "react-icons/gr"
import AuthContext from '../Context/AuthProvider'
import '../Style/ReservDeskInfoPopup.css'

const InfoPopup = ({Infodata,setopenInfoPopUp}) => {

    const { InfoData, setInfoData, fetchLoneStarBookings, EngineUrl, todaysAvaiblity,EngineNewUrl,RoomsData } = useContext(AuthContext)
    const { openInfoPopUp, setOpenInfoPopUp } = useContext(AuthContext)

    const handleClosePopup = () => {
        setopenInfoPopUp(false)
    }
    function formatTime(timeString) {
        const [hours, minutes] = timeString.split(':');
        let formattedHours = parseInt(hours, 10);
        const ampm = formattedHours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        formattedHours = formattedHours % 12 || 12;

        return `${formattedHours}:${minutes} ${ampm}`;
    }
    

    const PaidBookingFun = async (bookingid,orderid) => {
        const response = await fetch(`${EngineNewUrl}/sports/makeBookingSuccess/${localStorage.getItem("Token")}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "sportBookingId":bookingid,
                "orderId":orderid
            }),
        });
        await response.json()
        setOpenInfoPopUp(!openInfoPopUp)
        fetchLoneStarBookings()
    }
    function convertDateFormat(inputDate) {
        const parts = inputDate.split('-');
        if (parts.length === 3) {
          const [year, month, day] = parts;
          // Create a new Date object with the given parts
          const dateObject = new Date(`${year}-${month}-${day}`);
          // Format the date as 'dd-mm-yyyy'
          const formattedDate = `${String(dateObject.getDate()).padStart(2, '0')}-${String(dateObject.getMonth() + 1).padStart(2, '0')}-${dateObject.getFullYear()}`;
          return formattedDate;
        } else {
          // Handle invalid input
          console.error('Invalid date format');
          return inputDate;
        }
      }
    return (
        <div className='overlayStyle'>
            <div className='w-100 h-100' >
                <div style={{ position: "fixed", top: "20%", left: "35%", zIndex: 1, width: "850px", }}>
                    <div className='h-100 shadow mb-1 bg-body rounded popUpBG'>
                        <div style={{ display: "flex", position: 'relative', justifyContent: "end", }}>
                            <GrClose className='closeIcon' onClick={handleClosePopup} />
                        </div>

                        <div className="row rounded text-white" style={{ marginTop: '-1.8rem', backgroundColor: '#0A3A75' }} >
                            <div className="col py-2 text-center">
                                Booking ID :{Infodata.sportBookingId}
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col p-1">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>Payment Id:</th>
                                            <td>{Infodata.payment.RefNo}</td>
                                        </tr>
                                        <tr>
                                            <th>Guest Name:</th>
                                            <td>{Infodata.guestInfo.Name}</td>
                                        </tr>
                                        <tr>
                                            <th>Email:</th>
                                            <td>{Infodata.guestInfo.Email}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone:</th>
                                            <td>{Infodata.guestInfo.Phone}</td>
                                        </tr>
                                        

                                        <tr>
                                            <th>Bookings:</th>
                                            <td>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr >
                                                            <td style={{fontSize:"12px"}}>{formatTime(Infodata.bookedSlots[0].slotstart)} - {formatTime(Infodata.bookedSlots[Infodata.bookedSlots.length-1].slotEnd)}</td>
                                                            <td style={{fontSize:"12px"}}>{Infodata.bookedSlots[0].turfName}</td>
                                                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Payment Status:</th>
                                            <td>
                                                {Infodata.payment.Status === "PENDING" ? (
                                                    <span className='badge bg-danger'>{Infodata.payment.Status}</span>
                                                ) : Infodata.payment.Status === "ADVANCED" ? <span className='badge bg-warning'>{Infodata.payment.Status}</span> :
                                                    Infodata.payment.Status === "CANCELLED" ? <span className='badge bg-secondary'>{Infodata.payment.Status}</span> : (
                                                        <span>
                                                            <span className='badge bg-success'>{Infodata.payment.Status}</span>

                                                        </span>
                                                    )}
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col p-1">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>Check In:</th>
                                            <td>{convertDateFormat(Infodata.bookingDate)}</td>
                                        </tr>
                                       
                                        <tr>
                                            <th>Checked In:</th>
                                            <td>{Infodata.isCheckedIn === true ? <span className='badge bg-success'>Checked In</span> : <span className='badge bg-danger'>Pending</span>}</td>
                                        </tr>


                                        <tr>
                                            <th>Checked Out:</th>
                                            <td>{Infodata.isCheckedOut === true ? <span className='badge bg-success'>Checked Out</span> : <span className='badge bg-danger'>Pending</span>}</td>
                                        </tr>
                                        <tr>
                                            <th>Price:</th>
                                            <td>
                                                <table className="table table-bordered" style={{left:"0"}}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Amount:</td>
                                                            <td>{Infodata.price.amount.toString()}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tax:</td>
                                                            <td>{Infodata.price.tax.toString()}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total:</td>
                                                            <td>{Infodata.price.totalAmount.toString()}</td>
                                                        </tr>
                                                        {Infodata.payment.Status!== "PENDING"?<tr>
                                                            <td>Paid:</td>
                                                            <td>{Infodata.price.amountPaid.toString()}</td>
                                                        </tr>:<tr>
                                                            <td>Payment:</td>
                                                            <td><strong>PENDING</strong></td>
                                                        </tr>}
                                                    </tbody>
                                                </table>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                                {/* <img src='https://d6xcmfyh68wv8.cloudfront.net/assets/qr-code/scanner/qr.png' /> */}
                            </div>
                        </div>
                        <div>
                            <button className='btn btn-warning text-light'>Cancel Booking</button>
                            {Infodata.payment.Status === "PENDING" || Infodata.payment.Status === "ADVANCED" ? <button style={{float:"right"}} className='btn btn-success text-light' onClick={() => { PaidBookingFun(Infodata.sportBookingId,Infodata.payment.RefNo) }}>Full Amount Paid</button>:""}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default InfoPopup