import React, { useContext, useEffect } from "react";
// import ComingSoon from '../../Components/ComingSoon'
import ReservationDeskBanner from "../../Components/ReservationDeskBanner";
import ReservationDeskTable from "../../Components/ReservationDeskTable";
import ReservationDeskFilter from "../../Components/ReservationDeskFilter";
import AuthContext from "../../Context/AuthProvider";

function Reservationdesk() {
  const {
    fetchCheckInData,
    fetchCheckOutData,
    fetchBookingData,
    sendFilterBookingPaymentStatusRequest,
  } = useContext(AuthContext);
  useEffect(() => {
    fetchCheckInData();
    fetchCheckOutData();
    fetchBookingData();
  }, []);

  return (
    <>
      <ReservationDeskBanner />
      <ReservationDeskFilter />
      <ReservationDeskTable />
    </>
  );
}

export default Reservationdesk;
