import React from 'react'
import FrontDeskTable from './Frontdesktble.jsx'

function FrontDesk() {


  return (
    <FrontDeskTable />
  )
}

export default FrontDesk
