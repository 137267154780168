import React, { useState } from "react";
import BackBtn from "../../Components/BackBtn";
import CmsTeamsCard from "../../Components/CmsTeamsCard";
import comingsoon from "../../Images/comingsoon.png";
import { Button } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import AuthContext from "../../Context/AuthProvider.js";
import { useContext } from "react";
import Form from "react-bootstrap/Form";
import { FaPlus } from "react-icons/fa";
import { HashLoader } from "react-spinners";
function CmsTeams() {
  const { baseUrl, websiteTeamsData, setWebsiteTeamsData } =
    useContext(AuthContext);
  const [Name, setName] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Text, setText] = useState("");
  const [Image, setImage] = useState("");

  function uploadImage(e) {
    e.preventDefault();
    const imageInput = document.getElementById("file");
    const file = imageInput.files[0];
    if (!file) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      const base64String = reader.result.split(",")[1];

      UploadingImageS3(base64String);
    };
    reader.readAsDataURL(file);
  }

  function UploadingImageS3(base64String) {
    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    fetch(`${baseUrl}/api/uploadImage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: window.localStorage.getItem("Token"),
        Image: base64String,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from backend:", data);
        setImage(data.Image);
        // Handle the backend response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const AddTeamMember = () => {
    fetch(`${baseUrl}/api/dashboard/editTeam`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("Token"),
        operation: "append",
        Name: Name,
        Designation: Designation,
        Text: Text,
        url: Image,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        alert("Added");
        setWebsiteTeamsData(
          websiteTeamsData.concat({
            Name: Name,
            Designation: Designation,
            Text: Text,
            url: Image,
          })
        );
        setName("");
        setDesignation("");
        setText("");
        setImage("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {websiteTeamsData === "None" ? (
        <div
          style={{
            display: "flex",
            width: "80vw",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <HashLoader color="#E65502" />
        </div>
      ) : (
        <>
          <div
            className="homHead w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 0 20px 0",
            }}
          >
            <BackBtn />
            <h3 className="cmsHeading my-3">Our Team</h3>
            <Form>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
              />
            </Form>
          </div>

          <div className="CmsTeamForm">
            <div className="d-flex justify-content-between">
              <h5>Team Member Detail</h5>
            </div>

            <div className="TeamMemberDetail">
              <div className="CmsTeamInput">
                <label htmlFor="/">Name:</label>
                <input
                  type="text"
                  placeholder="Enter Here"
                  value={Name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="CmsTeamInput">
                <label htmlFor="/">Designation:</label>
                <input
                  type="text"
                  placeholder="Enter Here"
                  value={Designation}
                  onChange={(e) => {
                    setDesignation(e.target.value);
                  }}
                />
              </div>
              <div className="CmsTeamInput">
                <label htmlFor="/">Description:</label>
                <input
                  type="text"
                  placeholder="Enter Here"
                  value={Text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="TeamSocialDetail">
              <div className="CmsTeamInputdiv">
                <div className="teamlabeldiv">
                  <label htmlFor="/">Image:</label>
                  <button className="upload">
                    <span className="cmsupldspn">Upload Image</span>
                    <FaPlus className="cmsplusicon" />
                    <input type="file" id="file" onChange={uploadImage} />
                  </button>
                </div>
                <div className="teamimgdiv">
                  <img src={Image} alt="altimage" />
                </div>
              </div>
              {/* <label htmlFor="/">Facebook</label> */}
            </div>
          </div>

          <div className="d-flex justify-content-center w-100 my-3">
            <Button
              style={{ background: "#0A3A75", color: "#fff" }}
              onClick={AddTeamMember}
            >
              Add
            </Button>
          </div>

          <div className="teamCards">
            {websiteTeamsData.map((member) => {
              return (
                <CmsTeamsCard
                  Name={member.Name}
                  Designation={member.Designation}
                  Text={member.Text}
                  MemberImg={member.url}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default CmsTeams;
