import React, { useContext, useState } from "react";
import AuthContext from "../../../Context/AuthProvider";
import DateRange from "./DateRange";

function Landinf() {
  const {
    Sports,
    nextDate,
    prevDate,
    fetchAllSlotsPriceMgmtforDifferentDates,
    Apiresponse,
    setapiresponse,
    EngineNewUrl,
  } = useContext(AuthContext);

  const getDateOnly = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const dayOfWeek = date.toLocaleString("default", { weekday: "short" });
    const formattedDate = `${dayOfWeek} ${day} ${month}`;

    return formattedDate;
  };
  function CurrentDate() {
    const currentDate = new Date();

    // Get year, month, and day
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
  const [calenderdate, setcalenderdate] = useState(CurrentDate());

  const HitPriceChangeByDate = (date) => {
    setcalenderdate(date);
    fetchAllSlotsPriceMgmtforDifferentDates(date);
  };

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    let formattedHours = parseInt(hours, 10);
    const ampm = formattedHours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    formattedHours = formattedHours % 12 || 12;

    return `${formattedHours}:${minutes} ${ampm}`;
}
  const [datagstate, setDatagState] = useState({});
  let datag = {};
  const BulkUpdatePriceChange = (sportid, date, slot, price, turf) => {
    const updatedResponse = Apiresponse.map((apiSport) => {
      if (apiSport.sportId === sportid) {
        const updatedSlots = apiSport.slotData.map((slotData) => {
          if (slotData.slotName === slot) {
            const updatedTurfs = slotData.Turfs.map((turfData) => {
              if (turfData.name === turf) {
                return { ...turfData, price: price };
              }
              return turfData;
            });
            return { ...slotData, Turfs: updatedTurfs };
          }
          return slotData;
        });
        return { ...apiSport, slotData: updatedSlots };
      }
      return apiSport;
    });

    setapiresponse(updatedResponse);
    if (!datag.hasOwnProperty(sportid)) {
      datag[sportid] = {};
    }
    if (!datag[sportid].hasOwnProperty(date)) {
      datag[sportid][date] = {};
    }
    if (!datag[sportid][date].hasOwnProperty(slot)) {
      datag[sportid][date][slot] = {};
    }
    datag[sportid][date][slot][turf] = price;
    setDatagState(datag);
  };

  const HitBulkUpdateAPI = async () => {
    console.log(datagstate);
    try {
      const response = await fetch(
        `${EngineNewUrl}/sports/bulkUpdatePrice/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datagstate),
        }
      );

      const json = await response.json();
      console.log(json);
      if (json.Status) {
        HitPriceChangeByDate(calenderdate);
      } else {
      }
      setDatagState({});
    } catch {}
  };

  return (
    <>
    <DateRange />
      <div className="bookingRoomPrice">
        <div className="d-flex justify-content-between">
          <div
            className="mb-2 mt-2 form-group"
            style={{ width: "33%", justifyContent: "flex-end" }}
          >
            <label>Go to : </label>
            <input
              className="form-control"
              type="date"
              value={calenderdate}
              onChange={(e) => {
                HitPriceChangeByDate(e.target.value);
              }}
            />
          </div>

          <div className="button_prev_next" style={{ width: "33%" }}>
            <i
              className="fas fa-chevron-circle-left m-2"
              style={{ fontSize: "34px" }}
              onClick={() => {
                HitPriceChangeByDate(prevDate);
              }}
            ></i>
            <i
              className="fas fa-sync m-2"
              style={{ fontSize: "34px" }}
              onClick={() => {
                HitPriceChangeByDate(CurrentDate());
              }}
            ></i>
            <i
              className="fas fa-chevron-circle-right m-2"
              style={{ fontSize: "34px" }}
              onClick={() => {
                HitPriceChangeByDate(nextDate);
              }}
            ></i>
          </div>
          <div
            className="d-flex"
            style={{ width: "33%", justifyContent: "flex-end" }}
          >
            <button
              className="price_bulk_update_btn"
              onClick={() => {
                HitBulkUpdateAPI();
              }}
            >
              Bulk Update
            </button>
          </div>
        </div>

        <div className="price_table">
          {Apiresponse.map((sport) => {
            return (
              <div style={{
                padding: "18px",
                overflowX: "auto",
                overflowY: "auto",
                whiteSpace: "nowrap",
              }}>
                <table className="pricetable mt-4">
                  <thead>
                    <tr>
                      <th className="fixed-head">
                        {sport.sportName}-Slots/Turfs
                      </th>

                      {sport.slotData
                        .sort((a, b) => a.slotStart.localeCompare(b.slotStart))
                        .map((slots) => (
                          <th>
                            {formatTime(slots.slotStart)}-{formatTime(slots.slotEnd)}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {sport.slotData.map((slot, outerIndex) => (
                      <React.Fragment key={outerIndex}>
                        {outerIndex === 0 &&
                          slot.Turfs.map((turf, innerIndex) => (
                            <tr key={innerIndex}>
                              <td className="fixed-room-type">{turf.name}</td>
                              {sport.slotData.map((slt, index) => (
                                <td key={index}>
                                  <input
                                    onChange={(e) => {
                                      BulkUpdatePriceChange(
                                        sport.sportId,
                                        calenderdate,
                                        slt.slotName,
                                        e.target.value,
                                        turf.name
                                      );
                                    }}
                                    style={{
                                      border: "none",
                                      textAlign: "center",
                                    }}
                                    value={slt.Turfs[innerIndex].price}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
      </div>
      
    </>
  );
}

export default Landinf;
