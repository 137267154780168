import React, { useContext, useEffect } from "react";
import "../../Style/Landing.css";
import LandingCard from "../../Components/LandingCard";
import LandingBookingTable from "../../Components/LandingBookingTable";
import LandingActiviesTable from "../../Components/LandingActiviesTable";
import AuthContext from "../../Context/AuthProvider";

function Landing() {
  const {
    tCheckIn,
    tCheckOut,
    todaysAvaiblity,
    fetchCheckInData,
    fetchCheckOutData,
    fetchinHouseGuestAndRoomBooked,
    inhouseGuest,
    roomBookedAndTotal,
  } = useContext(AuthContext);
  useEffect(() => {
    todaysAvaiblity();
    fetchCheckInData();
    fetchCheckOutData();
    fetchinHouseGuestAndRoomBooked();
    console.log(inhouseGuest);
  }, []);
  return (
    <div className="landing">
      <div className="landingCardDiv">
        <LandingCard
          count={tCheckIn ? tCheckIn.Checkin : 0}
          heading="Today's Checkin"
          // para="Someone bookmarked your listing"
        />
        <LandingCard
          count={tCheckOut ? tCheckOut.Checkout : 0}
          heading="Todays Checkout"
          // para="Someone bookmarked your listing"
        />
        <LandingCard
          count={inhouseGuest ? inhouseGuest.personstaying : 0}
          heading="Inhouse Guest"
          // para="Someone bookmarked your listing"
        />
        <LandingCard
          count={
            inhouseGuest
              ? inhouseGuest.bookedroom + "/" + inhouseGuest.totalRoom
              : 0
          }
          heading="Occupied Rooms"
          // para="Someone bookmarked your listing"
        />
      </div>
      <div className="landingCanvasDiv">{/* <LandingCanvas /> */}</div>
      <div className="landingBookingDiv">
        <LandingBookingTable />
      </div>
      <div className="landingActivitiesDiv">
        <LandingActiviesTable />
      </div>
    </div>
  );
}

export default Landing;
