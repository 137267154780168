import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import "../Style/ReservationDeskFilter.css"
import AuthContext from '../Context/AuthProvider'

const InventoryUpdate = (props) => {
    const { EngineUrl,EngineNewUrl, handleGetInventory } = useContext(AuthContext)


    const { mheading, setAllBookings } = props;

    const [selectedValue, setSelectedValue] = useState('div1');
    const [checkinDate, setCheckinDate] = useState('')
    const [checkoutDate, setCheckoutDate] = useState('')
    const [Type, setType] = useState('1')
    const [Inventory, setInventory] = useState(0)

    

    const { priceData } = useContext(AuthContext); // Use destructuring to get priceData directly
    // console.log(priceData)

    const roomTypes = Object.keys(priceData);


    const UpdateDateRangePrice = async () => {

        try {
            const response = await fetch(`${EngineNewUrl}/inventory/update/daterange/inventory`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: localStorage.getItem('Token'),
                    "roomType": Type,
                    "date1": checkinDate,
                    "date2": checkoutDate,
                    "room": Inventory,
                    "hId":localStorage.getItem("hotelLocationId")
                }),
            });

            const json = await response.json();

            if (json.Status === true) {
                alert("updated")
                handleGetInventory()
                Referesh()

            } else {
                console.error("Error filtering dates:", json.Error);
            }
        } catch (error) {
            console.error("Error sending POST request:", error);
        }
    }

    

    const Referesh = () => {
        setCheckinDate('')
        setCheckoutDate('')
        setType('1')
        setInventory(0)
    }

    const handleOptionChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <div className='reservationdesktable'>
            <h3>Update Inventory</h3>
            <div className="b-filters">
                {/* <h5>Filters</h5> */}
                {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                            inline
                            label="Date Range"
                            name="group1"
                            type={type}
                            value="div1"
                            checked={selectedValue === 'div1'}
                            onChange={handleOptionChange}
                            id={`inline-${type}-2`}
                        />
                       
                        
                    </div>
                ))}
            </div>
            <div className="filter">
                <div id="div1" style={{ display: selectedValue === 'div1' ? 'block' : 'none' }}>

                    <div className="date-fltr">
                        <div className="dt-flt-inr">
                            <div className='book-chk'>
                                <label>Room Type</label>
                                <Form.Select className='form1 dates' value={Type} onChange={(e) => { setType(e.target.value) }} id="type" aria-label="Default select example">
                                    {roomTypes.map((roomType, index) => (
                                        <option value={roomType}>{roomType === "1" ? "Deluxe" : roomType === "2" ? "Super Delux" : roomType === "3" ? "Suite" : roomType == "4" ? "Premium" : ""}</option>
                                    ))}
                                </Form.Select>
                            </div>
                            <div className="book-chk">
                                <label htmlFor="/">Update Date From:</label>
                                <input className='dates' type="date" name="" id="checkin" value={checkinDate} onChange={(e) => { setCheckinDate(e.target.value) }} /></div>
                            <div className="book-chk">
                                <label htmlFor="/">Update Date To:</label>
                                <input className='dates' type="date" name="" id="checkout" value={checkoutDate} onChange={(e) => { setCheckoutDate(e.target.value) }} /></div>
                            <div className='book-chk'>
                                <label>Inventory</label>
                                <input className='dates' type="tel" placeholder='Price' value={Inventory} onChange={(e) => { setInventory(e.target.value) }} />
                            </div>
                        </div>

                    </div>
                    <Button className="btn" onClick={() => { UpdateDateRangePrice() }}>Update Price</Button>
                </div>
            </div>
        </div>

    )
}

export default InventoryUpdate
