import React, { useContext, useState } from 'react'

import { GrClose } from 'react-icons/gr'
import "../Style/AddUserPopUp.css"


import AuthContext from "../Context/AuthProvider.js"

const AddUserPopUP = ({ setPopup,data,setdata,editsportid }) => {
    

    const { EngineUrl, fetchUserManagementData,EngineNewUrl,setSports } = useContext(AuthContext)
    const [startDate,setstartDate] = useState("")
    const [endDate,setendDate] = useState("")
    function countofslot(detail){
        const slotsArray = Object.keys(detail);

        // Get the count of slots
        const slotCount = slotsArray.length;

        // Log the count of slots
        console.log("Number of slots:", slotCount);

        // Alternatively, you can return the count if you want to use it elsewhere
        return slotCount;
    }
    const GetAllSports = async()=>{
        try {
          const url = `${EngineUrl}/sports/getSportsDashboard/${localStorage.getItem("Token")}`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
    
          const json = await response.json();
          console.log(json)
          if (json.Status === true) {
            setSports(json.data);
          }
        } catch (err) {
          console.error("Error fetching", err);
        }
      }
    const DeleteSlot = (slot) => {
        // Confirm with the user
        const confirmDelete = window.confirm("Are you sure you want to delete this slot?");
      
        // If user confirms deletion
        if (confirmDelete) {
          // Create a copy of the current slots object
          const currentSlots = { ...data.slots };
      
          // Remove the specified slot from the copy
          delete currentSlots[slot];
      
          // Update the state without the removed slot
          setdata(prevState => ({
            ...prevState,
            slots: currentSlots
          }));
        } else {
          // If user cancels deletion, do nothing
          // You may choose to add some feedback or action here if needed
          console.log("Deletion cancelled by user");
        }
      }
      

    const Addslot=()=>{
        if(startDate==="" || endDate===""){
            alert("Please select values")
        }
        else{
            let count = countofslot(data.slots)
            let snumber = `slot${count+1}`
            
            const currentSlots = { ...data.slots };
            currentSlots[snumber]={
                "start":startDate,
                "end":endDate
            }

            setdata(prevState => ({
                ...prevState,
                slots: currentSlots
            }));
            setstartDate("")
            setendDate("")
        }
        
    }

    const handleInputChange = (event) => {
        // Update the sportName in state with the new value from the input field
        setdata(prevState => ({
          ...prevState,
          sportName: event.target.value
        }));
      };

    const UpdateSport = async()=>{
        
        try {
            const resp = await fetch(
            `${EngineNewUrl}/sports/updateSportsDashboard/${localStorage.getItem("Token")}/${editsportid}`,
            {
                method: "PUT",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(data), // Serialize the data as JSON
            }
            );
            const json = await resp.json();
            console.log(json)
            // Handle response as needed
            GetAllSports()
            setPopup(false)
        } catch (err) {
            console.error("Error adding WATI credentials:", err);
            // Handle error as needed
        }
       
    }

    
    
    return (
        <div className='Addpopup'>


            <div className='userform shadow-lg p-3 mb-5 bg-white rounded'>
                <div className='heading'>
                    <h3>Edit Sport</h3>
                    <GrClose size={25} style={{ color: '#0A3A75' }} onClick={()=>{setPopup(false)}} className='closebooking' />


                </div>

                    <div class="card mb-3">
                        <img src={data["images"][0]} class="card-img-top" alt="..." />
                        
                    </div>
                    <div className='row w-100'>
                    
                    <div className='col-md-12'>
                        
                        <input type='text' className='fromdata' value={data.sportName} onChange={(e)=>{handleInputChange(e)}} placeholder='Sport Name' required />
                    </div>
                    <div className='col-md-12' style={{ display: "flex",width:"100%" }}>
                        <h6>Slots</h6>
                        <div style={{display:"1px solid green"}} className='row'>
                        {Object.entries(data.slots).map(([key, value]) => (
                            <div className='col-md-3'>
                                <span className="badge text-bg-primary m-1 w-100" onClick={()=>{DeleteSlot(key)}}>{value.start}-{value.end}</span>
                            </div>    
                        ))}
                        <div className='row'>
                            <div className='col-md-4'>
                                <input type='time'  value={startDate} onChange={(e)=>{setstartDate(e.target.value)}} />
                            </div>
                            <div className='col-md-4'>
                                <input type='time' value={endDate} onChange={(e)=>{setendDate(e.target.value)}} />
                            </div>
                            <div className='col-md-4'>
                                <button onClick={()=>{Addslot()}}>Add slot</button>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                    <div className='col-md-12' style={{ display: "flex",width:"100%" }}>
                        <h6>Turf</h6>
                        <div style={{display:"1px solid green"}} className='row'>
                        {data.turfs.map((turf) => (
                            <div className='col-md-4'>
                                <span className="badge text-bg-primary m-1 w-100" >{turf.name}</span>
                            </div>    
                        ))}
                        
                        
                        </div>
                        
                    </div>
                </div>
                
                <div className='row w-100'>
                    <div className='col-md-12'>
                        <button className='confirmUser' onClick={()=>{UpdateSport()}} >Update</button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUserPopUP