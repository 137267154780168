import React from 'react'
import Card from 'react-bootstrap/Card';
import AuthContext from "../Context/AuthProvider.js"
import { useContext } from 'react';

function CmsTeamsCard(props) {
    const {baseUrl,websiteTeamsData,setWebsiteTeamsData}=useContext(AuthContext);
    const { Name, Designation, MemberImg,Text } = props;
    const DeleteTeamMember=()=>{
            for(let i=0;i<websiteTeamsData.length;i++){
                if(Name===websiteTeamsData[i].Name){
                    websiteTeamsData.splice(i,1);
                    break;
                }else{
                    continue;
                }
            };
            setWebsiteTeamsData([...websiteTeamsData]);
            fetch(`${baseUrl}/api/dashboard/editTeam`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                Token:localStorage.getItem("Token"),
                operation:"delete",
                Name:Name,
                Designation:Designation,
                Text:Text,
                url:MemberImg
            }),
            })
              .then(response => response.json())
              .then(data => {
                alert("Deleted")
              })
              .catch(error => {
                console.error('Error:', error);
              });
    }

    return (
        <Card style={{ width: '17rem', height:'22rem' }}>
            <Card.Img variant="top" src={MemberImg} />
            <Card.Body>
                <Card.Title>{Name}</Card.Title>
                <Card.Text>
                    {Designation}
                </Card.Text>
                <button className='btn btn-danger' onClick={DeleteTeamMember}>Delete</button>
                {/* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
        </Card>
    )
}

export default CmsTeamsCard
