import React, { useEffect } from "react";
import { useContext } from "react";
import AuthContext from "../Context/AuthProvider";
import "../Style/Alert.css"

const Alert = () => {
    const { alertMessage, isAlertOpen, setIsAlertOpen } = useContext(AuthContext);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsAlertOpen(false);
        }, 1000);

        return () => clearTimeout(timeout);
    }, []);

    const togglePopup = () => {
        setIsAlertOpen(!isAlertOpen);
    };
    return (

        <>
            {isAlertOpen ?
                <div className="Toast">
                    <span className="close-icon" onClick={togglePopup}>
                        &times;
                    </span>
                    <div>
                        {alertMessage}
                    </div>


                </div>

                : null
            }
        </>
    )


};

export default Alert;
