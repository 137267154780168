import { createContext, useState } from "react";

const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
  // const local = "http://127.0.0.1:5000";
  const local = "https://nexon.eazotel.com";

  // const baseUrl = "http://127.0.0.1:8000";
  const baseUrl = "https://api.eazotel.com";
  const imgUrl = "https://nexon.eazotel.com";
  const EngineUrl = local;

  const EngineNewUrl = local;

  const razorpayURL = local;

  const dinabiteURL = "https://www.dinabitedev.com";
  const dinabite_secret_token = "d3fb4e3c-dc5d-42a1-8d82-ea6fc6c33091";

  // const navigate = useNavigate();
  const joditConfig = {
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "ul",
      "ol",
      "font",
      "fontsize",
      "lineHeight",
      "superscript",
      "subscript",
      "spellcheck",
      "table",
      "link",
      "indent",
      "outdent",
      "left",
      "image",
    ],
  };

  const [Auth, setAuth] = useState(false);
  const [popup, setPopup] = useState(false);
  const [isEazotelAdmin, setisEazotelAdmin] = useState(false);
  const [isEazotelSports, setisEazotelSports] = useState(false);
  const [TemplateNumber, setTemplateNumber] = useState("1");
  const [termsAndCondition, setTermsAndCondition] = useState("None");
  // Landing of CMS
  const [Home, setHome] = useState(true);
  const [About, setAbout] = useState(true);
  const [Contact, setContact] = useState(true);
  const [HotelAds, setHotelAds] = useState(true);
  const [Terms, setTerms] = useState(true);
  const [Service, setService] = useState(true);
  const [Restaurant, setRestaurant] = useState(true);
  const [Gallery, setGallery] = useState(true);
  const [Testimonial, setTestimonial] = useState(true);
  const [Teams, setTeams] = useState(true);
  const [Blogs, setBlogs] = useState(true);
  const [Menu, setMenu] = useState(true);
  const [Facility, setFacility] = useState(true);
  const [Nearby, setNearby] = useState(true);

  // CMS DATA
  const [websiteAbout, setWebsiteAbout] = useState("None");
  const [pagetitles, setPagetitles] = useState("None");
  const [websiteTeamsData, setWebsiteTeamsData] = useState("None");
  const [websiteFacilitiesData, setwebsiteFacilitiesData] = useState("None");
  const [websiteHotelAdData, setwebsiteHotelAdData] = useState("None");
  const [websiteFAQs, setwebsiteFAQs] = useState("None");
  const [websiteServicesData, setwebsiteServicesData] = useState("None");
  const [websiteRestaurantData, setwebsiteRestaurantData] = useState("None");
  const [websiteBlogsData, setwebsiteBlogsData] = useState([]);
  const [websiteSeoData, setwebsiteSeoData] = useState([]);
  const [websiteLinksData, setwebsiteLinksData] = useState("None");
  const [websiteFooterData, setwebsiteFooterData] = useState("None");
  const [websiteBodyImageData, setwebsiteBodyImageData] = useState("None");
  const [websiteBannerData, setwebsiteBannerData] = useState("None");
  const [websiteNavbarData, setwebsiteNavbarData] = useState("None");
  const [websiteNearbyData, setwebsiteNearbyData] = useState("None");
  const [websiteGalleryData, setwebsiteGalleryData] = useState("None");
  const [websiteLocationData, setwebsiteLocationData] = useState("None");
  const [websiteSlugsData, setwebsiteSlugsData] = useState({});
  const [websiteReviewsData, setwebsiteReviewsData] = useState("None");
  const [events, setEvents] = useState("None");
  // user profile data

  const [userProfile, setUserProfile] = useState();
  const [userLinks, setUserLinks] = useState();
  const [userPlan, setUserPlan] = useState();
  const [userAccess, setUserAccess] = useState();
  const [isAdmin, setIsadmin] = useState(false);

  // alert popup
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [addSlugPopup, setAddSlugPopup] = useState(false);
  const [pcmData, setPcmData] = useState("None");
  //User Management
  const [userData, setuserData] = useState([]);
  const [isEditpPop, setIsEditPop] = useState(false);

  //Booking Engine
  //data
  const [AboutusEngine, setAboutusEngine] = useState("");
  const [CancellationPolicyEngine, setCancellationPolicyEngine] = useState("");
  const [PrivacyPolicyEngine, setPrivacyPolicyEngine] = useState("");
  const [TermsConditionsEngine, setTermsConditionsEngine] = useState("");

  //buttons
  const [ConfirmButton, setConfirmButton] = useState("");
  const [PayButton, setPayButton] = useState("");
  const [ReserveBoard, setReserveBoard] = useState("");
  const [ReserveButton, setReserveButton] = useState("");

  //Colors
  const [BackgroundColor, setBackgroundColor] = useState("");
  const [BoardColor, setBoardColor] = useState("");
  const [ButtonColor, setButtonColor] = useState("");

  //bgImage
  const [BackgroundImage, setBackgroundImage] = useState("");
  //CMS Info Popup

  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);
  const [openInfoPopUpII, setOpenInfoPopUpII] = useState(false);
  const [infoData, setInfoData] = useState("");
  const [cookies, setCookies] = useState(true);
  const [roomDetail, setRoomDetail] = useState("");

  const [bookingData, setBookingData] = useState(null);
  const [tCheckIn, setTCheckIn] = useState(null);
  const [inhouseGuest, setinhouseGuest] = useState(null);
  const [roomBookedAndTotal, setroomBookedAndTotal] = useState(null);
  const [tCheckOut, setTCheckOut] = useState(null);

  const [todayroom, settodayroom] = useState([]);

  const [editingData, setEditingData] = useState(null);
  const [RoomsData, setRoomsData] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  //rooms
  const [priceData, setPriceData] = useState({});
  const [haveroom, sethaveroom] = useState(true);
  const [next, setNext] = useState();
  const [prev, setPrev] = useState();

  //inventory
  const [inventoryData, setinventoryData] = useState({});
  const [haveinventory, sethaveinventory] = useState(true);
  const [nextinventory, setNextinventory] = useState();
  const [previnventory, setPrevinventory] = useState();

  const [slugEditingData, setSlugEditingData] = useState(null);
  const [userEditdata, setUserEditData] = useState(null);

  const [Cms, setCms] = useState(false);
  const [bookingEngine, setbookingEngine] = useState(false);
  const [socialMedia, setsocialMedia] = useState(false);
  const [reservationDesk, setreservationDesk] = useState(false);
  const [frontDesk, setfrontDesk] = useState(false);
  const [channelManager, setchannelManager] = useState(false);
  const [seoManager, setseoManager] = useState(false);
  const [foodManager, setfoodManager] = useState(false);
  const [themes, setthemes] = useState(false);
  const [gatewayManager, setgatewayManager] = useState(false);
  const [gateway, setgateway] = useState({});
  //Leads
  const [Queries, setQueries] = useState("None");
  const [Contacts, setContacts] = useState("None");
  const [Newsletter, setNewsletter] = useState("None");
  const [DigitalCheckin, setDigitalCheckin] = useState("None");

  //Meal Packages
  const [Mealpackages, setMealpackages] = useState("None");
  //Ads Packages
  const [Adspackages, setAdspackages] = useState("None");
  const [watiCred, setWatiCred] = useState("None");
  //Spreadsheet exists
  const [sheetaccessToken, setsheetaccessToken] = useState("None");
  const [sheetid, setsheetid] = useState("None");
  const [watiContactList, setWatiContactList] = useState([]);
  const [Spreadsheet, setSpreadsheet] = useState([]);
  const [tokenExpire, settokenExpire] = useState(false);
  const [watiSelectedContact, setWatiSelectedContact] = useState("None");
  const [templateMessagesWati, setTemplateMessagesWati] = useState([]);
  const [watiSelectedContactMessage, setWatiSelectedContactMessage] = useState(
    []
  );

  const [AllClients, setAllClients] = useState([]);
  const [ClientsCount, setClientsCount] = useState(0);
  const [AllAdmins, setAllAdmins] = useState([]);
  const [AdminsCount, setAdminsCount] = useState(0);
  const [AllUsers, setAllUsers] = useState([]);
  const [UsersCount, setUsersCount] = useState(0);
  const [AllQueries, setAllQueries] = useState([]);
  const [QueriesCount, setQueriesCount] = useState(0);

  //Lonestar Variables
  const [Sports, setSports] = useState([]);
  const [Users, setUsers] = useState([]);
  const [SportsUserMgmt, setSportsUserMgmt] = useState([]);
  const BlockSlots = async (sportName, date, start, end, value) => {
    try {
      const token = localStorage.getItem("Token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      const url = `${EngineUrl}/sports/updateSlotsForDate/${localStorage.getItem(
        "Token"
      )}/${sportName}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          date: date,
          start: start,
          end: end,
          value: value,
        }),
      });

      const json = await response.json();
      console.log(json);
    } catch (err) {
      console.error("Error fetching WATI credentials: ", err);
    }
  };
  const FetchAllAdmins = async () => {
    try {
      // Ensure EngineNewUrl is defined somewhere in your code.
      const token = localStorage.getItem("Token"); // Make sure the token exists in localStorage.
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      const url = `${EngineUrl}/admin/${localStorage.getItem("Token")}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      if (json.Status === true) {
        setAllAdmins(json.Admins);
        setAdminsCount(json.Admins.length);
      }
    } catch (err) {
      console.error("Error fetching WATI credentials: ", err);
    }
  };

  const FetchAllEazotelClients = async () => {
    try {
      const url = `${EngineNewUrl}/admin/clients/${localStorage.getItem(
        "Token"
      )}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      if (json.Status === true) {
        setAllClients(json.Clients);
        setClientsCount(json.Clients.length);
      }
    } catch (err) {
      console.error("Error fetching", err);
    }
  };
  const OperationServices = async (obj) => {
    try {
      obj["token"] = localStorage.getItem("Token");
      console.log(obj);
      const url = `${EngineNewUrl}/cms/operation/Services`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const resp = await response.json();
      console.log(resp);
      if (resp.Status === true) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };

  const FetchAllUsers = async () => {
    try {
      const url = `${EngineUrl}/admin/clients/getallusers/${localStorage.getItem(
        "Token"
      )}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      if (json.Status === true) {
        setAllUsers(json.Users);
        setUsersCount(json.Users.length);
      }
    } catch (err) {
      console.error("Error fetching", err);
    }
  };

  const FetchAllQueries = async () => {
    try {
      const url = `${EngineUrl}/admin/getAllEazotelClientQuery/${localStorage.getItem(
        "Token"
      )}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      if (json.Status === true) {
        setAllQueries(json.data);
        setQueriesCount(json.data.length);
      }
    } catch (err) {
      console.error("Error fetching", err);
    }
  };

  const [sheetNamess, setsheetNamess] = useState("");
  const FetchWatiCred = async () => {
    try {
      // Ensure EngineNewUrl is defined somewhere in your code.
      const token = localStorage.getItem("Token"); // Make sure the token exists in localStorage.
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      const url = `${EngineNewUrl}/wati/getWatiCred/${token}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      if (json.Status === true) {
        if (json.watiCred.tenantId !== "None") {
          setWatiCred(json.watiCred);
          FetchContactListWati();
          GetMessagesWati();
          FetchTemplateMessagesWati();
        }
      }
    } catch (err) {
      console.error("Error fetching WATI credentials: ", err);
    }
  };
  const AddWatiCred = async ({ tenantId, watiAccessToken }) => {
    try {
      const resp = await fetch(
        `${EngineNewUrl}/wati/addWatiCred/${localStorage.getItem("Token")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tenantId, watiAccessToken }), // Serialize the data as JSON
        }
      );
      const json = await resp.json();
      if (json.Status === true) {
        FetchWatiCred();
        FetchContactListWati();
      }
      // Handle response as needed
    } catch (err) {
      console.error("Error adding WATI credentials:", err);
      // Handle error as needed
    }
  };
  const AddWatiContact = async (data) => {
    try {
      const resp = await fetch(
        `${EngineNewUrl}/wati/addContact/${localStorage.getItem("Token")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data), // Serialize the data as JSON
        }
      );
      const json = await resp.json();
      if (json.Status === true) {
        FetchWatiCred();
        FetchContactListWati();

        return true;
      }
      return false;
      // Handle response as needed
    } catch (err) {
      console.error("Error adding WATI credentials:", err);
      // Handle error as needed
    }
  };
  const EditPageTitle = async (obj) => {
    obj["token"] = localStorage.getItem("Token");
    try {
      const resp = await fetch(`${EngineNewUrl}/cms/edit/pagetitles`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj), // Make sure to stringify the body object
      });
      const json = await resp.json();
      console.log(json);
      if (json.Status === true) {
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  const EditTermAndCondition = async (obj) => {
    obj["token"] = localStorage.getItem("Token");
    try {
      const resp = await fetch(`${EngineNewUrl}/cms/edit/termsandconditions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj), // Make sure to stringify the body object
      });
      const json = await resp.json();
      if (json.Status === true) {
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };
  const FetchTemplateMessagesWati = async () => {
    try {
      // Ensure EngineNewUrl is defined somewhere in your code.
      const token = localStorage.getItem("Token"); // Make sure the token exists in localStorage.
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      const url = `${EngineNewUrl}/wati/gettemplatemessages/${token}`;
      console.log(url);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      console.log(json);
      if (json.Status === true) {
        setTemplateMessagesWati(json.data);
      }
    } catch (err) {
      console.error("Error fetching WATI credentials: ", err);
    }
  };

  const SendWatiSessionMessage = async (messageText, phoneNumber) => {
    try {
      // Ensure EngineNewUrl is defined somewhere in your code.
      const token = localStorage.getItem("Token"); // Make sure the token exists in localStorage.
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const url = `${EngineNewUrl}/wati/sendSessionMessage/${phoneNumber}/${token}`;

      const formData = new FormData();
      formData.append("messageText", messageText);

      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      const json = await response.json();
      if (json.Status === true) {
        GetMessagesWati(phoneNumber);
      }
    } catch (err) {
      console.error("Error fetching WATI credentials: ", err);
    }
  };
  const GetMessagesWati = async (phoneNumber) => {
    try {
      // Ensure EngineNewUrl is defined somewhere in your code.
      const token = localStorage.getItem("Token"); // Make sure the token exists in localStorage.
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      const url = `${EngineNewUrl}/wati/getMessagees/${phoneNumber}/${token}`;
      console.log(url);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      if (json.Status === true) {
        if (json.data.result === "success") {
          console.log(json.data.messages.items);
          setWatiSelectedContactMessage(json.data.messages.items);
          console.log(watiSelectedContactMessage);
        }
      }
    } catch (err) {
      console.error("Error fetching WATI credentials: ", err);
    }
  };
  const sendTemplateMessageWati = async (data) => {
    try {
      // Ensure EngineNewUrl is defined somewhere in your code.
      const token = localStorage.getItem("Token"); // Make sure the token exists in localStorage.
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      console.log(data.phoneNumber);

      console.log(data.body);
      const url = `${EngineNewUrl}/wati/sendtemplatemessages/${token}/${data.phoneNumber}`;
      console.log(url);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data.body),
      });

      const json = await response.json();
      console.log(json);
      if (json.Status === true) {
        FetchWatiCred();
        GetMessagesWati();
        FetchContactListWati();
        return true;
      }
      return false;
    } catch (err) {
      console.error("Error fetching WATI credentials: ", err);
      return true;
    }
  };
  const FetchContactListWati = async () => {
    try {
      // Ensure EngineNewUrl is defined somewhere in your code.
      const token = localStorage.getItem("Token"); // Make sure the token exists in localStorage.
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      const url = `${EngineNewUrl}/wati/getContactList/${token}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      if (json.Status === true) {
        setWatiContactList(json.data.contact_list);
      }
    } catch (err) {
      console.error("Error fetching WATI credentials: ", err);
    }
  };
  const UpdateParamsWati = async (props) => {
    try {
      // Ensure EngineNewUrl is defined somewhere in your code.
      const token = localStorage.getItem("Token"); // Make sure the token exists in localStorage.
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      const url = `${EngineNewUrl}/wati/changeparams/${token}`;
      console.log(url);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(props),
      });

      const json = await response.json();
      if (json.Status === true) {
        FetchWatiCred();
        GetMessagesWati();
        FetchContactListWati();
        setWatiSelectedContact("None");
        return true;
      }
      return false;
    } catch (err) {
      console.error("Error fetching WATI credentials: ", err);
      return true;
    }
  };
  const FetchAccessTokenFromDb = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/leadmanagement/getGoogltoken/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      console.log(json);
      if (json.Status) {
        setsheetaccessToken(json.Message);
      }
    } catch {
      setsheetaccessToken("None");
    }
  };
  const FetchPcmDetails = async () => {
    try {
      const resp = await fetch(
        `https://nexon.eazotel.com/distribution/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await resp.json();
      if (json.Status === true) {
        setPcmData(json.Details);
      }
    } catch {}
  };

  const AddandDeleteEvent = async (obj) => {
    try {
      obj["token"] = localStorage.getItem("Token");
      const url = `${EngineNewUrl}/cms/operation/Events`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const resp = await response.json();
      console.log(resp);
      if (resp.Status === true) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };

  const EditEvent = async (obj) => {
    try {
      obj["token"] = localStorage.getItem("Token");
      const url = `${EngineNewUrl}/cms/edit/Events`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const resp = await response.json();
      console.log(resp);
      if (resp.Status === true) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };
  const EditWebsiteServicesData = async (obj) => {
    try {
      obj["token"] = localStorage.getItem("Token");
      console.log(obj);
      const url = `${EngineNewUrl}/cms/edit/Services`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const resp = await response.json();
      console.log(resp);
      if (resp.Status === true) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };
  const EditWebsiteNearByData = async (obj) => {
    try {
      obj["token"] = localStorage.getItem("Token");
      const url = `${EngineNewUrl}/cms/edit/Nearby`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const resp = await response.json();
      console.log(resp);
      if (resp.Status === true) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };
  const FetchSpreadSheetFromDb = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/leadmanagement/getSheetName/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      console.log(json);
      if (json.Status) {
        setSpreadsheet(json.data);
        if (json.data.length > 0) {
          setsheetid(json.data[0].id);
          FetchSheetofSpreadSheet(json.data[0].id);
        }
      }
    } catch {
      // alert("Some Problem update token");
    }
  };

  const updateAccessTokenDb = async (gtoken) => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/leadmanagement/updategoogletokenleadmanagement`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: localStorage.getItem("Token"),
            googleToken: gtoken,
          }),
        }
      );

      const json = await response.json();

      FetchSpreadSheetFromDb();
    } catch {
      // alert("Some Problem update token");
    }
  };

  const AddSpreadSheetToDb = async (sheetid, sheetname) => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/leadmanagement/addSheetName`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: localStorage.getItem("Token"),
            hId: "",
            spreadSheetId: sheetid,
            spreadSheetName: sheetname,
          }),
        }
      );

      const json = await response.json();
      updateAccessTokenDb(sheetaccessToken);
      FetchSpreadSheetFromDb();
    } catch {
      // alert("Some Problem update token");
    }
  };

  //Sheets
  const [Sheets, setSheets] = useState([]);
  const FetchSheetofSpreadSheet = async (sheetid) => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/leadmanagement/getsheetName/${sheetid}/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      console.log(json);
      if (json.Status) {
        setSheets(json.sheets);
        if (json.sheets.length > 0) {
          setsheetid(json.sheets[0]);
          FetchSheetsDataofSpreadSheet(
            sheetid,
            json.sheets[0].properties.title
          );
        }
      } else {
        settokenExpire(true);
        setSheets([]);
      }
    } catch {
      setSheets([]);
      setsheetaccessToken("None");
      updateAccessTokenDb("None");
      // alert("Some Problem update token getsheetname");
    }
  };

  //Leads Data
  const [Leads, setLeads] = useState([]);
  const FetchSheetsDataofSpreadSheet = async (sheetid, sheetname) => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/leadmanagement/getSheetDetailLead/${localStorage.getItem(
          "Token"
        )}/${sheetid}/${sheetname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      console.log(json);
      if (json.Status) {
        setsheetNamess(sheetname);
        setsheetid(sheetid);
        setLeads(json.Message.values);
        settokenExpire(false);
      } else {
        setLeads([]);
        settokenExpire(true);
      }
    } catch {
      // alert("Some Problem update token");
    }
  };

  const headers = {
    Accept: "application/json, test/plan,/",
    "Content-Type": "application/json",
  };

  const handleGetPrice = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/price/getprice/all/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        //console.log("Error fetching", response.status)
      } else {
        const getAllPriceData = await response.json();
        if (Object.keys(getAllPriceData.Prices).length === 0) {
          // alert("emp")
          sethaveroom(false);
        } else {
          // alert("not")
          sethaveroom(true);
        }
        setPriceData(getAllPriceData.Prices);
        setNext(getAllPriceData.next);
        setPrev(getAllPriceData.prev);
      }
    } catch (err) {
      //console.log("Error", err)
    }
  };

  const handleGetInventory = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/inventory/getinventory/all/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        //console.log("Error fetching", response.status)
      } else {
        const getAllInventoryData = await response.json();
        if (Object.keys(getAllInventoryData.Inventory).length === 0) {
          // alert("emp")
          sethaveinventory(false);
        } else {
          // alert("not")
          sethaveinventory(true);
        }
        setinventoryData(getAllInventoryData.Inventory);
        setNextinventory(getAllInventoryData.next);
        setPrevinventory(getAllInventoryData.prev);
      }
    } catch (err) {
      //console.log("Error", err)
    }
  };

  const getNextPrev = async (date, operation) => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/price/getprice/all/nextprev/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            date: date,
            operation: operation,
            hId: localStorage.getItem("hotelLocationId"),
          }),
        }
      );
      if (!response.ok) {
        //console.log(`Fetching next ${response.status} `)
      } else {
        const result = await response.json();
        //console.log("---------------------", result.Prices);
        setPriceData(result.Prices);
        setNext(result.next);
        setPrev(result.prev);
      }
    } catch (err) {
      //console.log("Error:", err)
    }
  };

  const getNextPrevInventory = async (date, operation) => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/inventory/getinventory/all/nextprev/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            date: date,
            operation: operation,
          }),
        }
      );
      if (!response.ok) {
        //console.log(`Fetching next ${response.status} `)
      } else {
        const result = await response.json();
        //console.log("---------------------", result.Inventory);
        setinventoryData(result.Inventory);
        setNextinventory(result.next);
        setPrevinventory(result.prev);
      }
    } catch (err) {
      //console.log("Error:", err)
    }
  };

  const WebsiteData = async () => {
    try {
      const response = await fetch(
        `${EngineUrl}/cms/get/navbar?id=${localStorage.getItem("Token")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();
      console.log(json);
      if (json.Status === true) {
        setWebsiteAbout(json.Details.About);
        setWebsiteTeamsData(json.Details.Team);
        setPagetitles(json.Details.PagesTitles);
        setwebsiteFacilitiesData(json.Details.Facilities);
        setwebsiteHotelAdData(json.Details.HotelAdvr);
        setTermsAndCondition(json.Details.TermsConditions);
        setwebsiteFAQs(json.Details.Faq);
        setwebsiteServicesData(json.Details.Services);
        setwebsiteRestaurantData(json.Details.Menu);
        setwebsiteBlogsData(json.Details.Blogs);
        setwebsiteSeoData(json.Details.SeoOptimisation);
        setwebsiteSlugsData(json.Details.Slugs);
        setwebsiteLinksData(json.Details.Links);
        setwebsiteFooterData(json.Details.Footer);
        setwebsiteBodyImageData(json.Details.Images);
        setwebsiteBannerData(json.Details.Banner);
        setwebsiteNavbarData(json.Details.Navbar);
        setwebsiteNearbyData(json.Details.Nearby);
        setwebsiteGalleryData(json.Details.Gallery);
        setwebsiteLocationData(json.Details.Location);
        setwebsiteReviewsData(json.Details.Reviews);
        setEvents(json.Details.Events);
        setQueries(json.Queries);
        setContacts(json.Contact);
        setNewsletter(json.Newsletter);
        setDigitalCheckin(json.DigitalCheckin);
        fetchUserManagementData();
        fetchBookingDatatData();
        fetchinHouseGuestAndRoomBooked();
      }
    } catch (error) {
      // alert("Integration PROBLEM");
    }
  };

  const fetchRoomsData = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/room/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET",
          header: headers,
        }
      );

      if (!response.ok) {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      } else {
        const responseData = await response.json();
        setRoomsData(responseData.data);
      }
    } catch (error) {
      //console.log('Error:', error);
    }
  };

  const fetchBookingDatatData = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/booking/getengine/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET", // Use 'GET' method
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (json.Status) {
        setgateway(json.Details.Gateway);
        setAboutusEngine(json.Details.AboutUs);
        setCancellationPolicyEngine(json.Details.CancellationPolicy);
        setPrivacyPolicyEngine(json.Details.PrivacyPolicy);
        setTermsConditionsEngine(json.Details.TermsConditions);
        setConfirmButton(json.Details.Labels.ConfirmButton);
        setPayButton(json.Details.Labels.PayButton);
        setReserveBoard(json.Details.Labels.ReserveBoard);
        setReserveButton(json.Details.Labels.ReserveButton);
        setBackgroundColor(json.Details.Colors.BackgroundColor);
        setBoardColor(json.Details.Colors.BoardColor);
        setButtonColor(json.Details.Colors.ButtonColor);
        setBackgroundImage(json.Details.BgImage);
        fetchRazorpayData("0");
      }
    } catch (error) {
      // console.log('Error fetching data:', error);
    }
  };

  const fetchUserManagementData = async () => {
    try {
      const token = localStorage.getItem("Token");
      // console.log(token);
      const response = await fetch(
        `${EngineNewUrl}/user/${localStorage.getItem("Token")}`,
        {
          method: "GET", // Use 'GET' method
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      setuserData(responseData.data);
    } catch (error) {
      //console.log('Error fetching data:', error);
    }
  };

  const fetchBookingData = async () => {
    try {
      const bookingDataResponse = await fetch(
        `${EngineNewUrl}/booking/bookings/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (bookingDataResponse.ok) {
        const bookingData = await bookingDataResponse.json();
        //console.log("Booking Data:", bookingData);
        setBookingData(bookingData);
      } else {
        console.error("Failed to fetch booking data");
      }
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };
  const fetchinHouseGuestAndRoomBooked = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/analytics/getinhouseandtodayoccup/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      console.log(json.data);
      // console.log(json.status === true);
      if (json.status === true) {
        setinhouseGuest(json.data);
        console.log(json);
      } else {
        console.error("Error fetching check-in data:", json.Error);
      }
    } catch (error) {
      console.error("Error fetching check-in data:", error);
    }
  };

  const fetchCheckInData = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/booking/checkintoday/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (json.Status === true) {
        const checkInData = json;
        //console.log("Check-in data:", checkInData);
        setTCheckIn(checkInData);
      } else {
        console.error("Error fetching check-in data:", json.Error);
      }
    } catch (error) {
      console.error("Error fetching check-in data:", error);
    }
  };

  const fetchCheckOutData = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/booking/checkouttoday/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (json.Status === true) {
        const checkOutData = json;
        //console.log("Check-out data:", checkOutData);
        setTCheckOut(checkOutData);
      } else {
        console.error("Error fetching check-in data:", json.Error);
      }
    } catch (error) {
      console.error("Error fetching check-in data:", error);
    }
  };

  const sendFilterBookingIdRequest = async (bookingId) => {
    try {
      const response = await fetch(`${EngineNewUrl}/booking/filter/bookingid`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: localStorage.getItem("Token"),
          bookingId: bookingId,
          hId: localStorage.getItem("hotelLocationId"),
        }),
      });

      const json = await response.json();

      if (json.Status === true) {
        const filteredData = json;
        //console.log("Filtered data:", filteredData);
        setBookingData(filteredData);
      } else {
        console.error("Error filtering by booking ID:", json.Error);
      }
    } catch (error) {
      console.error("Error sending POST request:", error);
    }
  };

  const sendFilterBookingPaymentStatusRequest = async (number) => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/booking/filter/payment/${number}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: localStorage.getItem("Token"),
            hId: localStorage.getItem("hotelLocationId"),
          }),
        }
      );

      const json = await response.json();

      if (json.Status === true) {
        var NewData = {};
        const filteredData = json;
        //console.log("Filtered data:", filteredData);
        NewData.Details = filteredData.Bookings;
        setBookingData(NewData);
      } else {
        console.error("Error filtering by booking ID:", json.Error);
      }
    } catch (error) {
      console.error("Error sending POST request:", error);
    }
  };

  const sendFilterDatesRequest = async (checkinDate, checkoutDate) => {
    try {
      const response = await fetch(`${EngineNewUrl}/booking/filter/dates`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: localStorage.getItem("Token"),
          checkIn: checkinDate,
          checkOut: checkoutDate,
          hId: localStorage.getItem("hotelLocationId"),
        }),
      });

      const json = await response.json();

      if (json.Status === true) {
        const filteredData = json;
        //console.log("Filtered data:", filteredData);
        setBookingData(filteredData);
      } else {
        console.error("Error filtering dates:", json.Error);
      }
    } catch (error) {
      console.error("Error sending POST request:", error);
    }
  };

  const todaysAvaiblity = async () => {
    try {
      const bookingDataResponse = await fetch(
        `${EngineNewUrl}/booking/availablity/today/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (bookingDataResponse.ok) {
        const bookingData = await bookingDataResponse.json();
        settodayroom([bookingData.Avaiblity]);
      } else {
        console.error("Failed to fetch booking data");
      }
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };

  const MealPackagesAPI = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/mpackage/packages/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();
      //console.log(json)

      if (json.Status === true) {
        setMealpackages(json.Packages);
      }
    } catch {
      // alert("Some Problem");
    }
  };

  const AdsPackagesAPI = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/rpackage/ad/packages/${localStorage.getItem(
          "Token"
        )}/${localStorage.getItem("hotelLocationId")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();

      if (json.Status === true) {
        setAdspackages(json.Packages);
      }
    } catch {
      // alert("Some Problem");
    }
  };

  const UpdateAllFunctions = () => {
    fetchRoomsData();
    handleGetPrice();
    handleGetInventory();
    fetchBookingDatatData();
    fetchinHouseGuestAndRoomBooked();
    fetchUserManagementData();
    MealPackagesAPI();
    AdsPackagesAPI();
    todaysAvaiblity();
    fetchCheckOutData();
    fetchCheckInData();
    fetchBookingData();
    WebsiteData();
  };

  //MULTI-LOCATIONS API
  const [CurrentLocation, setCurrentLocation] = useState("None");
  const [Locations, setLocations] = useState([]);
  const GetAllLocations_hotel = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/multilocation/findlocations/dashboard/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();

      if (json.Status === true) {
        setLocations(json.Locations);
        //console.log(json.Locations[0].hId)
        localStorage.setItem("hotelLocationId", json.Locations[0].hId);
        setCurrentLocation(json.Locations[0].hId);
        UpdateAllFunctions();
      } else {
        //console.log("False")
      }
    } catch {
      alert("Some Problem");
    }
  };

  // Get Dinabite Access Token
  const [DinabiteToken, setDinabiteToken] = useState("None");
  const [DinabiteUser, setDinabiteUser] = useState({});
  const [CompanyId, setCompanyId] = useState();
  const [FacebookPageId, setFacebookPageId] = useState("");
  //Facebook
  const [isFacebookConnected, setisFacebookConnected] = useState(false);
  const [isGoogleConnected, setisGoogleConnected] = useState(false);
  const [FacebookUserId, setFacebookUserId] = useState("None");
  const [dinabiteFacebookPages, setdinabiteFacebookPages] = useState([]);
  const [FacebookConnectedPage, setFacebookConnectedPage] = useState("None");
  const [DinabiteReviews, setDinabiteReviews] = useState([]);

  const CheckDinabiteToken_inDb = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/dinabite/${localStorage.getItem("Token")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();
      // console.log(json);

      if (json.Status === true) {
        // console.log(json)
        setDinabiteToken(json.access_token);
        DinabiteUserInformation(json.access_token);
      } else {
        setDinabiteToken("None");
      }
    } catch {
      alert("Some Problem");
    }
  };

  const UpdateDinabiteToken_inDb = async (access_token) => {
    try {
      const response = await fetch(`${EngineNewUrl}/dinabite/update`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: localStorage.getItem("Token"),
          access_token: access_token,
        }),
      });

      const json = await response.json();
      console.log(json);
    } catch {
      // alert("Some Problem update token");
    }
  };

  const DinabiteUserInformation = async (token) => {
    try {
      const response = await fetch(`${dinabiteURL}/users/me`, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": dinabite_secret_token,
        },
      });

      const json = await response.json();
      console.log(json);
      if (json.id) {
        json.companies.forEach((company) => {
          setCompanyId(company.id);
          company.tools.forEach((tool) => {
            if (tool.type === "facebook") {
              // console.log("Facebook type is present!");
              setisFacebookConnected(true);
              setFacebookConnectedPage(tool.pageId);
              fetchDinabiteMessages(token);
              fetchDinabitePosts(token);
              fetchDinabiteReviews(token, company.id);
            }
          });
        });

        setDinabiteUser(json);
      } else {
        alert("User is not Valid");
        UpdateDinabiteToken_inDb("None");
        setDinabiteToken("None");
      }
    } catch {
      alert("Some Problem");
    }
  };

  const DinabiteRegisterAPI = async (emailId, auth_token) => {
    try {
      const response = await fetch(`${dinabiteURL}/auth/account-google`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
          "x-api-key": dinabite_secret_token,
        },
        body: JSON.stringify({
          tokenId: auth_token,
        }),
      });
      const json = await response.json();
      console.log(json);
      if (json.register) {
        // alert("Need to Register the user")
        RegisterToDinabiteManual(emailId, auth_token);
      } else {
        // alert("User already registered")
        // console.log(json.access_token)
        setDinabiteToken(json.access_token);
        UpdateDinabiteToken_inDb(json.access_token);
        DinabiteUserInformation(json.access_token);
        fetchDinabiteMessages(json.access_token);
        fetchDinabitePosts(json.access_token);
      }
    } catch {
      alert("Some Problem Occured with Social Media Setup");
    }
  };

  const RegisterToDinabiteManual = async (emailId, auth_token) => {
    try {
      const response = await fetch(`${dinabiteURL}/companies/company-user`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
          "x-api-key": dinabite_secret_token,
        },
        body: JSON.stringify({
          name: userProfile.hotelName,
          phone: userProfile.hotelPhone,
          phoneCode: "+91",
          country: userProfile.hoteladdress.country,
          address: userProfile.hoteladdress.city,
          city: userProfile.hoteladdress.city,
          zipCode: userProfile.hoteladdress.pinCode,
          webLink: "www.dinabite.ai",
          facebookAdId: "string",
          email: emailId,
          firstName: userProfile.userName,
          lastName: userProfile.userName,
          accountType: "SOCIAL",
        }),
      });

      const json = await response.json();
      if (json.statusCode) {
        alert("Setup Again Please");
      } else {
        DinabiteRegisterAPI(emailId, auth_token);
      }
    } catch {
      alert("Some Problem");
    }
  };

  //Facebook API
  const FacebookConnect = async (facebooktoken, token) => {
    try {
      const response = await fetch(`${dinabiteURL}/tools/facebook-connect`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": dinabite_secret_token,
        },
        body: JSON.stringify({
          userToken: facebooktoken,
        }),
      });

      const json = await response.json();
      console.log(json);
      if (json.statusCode) {
        alert("Some Problem with facebook-connect api");
      } else {
        setdinabiteFacebookPages(json.pages);
        setFacebookUserId(json.facebookUserId);
      }
    } catch {
      alert("Some Problem Occured with Social Media Setup");
    }
  };

  const FacebookSpecificPageAPI = async (object) => {
    const updatedObject = {
      ...object,
      facebookUserId: FacebookUserId,
    };
    console.log({ pageData: updatedObject });
    try {
      const response = await fetch(`${dinabiteURL}/tools/facebook-specific`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
          Authorization: `Bearer ${DinabiteToken}`,
          "x-api-key": dinabite_secret_token,
        },
        body: JSON.stringify({
          pageData: updatedObject,
        }),
      });

      const json = await response.json();
      console.log(json);
      if (json.statusCode) {
        alert("Some Problem with facebook-specific api");
      } else {
        FacebookAssignToolAPI(json);
      }
    } catch {
      alert("Some Problem Occured with Social Media Setup");
    }
  };

  const FacebookAssignToolAPI = async (object) => {
    let data = {
      companyId: CompanyId,
      tool: {
        type: "facebook",
        accessToken: object.access_token,
        pageId: object.id,
        pageName: object.name,
        userId: object.facebookUserId,
        adAccountId: null,
      },
    };

    try {
      const response = await fetch(`${dinabiteURL}/companies/assign-tool`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
          Authorization: `Bearer ${DinabiteToken}`,
          "x-api-key": dinabite_secret_token,
        },
        body: JSON.stringify(data),
      });

      const json = await response.json();
      console.log(json);
      if (json.statusCode) {
        alert("Some Problem with facebook-specific api");
      } else {
        DinabiteUserInformation(DinabiteToken);
      }
    } catch {
      alert("Some Problem Occured with Social Media Setup");
    }
  };

  //Google API
  const GoogleConnect = async (code, tokenid) => {
    try {
      const response = await fetch(
        `${dinabiteURL}/tools/google-business-account/`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
            Authorization: `Bearer ${DinabiteToken}`,
            "x-api-key": dinabite_secret_token,
          },
          body: JSON.stringify({
            code: code,
          }),
        }
      );

      const json = await response.json();
      console.log(json);
      if (json.statusCode) {
        const object = {
          data: {
            accounts: {
              accounts: [
                {
                  name: "accounts/1054951481570",
                  accountName: "John Doe",
                  type: "PERSONAL",
                  verificationState: "UNVERIFIED",
                  vettedState: "NOT_VETTED",
                },
              ],
            },
            credentials:
              '{"access_token":"ya29.a0AfB_f1s-9CiUmsWgcQ9nSM-3qpIDu0qwJs6B_pEg-l_UfkSPQ31ZY-EbIfDo73rzegAMWdNjo9Ta1ubtia","refresh_token":"1//03j47B22p3W8kCgYIARAAGAMSNwF-L9IrZWtv6EJsSrKFDxeUE8oIWkrzy8lJKVljaGlo4n1WcmplVZoEQk92_yKZe7fz66BtBcA","scope":"https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/business.manage openid","token_type":"Bearer","id_token":"eyJhbGciOiJSUzI1NiIsImtpZCI6.eyJpc3MiO9sW.jQnzv8v-Jjvc_O7WHHP0Uoensm03Pc4PQWFBvSFDqnMwiW-c9yIiwshZUM1-1tJKZ9eqEXWgFcKQDM_Cp2g","expiry_date":1692631160633}',
          },
        };
        GoogleSpecificPageAPI(object, tokenid);
      } else {
        GoogleSpecificPageAPI(json, tokenid);
      }
    } catch {
      alert("Some Problem Occured with Social Media Setup");
    }
  };

  const GoogleSpecificPageAPI = async (object, tokenid) => {
    const data = {
      tokenId: tokenid,
      credentials: object.data.credentials,
    };

    console.log(data);
    try {
      const response = await fetch(
        `${dinabiteURL}/tools/google-business-specific`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
            Authorization: `Bearer ${DinabiteToken}`,
            "x-api-key": dinabite_secret_token,
          },
          body: JSON.stringify(data),
        }
      );

      const json = await response.json();
      console.log(json);
    } catch {
      alert("Some Problem Occured with Social Media Setup");
    }
  };

  const GoogleAssignToolAPI = async (object) => {
    let data = {
      companyId: CompanyId,
      tool: {
        type: "google",
        accessToken: object.access_token,
        pageId: object.id,
        pageName: object.name,
        userId: object.facebookUserId,
        adAccountId: null,
      },
    };

    try {
      const response = await fetch(`${dinabiteURL}/companies/assign-tool`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
          Authorization: `Bearer ${DinabiteToken}`,
          "x-api-key": dinabite_secret_token,
        },
        body: JSON.stringify(data),
      });

      const json = await response.json();
      console.log(json);
      if (json.statusCode) {
        alert("Some Problem with facebook-specific api");
      } else {
        DinabiteUserInformation(DinabiteToken);
      }
    } catch {
      alert("Some Problem Occured with Social Media Setup");
    }
  };

  //Messages from Social Accounts
  const [dinabiteMessages, setdinabiteMessages] = useState([]);
  const fetchDinabiteMessages = async (token) => {
    // alert("Fetching Message")
    try {
      const response = await fetch(`${dinabiteURL}/tools/messages`, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": dinabite_secret_token,
        },
      });

      const json = await response.json();
      // console.log(json)
      if (json.statusCode) {
        alert("Token might be expired");
      } else {
        setdinabiteMessages(json);
      }
    } catch {
      alert("Some Problem");
    }
  };

  const DinabiteMessagesReply = async (type, senderId, message) => {
    const response = await fetch(
      `${dinabiteURL}/tools/messages/${senderId}/reply`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
          Authorization: `Bearer ${DinabiteToken}`,
          "x-api-key": dinabite_secret_token,
        },
        body: JSON.stringify({
          message: message,
          type: type,
        }),
      }
    );
    // const json = await response.json();
    // console.log(json);
    alert(message);
    fetchDinabiteMessages(DinabiteToken);
    // } catch {
    //   alert("Some Problem Occured with Social Media Setup");
    // }
  };

  //Posts Posted from social Account
  const [dinabitePosts, setdinabitePosts] = useState([]);
  const fetchDinabitePosts = async (token) => {
    // alert("Fetching Message")
    try {
      const response = await fetch(`${dinabiteURL}/posts`, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": dinabite_secret_token,
        },
      });

      const json = await response.json();
      // console.log(json)
      if (json.statusCode) {
        alert("Token might be expired");
      } else {
        setdinabitePosts(json);
      }
    } catch {
      alert("Some Problem");
    }
  };

  //Posts Schedules for social Account
  const [dinabiteScheduled, setdinabiteScheduled] = useState([]);

  //Reviews Fetch
  const fetchDinabiteReviews = async (token, compid) => {
    try {
      const response = await fetch(`${dinabiteURL}/tools/reviews/${compid}`, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": dinabite_secret_token,
        },
      });

      const json = await response.json();
      console.log(json);
      if (json.statusCode) {
        alert("Token might be expired");
      } else {
        setDinabiteReviews(json.reviews);
      }
    } catch {
      alert("Some Problem");
    }
  };

  const LogoutDinabiteUser = async () => {
    setDinabiteUser();
    setDinabiteToken("None");
    UpdateDinabiteToken_inDb("None");
    setCompanyId();
    setisFacebookConnected(false);
    setFacebookUserId("None");
    setdinabiteFacebookPages([]);
    setdinabiteMessages([]);
    setdinabitePosts([]);
    setdinabiteScheduled([]);
  };

  const RedirectDinabitetool = async () => {
    if (DinabiteToken === "None") {
      alert("/dashboard/client/socialmedi/tools");
    }
  };

  //Razorpay
  const [razorpayData, setrazorpayData] = useState([]);
  const fetchRazorpayData = async (skip) => {
    try {
      const response = await fetch(`${razorpayURL}/razorpay/v1/payments`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, /",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: localStorage.getItem("Token"),
          hId: localStorage.getItem("hotelLocationId"),
          skip: skip,
        }),
      });

      const json = await response.json();
      // console.log(json)
      if (json.status) {
        setrazorpayData(json.Details.items);
      }
    } catch {
      // alert("Some Problem update token");
    }
  };
  const fetchByOrderid = async (orderId) => {
    try {
      const response = await fetch(
        `${razorpayURL}/razorpay/v1/filtered/orders`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: localStorage.getItem("Token"),
            hId: localStorage.getItem("hotelLocationId"),
            orderid: orderId,
          }),
        }
      );

      const json = await response.json();
      // console.log(json)
      if (json.status) {
        console.log(json.Details);
        setrazorpayData([json.Details]);
      } else {
        setrazorpayData([]);
      }
    } catch {
      // alert("Some Problem update token");
    }
  };
  const fetchByPaymentid = async (paymentId) => {
    try {
      const response = await fetch(
        `${razorpayURL}/razorpay/v1/filtered/payments`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: localStorage.getItem("Token"),
            hId: localStorage.getItem("hotelLocationId"),
            payid: paymentId,
          }),
        }
      );

      const json = await response.json();
      // console.log(json)
      if (json.status) {
        console.log(json.Details);
        setrazorpayData([json.Details]);
      } else {
        setrazorpayData([]);
      }
    } catch {
      // alert("Some Problem update token");
    }
  };

  // Lone Star
  const [LoneStarBookings, setLoneStarBookings] = useState([]);
  const [LoginUsers, setLoginUsers] = useState([]);
  const [nextDate, setnextDate] = useState();
  const [prevDate, setprevDate] = useState();
  function CurrentDate() {
    const currentDate = new Date();

    // Get year, month, and day
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
  const SetRangeValue = async (obj) => {
    try {
      let token = localStorage.getItem("Token");
      const url = `${EngineNewUrl}/sports/bulkUpdatePrice/${token}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const resp = await response.json();
      console.log(resp);
      if (resp.Status === true) {
        fetchAllSlotsPriceMgmtforDifferentDates(CurrentDate());
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };

  const GetAllSports = async () => {
    try {
      const url = `${EngineUrl}/sports/getSportsDashboard/${localStorage.getItem(
        "Token"
      )}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      console.log(json);
      if (json.Status === true) {
        setSports(json.data);
      }
    } catch (err) {
      console.error("Error fetching", err);
    }
  };

  const GetAllUsers = async () => {
    try {
      const url = `${EngineUrl}/sports/${localStorage.getItem("Token")}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      console.log(json);
      if (json.Status === true) {
        setUsers(json.Details);
      }
    } catch (err) {
      console.error("Error fetching ", err);
    }
  };

  const GetAllUsersLogs = async () => {
    try {
      const url = `${EngineUrl}/sports/getAllUsersSports/${localStorage.getItem(
        "Token"
      )}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      console.log(json);
      if (json.Status === true) {
        setLoginUsers(json.Data);
      }
    } catch (err) {
      console.error("Error fetching ", err);
    }
  };

  const fetchLoneStarBookings = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/sports/getAllBooking/${localStorage.getItem("Token")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();
      // console.log(json)
      if (json.Status) {
        setLoneStarBookings(json.data);
      } else {
        setLoneStarBookings([]);
      }
    } catch {
      // alert("Some Problem update token");
    }
  };

  const fetchLoneStarThisMonthBookings = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/sports/getAllBookingMonth/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();
      // console.log(json)
      if (json.Status) {
        setLoneStarBookings(json.data);
      } else {
        setLoneStarBookings([]);
      }
    } catch {
      // alert("Some Problem update token");
    }
  };

  const fetchDateRangeBookings = async (fromdate, todate) => {
    try {
      const response = await fetch(
        `${razorpayURL}/sports/getAllBookingDateRange/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fromDate: fromdate,
            toDate: todate,
          }),
        }
      );

      const json = await response.json();
      // console.log(json)
      if (json.Status) {
        setLoneStarBookings(json.data);
      } else {
        setLoneStarBookings([]);
      }
    } catch {
      // alert("Some Problem update token");
    }
  };

  const fetchLoneStarBookingIdBookings = async (bookingId) => {
    try {
      const response = await fetch(
        `${razorpayURL}/sports/getParticularBooking/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sportBookingId: bookingId,
          }),
        }
      );

      const json = await response.json();
      // console.log(json)
      if (json.Status) {
        setLoneStarBookings([json.data]);
      } else {
        setLoneStarBookings([]);
      }
    } catch {
      // alert("Some Problem update token");
    }
  };

  const fetchLoneStarBookingPaymentstatusBookings = async (status) => {
    try {
      if (status == 1) {
        var statusname = "ADVANCED";
      } else {
        var statusname = "SUCCESS";
      }

      const response = await fetch(
        `${EngineNewUrl}/sports/getAllBookingPaymentStatus/${localStorage.getItem(
          "Token"
        )}/${statusname}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();
      // console.log(json)
      if (json.Status) {
        setLoneStarBookings(json.data);
      } else {
        setLoneStarBookings([]);
      }
    } catch {
      // alert("Some Problem update token");
    }
  };
  const [Apiresponse, setapiresponse] = useState([]);
  const fetchAllSlotsPriceMgmt = async (today, yesterday, tommorow) => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/sports/getEightPrevAfter/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: today,
          }),
        }
      );

      const json = await response.json();
      console.log(json);
      if (json.Status) {
        setapiresponse(json.data);
        setnextDate(tommorow);
        setprevDate(yesterday);
      } else {
      }
    } catch {
      // alert("Some Problem update token");
    }
  };

  function getDateRange(onedate) {
    const currentDate = new Date(onedate);

    // Get provided date
    const providedYear = currentDate.getFullYear();
    const providedMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const providedDay = String(currentDate.getDate()).padStart(2, "0");
    const providedDate = `${providedYear}-${providedMonth}-${providedDay}`;

    // Get previous date
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    const previousYear = previousDate.getFullYear();
    const previousMonth = String(previousDate.getMonth() + 1).padStart(2, "0");
    const previousDay = String(previousDate.getDate()).padStart(2, "0");
    const previous = `${previousYear}-${previousMonth}-${previousDay}`;

    // Get next date
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1);
    const nextYear = nextDate.getFullYear();
    const nextMonth = String(nextDate.getMonth() + 1).padStart(2, "0");
    const nextDay = String(nextDate.getDate()).padStart(2, "0");
    const next = `${nextYear}-${nextMonth}-${nextDay}`;

    return {
      providedDate: providedDate,
      previousDate: previous,
      nextDate: next,
    };
  }
  const fetchAllSlotsPriceMgmtforDifferentDates = async (date) => {
    let details = getDateRange(date);
    try {
      const response = await fetch(
        `${EngineNewUrl}/sports/getEightPrevAfter/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: details.providedDate,
          }),
        }
      );

      const json = await response.json();
      console.log(json);
      if (json.Status) {
        setapiresponse(json.data);
        setnextDate(details.nextDate);
        setprevDate(details.previousDate);
      } else {
      }
    } catch {
      // alert("Some Problem update token");
    }
  };
  const [TodaysBookings, setTodaysBookings] = useState(0);
  const fetchAllTodaysBookings = async () => {
    try {
      const response = await fetch(
        `${EngineNewUrl}/sports/getTodayBooking/${localStorage.getItem(
          "Token"
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, /",
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();
      console.log(json);
      if (json.Status) {
        setTodaysBookings(json.data);
      } else {
      }
    } catch {
      // alert("Some Problem update token");
    }
  };
  return (
    <AuthContext.Provider
      value={{
        baseUrl,
        EngineUrl,
        EngineNewUrl,
        joditConfig,
        WebsiteData,
        fetchUserManagementData,
        fetchBookingDatatData,
        fetchinHouseGuestAndRoomBooked,
        todaysAvaiblity,
        Auth,
        setAuth,
        popup,
        setPopup,
        Home,
        setHome,
        TemplateNumber,
        setTemplateNumber,
        About,
        setAbout,
        Contact,
        setContact,
        HotelAds,
        setHotelAds,
        Terms,
        setTerms,
        Service,
        setService,
        Restaurant,
        setRestaurant,
        Gallery,
        setGallery,
        Testimonial,
        setTestimonial,
        Teams,
        setTeams,
        Blogs,
        setBlogs,
        Menu,
        setMenu,
        Facility,
        setFacility,
        Nearby,
        setNearby,
        //data
        websiteAbout,
        setWebsiteAbout,
        websiteTeamsData,
        setWebsiteTeamsData,
        websiteFacilitiesData,
        setwebsiteFacilitiesData,
        websiteHotelAdData,
        setwebsiteHotelAdData,
        websiteFAQs,
        setwebsiteFAQs,
        websiteServicesData,
        setwebsiteServicesData,
        websiteRestaurantData,
        setwebsiteRestaurantData,
        websiteBlogsData,
        setwebsiteBlogsData,
        websiteSeoData,
        setwebsiteSeoData,
        websiteLinksData,
        setwebsiteLinksData,
        websiteFooterData,
        setwebsiteFooterData,
        websiteBodyImageData,
        setwebsiteBodyImageData,
        websiteBannerData,
        setwebsiteBannerData,
        websiteNavbarData,
        setwebsiteNavbarData,
        websiteNearbyData,
        setwebsiteNearbyData,
        websiteGalleryData,
        setwebsiteGalleryData,
        websiteLocationData,
        setwebsiteLocationData,
        websiteSlugsData,
        setwebsiteSlugsData,
        userData,
        setuserData,
        isEditpPop,
        setIsEditPop,
        userEditdata,
        setUserEditData,
        websiteReviewsData,
        setwebsiteReviewsData,
        EditEvent,
        todayroom,
        settodayroom,

        // alert popup

        alertMessage,
        setAlertMessage,
        isAlertOpen,
        setIsAlertOpen,
        addSlugPopup,
        setAddSlugPopup,

        // user profile data
        userProfile,
        setUserProfile,
        userLinks,
        setUserLinks,
        userPlan,
        setUserPlan,
        userAccess,
        setUserAccess,
        isAdmin,
        setIsadmin,
        OperationServices,
        //Bookingengine
        BackgroundColor,
        setBackgroundColor,
        BoardColor,
        setBoardColor,
        ButtonColor,
        setButtonColor,

        //Label
        ConfirmButton,
        setConfirmButton,
        PayButton,
        setPayButton,
        ReserveBoard,
        setReserveBoard,
        ReserveButton,
        setReserveButton,
        SetRangeValue,
        //Backgroud
        BackgroundImage,
        setBackgroundImage,

        //Content
        AboutusEngine,
        setAboutusEngine,
        CancellationPolicyEngine,
        setCancellationPolicyEngine,
        PrivacyPolicyEngine,
        setPrivacyPolicyEngine,
        TermsConditionsEngine,
        setTermsConditionsEngine,

        //Information Popup

        openInfoPopUp,
        setOpenInfoPopUp,
        openInfoPopUpII,
        setOpenInfoPopUpII,
        infoData,
        setInfoData,
        imgUrl,
        fetchBookingData,
        setBookingData,
        bookingData,
        fetchCheckInData,
        setTCheckIn,
        tCheckIn,
        inhouseGuest,
        setinhouseGuest,
        roomBookedAndTotal,
        setroomBookedAndTotal,
        fetchCheckOutData,
        setTCheckOut,
        tCheckOut,
        sendFilterBookingIdRequest,
        sendFilterDatesRequest,
        sendFilterBookingPaymentStatusRequest,
        handleGetPrice,
        getNextPrev,
        getNextPrevInventory,

        cookies,
        setCookies,
        roomDetail,
        setRoomDetail,
        editingData,
        setEditingData,
        slugEditingData,
        setSlugEditingData,
        RoomsData,
        setRoomsData,
        selectedFacilities,
        setSelectedFacilities,
        priceData,
        setPriceData,
        next,
        setNext,
        prev,
        setPrev,
        haveroom,
        sethaveroom,
        fetchByOrderid,
        fetchByPaymentid,

        Cms,
        setCms,
        bookingEngine,
        setbookingEngine,
        socialMedia,
        setsocialMedia,
        reservationDesk,
        setreservationDesk,
        frontDesk,
        setfrontDesk,
        channelManager,
        setchannelManager,
        seoManager,
        setseoManager,
        foodManager,
        setfoodManager,
        EditWebsiteNearByData,
        themes,
        setthemes,
        gatewayManager,
        setgatewayManager,
        gateway,
        setgateway,
        fetchRazorpayData,
        razorpayData,
        Queries,
        setQueries,
        Contacts,
        setContacts,
        Newsletter,
        setNewsletter,
        DigitalCheckin,
        setDigitalCheckin,

        //packages
        Mealpackages,
        setMealpackages,
        Adspackages,
        setAdspackages,
        MealPackagesAPI,
        AdsPackagesAPI,

        // Dinabite Token
        DinabiteToken,
        setDinabiteToken,
        DinabiteUser,
        setDinabiteUser,
        CheckDinabiteToken_inDb,
        LogoutDinabiteUser,
        RedirectDinabitetool,
        DinabiteRegisterAPI,
        DinabiteUserInformation,

        UpdateAllFunctions,
        CurrentLocation,
        Locations,
        setCurrentLocation,
        setLocations,
        GetAllLocations_hotel,
        fetchRoomsData,
        handleGetInventory,
        inventoryData,
        setinventoryData,
        haveinventory,
        sethaveinventory,
        nextinventory,
        setNextinventory,
        previnventory,
        setPrevinventory,
        EditTermAndCondition,
        CompanyId,
        setCompanyId,
        FacebookUserId,
        dinabiteFacebookPages,
        setdinabiteFacebookPages,
        FacebookConnect,
        FacebookConnectedPage,
        FacebookSpecificPageAPI,
        isFacebookConnected,
        isGoogleConnected,
        dinabiteMessages,
        setdinabiteMessages,
        fetchDinabiteMessages,
        dinabitePosts,
        setdinabitePosts,
        fetchDinabitePosts,
        dinabiteScheduled,
        setdinabiteScheduled,
        DinabiteReviews,
        setDinabiteReviews,
        fetchDinabiteReviews,
        DinabiteMessagesReply,
        GoogleConnect,
        Leads,
        Sheets,
        Spreadsheet,
        AddSpreadSheetToDb,
        FetchSpreadSheetFromDb,
        sheetaccessToken,
        setsheetaccessToken,
        sheetid,
        setsheetid,
        setSheets,
        FetchSheetofSpreadSheet,
        updateAccessTokenDb,
        FetchSheetsDataofSpreadSheet,
        FetchAccessTokenFromDb,
        FetchWatiCred,
        FetchContactListWati,
        UpdateParamsWati,
        setWatiSelectedContact,
        SendWatiSessionMessage,
        setWatiSelectedContactMessage,
        sendTemplateMessageWati,
        watiSelectedContactMessage,
        watiSelectedContact,
        GetMessagesWati,
        AddWatiCred,
        watiCred,
        templateMessagesWati,
        FetchTemplateMessagesWati,
        AddWatiContact,
        watiContactList,
        tokenExpire,
        settokenExpire,
        setLeads,
        sheetNamess,
        setsheetNamess,
        FetchPcmDetails,
        pagetitles,
        termsAndCondition,
        setTermsAndCondition,
        setPagetitles,
        // ADMIN DASHBOARD
        isEazotelAdmin,
        setisEazotelAdmin,
        AllClients,
        EditPageTitle,
        setAllClients,
        FetchAllEazotelClients,
        ClientsCount,
        setClientsCount,
        FetchAllAdmins,
        AllAdmins,
        setAllAdmins,
        AdminsCount,
        setAdminsCount,
        AllUsers,
        setAllUsers,
        UsersCount,
        setUsersCount,
        FetchAllUsers,
        events,
        setEvents,
        AddandDeleteEvent,
        EditWebsiteServicesData,
        AllQueries,
        setAllQueries,
        QueriesCount,
        setQueriesCount,
        FetchAllQueries,

        //Lone Star
        isEazotelSports,
        setisEazotelSports,
        Sports,
        setSports,
        GetAllSports,
        GetAllUsers,
        Users,
        setUsers,
        SportsUserMgmt,
        setSportsUserMgmt,
        LoneStarBookings,
        setLoneStarBookings,
        fetchLoneStarBookings,
        fetchLoneStarThisMonthBookings,
        fetchDateRangeBookings,
        fetchLoneStarBookingIdBookings,
        fetchLoneStarBookingPaymentstatusBookings,
        GetAllUsersLogs,
        LoginUsers,
        setLoginUsers,
        fetchAllSlotsPriceMgmt,
        nextDate,
        setnextDate,
        fetchAllSlotsPriceMgmtforDifferentDates,
        prevDate,
        setprevDate,
        Apiresponse,
        setapiresponse,
        fetchAllTodaysBookings,
        TodaysBookings,
        setTodaysBookings,
        BlockSlots,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
