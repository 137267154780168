import React from 'react'
import { useContext,useState } from 'react'
import AuthContext from '../Context/AuthProvider'
import { GrClose } from "react-icons/gr"
import '../Style/ReservDeskInfoPopup.css'
import "../Style/AddUserPopUp.css"

const InfoPopUpII = ({setlocationpopup}) => {

    const {Locations,GetAllLocations_hotel,EngineNewUrl} = useContext(AuthContext)
    const [Name,setName] = useState("")
    const [pinCode,setpinCode]=useState("")

    const deleteLocation = async(locationid)=>{
        try{
            const response = await fetch(`${EngineNewUrl}/multilocation/deletelocations/dashboard`, {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, /",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(
                {
                "Token":localStorage.getItem('Token'),
                "hId":locationid
                }
            )
            });
        
            const json = await response.json();
            console.log(json)
        
            if (json.Status === true) {
                GetAllLocations_hotel()
            }
            else{
                alert("Loactions api")
            }
        }
        catch{
            alert("Some Problem")
        }
    }
    const addLocation = async()=>{
        if(Name==="" || pinCode===""){
            alert("Please fill the feilds")
        }
        else{
            try{
                const response = await fetch(`${EngineNewUrl}/multilocation/addlocations/dashboard`, {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, /",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    {
                    "Token":localStorage.getItem('Token'),
                    "Name":Name,
                    "pincode":pinCode
                    }
                )
                });
            
                const json = await response.json();
                console.log(json)
            
                if (json.Status === true) {
                    GetAllLocations_hotel()
                    setName("")
                    setpinCode("")
                }
                else{
                    alert("Loactions api")
                }
            }
            catch{
                alert("Some Problem")
            }
    }
    }
    return (
        <div className='overlayStyle'>
            <div className='w-100 h-100' >
                <div style={{ position: "fixed", top: "10%", left: "25%", zIndex: 1, width: "950px", }}>
                    <div className='h-100 shadow mb-1 bg-body rounded popUpBG'>
                        <div style={{ display: "flex", position: 'relative', justifyContent: "end", }}>
                            <GrClose className='closeIcon' onClick={()=>{setlocationpopup(false)}} />
                        </div>

                        <div className="row rounded text-white" style={{ marginTop: '-1.8rem', backgroundColor: '#0A3A75' }} >
                            <div className="col py-2 text-center">
                                Locations
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col p-1">
                                <div>
                                        <h5 style={{textAlign:"center"}}>Add Location</h5>
                                        <div className='row w-100'>
                                            <div className='col-md-6'>
                                                <input type='text' style={{width:"100%"}} className='fromdata' placeholder='Name' value={Name} onChange={(e)=>{setName(e.target.value)}}  />

                                            </div>
                                            <div className='col-md-6'>
                                                <input type='email' style={{width:"100%"}} className='fromdata' placeholder='pincode' value={pinCode} onChange={(e)=>{setpinCode(e.target.value)}} />

                                            </div>
                                        </div>
                                        <div className='row w-100' style={{justifyContent:"center"}}>
                                            <button className='m-1' style={{width:"20%",height:"20%"}} onClick={()=>{addLocation()}}>Add</button>
                                        </div>
                                </div>
                                <hr />
                                <table className="table">
                                    <tbody>
                                        {Locations.map((location)=>{
                                            return <tr>
                                                        <th>{location.hId}</th>
                                                        <td>{location.location}, {location.pinCode} {Locations.length>1?<span style={{"float":"right","cursor":"pointer","fontSize":"20px"}} onClick={()=>{deleteLocation(location.hId)}}><i class="fa fa-trash"></i></span>:""}</td>
                                                    </tr>
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoPopUpII