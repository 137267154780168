import React from 'react'
import Nav from 'react-bootstrap/Nav';

import { Link } from 'react-router-dom';

function PostFilters() {
    return (
        <div className='postBtn'>
            <Nav variant="tabs" defaultActiveKey="#first" className='s-nav'>
                <Nav.Item>
                    <Link to="/dashboard/client/Socialmedia/post" className='pf-btn'>Previous</Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/dashboard/client/Socialmedia/post/scheduled" className='pf-btn'>Scheduled</Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/dashboard/client/Socialmedia/post/newpost" className='pf-btn'>New Post +</Link>
                </Nav.Item>
            </Nav>
        </div>
    )
}

export default PostFilters
